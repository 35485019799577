import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { offlineUser } from '../../../../../../config/offline';
import { formatDistanceJsonDate, formatJsonDate, UserNameLabel } from '../../../../../../shared';
import { IconWrapper } from '../../../../../../theme';
import { IconFlag, IconTime, IconTrain } from '../../../../../../theme/assets/img';
import { isTrainBinderType } from '../../../../../binder/utils/binder-type';
import { ReactComponent as IconOwner } from '../../../../assets/icon-owner.svg';
import { dateFormatDate, dateFormatTime } from '../../../../utils';

/**
 * @returns {JSX.Element}
 */
const ProcessInformationHeader = ({ processInfo = {}, processHistory, trainId }) => {
	const { t } = useTranslation();
	const { started_at } = processInfo;

	const isTrainBinder = isTrainBinderType(processHistory?.[0]?.binder?.type);
	const lastHistoryTask =
		(Array.isArray(processHistory) && processHistory?.[processInfo?.history?.length - 1]) || {};
	const startDate = formatJsonDate(started_at, dateFormatDate);
	const startTime = formatJsonDate(started_at, dateFormatTime);
	const duration = formatDistanceJsonDate(started_at, lastHistoryTask?.finishedAt || new Date());

	const getFirstUser = (taskHistory = []) => {
		const [firstTask = {}] = taskHistory;
		return firstTask?.userInfo || {};
	};

	const firstUser = getFirstUser(processHistory);

	const getOwnerInfo = () => {
		const { owner, owner_firstname, owner_lastname, owner_id } = processInfo;
		return {
			id: owner_id,
			firstname: owner_firstname,
			lastname: owner_lastname,
			tech_id: owner,
		};
	};

	const processOwnerInfo = getOwnerInfo();
	const isProcessPartOffline = processHistory.some(
		(task) => task.finishedBy === offlineUser.tech_id
	);

	return (
		<div className="process-history-view__header">
			<div className="header__start">
				<IconWrapper className="header__start__icon" Component={IconFlag} />
				<span className="header__start__label">{`${t('process:history-view.start')} :`}</span>
				<span className="header__start__date">{startDate}</span>
				<span className="header__start__time">{startTime}</span>
			</div>
			{isTrainBinder && (
				<div className="header__train">
					<IconWrapper className="header__train__icon" Component={IconTrain} />
					<span className="header__train__label">{`${t('process:history-view.train')} :`}</span>
					<span className="header__train__value">{trainId || '-'}</span>
				</div>
			)}
			<div className="header__owner">
				<IconWrapper className="header__owner__icon" Component={IconOwner} />
				{processOwnerInfo?.tech_id && (
					<span className="header__owner__label">
						{`${isProcessPartOffline ? t('process:history-view.publisher') : t('process:history-view.owner')} :`}
					</span>
				)}
				{!processOwnerInfo?.tech_id && (
					<span className="header__owner__label">{t('process:history-view.not-sync')}</span>
				)}
				{processOwnerInfo.tech_id && (
					<span className="header__owner__value">
						<UserNameLabel user={isProcessPartOffline ? processOwnerInfo : firstUser} />
					</span>
				)}
			</div>
			<div className="header__duration">
				<IconWrapper className="header__duration__icon" Component={IconTime} />
				<span className="header__duration__label">{`${t('process:history-view.duration')} :`}</span>
				<span className="header__duration__value">{duration}</span>
			</div>
		</div>
	);
};

export default ProcessInformationHeader;

ProcessInformationHeader.propTypes = {
	processInfo: PropTypes.object,
	processHistory: PropTypes.array,
	trainId: PropTypes.string,
};
