import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './text-template-form.scss';

import { useDebouncedAction } from '../../../../../../../../shared';
import { Editor } from '../../../../../../../../theme';
import { defaultEditorToolbarConfig } from '../../../../../..';
import { useSheetContentContext } from '../../../../../../context/sheet-content-context';
import AppendixForm from '../../appendix-form/appendix-form';
import ImageForm from '../../image-form/image-form';

const TextTemplateForm = ({ content, type, isTrainBinder, binderData }) => {
	const { t } = useTranslation();

	const { setCurrentNodeContent, updateDelay } = useSheetContentContext();
	const debouncedSetCurrentNodeContent = useDebouncedAction(setCurrentNodeContent, updateDelay);

	const [text, setText] = useState(content?.text || '');
	const [initText, setInitText] = useState('');
	const [saveHistory, setSaveHistory] = useState(true);

	// send data to context
	const setNodeContent = (newText, setHistory) => {
		let formattedInitText = '';
		const hasNewText = newText && newText !== '';
		if (initText) {
			formattedInitText = JSON.stringify(initText);
		}
		// update in history only if text value changed compared to initial value
		const updateHistory = newText !== formattedInitText && setHistory;
		if (updateHistory && hasNewText) {
			//update the init text
			setInitText(JSON.parse(newText || null));
			setSaveHistory(false);
		}

		debouncedSetCurrentNodeContent({ ...content, text: newText || '' }, updateHistory);
		setText(newText || ' ');
	};

	const handleBlur = () => {
		if (saveHistory) {
			setNodeContent(text || '', true);
		}
	};

	const handleChange = (event) => {
		let { value } = event.target;
		if (value) {
			value = JSON.stringify(value);
		}
		setNodeContent(value, false);
		setSaveHistory(true);
	};

	const reloadForm = () => {
		const newStrText = content?.text || '';
		const strInitText = JSON.stringify(initText || null);
		const isTextChange = newStrText !== strInitText;
		if (isTextChange) {
			setText(newStrText);
			// init current node content and set history if it's a new template
			if (!content) {
				setNodeContent(newStrText, true);
			}
			// initialize editor or update when selecting another text template
			if (!initText || content?.text !== text) {
				try {
					setInitText(JSON.parse(content?.text));
				} catch (e) {
					setInitText(null);
				}
				setSaveHistory(false);
			}
		}
	};

	const renderImageForm = () => {
		let displayImagesTypes = ['note', 'action'];
		if (!isTrainBinder) {
			displayImagesTypes = [...displayImagesTypes, 'systemEffect'];
		}

		if (displayImagesTypes.includes(type)) {
			return <ImageForm content={content} />;
		}
		return null;
	};

	const renderAppendixForm = () => {
		let displayAppendixTypes = ['note', 'action', 'call'];
		if (isTrainBinder && displayAppendixTypes.includes(type)) {
			return <AppendixForm content={content} binderData={binderData} />;
		}
		return null;
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(reloadForm, [content, type]);

	return (
		<div>
			<form className="text-template-form" onSubmit={(event) => event.preventDefault()}>
				<label className="text-template-form__label">
					{t(`sheet:sheet-details.template.${type}.label`)}
				</label>
				<Editor
					className="text-template-form__editor"
					name="text"
					focus={type}
					value={initText || null}
					toolbarConfig={defaultEditorToolbarConfig}
					onChange={handleChange}
					onBlur={handleBlur}
				/>
				{renderImageForm()}
				{renderAppendixForm()}
			</form>
		</div>
	);
};

export default TextTemplateForm;

TextTemplateForm.propTypes = {
	content: PropTypes.object,
	type: PropTypes.string,
	isTrainBinder: PropTypes.bool,
	binderData: PropTypes.object,
};
