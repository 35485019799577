import React from 'react';
import PropTypes from 'prop-types';

import { useAppEnv } from '../../../config/env/app-env';
import { ReactComponent as LogoDigiprocProd } from '../../assets/img/logo-digiproc.svg';
import { ReactComponent as LogoDigiprocFormation } from '../../assets/img/logo-digiproc-formation.svg';
import { ReactComponent as LogoDigiprocMobile } from '../../assets/img/logo-digiproc-mobile.svg';

const LogoDigiproc = ({ className = '', isMobile = false }) => {
	const env = useAppEnv();
	const logoDigiproc = isMobile ? LogoDigiprocMobile : LogoDigiprocProd;

	const LogoComponent = env.REACT_APP_ENV === 'formation' ? LogoDigiprocFormation : logoDigiproc;

	return (
		<LogoComponent
			className={className}
			fill="currentColor"
			role="img"
			aria-hidden="true"
			focusable="false"
		/>
	);
};

export default LogoDigiproc;

LogoDigiproc.propTypes = {
	className: PropTypes.string,
	isMobile: PropTypes.bool,
};
