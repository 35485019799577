import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './next-template-view.scss';

import { IconWrapper } from '../../../../../../../../theme';
import { ReactComponent as IconArrowDownFilled } from '../../../../../../../../theme/assets/img/icon-arrow-down-filled.svg';
import { useSheetModeContext } from '../../../../../../context/sheet-mode-context';
import TemplateWrapper from '../../template-wrapper/template-wrapper';

const NextTemplateView = ({ template, location }) => {
	const { readOnly } = useSheetModeContext();

	return (
		<TemplateWrapper
			id={template.id}
			location={location}
			template={template}
			className={classNames('next-template-view', { 'next-template-view--edition': !readOnly })}
		>
			<IconWrapper className="next-template-view__arrow" Component={IconArrowDownFilled} />
			<IconWrapper className="next-template-view__arrow" Component={IconArrowDownFilled} />
			<IconWrapper className="next-template-view__arrow" Component={IconArrowDownFilled} />
		</TemplateWrapper>
	);
};

export default NextTemplateView;

NextTemplateView.propTypes = {
	template: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};
