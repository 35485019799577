import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './appendix-list-view.scss';

import { IconWrapper } from '../../../../../../../../theme';
import { IconArrowRight } from '../../../../../../../../theme/assets/img';
import SheetType from '../../../../../sheet-type/sheet-type';

const AppendixListView = ({ className, appendixes = [] }) => {
	const hasAppendix =
		appendixes.length > 0 && appendixes[0].sheetNumber !== '' && appendixes[0].sheetId !== '';

	const renderAppendixView = (appendix) => (
		<li key={appendix?.appendixId} className="appendix-list-view__item">
			<SheetType type={'appendix'} sheetNumber={appendix.sheetNumber} color={appendix.sheetColor} />
		</li>
	);

	if (hasAppendix) {
		return (
			<ul className={classNames('appendix-list-view', className)}>
				<IconWrapper className="appendix-list-view__item__icon" Component={IconArrowRight} />
				{appendixes.map(renderAppendixView)}
			</ul>
		);
	}

	return null;
};

export default AppendixListView;

AppendixListView.propTypes = {
	className: PropTypes.string,
	appendixes: PropTypes.array,
};
