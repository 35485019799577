import React from 'react';
import { useTranslation } from 'react-i18next';
import classname from 'classnames';
import PropTypes from 'prop-types';

import './binder-detail-card-template.scss';

/**
 * BinderDetailCardTemplate component
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.title
 * @param {string[]} [props.binderWarnings]
 * @returns {JSX.Element}
 */
const BinderDetailCardTemplate = ({ title = '', className = '', binderWarnings, children }) => {
	const { t } = useTranslation();

	return (
		<div className="binder-detail-card-template">
			<div className="binder-detail-card-template__title">{title}</div>
			<div className={classname('binder-detail-card-template__content', className)}>{children}</div>
			{Array.isArray(binderWarnings) && binderWarnings.length > 0 && (
				<div className="binder-detail-card-template__warning">
					{t('binder:binder-detail.specification.warning-label', {
						binders: binderWarnings.map((element) => element.id).join(', '),
					})}
				</div>
			)}
		</div>
	);
};

export default BinderDetailCardTemplate;

BinderDetailCardTemplate.propTypes = {
	className: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	binderWarnings: PropTypes.arrayOf([PropTypes.string]),
	children: PropTypes.node,
};
