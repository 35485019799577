import React from 'react';
import PropTypes from 'prop-types';

import './new-template-view.scss';

import { useSheetModeContext } from '../../../../../../context/sheet-mode-context';
import DeleteTemplateButton from '../../delete-template-button/delete-template-button';
import TemplateWrapper from '../../template-wrapper/template-wrapper';

const NewTemplateView = ({ template, location }) => {
	const { readOnly } = useSheetModeContext();

	if (readOnly) {
		return null;
	}

	return (
		<TemplateWrapper
			id={template.id}
			template={template}
			location={location}
			className="new-template"
		>
			<DeleteTemplateButton template={template} location={location} />
		</TemplateWrapper>
	);
};

export default NewTemplateView;

NewTemplateView.propTypes = {
	template: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};
