/**
 * Determine the theme color to render
 * @param {string} themeColor
 * @param {string} binderColor
 * @returns {string}
 */
const resolveThemeColor = (themeColor, binderColor) =>
	!themeColor || themeColor === 'default' ? binderColor : themeColor;

export default resolveThemeColor;
