import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './copy-template-button.scss';

import { usePopup } from '../../../../../../../shared';
import { IconWrapper } from '../../../../../../../theme';
import { ReactComponent as IconDuplicate } from '../../../../../../../theme/assets/img/icon-duplicate.svg';
import PopupCopyTemplate from '../../../../../pages/sheet-detail/components/popup-copy-template/popup-copy-template';
import { setTemplateCopiedInLocalStorage } from '../../../../../pages/sheet-detail/utils/handle-copy-paste-template';

const CopyTemplateButton = ({ template }) => {
	const popupCopyTemplateControl = usePopup();
	const { t } = useTranslation();

	const handleCopyTemplate = () => {
		setTemplateCopiedInLocalStorage(template);
		popupCopyTemplateControl.show();
	};

	return (
		<>
			<PopupCopyTemplate popupControl={popupCopyTemplateControl} template={template} />
			<button type="button" onClick={handleCopyTemplate}>
				<IconWrapper
					className="copy-template-button__icon"
					Component={IconDuplicate}
					tooltip={t('sheet:sheet-details.tooltip.copy')}
				/>
			</button>
		</>
	);
};

export default CopyTemplateButton;

CopyTemplateButton.propTypes = {
	template: PropTypes.object.isRequired,
};
