import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'qs';

import { offlineUser } from '../../../../config/offline';
import {
	buildSprProcessUri,
	useAppSetupContext,
	useInReadContextCheck,
	usePopup,
	useSprContext,
} from '../../../../shared';
import { useTemplateTypePosition } from '../../../../shared/utils/use-template-type-position';
import { isTrainBinderType } from '../../../binder/utils/binder-type';
import { fetchSheetByBusinessIds } from '../../../sheet/sheet.services';
import { getTrainByTechId } from '../../../train/train.services';
import {
	buildTaskLog,
	createStartHistoryEntry,
	getSheetsFromHistory,
	pushHistoryEntry,
} from '../../pages/spr-run-process/utils/task-history-utils';
import {
	areAllChosenChoicesDone,
	buildProcess,
	buildSubTaskList,
	getActiveRootTask,
	goToNextRootTask,
	goToNextTask,
	goToSelectedTask,
	isActiveTask,
	moveToNextSubTask,
	setAllTaskAsInactive,
} from '../../pages/spr-run-process/utils/task-list-utils';
import { createProcess, getProcessByTechId, updateProcess } from '../../process.services';

const ContextRunProcess = createContext({});

/**
 * process provider
 * @param {object} props
 * @param {boolean} props.cdrMode
 * @param {object} props.initialSheet
 * @returns {JSX.Element}
 */
const ProcessProvider = ({ children, cdrMode = false, initialSheet }) => {
	const { user: currentUser, updateNetworkStatus } = useAppSetupContext();
	const { side, associatedLine } = useSprContext();

	const { isInReadContext, isInLiveReadContext } = useInReadContextCheck();
	const location = useLocation();
	const history = useHistory();
	const { processId: paramProcessId } = useParams();
	const popupControlSheetRedirect = usePopup();
	const queryParam = qs.parse(location?.search || '', { ignoreQueryPrefix: true }) || {};
	const initialProcessId = useMemo(
		() => queryParam.processId || paramProcessId,
		[queryParam.processId, paramProcessId]
	);

	const [processTechId, setProcessTechId] = useState();
	const [processInfo, setProcessInfo] = useState({});
	const [currentSheetContent, setCurrentSheetContent] = useState([]);
	const [currentSheetData, setCurrentSheetData] = useState({});
	const [nextSheetData, setNextSheetData] = useState({});
	const [processHist, setProcessHist] = useState([]);
	const [previousSheets, setPreviousSheets] = useState([]);
	const [deadEnd, setDeadEnd] = useState(false);
	const [processInitialized, setProcessInitialized] = useState(false);
	const [processLoading, setProcessLoading] = useState(true);
	const [nextReady, setNextReady] = useState(true);
	const [isBinderSignalChoiceVisible, setIsBinderSignalChoiceVisible] = useState(false);
	const [viewTablet, setViewTablet] = useState(false);
	// boolean to show the initial interface before starting the process / set to undefined to hide the interface in case of determination sheet
	const [processStarted, setProcessStarted] = useState();
	const { getTaskPosition } = useTemplateTypePosition(currentSheetContent, processHist);
	const isTrainBinder = useMemo(
		() => isTrainBinderType(currentSheetData?.binder_type),
		[currentSheetData?.binder_type]
	);
	const isCurrentUserProcessOwner = currentUser?.tech_id === processInfo?.owner;
	const isOfflineUser = currentUser?.tech_id === offlineUser.tech_id;

	/**
	 * @typedef Task
	 * @prop {string} id
	 * @prop {Object} content
	 * @prop {string} type
	 * @prop {("yes" | "no")|Object} [choice]
	 * @prop {boolean} [active]
	 * */

	/**
	 * Set process as unready to go to next task during 500ms
	 */
	const blockNextTask = () => {
		setNextReady(false);
		setTimeout(() => setNextReady(true), 500);
	};

	/**
	 * @callback logTaskCallback
	 * @param {Task} task
	 * @param {Task} parentTask
	 */

	/**
	 * create add to history cb
	 * @param {*} additionalData
	 * @returns {logTaskCallback}
	 */
	const addTaskToHistory = (additionalData) => (task, parentTask) => {
		setProcessHist((prevProcessHist = []) => {
			const taskInfo = { task, parentTask, additionalData };
			return pushHistoryEntry(prevProcessHist, currentSheetData, taskInfo, currentUser);
		});
	};

	/**
	 * @param {object} sheetContent
	 * @param {boolean} shouldLog
	 * @param {{position: number, notApplicable: boolean}} additionalData
	 * @returns {object}
	 */
	const goToNext = (sheetContent, shouldLog = true, additionalData = {}) => {
		// function add the task the current process's history with additionalData
		const logTask = addTaskToHistory(additionalData);
		// function go to next task with previous function as parameters
		const goToNextTaskFunction = goToNextTask(logTask);
		// function with every parameters in
		return goToNextTaskFunction(sheetContent, shouldLog);
	};

	const setupPreviousSheets = (processHistory) => {
		const cleanedList = setAllTaskAsInactive(processHistory);
		const newPreviousSheets = getSheetsFromHistory(cleanedList);
		setPreviousSheets(newPreviousSheets);
	};

	const handleSignalChoice = () => {
		setIsBinderSignalChoiceVisible(true);
	};

	const setupSheetState = (sheetResponseData, actionId) => {
		const sheetData = { ...sheetResponseData };
		let sheetContent;
		if (sheetResponseData?.content && sheetResponseData.content.length > 0) {
			// handle simple link go to specified action
			if (actionId) {
				let newSheetContent = JSON.parse(JSON.stringify(sheetResponseData.content));
				newSheetContent = setAllTaskAsInactive(newSheetContent);
				const actionPos = newSheetContent.findIndex((task) => task.id === actionId);
				const actionTask = newSheetContent[actionPos];
				actionTask.active = true;
				sheetContent = newSheetContent;
				sheetData.content = newSheetContent;
			} else {
				const newContent = goToNext(sheetResponseData.content, !initialProcessId);
				sheetContent = [...newContent];
			}

			setCurrentSheetData(sheetData);
			setCurrentSheetContent(sheetContent);
			return sheetData;
		}
	};

	/**
	 * @param {string} binderId The binder's id
	 * @param {string} sheetNumber The sheet's number
	 * @param {string} [binderTechId] The binder's technical id
	 * @param {string} [signalTechId] The sheet's signal technical id
	 * @param {string} [actionId] The sheet's action task id
	 */
	const loadSheet = async (
		binderId,
		sheetNumber,
		binderTechId = '',
		signalTechId = '',
		actionId = ''
	) => {
		if ((binderTechId || binderId) && sheetNumber) {
			const response = await fetchSheetByBusinessIds(
				binderId,
				sheetNumber,
				binderTechId,
				signalTechId,
				{ extendTheme: true }
			);
			const sheetData = setupSheetState(response?.data, actionId);
			setupPreviousSheets(processHist);
			return sheetData;
		}
	};

	/**
	 * Get last task through history process
	 * Filter history processes by informations Type like "change-owner"
	 * @param {Task[]} taskHistory
	 * @returns {Task}
	 */
	const getLastTask = (taskHistory) => {
		const informationType = 'change-owner';
		const filteredTaskHistory = taskHistory.filter(
			(task) => informationType !== task.type && !task?.canceled
		);
		return filteredTaskHistory[filteredTaskHistory.length - 1];
	};

	/**
	 * get process existed and set the actual step
	 * @returns {Promise<void>}
	 */
	const loadProcess = async () => {
		setProcessTechId(initialProcessId);
		const processResponse = await getProcessByTechId(initialProcessId, {
			extendOwner: true,
			extendMaterial: true,
			extendTrain: true,
		});
		if (processResponse.fromCache) {
			updateNetworkStatus(false);
		}
		const { history: taskHistory, ...newProcessInfo } = processResponse?.data || {};
		// Havesave last sheet/binder tech id (handle reload when history is empty)
		if (taskHistory?.length > 0) {
			// Get the last sheet from the last task logged
			const lastTask = getLastTask(taskHistory);
			const { sheet, binder } = lastTask;
			let sheetResponse;
			let isLoadingLink = false;
			// Handle recover for multipleLink or simpleLink choice -> load the chosen sheet
			if (lastTask?.type === 'multipleLink' && lastTask?.choice) {
				// Load next sheet by business ids with the current loaded binder and with sheet's task info
				sheetResponse = await fetchSheetByBusinessIds(
					binder?.id,
					lastTask?.choice?.sheetNumber,
					binder?.techId,
					sheet.signalTechId
				);
				isLoadingLink = true;
			} else if (lastTask?.type === 'simpleLink') {
				if (lastTask?.content.type === 'other-sheet') {
					// Load sheet by business ids with binder and sheet's task info
					sheetResponse = await fetchSheetByBusinessIds(
						lastTask?.content?.binder?.id,
						lastTask?.content?.sheetNumber,
						lastTask?.content?.binder?.techId
					);
					isLoadingLink = true;
				} else if (lastTask?.content.type === 'sheet') {
					// Load next sheet by business ids with the current loaded binder and with sheet's task info
					sheetResponse = await fetchSheetByBusinessIds(
						binder?.id,
						lastTask?.content?.sheetNumber,
						binder?.techId,
						sheet.signalTechId
					);
					sheetResponse.data.test = true;
					isLoadingLink = true;
				}
			} else {
				// Load next sheet by business ids with the current binder and current sheet
				sheetResponse = await fetchSheetByBusinessIds(
					binder?.id,
					sheet?.number,
					binder.techId,
					sheet.signalTechId,
					{ extendTheme: true }
				);
			}

			// Load empty task to start on first loaded sheet task
			// Load last current sheet task
			const newSheetContent = goToSelectedTask(
				sheetResponse?.data?.content,
				isLoadingLink ? {} : lastTask
			);

			// Go to the last task known
			// Go to the task after the last logged one (do not log in history this transition)
			setProcessHist(taskHistory);
			setupPreviousSheets(taskHistory);
			setupSheetState(
				{ ...sheetResponse?.data, content: newSheetContent },
				lastTask?.content?.actionId
			);
			setProcessInfo(newProcessInfo);
		}
	};

	/**
	 * create new process
	 * @param {number} sheetNumber
	 * @param {string} binderId
	 * @param {string} binderTechId
	 * @param {{signalTechId: string: trainTechId: string, line: string, processId: string, started_at: boolean}} [options]
	 */
	const createNewProcess = async (sheetNumber, binderId, binderTechId, options = {}) => {
		const { signalTechId, processId, trainTechId } = options;
		if (sheetNumber && (binderId || binderTechId)) {
			// Loading a new process with the first sheet to start
			try {
				// set train id info
				let trainTechIdSearch = trainTechId;
				if (!trainTechIdSearch && processId) {
					const oldProcess = await getProcessByTechId(processId);
					trainTechIdSearch = oldProcess?.data?.train_tech_id;
				}
				const trainData = trainTechIdSearch ? await getTrainByTechId(trainTechIdSearch) : null;
				const taskInfo = {
					task: createStartHistoryEntry(),
					additionalData: { train_id: trainData?.data?.id },
				};
				const sheetData = await loadSheet(binderId, sheetNumber, binderTechId, signalTechId);
				const taskLog = buildTaskLog(sheetData, taskInfo, currentUser);

				// trainbinder in uppper scope updates async
				options.isTrainBinder = isTrainBinderType(sheetData?.binder_type);
				// is start means created from material list without train tech id
				const newProcess = buildProcess(
					taskLog,
					sheetData?.sheet_id,
					currentUser?.tech_id,
					binderTechId,
					options
				);
				// Handle process already exists case when SL start the process after taking in charge a process created by SPR
				const response = processId
					? await updateProcess(processId, newProcess)
					: await createProcess(newProcess, { line: options.line });
				const data = processId ? response?.data?.[0] : response?.data;
				const { tech_id: newProcessTechId, ...newProcessInfo } = data || {};
				setProcessTechId(newProcessTechId);
				setProcessInfo(newProcessInfo);
				setProcessStarted(true);
				setProcessHist(newProcessInfo.history);
				const uriPrefix = location.pathname.startsWith('/railway') ? 'railway' : 'spr';
				const processUri = buildSprProcessUri({ processId: newProcessTechId }, uriPrefix);
				history.replace(processUri);
			} catch (error) {
				console.error(error);
			}
		}
	};

	const setupContextRunProcessForCDRMode = () => {
		// Load provided sheet
		setupSheetState(initialSheet);
		// Init process
		const taskInfo = { task: createStartHistoryEntry() };
		setProcessHist([buildTaskLog(initialSheet, taskInfo, currentUser)]);
	};

	const setupProcessWithFirstSheet = async () => {
		const { binderId, sheetNumber, binderTechnicalId, trainTechId } = queryParam;
		const { data: sheet } = await fetchSheetByBusinessIds(binderId, sheetNumber, binderTechnicalId);
		const isPCCDeterminationSheet =
			!isTrainBinderType(sheet?.binder_type) && sheet?.type === 'determination';
		if (isPCCDeterminationSheet) {
			const options = { line: associatedLine, trainTechId };
			await createNewProcess(sheetNumber, binderId, binderTechnicalId, options);
		} else {
			setProcessStarted(false);
			setCurrentSheetData(sheet);
		}
	};

	const setupContextRunProcessForSPRMode = async () => {
		// check if process exist
		if (initialProcessId) {
			setProcessStarted(true);
			if (queryParam.sheetNumber && (queryParam.binderId || queryParam.binderTechnicalId)) {
				// Loading a new sheet <- multiple link choice
				await loadSheet(queryParam.binderId, queryParam.sheetNumber, queryParam.binderTechnicalId);
			} else {
				// Loading an existing process
				await loadProcess();
			}
		} else if (queryParam.sheetNumber && (queryParam.binderId || queryParam.binderTechnicalId)) {
			await setupProcessWithFirstSheet();
		}
	};

	const setupContextRunProcess = () => {
		setDeadEnd(false);
		if (cdrMode) {
			setupContextRunProcessForCDRMode();
		} else {
			// Set Loading process only for initial load
			setProcessLoading(!processInitialized);
			setupContextRunProcessForSPRMode()
				.then(() => setProcessInitialized(true))
				.finally(() => setProcessLoading(false));
		}
	};

	const saveProcessHistory = () => {
		if (
			!cdrMode &&
			processTechId &&
			processHist?.length > 0 &&
			(isCurrentUserProcessOwner || isOfflineUser)
		) {
			const processData = { history: processHist };
			updateProcess(processTechId, processData, { line: associatedLine })
				.then((response) => {
					const { status: processUpdatedStatus } = response.data?.[0] || {};
					if (processUpdatedStatus && processUpdatedStatus.status === 'closed') {
						setProcessInfo((previousProcessInfo) => ({
							...previousProcessInfo,
							status: processUpdatedStatus.status,
						}));
					}
				})
				.catch((error) => {
					if (error?.response?.status === 403) {
						setupContextRunProcess();
					}
					console.error(error);
				});
		}
	};

	const syncPreviousSheet = () => {
		setupPreviousSheets(processHist);
	};

	/**
	 * Load sheet depending the simple link type
	 * @param simpleLinkContent
	 * @param sheetData
	 */
	const handleLoadSimpleLink = async (simpleLinkContent, sheetData) => {
		const isSheetLoadable = !cdrMode;
		try {
			if (simpleLinkContent.type === 'step') {
				const stepTask = sheetData.content.find((task) => task.id === simpleLinkContent.step);
				const newSheetContent = goToSelectedTask(sheetData.content, stepTask);
				setCurrentSheetContent([...newSheetContent]);
				handleUnfinishedProcess(newSheetContent);
			} else if (isSheetLoadable) {
				const loadingArguments = {
					'other-sheet': [
						simpleLinkContent.binder?.id,
						simpleLinkContent?.binder?.techId,
						'',
						simpleLinkContent?.actionId,
					],
					sheet: [
						sheetData?.binder_id,
						sheetData?.binder_tech_id,
						sheetData.signal_tech_id,
						simpleLinkContent?.actionId,
					],
				};
				if (Object.hasOwn(loadingArguments, simpleLinkContent.type)) {
					const [binderId, binderTechId, signalTechId, actionId] =
						loadingArguments[simpleLinkContent.type];
					await loadSheet(
						binderId,
						simpleLinkContent.sheetNumber,
						binderTechId,
						signalTechId,
						actionId
					);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	/**
	 * Launch specific action depending on the current active task
	 * @param {Task[]} taskList
	 * @param {Task} parentTask
	 */
	const runTaskAction = async (taskList = [], parentTask) => {
		const activeTaskPos = taskList.findIndex(isActiveTask);
		if (activeTaskPos === -1) return;
		const activeTask = taskList[activeTaskPos];
		const { type: taskType, choice: taskChoice, content: taskContent } = activeTask;
		const skippedTaskTypes = ['step', 'history-start', 'pageBreak', 'space', 'new'];

		if (taskType === 'termination') {
			const logTaskHistory = addTaskToHistory();
			logTaskHistory(activeTask, parentTask);
		}

		if (taskType === 'next') {
			activeTask.active = false;
			const newSheetContent = [...currentSheetContent];
			const newProcessTaskList = goToNextRootTask(addTaskToHistory)(
				newSheetContent,
				undefined,
				true
			);
			setCurrentSheetContent([...newProcessTaskList]);
			handleUnfinishedProcess(newProcessTaskList);
			return;
		}

		if (taskType === 'simpleLink') {
			// Get step number of simple link step for display
			if (taskContent?.type === 'step') {
				const step = currentSheetData?.content?.find((task) => task.id === taskContent.step);
				taskContent.stepNumber = step?.content?.step;
			}
			const logTaskHistory = addTaskToHistory();
			logTaskHistory(activeTask, parentTask);
			if (side === 'train') {
				await loadSimpleLinkNextSheetData(activeTask);
			} else {
				handleLoadSimpleLink(taskContent, currentSheetData);
			}
			return;
		}

		if (taskType === 'multipleLink' && taskChoice?.sheetNumber) {
			// Continue process run on chosen multipleLink
			loadSheet(
				activeTask?.binder?.id,
				taskChoice?.sheetNumber,
				activeTask?.binder?.techId,
				currentSheetData.signal_tech_id
			).catch((error) => console.error(error));
			return;
		}

		if (taskType === 'question' && taskChoice) {
			const subTaskList = buildSubTaskList(taskContent[taskChoice]);
			runTaskAction(subTaskList, activeTask);
			return;
		}

		// multipleChoice subNodes ending with subnodes type with value call, action , note
		if (taskType === 'multipleChoice') {
			// if all flag isDone are true, exit the multipleChoice loop and go to next main task
			if (areAllChosenChoicesDone(activeTask?.content?.choices)) {
				const newProcessTaskList = goToNextRootTask(addTaskToHistory)(
					currentSheetContent,
					undefined,
					false
				);
				setCurrentSheetContent([...newProcessTaskList]);
				handleUnfinishedProcess(newProcessTaskList);
			} else {
				runTaskAction(taskContent?.subNodes, activeTask);
			}
			return;
		}

		if (skippedTaskTypes.includes(taskType)) {
			const newProcessTaskList = goToNext(currentSheetContent);
			setCurrentSheetContent([...newProcessTaskList]);
			handleUnfinishedProcess(newProcessTaskList);
		}
	};

	const handleTaskAction = () => runTaskAction(currentSheetContent);

	const logCurrentTaskChoice = (task, choice) => {
		task.choice = choice;
		const logTaskHistory = addTaskToHistory();
		logTaskHistory(task);
	};

	const logCurrentMultipleChoice = (task, choicesCheck) => {
		choicesCheck.forEach((choiceCheck) => {
			task.content.choices[choiceCheck].chosen = true;
			task.content.choices[choiceCheck].isDone = false;
		});
		const logTaskHistory = addTaskToHistory();
		logTaskHistory(task);
	};

	/**
	 * Handle Question choice by logging the choice and moving to the first task in the chosen branch
	 * @param {"yes"|"no"} choice
	 */
	const handleQuestionTaskAnswer = (choice) => {
		if (nextReady && (isCurrentUserProcessOwner || cdrMode || isOfflineUser)) {
			blockNextTask();
			const activeTask = currentSheetContent.find(isActiveTask);
			logCurrentTaskChoice(activeTask, choice);
			moveToNextSubTask(addTaskToHistory)(activeTask, false);
			setCurrentSheetContent([...currentSheetContent]);
			handleUnfinishedProcess(currentSheetContent);
		}
	};

	/**
	 * Handle MultipleChoice by logging the choice and set a flag isDone to false
	 * isDone will be the flag set to know if all subTask was passed for one choice
	 * @param choicesChecked
	 */
	const handleMultipleChoiceTaskAnswer = (choicesChecked) => {
		if (isCurrentUserProcessOwner || cdrMode || isOfflineUser) {
			const activeTask = currentSheetContent.find(isActiveTask);
			logCurrentMultipleChoice(activeTask, choicesChecked);
			moveToNextSubTask(addTaskToHistory)(activeTask, false);
			setCurrentSheetContent([...currentSheetContent]);
			handleUnfinishedProcess(currentSheetContent);
		}
	};

	const handleMultipleLinkTaskAnswer = async (task, choice) => {
		if (nextReady) {
			blockNextTask();
			logCurrentTaskChoice(task, choice);
			if (!cdrMode) {
				if (side === 'train') {
					const newSheet = await fetchSheetByBusinessIds(
						currentSheetData?.binder_id,
						choice.sheetNumber,
						currentSheetData.binder_tech_id,
						currentSheetData.signal_tech_id
					);
					const { sheet_id: sheetId, number_search: sheetNumber, type } = newSheet?.data;
					setNextSheetData({
						sheetId,
						sheetNumber,
						type,
						binder: { binderTechId: currentSheetData.binder_tech_id },
						...newSheet?.data,
					});
					popupControlSheetRedirect.show();
					popupControlSheetRedirect.onHide.current = () =>
						loadSheet(
							currentSheetData?.binder_id,
							choice.sheetNumber,
							currentSheetData.binder_tech_id,
							currentSheetData.signal_tech_id
						);
				} else {
					await loadSheet(
						currentSheetData?.binder_id,
						choice.sheetNumber,
						currentSheetData.binder_tech_id,
						currentSheetData.signal_tech_id
					);
				}
			}
		}
	};

	const loadSimpleLinkNextSheetData = async (activeTask) => {
		popupControlSheetRedirect.show();
		try {
			const {
				content: {
					sheetNumber,
					binder: { id: binderIdNextSheet, techId: binderTechIdNextSheet } = {},
				} = {},
			} = activeTask || {};
			const {
				signal_tech_id,
				binder_id: binderIdCurrentSheet,
				binder_tech_id: binderTechIdCurrentSheet,
			} = currentSheetData || {};
			const newSheet = await fetchSheetByBusinessIds(
				binderIdNextSheet || binderIdCurrentSheet,
				sheetNumber,
				binderTechIdNextSheet || binderTechIdCurrentSheet,
				signal_tech_id
			);

			if (!newSheet) {
				throw new Error('No sheet(s) found from parameters provided');
			}

			const { title } = newSheet?.data;

			setNextSheetData({ ...activeTask.content, title });
			popupControlSheetRedirect.onHide.current = () =>
				handleLoadSimpleLink(activeTask?.content, currentSheetData);
		} catch (err) {
			console.error(err);
			const prefix = side === 'train' ? 'railway' : 'spr';
			history.push(`/${prefix}/process/dead-end/`);
		}
	};

	const handleNextSheetData = async (nextSheet, binderId, binderTechId, signalTechId) => {
		const activeTask = getActiveTask();
		try {
			if (!nextSheet) {
				throw new Error('No sheet(s) found from parameters provided');
			}

			setNextSheetData({ ...activeTask.content, nextSheet });
			loadSheet(binderId, nextSheet.number, binderTechId, signalTechId);
		} catch (err) {
			console.error(err);
			const prefix = side === 'train' ? 'railway' : 'spr';
			history.push(`/${prefix}/process/dead-end/`);
		}
	};

	/**
	 * handle passing to the next task
	 * @param {{position: number, notApplicable: boolean}} additionalData
	 */
	const handleNext = (additionalData = {}) => {
		if (nextReady && (isCurrentUserProcessOwner || cdrMode || isOfflineUser)) {
			blockNextTask();
			const newCurrentSheetContent = goToNext(currentSheetContent, true, additionalData);
			setCurrentSheetContent(newCurrentSheetContent);
			handleUnfinishedProcess(newCurrentSheetContent);
		}
	};

	const handleLiveReload = () => {
		const isReloadInLiveReadOnly = !isInReadContext || isInLiveReadContext;
		if (
			!(isCurrentUserProcessOwner || isOfflineUser) &&
			!cdrMode &&
			processStarted &&
			isReloadInLiveReadOnly
		) {
			const intervalId = setInterval(setupContextRunProcess, 1000);
			return () => {
				clearInterval(intervalId);
			};
		}
	};

	const getActiveTask = () => getActiveRootTask(currentSheetContent);

	/**
	 * Get user's label from last history
	 * @returns {string}
	 */
	const getOwnerLabel = () => {
		const lastTask = processHist[processHist.length - 1];
		return lastTask?.userInfo;
	};

	// if there is no active task, process lead to a dead-end
	const handleUnfinishedProcess = (taskList) => {
		const taskListCopy = JSON.parse(JSON.stringify(taskList));
		const someTaskActive = taskListCopy.some((task) => task.active);
		setDeadEnd(!someTaskActive);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(setupContextRunProcess, [
		queryParam.binderTechnicalId,
		queryParam.sheetNumber,
		initialProcessId,
		initialSheet,
	]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(handleLiveReload, [isCurrentUserProcessOwner, processStarted]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(handleTaskAction, [currentSheetContent]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(saveProcessHistory, [processHist]);

	useEffect(syncPreviousSheet, [processHist]);

	if (deadEnd && !cdrMode) {
		return <Redirect to="/spr/process/dead-end" />;
	}

	return (
		<ContextRunProcess.Provider
			value={{
				currentSheetData,
				currentSheetContent,
				init: setupContextRunProcess,
				createNewProcess,
				handleNext,
				handleNextSheetData,
				handleMultipleChoiceTaskAnswer,
				handleQuestionTaskAnswer,
				handleMultipleLinkTaskAnswer,
				handleSignalChoice,
				logCurrentTaskChoice,
				getActiveTask,
				getOwnerLabel,
				getLastTask,
				goToNext,
				processHist,
				processInfo,
				previousSheets,
				processStarted,
				deadEnd,
				isBinderSignalChoiceVisible,
				processTechId: processTechId || initialProcessId,
				processLoading: !processInitialized && processLoading,
				cdrMode,
				queryParam,
				popupControlSheetRedirect,
				nextSheetData,
				viewTablet,
				setViewTablet,
				setNextSheetData,
				getTaskPosition,
				isTrainBinder,
			}}
		>
			{children}
		</ContextRunProcess.Provider>
	);
};

/**
 * use the context run process hook
 * @returns {{
 *	currentSheetData: {binder_id: string, sheetNumber: string, binder_tech_id: string, signal_tech_id: string},
 *	currentSheetContent: object,
 *	init: () => void,
 *	createNewProcess: (sheetNumber: string, binderId: string, binderTechId: string, options: {signalTechId: string, processId: string}) => Promise<*>,
 *	handleNext: (additionalData?: { position: number, notApplicable: boolean }) => void,
 *  handleNextSheetData: (nextSheet: object, binderTechId: string) => void,
 *	handleMultipleChoiceTaskAnswer: (choicesChecked: object[]) => void,
 *	handleQuestionTaskAnswer: (choice: "yes" | "no") => void,
 *	handleMultipleLinkTaskAnswer: (choicesChecked: object[]) => void,
 *  handleSignalChoice:() => void,
 *	logCurrentTaskChoice: (task: {choice: object}, choice: object) => void,
 *	getActiveTask: () => Task,
 *	getOwnerLabel: () => string,
 *	getLastTask: (taskHistory: Task[]) => Task,
 *	goToNext: (sheetContent: object, shouldLog?: boolean, additionalData?: object) => Task[],
 *	processHist: [],
 *	processInfo: {	history: [], owner: string, sheet_tech_id: string, binder_tech_id: string, train_tech_id?: string, line?: string, isStart?: string, resume_conditions: { position_supply: 'normal' | 'direct', engine_units: number, bogie_car_units: number, resume: 'travelers' | 'hlp' | 'secours' }},
 *	previousSheets: object[],
 *	processStarted: boolean,
 *	deadEnd: boolean,
 *  isBinderSignalChoiceVisible: boolean,
 *	processTechId: string,
 *	processLoading: boolean,
 *	cdrMode: boolean,
 *	queryParam: qs.ParsedQs,
 *	popupControlSheetRedirect: { visible: boolean, setVisible: React.Dispatch<React.SetStateAction<boolean>>, show: () => void, hide: () => void, onHide: React.MutableRefObject<() => void>},
 *	nextSheetData: object,
 *  viewTablet: boolean,
 *  setViewTablet: React.Dispatch<React.SetStateAction<{}>>,
 *	setNextSheetData: React.Dispatch<React.SetStateAction<{}>>,
 *	getTaskPosition: import("../../../../shared/utils/use-template-type-position").getTaskPositionCallback,
 *	isTrainBinder: boolean
 * }}
 */
const useRunProcessContext = () => useContext(ContextRunProcess);

export { ContextRunProcess, ProcessProvider, useRunProcessContext };

ProcessProvider.propTypes = {
	children: PropTypes.node,
	cdrMode: PropTypes.bool,
	initialSheet: PropTypes.object,
};
