import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './process-history-view.scss';

import {
	buildSprProcessUri,
	buildTrainProcessBinderList,
	groupNeighborBy,
	LoadWrapper,
	useInReadContextCheck,
	useRailwayOnlineReturn,
	useSprContext,
} from '../../../../shared';
import { getLastReadSheetLink } from '../../../../shared/utils/sheet-read-utils';
import { IconWrapper, SprPage, ToggleSwitch } from '../../../../theme';
import { IconTrain } from '../../../../theme/assets/img';
import { resolveSheetColor } from '../../../sheet';
import { useSheetReadContext } from '../../../sheet/pages/railway-sheet-view/components/sheet-read-context/sheet-read-context';
import { taskTypeMap } from '../..';
import ProcessTrainBreadcrumbSubheader from '../../components/process-train-breadcrumb-subheader/process-train-breadcrumb-subheader';
import ProcessTrainSubheader from '../../components/process-train-subheader/process-train-subheader';
import { updateProcess } from '../../process.services';
import { findLastIndexOf } from '../../utils';

import {
	HistoryProcessProvider,
	useHistoryProcessContext,
} from './components/context-history-process/context-history-process';
import ProcessHistoryHeader from './components/process-history-header/process-history-header';
import ProcessInformationHeader from './components/process-information-header/process-information-header';
import RerResumeConditionsDrawer from './components/rer-resume-conditions-drawer/rer-resume-conditions-drawer';
import ResumeConditionsDrawer from './components/resume-conditions-drawer/resume-conditions-drawer';
import TaskListDrawer from './components/task-list-drawer/task-list-drawer';

/**
 * @typedef Task
 * @prop {string} id
 * @prop {Object} content
 * @prop {string} type
 * @prop {("yes" | "no")|Object} [choice]
 * @prop {boolean} [active]
 */

/**
 * Page component of process's history view
 * @returns {JSX.Element}
 */
const ProcessHistoryView = () => {
	const { t } = useTranslation();
	const { processId } = useParams();
	const history = useHistory();

	const [isDetailFilter, setIsDetailFilter] = useState(false);

	const { side, associatedLine } = useSprContext();
	const {
		processInfo: historyProcess,
		pageLoading: pageHistoryLoading,
		isOffline,
	} = useHistoryProcessContext();

	const { isInReadContext } = useInReadContextCheck();
	const { allowOnlineReturn } = useRailwayOnlineReturn(false);

	// context only available if wrapped in sheet read ctx provider
	const {
		currentProcess = null,
		trainAndProcessLoading,
		currentTrain,
	} = useSheetReadContext() || {};
	const {
		processInfo,
		pageLoading,
		trainId = '',
	} = isInReadContext
		? {
				processInfo: currentProcess,
				pageLoading: trainAndProcessLoading,
				trainId: currentTrain?.id,
			}
		: {
				processInfo: historyProcess,
				pageLoading: pageHistoryLoading,
				trainId: historyProcess?.train_id || historyProcess?.history?.[0]?.train_id,
			};
	const lastCanceledTaskPosition = findLastIndexOf(processInfo?.history, function (task) {
		return task?.canceled;
	});
	const {
		history: processHistory = [],
		resume_conditions: resumeConditions = {},
		line: processLine,
	} = processInfo;
	const {
		resume: resumeForm,
		damage_location: damageLocation,
		resume_rer: resumeRer,
		results_sheet: resultsSheet,
	} = resumeConditions;

	const isEmergencyLightsCompleted = !damageLocation && !resultsSheet && resumeRer?.step_completed;
	const displayRerResumeConditionsDrawer =
		(damageLocation?.step_completed && resumeRer?.step_completed && resultsSheet?.step_completed) ||
		isEmergencyLightsCompleted;

	/**
	 * Update the process's history to tag entries after returnedTask as cancelled
	 * @param {any[]} processHist The process history to update
	 * @param {Task} returnedTask The task to return on
	 * */
	const updateProcessHistoryOnJumpBack = (processHist, returnedTask) => {
		// Retrieve the jumped back task position
		const historyEntryPosition = findLastIndexOf(processHist, function (task) {
			return task?.id === returnedTask?.id;
		});

		// Update history entries: canceled all task after the jumped one
		return processHist.map((task, taskPosition) => {
			task.canceled = taskPosition >= historyEntryPosition || task?.canceled;
			task.isFirstCanceled = taskPosition === historyEntryPosition || task?.isFirstCanceled;
			return task;
		});
	};

	const handleJumpBack = (pHistory) => (returnedTask) => async () => {
		const updatedProcessHistory = updateProcessHistoryOnJumpBack(pHistory, returnedTask);
		await updateProcess(processId, { history: updatedProcessHistory }, { line: associatedLine });
		const prefix = side === 'train' ? 'railway' : 'spr';
		history.push(buildSprProcessUri({ processId }, prefix));
	};

	const getMultipleChoiceStepTaskPosition = (
		acc,
		currentTask,
		currentTaskIndex,
		initialTaskList
	) => {
		const { optionalInfos } = currentTask;
		const reversedTaskList = initialTaskList.slice(0, currentTaskIndex + 1).reverse();
		// Search last multipleChoice
		const lastMultipleChoice = reversedTaskList.find((task) => task.type === 'multipleChoice');
		const lastMultipleChoiceIndex = initialTaskList.findIndex(
			(task) =>
				task.id === lastMultipleChoice?.id && task.finishedAt === lastMultipleChoice.finishedAt
		);
		// Avoid considering old execution of the same multipleChoice by filtering multipleChoiceStepPosition to insert before current last multipleChoice
		const filteredStepPosition = acc.filter(
			(stepPosition) => stepPosition.positionToInsert >= lastMultipleChoiceIndex
		);
		if (optionalInfos?.parentType === 'multipleChoice') {
			const choiceIdTitle = `${optionalInfos.parentId}${optionalInfos.choiceTitle}`;
			// if concat id + choice title is new, then there is a new step
			if (!filteredStepPosition.find((value) => value.choiceIdTitle === choiceIdTitle)) {
				return [
					...acc,
					{
						positionToInsert: currentTaskIndex,
						choiceIdTitle,
						choiceTitle: optionalInfos.choiceTitle,
					},
				];
			}
		}
		return [...acc];
	};

	// add a multipleChoice step to show the current choice of each sub nodes
	const generateMultipleChoiceStep = (groupedTaskList) => {
		return groupedTaskList.map((taskList) => {
			const taskListReduced = taskList.reduce(getMultipleChoiceStepTaskPosition, []);
			taskListReduced.forEach((newTask, index) => {
				const { choiceTitle, positionToInsert } = newTask;
				const task = { type: 'multipleChoiceStep', choiceTitle, index };
				taskList.splice(positionToInsert + index, 0, task);
			});
			return taskList;
		});
	};

	/**
	 * set task hidden when necessary and add history position
	 * @param {Task[]} taskList
	 * @return {Task[]}
	 */
	const addHiddenAndHistoryPosition = useCallback(
		(taskList = []) => {
			let checkHidden;
			if (isDetailFilter) {
				const {
					resume,
					pccInstruction,
					officerIntervention,
					mrfIntervention,
					question,
					multipleLink,
					termination,
				} = taskTypeMap;
				const visibleTaskType = [
					resume,
					pccInstruction,
					officerIntervention,
					mrfIntervention,
					question,
					multipleLink,
					termination,
				];
				checkHidden = (type) => !visibleTaskType.includes(type);
			} else {
				checkHidden = (type) => type === 'simpleLink';
			}
			return taskList?.map((task, position) => ({
				...task,
				historyPosition: position,
				hidden: checkHidden(task?.type),
			}));
		},
		[isDetailFilter]
	);

	const generateDrawerList = (taskList = []) => {
		const cleanedTaskList = addHiddenAndHistoryPosition(taskList);
		const groupedBySheetTaskList = groupNeighborBy(cleanedTaskList, 'sheet', 'techId');
		const groupedTaskList = generateMultipleChoiceStep(groupedBySheetTaskList);

		return (
			<>
				{Array.isArray(taskList) &&
					taskList.length > 0 &&
					groupedTaskList.map((sheetTaskList) => {
						const [task] = sheetTaskList;
						const color = resolveSheetColor(
							task?.sheet?.color,
							task?.binder?.color,
							task?.theme?.theme_color
						);
						const { number, title, type, signalTechId } = task?.sheet;
						return (
							<li className="process-history-view__item" key={`${task?.id}${task?.finishedAt}`}>
								<TaskListDrawer
									taskList={sheetTaskList}
									color={color}
									sheetNumber={number}
									sheetTitle={title}
									sheetType={type}
									startTime={task?.finishedAt}
									lastCanceledTaskPosition={lastCanceledTaskPosition}
									handleJumpBack={handleJumpBack(taskList)}
									processInfo={processInfo}
									binderType={task?.binder?.type}
									signalTechId={signalTechId}
								/>
							</li>
						);
					})}
			</>
		);
	};

	const renderSubHeader = () => {
		if (isOffline) {
			return null;
		}
		if (isInReadContext) {
			// Return to last visited sheet else to sheet list
			const subHeaderReturnLink =
				getLastReadSheetLink() || buildTrainProcessBinderList({ processId }, 'spr', true);
			return (
				<>
					<ProcessTrainSubheader hideButton="step" />
					<ProcessTrainBreadcrumbSubheader
						showOverlay={false}
						processExecutorLabel={t('process:process-train-breadcrumb-subheader.executor')}
						returnLink={subHeaderReturnLink}
						returnLabel={t('process:process-train-breadcrumb-subheader.return-binder')}
					/>
				</>
			);
		}
		return <ProcessHistoryHeader process={processInfo} />;
	};

	const showFilterToggleAndTrainProcedure = processInfo?.type === 'train' && side === 'pcc';

	useEffect(() => {
		allowOnlineReturn(processInfo.status === 'closed');
	}, [processInfo?.status, allowOnlineReturn]);

	return (
		<LoadWrapper className="process-history-view__loader" loading={pageLoading}>
			<SprPage
				className={classnames('process-history-view', {
					'process-history-view--read': isInReadContext,
				})}
				displayHeader={!isOffline}
				subheader={renderSubHeader()}
				borderOverlayType={isInReadContext && 'read'}
				allowLineSwitch={side === 'train'}
			>
				{showFilterToggleAndTrainProcedure && (
					<div className="process-history-view__train-banner">
						<span className="process-history-view__train-display">
							<IconWrapper className="process-history-view__train-icon" Component={IconTrain} />
							<span>{t('process:history-view.process-train')}</span>
						</span>
						<span className="process-history-view__toggle-wrapper">
							<span>{t('process:history-view.process-detail')}</span>
							<ToggleSwitch
								className="process-history-view__toggle"
								isChecked={isDetailFilter}
								disabled={false}
								handleOnChange={() => setIsDetailFilter((prev) => !prev)}
							/>
						</span>
					</div>
				)}
				<ProcessInformationHeader
					processInfo={processInfo}
					processHistory={processHistory}
					trainId={trainId}
				/>
				<ul className="process-history-view__list">
					{generateDrawerList(processHistory)}
					{resumeForm && (
						<ResumeConditionsDrawer
							brakeSystem={processInfo?.train_brake_system}
							resumeConditions={resumeConditions}
						/>
					)}
					{displayRerResumeConditionsDrawer && (
						<RerResumeConditionsDrawer processInfo={processInfo} line={processLine} />
					)}
				</ul>
			</SprPage>
		</LoadWrapper>
	);
};

const ProcessHistoryViewWrapper = ({ processOffline }) => (
	<HistoryProcessProvider processOffline={processOffline}>
		<ProcessHistoryView />
	</HistoryProcessProvider>
);

export default ProcessHistoryViewWrapper;

ProcessHistoryViewWrapper.propTypes = {
	processOffline: PropTypes.bool,
};
