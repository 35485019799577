const fields = [
	{
		name: 'valve_maneuver',
		key: 'valve-maneuver',
		step: 0,
		line: ['A', 'B'],
		type: 'radio',
		values: ['yes', 'no'],
	},
	{
		name: 'affected_equipment',
		key: 'affected-equipment',
		step: 0,
		line: ['A', 'B'],
		type: 'table',
	},
	{
		name: 'isolated_compressor_units',
		key: 'isolated-compressor-units',
		step: 0,
		line: ['A', 'B'],
		type: 'number',
	},
	{
		name: 'isolated_emd_units',
		key: 'isolated-emd-units',
		step: 0,
		line: ['A', 'B'],
		type: 'number',
	},
	{
		name: 'kadi_position',
		key: 'kadi-position',
		step: 0,
		line: ['B'],
		type: 'radioInput',
		values: ['normal', 'direct'],
	},
	{
		name: 'break_test',
		key: 'break-test',
		step: 1,
		line: ['A', 'B'],
		type: 'radio',
		values: ['yes', 'no'],
	},
	{
		name: 'break_emd_units',
		key: 'break-emd-units',
		step: 1,
		line: ['A'],
		type: 'number',
	},
	{
		name: 'ziscov_position',
		key: 'ziscov-position',
		step: 1,
		line: ['A'],
		type: 'radio',
		values: ['service', 'COVTHS'],
	},
	{
		name: 'speed_decrease',
		key: 'speed-decrease',
		step: 1,
		line: ['A'],
		type: 'radioInput',
		values: ['1/10', '1/4', '1/2', '30km/h'],
	},
	{
		name: 'zalidi_position',
		key: 'zalidi-position',
		step: 1,
		line: ['A'],
		type: 'radioInput',
		values: ['normal', 'direct'],
	},
	{
		name: 'resume',
		key: 'resume',
		step: 1,
		line: ['A'],
		type: 'radio',
		values: ['travellers', 'hlp', 'assistance'],
	},
	{
		name: 'isolated_break_bogies_units',
		key: 'isolated-break-bogies-units',
		step: 1,
		line: ['B'],
		type: 'number',
	},
	{
		name: 'break_bogies_units',
		key: 'break-bogies-units',
		step: 1,
		line: ['B'],
		type: 'number',
	},
];

const ResultsSheetFormFields = (line, step) =>
	fields.filter((field) => {
		const { line: currentLine, step: currentStep } = field;
		return currentLine.includes(line) && currentStep === step;
	});

export default ResultsSheetFormFields;
