import React, { useCallback, useEffect, useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './textarea.scss';

/**
 * Render a Textarea component
 * @param {object} props
 * @param {string} [props.value=""]
 * @param {string} props.label
 * @param {function} props.onChange
 * @param {function} props.onBlur
 * @param {string} props.name
 * @param {string} props.placeholder
 * @param {string} props.className
 * @param {string} props.helperText
 * @param {boolean} props.invalid
 * @param {boolean} props.disabled
 * @param {boolean} props.preventNewLine
 * @param {boolean} props.hasFocus
 */
const Textarea = ({
	value = '',
	label,
	onChange,
	onBlur,
	name,
	placeholder,
	className,
	helperText,
	invalid,
	disabled,
	preventNewLine,
	hasFocus = false,
}) => {
	const ref = useRef();

	const initFocus = () => {
		if (hasFocus) {
			handleLabelFocus();
		}
	};

	const handleLabelFocus = useCallback(() => {
		if (ref?.current) {
			ref.current.focus();
			// set focus position at the end of the field
			ref.current.setSelectionRange(value.length, value.length);
		}
	}, [value.length]);

	const handleKeyPress = (event) => {
		if (preventNewLine && event.key === 'Enter') {
			event.preventDefault();
		}
	};

	useEffect(initFocus, [handleLabelFocus, hasFocus]);

	return (
		<div
			className={classnames('textarea', className, {
				'textarea--invalid': invalid,
				'textarea--disabled': disabled,
			})}
		>
			<TextareaAutosize
				ref={ref}
				className={classnames('textarea__field', { 'textarea__field--valid': value })}
				type="text"
				name={name}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				placeholder={placeholder}
				disabled={disabled}
				onKeyUp={handleKeyPress}
			/>
			<label onClick={handleLabelFocus} className="textarea__label" role="presentation">
				{label}
			</label>
			{helperText && <span className="textarea__helper-text">{helperText}</span>}
		</div>
	);
};

export default Textarea;

Textarea.propTypes = {
	value: PropTypes.string.isRequired,
	label: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	helperText: PropTypes.string,
	invalid: PropTypes.bool,
	disabled: PropTypes.bool,
	preventNewLine: PropTypes.bool,
	hasFocus: PropTypes.bool,
};
