import { differenceInMilliseconds } from 'date-fns';

import { getCacheVersionEnv } from '../../config';
import { getBinderStatus } from '../../domains/binder/binder.services';

import { fetchImage } from './api/api-service';
import { getPendingEntity } from './api/api-utils';
import fetchAllEntityFromAPI from './api/fetch-all-entity-from-api';
import {
	getCacheContent,
	handleCacheAction,
	publishEntity,
	saveImageInCache,
} from './cache/cache-service';
import { getAllEntityDiff, removeEntity } from './entity/entity-utils';
import {
	activityUpdateRateTime,
	getStatus,
	isLastActivityRecent,
	setStatus,
	updateStatusActivityDate,
} from './entity/status-utils';

/**
 * @typedef {Object} CacheEntity
 * @property data
 * @property metadata
 */

/**
 * @typedef {Object} CacheUpdateAction
 * @property {'save'|'remove'} action
 * @property {string} entity,
 * @property {string} ref
 */

/**
 * Update cache according to new data fetched
 * @param {string} line The current line updated
 * @param {object[]} cacheData The current cache content
 * @param {object[]} fetchData The last fetched data
 * @param {object} currentStatus The status from the synchronisation start
 * @return {Promise<void>}
 */
const updateCache = async (line, cacheData, fetchData, currentStatus) => {
	await setStatus(line, 'in-progress');

	// Update last activity date every 5s
	await updateStatusActivityDate(line);
	const intervalId = setInterval(() => {
		updateStatusActivityDate(line);
	}, activityUpdateRateTime);

	try {
		// Generate all change action to do on current cache content
		const cacheDiff = getAllEntityDiff(cacheData, fetchData, currentStatus.metadata.date);
		if (Array.isArray(cacheDiff)) {
			// Apply all action except for image download ( not (entity = image && action = save) )
			const entityActionList = cacheDiff.filter(
				({ entity, action }) => entity !== 'image' || action === 'remove'
			);
			await Promise.all(entityActionList.map(handleCacheAction(fetchData, currentStatus)));

			// Handle image download
			const saveImageAction = cacheDiff.filter(
				({ entity, action }) => entity === 'image' && action === 'save'
			);
			const imageList = await fetchImage(saveImageAction);
			await saveImageInCache(imageList, fetchData, line);
		}
	} catch (err) {
		console.error(err);
	} finally {
		clearInterval(intervalId);
	}

	await setStatus(line, 'success');
};

/**
 * Synchronize the cache with all up to date data
 * @param line The line to synchronize
 */
const synchroniseCache = async (line) => {
	const storedStatus = await getStatus(line);

	// launch synchronization on failed or in-progress but timed-out cache status
	let launchSync =
		storedStatus.data.state === 'failed' ||
		(storedStatus.data.state === 'in-progress' && !isLastActivityRecent(storedStatus));

	// launch synchronization on success status only when cache age is older than api age
	if (storedStatus.data.state === 'success') {
		const binderStatus = await getBinderStatus(line);
		// compare last cache and api update dates
		const cacheAge = differenceInMilliseconds(Date.now(), storedStatus?.data?.lastSyncDate);
		let apiAge = 0;
		if (binderStatus?.data) {
			const { binder_last_update: binderLastUpdate, train_last_update: trainLastUpdate } =
				binderStatus.data;
			const lastApiSyncDate = Math.max(Date.parse(binderLastUpdate), Date.parse(trainLastUpdate));
			apiAge = differenceInMilliseconds(Date.now(), lastApiSyncDate);
		}
		launchSync = cacheAge > apiAge;
	}

	if (launchSync) {
		const cacheVersion = getCacheVersionEnv();
		// Valid when global page version not changed and status is valid
		const valid =
			storedStatus.data.cacheVersion === cacheVersion && storedStatus.data.state === 'success';
		try {
			const cacheContent = await getCacheContent(valid, line);
			const apiContent = await fetchAllEntityFromAPI(line);

			return await updateCache(line, cacheContent, apiContent, storedStatus);
		} catch (error) {
			console.error(error);
			await setStatus(line, 'failed');
		}
	}
};

const publishPendingEntityContext = { syncInProgress: false };
/**
 * Attempt to publish all pending entity for the current line.
 * @param {string|number} line The current line
 * @returns {Promise<void>}
 */
let publishPendingEntity = async function (line) {
	if (!this.syncInProgress) {
		this.syncInProgress = true;
		try {
			const pendingEntityList = await getPendingEntity(line);

			// Attempt to send publication pending entity
			const publishProcessList = pendingEntityList.map(async (processCacheEntry) => {
				try {
					await publishEntity(processCacheEntry);
					const { entity, line: processLine, ref } = processCacheEntry.metadata;
					// Clear sent entity from cache
					await removeEntity(entity, processLine, ref);
				} catch (error) {
					// If any error happen, do nothing to cache.
					// Next retry for failed publication entity we'll be triggered on next call
					console.info('Unable to publish entity: ', error);
				}
			});

			await Promise.allSettled(publishProcessList);
		} catch (error) {
			console.error(error);
		} finally {
			this.syncInProgress = false;
		}
	}
};
publishPendingEntity = publishPendingEntity.bind(publishPendingEntityContext);

export { publishPendingEntity, synchroniseCache, updateCache };
