import { requiredFieldValidator } from '../../../../shared';
import { sheetNumberValidator } from '../../utils/form-utils';

/**
 * Validate the edit sheet title popup's form
 * @param form
 * @param params
 * @param params.sheetNumber
 * @param params.sheetList
 * @param params.binderType
 * @return {{color: string[], title: string[], sheetSignal: string[] }}
 */
const validateEditSheetTitleForm = (form, params = {}) => {
	const { title, number } = form;
	const { sheetNumber, sheetList = [], binderType = '', existingSheet = [] } = params;
	const sheetNumberErrorList = [
		requiredFieldValidator(number),
		sheetNumberValidator(number, existingSheet, sheetNumber),
	].flat(1);
	return {
		title: requiredFieldValidator(title),
		number: sheetNumberErrorList,
		color: [],
		sheetSignal: binderType === 'man' ? sheetNumberValidator(sheetNumber, sheetList) : [],
	};
};

export default validateEditSheetTitleForm;
