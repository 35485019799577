import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import qs from 'qs';

import './offline-auth.scss';

import { offlineUser } from '../../../../config/offline';
import { setupOfflineAuth, verifyCode } from '../../../../config/otp';
import {
	checkFormError,
	hasFieldError,
	requiredFieldValidator,
	useAppSetupContext,
} from '../../../../shared';
import { getEntityList } from '../../../../shared/cache-access/entity/entity-utils';
import {
	setStoredAssociatedLine,
	setStoredSide,
} from '../../../../shared/context/spr-context/spr-context-localstorage-utils';
import { Button } from '../../../../theme';
import LineDropdown from '../../components/line-dropdown/line-dropdown';
import OfflineHeader from '../../components/offline-header/offline-header';
import OtpInput from '../../components/otp-input/otp-input';

/**
 * Render the offline auth page form
 * @return {JSX.Element}
 * @constructor
 */
const OfflineAuth = () => {
	const [form, setForm] = useState({ code: '' });
	const [formError, setFormError] = useState({});
	const [lineOptions, setLineOptions] = useState([]);
	const { otpConfig } = useAppSetupContext();
	const history = useHistory();
	const { t } = useTranslation();

	const updateForm = (name, value) =>
		setForm((previousForm) => ({ ...previousForm, [name]: value }));

	const validateOfflineAuthForm = useCallback(
		(formData) => {
			const codeErrorList = [];
			if (!/\d{6}/.test(formData.code)) {
				codeErrorList.push('format');
			}
			if (!verifyCode(otpConfig, formData.code)) {
				codeErrorList.push('invalid');
			}

			return {
				code: codeErrorList,
				line: requiredFieldValidator(formData.line),
			};
		},
		[otpConfig]
	);

	useEffect(() => {
		setFormError(validateOfflineAuthForm(form));
	}, [form, validateOfflineAuthForm]);

	const getSyncLine = useCallback(() => {
		getEntityList('status').then((lineCacheEntryList) => {
			const isCacheEmpty = lineCacheEntryList.length === 0;
			if (isCacheEmpty) {
				return history.replace('/no-cache');
			}
			const syncLineList = lineCacheEntryList
				.filter((lineEntry) => lineEntry.data.state === 'success')
				.map((lineEntry) => lineEntry.metadata.ref);
			setLineOptions(syncLineList);

			// Select the first line when only one
			if (syncLineList.length === 1) {
				updateForm('line', syncLineList[0]);
			}
		});
	}, [history]);

	const handleSubmit = () => {
		const newFormError = validateOfflineAuthForm(form);
		if (!checkFormError(newFormError)) {
			setStoredAssociatedLine(form.line);
			setStoredSide('train');
			setupOfflineAuth(offlineUser).then(() => {
				history.push(`/railway${qs.stringify({ showLineStatus: true }, { addQueryPrefix: true })}`);
			});
		} else {
			setFormError(newFormError);
		}
	};

	useEffect(getSyncLine, [getSyncLine]);

	return (
		<main className="offline-auth">
			<OfflineHeader />
			<div className="offline-auth__content">
				<p className="offline-auth__text">{t('user:offline-auth.choose-line')}</p>
				<LineDropdown
					lines={lineOptions}
					textPlaceholder={t('user:offline-auth.line-placeholder')}
					onChange={(event) => updateForm('line', event.target.value)}
				/>
				<p className="offline-auth__text">{t('user:offline-auth.enter-password')}</p>
				<OtpInput
					className="offline-auth__otp"
					onChange={(value) => updateForm('code', value)}
					error={
						hasFieldError('invalid', formError.code) && !hasFieldError('format', formError.code)
					}
				/>
				<Button
					label={t('user:offline-auth.submit')}
					disabled={checkFormError(formError)}
					onClick={handleSubmit}
				/>
			</div>
		</main>
	);
};

export default OfflineAuth;
