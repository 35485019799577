import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { TextField } from '../../../../../../theme';
import Dropdown from '../../../../../../theme/components/form/dropdown/dropdown';

/**
 * BinderDetailInfoDirector component
 * @param {object} props
 * @param {boolean} props.isTrainBinder
 * @param {boolean} props.isEditing
 * @param {object} props.lineManager
 * @param {function} props.handleChange
 * @returns {JSX.Element}
 */
const BinderDetailInfoDirector = ({
	isTrainBinder,
	lineManager,
	isEditing,
	handleChange,
	documentManager,
}) => {
	const { t } = useTranslation();

	const baseI18 = 'binder:binder-detail.info.';
	const dropDownName = 'title';
	const textFieldName = 'manager';

	const dropDownMtsOptions = [
		{
			label: t(baseI18 + 'choose-title'),
			value: 'null',
			dataGender: true,
		},
		{
			label: t(baseI18 + 'director-male'),
			value: 'lineManagerMale',
			hidden: isTrainBinder,
			dataGender: 'male',
		},
		{
			label: t(baseI18 + 'director-female'),
			value: 'lineManagerFemale',
			hidden: isTrainBinder,
			dataGender: 'female',
		},
		{
			label: t(baseI18 + `for-director${isTrainBinder ? '-mts' : ''}-male`),
			value: isTrainBinder ? 'forLineManagerTrainMale' : 'forLineManagerMale',
			dataGender: 'male',
		},
		{
			label: t(baseI18 + `for-director${isTrainBinder ? '-mts' : ''}-female`),
			value: isTrainBinder ? 'forLineManagerTrainFemale' : 'forLineManagerFemale',
			dataGender: 'female',
		},
		{
			label: t(baseI18 + 'director-operational-unit-male'),
			value: 'operationalUnitManagerMale',
			hidden: isTrainBinder,
			dataGender: 'male',
		},
		{
			label: t(baseI18 + 'director-operational-unit-female'),
			value: 'operationalUnitManagerFemale',
			hidden: isTrainBinder,
			dataGender: 'female',
		},
		{
			label: t(baseI18 + 'for-director-operational-unit-male'),
			value: 'forOperationalUnitManagerMale',
			hidden: isTrainBinder,
			dataGender: 'male',
		},
		{
			label: t(baseI18 + 'for-director-operational-unit-female'),
			value: 'forOperationalUnitManagerFemale',
			hidden: isTrainBinder,
			dataGender: 'female',
		},
		{
			label: t(baseI18 + 'for-reglementation'),
			value: 'reglementationMale',
			dataGender: 'male',
		},
	];

	const dropDownRerOptions = [
		{
			label: t(baseI18 + 'choose-title'),
			value: 'null',
			dataGender: true,
		},
		{
			label: t(baseI18 + 'director-rer-male'),
			value: 'lineManagerTrainMale',
			dataGender: 'male',
		},
		{
			label: t(baseI18 + 'director-rer-female'),
			value: 'lineManagerTrainFemale',
			dataGender: 'female',
		},
		{
			label: t(baseI18 + 'for-director-rer-male'),
			value: 'forLineManagerTrainMale',
			dataGender: 'male',
		},
		{
			label: t(baseI18 + 'for-director-rer-female'),
			value: 'forLineManagerTrainFemale',
			dataGender: 'female',
		},
		{
			label: t(baseI18 + 'for-reglementation'),
			value: 'reglementationMale',
		},
	];

	const renderOption = () => {
		const dropDownOptions = documentManager === 'MTS' ? dropDownMtsOptions : dropDownRerOptions;
		return dropDownOptions.map((option) => {
			if (!option.hidden) {
				const { label: optionLabel, value: optionValue } = option;
				return (
					<option key={optionValue} value={optionValue}>
						{optionLabel}
					</option>
				);
			}
			return null;
		});
	};

	return (
		<div className="content__group__fields">
			<Dropdown
				disabled={!isEditing}
				onChange={handleChange}
				name={dropDownName}
				className="half-width"
				value={lineManager?.title + (lineManager?.gender === 'male' ? 'Male' : 'Female')}
			>
				{renderOption()}
			</Dropdown>
			<div className="fields-separator"> </div>
			<TextField
				inputClassName="content__group__fields--text"
				className="half-width"
				name={textFieldName}
				label={lineManager?.manager ? '' : t(baseI18 + 'enter-a-name')}
				value={lineManager?.manager || ''}
				disabled={!isEditing || !lineManager?.title || lineManager?.title === 'null'}
				onChange={handleChange}
			/>
		</div>
	);
};

export default memo(BinderDetailInfoDirector);

BinderDetailInfoDirector.propTypes = {
	isTrainBinder: PropTypes.bool,
	isEditing: PropTypes.bool,
	lineManager: PropTypes.object,
	handleChange: PropTypes.func,
	documentManager: PropTypes.string,
};
