import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-copy-template.scss';

import { PopupConfirm } from '../../../../../../theme';
import {
	hasNextTemplatesInBothYesAndNo,
	hasNoNextTemplatesInBothYesAndNo,
} from '../../utils/handle-copy-paste-template';

const PopupCopyTemplate = ({ template, popupControl }) => {
	const { t } = useTranslation();

	return (
		<PopupConfirm
			popupControl={popupControl}
			title={t('sheet:popup.copy-template.title')}
			ctaConfirmLabel="sheet:popup.copy-template.cta-confirm-label"
			className="popup-copy-template"
		>
			<div className="popup-copy-template__message">
				<span className="popup-copy-template__message__text">
					{t('sheet:popup.copy-template.text.basic')}
				</span>
				<span className="popup-copy-template__message__text">
					{t('sheet:popup.copy-template.text.no-image-copied')}
				</span>
				{hasNoNextTemplatesInBothYesAndNo(template) && (
					<span className="popup-copy-template__message__text">
						{t('sheet:popup.copy-template.text.question-terminations')}
					</span>
				)}
				{template?.type === 'question' && !hasNextTemplatesInBothYesAndNo(template) && (
					<span className="popup-copy-template__message__text">
						{t('sheet:popup.copy-template.text.question')}
					</span>
				)}
			</div>
		</PopupConfirm>
	);
};

export default PopupCopyTemplate;

PopupCopyTemplate.propTypes = {
	template: PropTypes.object,
	popupControl: PropTypes.object,
};
