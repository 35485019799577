import React from 'react';
import { useTranslation } from 'react-i18next';

import './mrf-intervention-template-form.scss';

/**
 * Render the MRFInterventionTemplateForm component
 * @returns {JSX.Element}
 */
const MRFInterventionTemplateForm = () => {
	const { t } = useTranslation();

	return (
		<div className="mrf-intervention-template-panel">
			<div className="mrf-intervention-template-panel__title">
				{t('sheet:sheet-details.template.mrfIntervention.label')}
			</div>
		</div>
	);
};

export default MRFInterventionTemplateForm;
