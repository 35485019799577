import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import './railway-binder-list.scss';

import { SprPage } from '../../../../theme';

import RailwayBinderRow from './components/railway-binder-row/railway-binder-row';

/**
 * Render the railway binder list page
 * @param {object} props
 * @param {object[]} props.binders
 * @param {string} props.processId
 * @param {JSX.Element} props.subHeader
 * @param {JSX.Element} props.sideBar
 * @param {boolean} props.allowLineSwitch
 * @param {boolean} props.disabledLineSwitch
 * @return {JSX.Element}
 * @constructor
 */
const RailwayBinderList = ({
	binders = [],
	subHeader,
	processId,
	sideBar = null,
	allowLineSwitch = false,
	disabledLineSwitch = false,
}) => {
	const { trainTechId, materialTechId } = useParams();
	const { t } = useTranslation();

	return (
		<SprPage
			className="railway-binder-list"
			subheader={subHeader}
			allowLineSwitch={allowLineSwitch}
			disabledLineSwitch={disabledLineSwitch}
		>
			{sideBar}
			<p className="railway-binder-list__text">{t('train:railway-binder-list.select-binder')}</p>
			<ul className="railway-binder-list__list">
				{binders.map((binder) => (
					<RailwayBinderRow
						key={binder?.id}
						binder={binder}
						processId={processId}
						trainTechId={trainTechId}
						materialTechId={materialTechId}
					/>
				))}
			</ul>
		</SprPage>
	);
};

export default RailwayBinderList;

RailwayBinderList.propTypes = {
	binders: PropTypes.arrayOf(PropTypes.object),
	processId: PropTypes.string,
	sideBar: PropTypes.element,
	subHeader: PropTypes.element,
	allowLineSwitch: PropTypes.bool,
	disabledLineSwitch: PropTypes.bool,
};
