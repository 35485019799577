import { useSheetContentContext } from '../../../context/sheet-content-context';
import { deleteTemplate } from '../../../context/sheet-content-context-utils';

const useStepTemplate = () => {
	const { nodeList, setNodeList, addNodeHistory } = useSheetContentContext();

	const generateStepContent = (list) => {
		list.reduce((counter, template) => {
			if (template.type === 'step') {
				template.content = { step: counter };
				return counter + 1;
			}
			return counter;
		}, 1);
		return [...list];
	};

	const updateStepContent = () => {
		const newNodeList = generateStepContent(nodeList);
		setNodeList(newNodeList);
		addNodeHistory(newNodeList);
	};

	const deleteStepTemplate = (location) => {
		setNodeList((previousNodeList) => {
			let newNodeList = deleteTemplate(previousNodeList, location);
			newNodeList = generateStepContent(newNodeList);
			addNodeHistory(newNodeList);
			return newNodeList;
		});
	};

	return { updateStepContent, deleteStepTemplate };
};

export default useStepTemplate;
