import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-create-process-train.scss';

import { checkFormError, useAppSetupContext } from '../../../../shared';
import { Button, ErrorMessage, PopupDefault } from '../../../../theme';
import { fetchSheetByBinder } from '../../../sheet/sheet.services';
import { fetchTrain } from '../../../train/train.services';
import {
	buildTaskLog,
	createStartHistoryEntry,
} from '../../pages/spr-run-process/utils/task-history-utils';
import { createProcess } from '../../process.services';
import {
	getFormProcessTrainFieldMessageKey,
	validatePopupCreateProcessTrainForm,
} from '../../utils/validate-popup-process-train-form';
import FormProcessTrain from '../form-process-train/form-process-train';

/**
 * Render the popup to create process train
 * @param {object} props
 * @param {object} props.popupControl
 * @param {function} props.onSubmitSuccess
 * @param {number} props.line
 * @returns {JSX.Element}
 */
const PopupCreateProcessTrain = ({ onSubmitSuccess = () => {}, popupControl, line }) => {
	const { t } = useTranslation();
	const { user: currentUser } = useAppSetupContext();

	const [formData, setFormData] = useState({ line, type: 'train' });
	const [networkError, setNetworkError] = useState(false);
	const [trainList, setTrainList] = useState([]);

	const formError = validatePopupCreateProcessTrainForm(formData);
	const formErrorMessage = getFormProcessTrainFieldMessageKey(formError);

	const getTrainList = () => {
		fetchTrain('', { line, is_active: true }).then((response) => {
			setTrainList(response?.data);
		});
	};

	const reInitForm = () => {
		setFormData({ line, type: 'train' });
		setNetworkError(false);
	};

	const handleChange = useCallback((event) => {
		const { name, value } = event.target;
		// reset position fields on position_type change except when switching between platform & half-platform
		const avoidResetPosition = ['platform', 'half-platform'];
		setFormData((oldFormData) => {
			const newFormData = { ...oldFormData };

			// reset position fields on position_type change except when switching between platform & half-platform
			if (
				name === 'position_type' &&
				![value, oldFormData.position_type].every((pos) => avoidResetPosition.includes(pos))
			) {
				delete newFormData.position_info;
				delete newFormData.track;
			}
			// reset position_info on track change when in interstation
			else if (name === 'track' && newFormData.position_type === 'interstation') {
				delete newFormData.position_info;
			}
			// reset sheet field on binder change
			else if (name === 'binder_tech_id') {
				delete newFormData.sheet_tech_id;
			}

			return { ...newFormData, [name]: value };
		});
	}, []);

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!checkFormError(formError)) {
			reInitForm();

			let sheetData;

			try {
				const sheetsByBinder = await fetchSheetByBinder(formData.binder_tech_id);
				const sheetsByBinderData = await sheetsByBinder?.data;
				if (!sheetsByBinderData || sheetsByBinderData.length === 0) {
					throw new Error(t('process:popup.create-process-train.sheetDefaultErrorMsg'));
				}
				if (formData?.sheet_tech_id?.length > 0) {
					sheetData = sheetsByBinderData.find((sheet) => sheet.sheet_id === formData.sheet_tech_id);
				} else {
					sheetData = sheetsByBinderData[0] || [];
					formData.sheet_tech_id = sheetData.sheet_id;
				}
			} catch (err) {
				setNetworkError(true);
			}

			const taskInfo = { task: createStartHistoryEntry(), additionalData: {} };
			// add train info
			taskInfo.additionalData.train_id = trainList.find(
				(train) => train.tech_id === formData.train_tech_id
			)?.id;
			const taskLog = buildTaskLog(sheetData, taskInfo, currentUser);

			createProcess({ history: [taskLog], ...formData }, { isCurrentOwner: false })
				.then(popupControl.hide)
				.then(onSubmitSuccess)
				.catch(() => setNetworkError(true));
		}
	};

	useEffect(getTrainList, [line]);

	return (
		<PopupDefault
			className="popup-create-train__wrapper"
			popupControl={popupControl}
			title={t('process:popup.create-process-train.title')}
			onClose={reInitForm}
		>
			<form className="popup-create-train__form" onSubmit={handleSubmit}>
				<div className="popup-create-train__form-wrapper">
					<FormProcessTrain
						form={formData}
						validation={formError}
						errorMessage={formErrorMessage}
						handleChange={handleChange}
						line={line}
						trainList={trainList}
					/>
				</div>
				<div className="popup-create-train__controller">
					<Button
						variant="primary"
						className="popup-create-train__submit"
						type="submit"
						disabled={checkFormError(formError)}
						label={t('train:popup.form.submit')}
					/>
				</div>
				{networkError && (
					<ErrorMessage className="popup-create-train__network-error">
						{t('error.default')}
					</ErrorMessage>
				)}
			</form>
		</PopupDefault>
	);
};

export default PopupCreateProcessTrain;

PopupCreateProcessTrain.propTypes = {
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired,
		visible: PropTypes.bool.isRequired,
	}).isRequired,
	line: PropTypes.number.isRequired,
	onSubmitSuccess: PropTypes.func.isRequired,
};
