import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './results-sheet-radio-button.scss';

import { getUniqueKey } from '../../../../../../../shared/utils/string-utils';

const ResultsSheetRadioButton = ({
	resultsSheetFormData,
	options,
	field,
	handleChange,
	currentGroup,
}) => {
	const { name: fieldName, key, type } = field;
	const { t } = useTranslation();
	const isPartFirstField = fieldName === 'valve_maneuver' || fieldName === 'break_test';

	const onChange = async (event) => {
		const { name, value } = event.target;
		handleChange({ target: { group: currentGroup, name, value } });
	};

	const initListClassnameList = () =>
		classNames('process-results-sheet__radio-button__list', {
			'process-results-sheet__radio-button__list--first-field': isPartFirstField,
			'process-results-sheet__radio-button__list--multiple-values-field':
				options.length > 2 || resultsSheetFormData?.[currentGroup][fieldName]?.choice === 'direct',
		});

	return (
		<div
			className={classNames('process-results-sheet__radio-button', {
				'process-results-sheet__radio-button--first-field': isPartFirstField,
			})}
		>
			<div
				className={classNames('process-results-sheet__radio-button__title-wrapper', {
					'process-results-sheet__radio-button__title-wrapper--first-field': isPartFirstField,
				})}
			>
				<span
					className={classNames('process-results-sheet__radio-button__title-wrapper__title', {
						'process-results-sheet__radio-button__title-wrapper__title--first-field':
							isPartFirstField,
					})}
				>
					{t(`process:process-rer-resume.results-sheet.${key}`)}
				</span>
			</div>
			<ul className={initListClassnameList()}>
				{options.map((option) => {
					const selected =
						resultsSheetFormData?.[currentGroup][fieldName] === option ||
						resultsSheetFormData?.[currentGroup][fieldName]?.choice === option;
					return (
						<li
							className="process-results-sheet__radio-button__list__item"
							key={`${getUniqueKey(option)}`}
						>
							<input
								className="process-results-sheet__radio-button__list__input-field"
								name={fieldName}
								type={type}
								checked={selected}
								value={option}
								onClick={onChange}
							/>
							<label
								htmlFor={option}
								className="process-results-sheet__radio-button__list__item__label"
							>
								{t(`process:process-rer-resume.results-sheet.${key}-form.${option}`)}
							</label>
						</li>
					);
				})}
			</ul>
			{fieldName === 'break_test' && resultsSheetFormData?.[currentGroup][fieldName] === 'yes' && (
				<span className="process-results-sheet__radio-button__list__item__info">
					{t('process:process-rer-resume.results-sheet.break-test-info')}
				</span>
			)}
		</div>
	);
};

export default ResultsSheetRadioButton;

ResultsSheetRadioButton.propTypes = {
	resultsSheetFormData: PropTypes.object,
	options: PropTypes.array,
	field: PropTypes.object,
	handleChange: PropTypes.func,
	currentGroup: PropTypes.string,
};
