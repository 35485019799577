import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './profile-icon.scss';

import { adminAccessRoles } from '../../../domains/user';
import { useAppSetupContext, useDropdownMenu } from '../../../shared';
import { DropdownMenu, DropdownMenuItem } from '../../../theme/index';
import { logout } from '../../auth/auth.services';
import { buildUserFullName } from '../../utils/build-user-name';

const ProfileIcon = ({ className }) => {
	const { oktaAuth = {} } = useOktaAuth() || {};

	const history = useHistory();
	const { t } = useTranslation();
	const { user } = useAppSetupContext();

	const { itemConfig, ...menuConfig } = useDropdownMenu();

	const items = [
		{
			name: 'admin-page',
			label: t('theme:cdr-page.profile-button.admin-page'),
			onClick: () => history.push('/admin'),
		},
		{
			name: 'legals',
			label: t('theme:cdr-page.profile-button.legals'),
			onClick: () => history.push('/legals'),
		},
		{
			name: 'logout',
			label: t('theme:cdr-page.profile-button.logout'),
			onClick: () => logout(oktaAuth),
		},
	];

	const itemsDependingOfRole = () => {
		const removeAdminPageLink = items.filter((item) => item.name !== 'admin-page');
		if (!adminAccessRoles.includes(user?.role)) {
			return removeAdminPageLink;
		}
		return items;
	};

	const displayDropdownItem = (item) => (
		<DropdownMenuItem key={item.name} {...item} {...itemConfig} />
	);

	const userFullName = buildUserFullName(user?.firstname, user?.lastname);
	const userIdentifier = `(${user?.id})`;

	const renderStyledUserName = (fullName, identifier) => {
		return (
			<>
				<span className="profile-icon__user-info__name">{fullName}</span>
				<span className="profile-icon__user-info__matricule">{identifier}</span>
			</>
		);
	};

	return (
		<div className={classNames('profile-icon', className)}>
			<div className="profile-icon__user-info">
				{renderStyledUserName(userFullName, userIdentifier)}
			</div>
			<DropdownMenu right {...menuConfig} items={itemsDependingOfRole().map(displayDropdownItem)} />
		</div>
	);
};

export default ProfileIcon;

ProfileIcon.propTypes = {
	className: PropTypes.string,
};
