import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './textfield-autocomplete.scss';

import useDebouncedAction from '../../../../shared/custom-hook/use-debounced-action';
import { ReactComponent as IconSearch } from '../../../assets/img/icon-search.svg';
import TextField from '../text-field/text-field';

const TextfieldAutocomplete = ({
	selectedValue,
	value = '',
	name = '',
	className,
	inputClassName,
	handleChange,
	getSuggestions,
	disabled,
	label,
	refreshSuggestion,
	icon = IconSearch,
}) => {
	const ref = useRef(null);
	const [showList, setShowList] = useState(false);
	const [suggestionsList, setSuggestionsList] = useState([]);

	const handleSelectedValue = (val) => {
		selectedValue(val);
		setSuggestionsList([]);
		setShowList(false);
	};

	const handleInputChange = async (event) => {
		handleChange(event);
	};

	const handleFocusEvent = (event) => {
		// disabled firefox autocomplete
		event.target.setAttribute('autocomplete', 'off');
		setShowList(true);
	};

	// hide autocomplete list when click outside of component
	const handleClickOutside = (event) => {
		if (
			showList &&
			suggestionsList &&
			suggestionsList.length > 0 &&
			ref.current &&
			!ref.current.contains(event.target)
		) {
			setShowList(false);
		}
	};

	const handleValueChange = useDebouncedAction(() => {
		getSuggestions(value).then((data) => {
			setSuggestionsList(data);
		});
	}, 200);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(handleValueChange, [value, refreshSuggestion]);

	const generateSuggestionList = (suggestion, index) => {
		const { label: sheetLabel, value: sheetValue } = suggestion || {};
		return (
			<li key={sheetValue + index}>
				<div
					data-testid="listItem"
					className={sheetLabel?.className}
					onClick={() => handleSelectedValue(suggestion)}
				>
					{sheetLabel?.lines?.map(generateSuggestionLabel)}
				</div>
			</li>
		);
	};

	const generateSuggestionLabel = (line, index) => (
		<div key={index} className={`autocomplete-label--${line.type}`}>
			{' '}
			{line.text}{' '}
		</div>
	);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	});

	return (
		<div ref={ref}>
			<TextField
				inputClassName={classnames('textfield-autocomplete--input', inputClassName)}
				handleFocus={(e) => handleFocusEvent(e)}
				name={name}
				className={classnames('textfield-autocomplete__form-field', className)}
				value={value}
				onChange={(e) => handleInputChange(e)}
				disabled={disabled}
				label={label}
				icon={icon}
				preventNewLine
			>
				{showList && Array.isArray(suggestionsList) && suggestionsList.length > 0 && (
					<ul className="textfield-autocomplete__suggestions-list">
						{suggestionsList.map(generateSuggestionList)}
					</ul>
				)}
			</TextField>
		</div>
	);
};

export default TextfieldAutocomplete;

TextfieldAutocomplete.propTypes = {
	selectedValue: PropTypes.func.isRequired,
	value: PropTypes.string,
	name: PropTypes.string,
	className: PropTypes.string,
	inputClassName: PropTypes.string,
	handleChange: PropTypes.func,
	getSuggestions: PropTypes.func,
	disabled: PropTypes.bool,
	label: PropTypes.string,
	icon: PropTypes.node,
	hasFocus: PropTypes.bool,
	refreshSuggestion: PropTypes.func,
};
