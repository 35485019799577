import React from 'react';
import { useTranslation } from 'react-i18next';

import './termination-template-form.scss';

function TerminationTemplateForm() {
	const { t } = useTranslation();

	return (
		<div className="termination-template-panel">
			<div className="termination-template-panel__title">
				{t('sheet:sheet-details.template.termination.title')}
			</div>
		</div>
	);
}

export default TerminationTemplateForm;
