import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './cdr-main-infos.scss';

import { resolveSheetColor, SheetType } from '../../../../../domains/sheet';
import { arraySortAscending } from '../../../../../shared';
import { SubwayLineNumber } from '../../../../index';

/**
 * @typedef Props
 * @prop {object} props
 * @prop {object} binder
 * @prop {object} signalInfo
 * @prop {boolean} onSheetDetails
 * @prop {boolean} isTrainBinder
 */

/**
 * CdrMainInfos component
 * @param {Props} props
 * @returns {JSX.Element}
 */
const CdrMainInfos = ({ binder, sheet, onSheetDetails, signalInfo, isTrainBinder }) => {
	const sheetColor =
		binder?.type !== 'man' && resolveSheetColor(sheet?.color, binder?.color, sheet?.theme_color);

	const displayBinderPage = () =>
		isTrainBinder ? (
			<>
				<SheetType
					type={sheet?.type || 'parent'}
					binderType={binder?.type}
					sheetNumber={binder?.material_label}
					color={binder?.color}
					isMaterial
				/>
				<h1 className="id__text">{binder.id}</h1>
			</>
		) : (
			<>
				<h1 className="id__text">{binder.id}</h1>
				<SheetType
					type="parent"
					sheetNumber={binder?.abbreviation?.toUpperCase()}
					color={binder?.color}
					signalInfo={signalInfo}
				/>
			</>
		);
	const displayDependingOnPage = () =>
		onSheetDetails ? (
			<>
				<h1 className="id__text">
					<Link className="id__text__link" to={`/binders/${binder?.tech_id}`}>
						{binder?.id}
					</Link>
				</h1>
				<SheetType
					type={sheet.type || 'parent'}
					sheetNumber={sheet.number}
					color={sheetColor}
					binderType={binder?.type}
					signalInfo={signalInfo}
				/>
			</>
		) : (
			<>{displayBinderPage()}</>
		);

	const renderLineNumbers = () => {
		const linesSorted = arraySortAscending(binder?.associated_line);
		return linesSorted?.map((line) => (
			<div key={line} className="id__line">
				<SubwayLineNumber lineNumber={line} />
			</div>
		));
	};

	return (
		<div className="title-wrapper__id">
			{renderLineNumbers()}
			{displayDependingOnPage()}
		</div>
	);
};

export default CdrMainInfos;

CdrMainInfos.propTypes = {
	binder: PropTypes.shape({
		type: PropTypes.oneOf(['sheet', 'theme', 'man', 'train-driver', 'train-officer', 'train-auto']),
		color: PropTypes.string,
		tech_id: PropTypes.string,
		id: PropTypes.string,
		abbreviation: PropTypes.string,
		material_label: PropTypes.string,
		associated_line: PropTypes.array,
	}),
	sheet: PropTypes.shape({
		color: PropTypes.string,
		theme_color: PropTypes.string,
		type: PropTypes.string,
		number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	onSheetDetails: PropTypes.bool,
	signalInfo: PropTypes.shape({
		title: PropTypes.string,
		destination: PropTypes.string,
	}),
	isTrainBinder: PropTypes.bool,
};
