import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-warning-return.scss';

import { IconWrapper, PopupConfirm } from '../../../../theme';
import { ReactComponent as IconWarning } from '../../../../theme/assets/img/icon-warning.svg';

/**
 * Render the popup warning when doing a return on process
 * @param {object} props
 * @param {function} props.onConfirm
 * @param {object} props.popupControl
 * @return {JSX.Element}
 * @constructor
 */
const PopupWarningReturn = ({ popupControl, onConfirm }) => {
	const { t } = useTranslation();

	return (
		<PopupConfirm
			title={t('process:popup.warning-return.title')}
			error
			popupControl={popupControl}
			onConfirm={onConfirm}
		>
			<div className="popup-warning-return">
				<div className="popup-warning-return__wrapper">
					<IconWrapper className="popup-warning-return__icon" Component={IconWarning} />
					<span className="popup-warning-return__text">
						{t('process:popup.warning-return.warning')}
					</span>
				</div>
				<span className="popup-warning-return__info">{t('process:popup.warning-return.info')}</span>
			</div>
		</PopupConfirm>
	);
};

export default PopupWarningReturn;

PopupWarningReturn.propTypes = {
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired,
		show: PropTypes.func.isRequired,
		visible: PropTypes.bool.isRequired,
	}).isRequired,
	onConfirm: PropTypes.func.isRequired,
};
