import i18next from '../config/i18next';

import BorderOverlay from './components/border-overlay/border-overlay';
import BurgerMenu from './components/burger-menu/burger-menu';
import DigiprocLoader from './components/digiproc-loader/digiproc-loader';
import DottedCard from './components/dotted-card/dotted-card';
import Drawer from './components/drawer/drawer';
import DropdownMenu from './components/dropdown-menu/dropdown-menu';
import DropdownMenuItem from './components/dropdown-menu/dropdown-menu-item/dropdown-menu-item';
import Editor from './components/editor/editor';
import EditorRendering from './components/editor-rendering/editor-rendering';
import ErrorMessage from './components/error-message/error-message';
import EyeTriangle from './components/eye-triangle/eye-triangle';
import FilterAssociatedLineLabel from './components/filter/filter-associated-line-label/filter-associated-line-label';
import FilterDocTypeOption from './components/filter/filter-doc-type-option/filter-doc-type-option';
import FilterLabelLine from './components/filter/filter-label-line/filter-label-line';
import FilterMaterialOption from './components/filter/filter-material-option/filter-material-option';
import FilterTags from './components/filter/filter-tags/filter-tags';
import Button from './components/form/button/button';
import ColorChooser from './components/form/color-chooser/color-chooser';
import CustomDropdown from './components/form/custom-dropdown/custom-dropdown';
import DateChooser from './components/form/date-chooser/date-chooser';
import Dropdown from './components/form/dropdown/dropdown';
import FilterDropDown from './components/form/filter-dropdown/filter-dropdown';
import LineSelector from './components/form/line-selector/line-selector';
import TextField from './components/form/text-field/text-field';
import TextfieldAutocomplete from './components/form/text-field-autocomplete/textfield-autocomplete';
import Textarea from './components/form/textarea/textarea';
import TimeChooser from './components/form/time-chooser/time-chooser';
import IconWrapper from './components/icon-wrapper/icon-wrapper';
import ImageListView from './components/image-list-view/image-list-view';
import ListContentTemplate from './components/list-content-template/list-content-template';
import LogoDigiproc from './components/logo-digiproc/logo-digiproc';
import CdrPage from './components/page/cdr-page/cdr-page';
import SprPage from './components/page/spr-page/spr-page';
import PopupConfirm from './components/popup/popup-confirm/popup-confirm';
import PopupDefault from './components/popup/popup-default/popup-default';
import SearchBar from './components/search-bar/search-bar';
import Spinner from './components/spinner/spinner';
import SquareColorIcon from './components/square-color-icon/square-color-icon';
import SubwayLineNumber from './components/subway-line-number/subway-line-number';
import TabsNav from './components/tabs/tabs-nav/tabs-nav';
import TabsNavItem from './components/tabs/tabs-nav-item/tabs-nav-item';
import TabsPanel from './components/tabs/tabs-nav-panel/tabs-nav-panel';
import ToggleSwitch from './components/toggle-switch/toggle-switch';
import i18nTheme from './i18n/fr.json';

export { default as LoadingPage } from './pages/loading-page/loading-page';
export { default as NoCacheAvailable } from './pages/no-cache-available/no-cache-available';

i18next.addResourceBundle('fr', 'theme', i18nTheme);

export {
	BorderOverlay,
	BurgerMenu,
	Button,
	CdrPage,
	ColorChooser,
	CustomDropdown,
	DateChooser,
	DigiprocLoader,
	DottedCard,
	Drawer,
	Dropdown,
	DropdownMenu,
	DropdownMenuItem,
	Editor,
	EditorRendering,
	ErrorMessage,
	EyeTriangle,
	FilterAssociatedLineLabel,
	FilterDocTypeOption,
	FilterDropDown,
	FilterLabelLine,
	FilterMaterialOption,
	FilterTags,
	IconWrapper,
	ImageListView,
	LineSelector,
	ListContentTemplate,
	LogoDigiproc,
	PopupConfirm,
	PopupDefault,
	SearchBar,
	Spinner,
	SprPage,
	SquareColorIcon,
	SubwayLineNumber,
	TabsNav,
	TabsNavItem,
	TabsPanel,
	Textarea,
	TextField,
	TextfieldAutocomplete,
	TimeChooser,
	ToggleSwitch,
};
