import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './filter-associated-line-label.scss';

import { SubwayLineNumber } from '../../../index';

/**
 * Render the AssociatedLineDropdownOption components
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.line
 * @returns {JSX.Element}
 */
const FilterAssociatedLineLabel = ({ className, line }) => {
	const { t } = useTranslation();

	return (
		<label className={classnames(className, 'filter-associated-line-label')} htmlFor={line}>
			<SubwayLineNumber className="filter-associated-line-label__line" lineNumber={line} />
			<span className="filter-associated-line-label__label">
				{`${t('binder:binder-list.filter.filter-list.line')} ${line}`}
			</span>
		</label>
	);
};

export default FilterAssociatedLineLabel;

FilterAssociatedLineLabel.propTypes = {
	className: PropTypes.string,
	line: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
