const resultsSheetTableLineA = [
	{
		element1: {
			key: 'first-element',
			content: [
				'EMD',
				'EMD',
				'CP',
				'CP',
				'EMD',
				'EMD',
				'CP',
				'CP',
				'EMD',
				'EMD',
				'CP',
				'CP',
				'EMD',
				'EMD',
				'CP',
				'CP',
				'EMD',
				'EMD',
				'CP',
			],
		},
	},
	{
		element2: {
			key: 'second-element',
			content: [
				'CP',
				'EMD',
				'EMD',
				'CP',
				'CP',
				'EMD',
				'EMD',
				'CP',
				'CP',
				'EMD',
				'EMD',
				'CP',
				'CP',
				'EMD',
				'EMD',
				'CP',
				'CP',
				'EMD',
				'EMD',
			],
		},
	},
];

export default resultsSheetTableLineA;
