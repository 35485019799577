import { v4 as uuid } from 'uuid';

/**
 * @typedef TemplateLocation
 * @property {string} id - The active template id
 * @property {string} [parentId] - The parent template id (only for sub template)
 * @property {"yes"|"no"} [questionSide] - The template's side of the list containing the active template (only for question template)
 * @property {boolean} [questionEnd] - True if template is in end-item (only for question template)
 */

/**
 * @typedef SheetTemplate
 * @property {string} id - The template id
 * @property {string} type - The template type
 * @property {TemplateLocation} [location] - The template location in the list
 * @property {object} [content] - The template content
 */

const deepCopy = (data) => JSON.parse(JSON.stringify(data));

/**
 * Create an empty template
 * @param {string} type The created template type
 * @return {SheetTemplate}
 */
const createTemplate = (type = 'new') => {
	const id = uuid();
	return { id, type };
};

/**
 * Get template list from parent
 * @param {SheetTemplate} parent
 * @return {SheetTemplate[]|*|*[]}
 */
const getTemplateSubList = (parent = {}) => {
	const { type, content = {} } = parent;

	if (type === 'multipleChoice') {
		return content.subNodes || [];
	} else if (type === 'question') {
		const { yes = {}, no = {} } = content;
		const { items: yesItems = [], 'end-item': yesEnd = {} } = yes;
		const { items: noItems = [], 'end-item': noEnd = {} } = no;
		const result = [...yesItems, ...noItems];

		if (yesEnd) result.push(yesEnd);
		if (noEnd) result.push(noEnd);

		return result;
	}

	return [];
};

// List manipulation
/**
 * Get template from provided active info
 * @param {SheetTemplate[]} list
 * @param {TemplateLocation} location
 * @return {SheetTemplate|undefined}
 */
const findTemplate = (list, location) => {
	const { id, parentId } = location;
	let result;

	// Get child template in sub list
	if (parentId) {
		const parent = list.find((templateItem) => templateItem?.id === parentId);
		const subList = getTemplateSubList(parent);
		result = subList.find((template) => template?.id === id);
	} else {
		result = list.find((templateItem) => templateItem?.id === id);
	}
	return result && deepCopy(result);
};

/**
 * Delete a template in a parent based on provided location and parent type
 * @param {SheetTemplate} parent
 * @param {TemplateLocation} location
 * @return {SheetTemplate}
 */
const deleteSubTemplate = (parent = {}, location = {}) => {
	const { id: deleteId } = location;
	const { type, content } = parent;
	if (type === 'multipleChoice') {
		content.subNodes = content.subNodes.filter(({ id }) => id !== deleteId);
	} else if (type === 'question') {
		const { questionSide, questionEnd } = location;
		if (questionEnd) {
			content[questionSide]['end-item'] = createTemplate('next');
		} else {
			content[questionSide].items = content[questionSide].items.filter(({ id }) => id !== deleteId);
		}
	}

	return parent;
};

/**
 * Delete a template in a list by provided info
 * @param {SheetTemplate[]} list
 * @param {TemplateLocation} location
 * @return {SheetTemplate[]}
 */
const deleteTemplate = (list, location) => {
	const { id, parentId } = location;
	if (parentId) {
		const result = [...list];
		const parentPosition = result.findIndex((templateItem) => templateItem?.id === parentId);
		const parentCopy = parentPosition !== -1 && deepCopy(list[parentPosition]);
		result[parentPosition] = deleteSubTemplate(parentCopy, location);

		return result;
	}
	return list.filter((templateItem) => templateItem?.id !== id);
};

/**
 * Callback that return the updated template content
 * @callback TemplateUpdater
 * @param {SheetTemplate} previousTemplate
 * @return {SheetTemplate}
 **/

/**
 * Update a template based on its id in a list
 * @param {SheetTemplate[]}list
 * @param {string} updatedTemplateId
 * @param {TemplateUpdater} getNewTemplate
 * @return {SheetTemplate[]}
 */
const replaceTemplateInListById = (list, updatedTemplateId, getNewTemplate) => {
	const position = list.findIndex((template) => template.id === updatedTemplateId);
	if (position !== -1) {
		const oldTemplate = list[position];
		const newTemplate = getNewTemplate(oldTemplate);

		if (getNewTemplate && newTemplate) {
			list[position] = newTemplate;
		}
	}
	return list;
};

/**
 * Update a template in its parent based on location and parent type
 * @param {SheetTemplate} parent The parent template
 * @param {TemplateLocation} location The updated template location
 * @param {TemplateUpdater} getNewTemplate The template updater
 * @return {SheetTemplate}
 */
const updateSubTemplate = (parent = {}, location = {}, getNewTemplate) => {
	const { id: updateId } = location;
	const { type, content } = parent;

	if (type === 'multipleChoice') {
		content.subNodes = replaceTemplateInListById(content.subNodes, updateId, getNewTemplate);
	} else if (type === 'question') {
		const { questionSide, questionEnd } = location;
		if (questionEnd) {
			content[questionSide]['end-item'] = getNewTemplate(content[questionSide]['end-item']);
		} else {
			content[questionSide].items = replaceTemplateInListById(
				content[questionSide].items,
				updateId,
				getNewTemplate
			);
		}
	}

	return parent;
};

/**
 * Update a template base on provided info
 *
 * No update done on falsy value return by `getNewTemplate`
 * @param list - The Template list
 * @param location - The updated template info
 * @param {TemplateUpdater} getNewTemplate - Function that return the new Template
 * @return {SheetTemplate[]} The updated template list
 */
const updateTemplate = (list, location, getNewTemplate = (oldTemplate) => oldTemplate) => {
	const { id, parentId } = location;

	// Get child template in sub list
	if (parentId) {
		const parentPosition = list.findIndex((templateItem) => templateItem?.id === parentId);
		const parent = parentPosition !== -1 && deepCopy(list[parentPosition]);

		if (parent) {
			const result = [...list];
			result[parentPosition] = updateSubTemplate(parent, location, getNewTemplate);
			return result;
		} else {
			console.error(`Unable to find ${id} in template ${parentId}`);
			return list;
		}
	} else {
		const result = [...list];
		return replaceTemplateInListById(result, id, getNewTemplate);
	}
};

/**
 *
 * @param {SheetTemplate[]} list The template list where new node will be inserted
 * @param {number} position The inserted template's position
 * @return {SheetTemplate[]}
 */
const insertEmptyTemplateInList = (list, position) => {
	const listCopy = [...list];
	const newEmptyTemplate = createTemplate();
	const cleanPosition = position > 0 ? Math.min(list.length, position) : 0;
	listCopy.splice(cleanPosition, 0, newEmptyTemplate);
	return listCopy;
};

const stringifyWithoutKey = (draftJSContent) =>
	JSON.stringify(draftJSContent, (key, value) => (key === 'key' ? undefined : value));

export {
	createTemplate,
	deepCopy,
	deleteSubTemplate,
	deleteTemplate,
	findTemplate,
	getTemplateSubList,
	insertEmptyTemplateInList,
	replaceTemplateInListById,
	stringifyWithoutKey,
	updateSubTemplate,
	updateTemplate,
};
