import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { usePopup } from '../../../../../../shared';
import { Button } from '../../../../../../theme';
import { IconTrash } from '../../../../../../theme/assets/img';
import PopupDeleteBinder from '../../../../components/popup/popup-delete-binder/popup-delete-binder';

/**
 * Render the DeleteBinderButton component
 * @param {object} props
 * @param {object} props.binder
 * @param {function} props.onDelete
 * @param {string} props.className
 * @returns {JSX.Element}
 */
const DeleteBinderButton = ({ className = '', binder = {}, onDelete = () => {} }) => {
	const { t } = useTranslation();
	const popupDeleteBinderControl = usePopup();

	const handlePopupDeleteBinderVisible = (e) => {
		e.preventDefault();
		popupDeleteBinderControl.show();
	};

	return (
		<>
			<PopupDeleteBinder
				popupControl={popupDeleteBinderControl}
				binder={binder}
				onDelete={onDelete}
			/>
			<Button
				variant="minimal"
				icon={IconTrash}
				iconTooltipContent={t('binder:binder-list.tooltip.icon-label.delete')}
				onClick={(e) => handlePopupDeleteBinderVisible(e)}
				className={className}
			/>
		</>
	);
};

export default DeleteBinderButton;

DeleteBinderButton.propTypes = {
	binder: PropTypes.shape({
		title: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		tech_id: PropTypes.string.isRequired,
	}).isRequired,
	className: PropTypes.string.isRequired,
	onDelete: PropTypes.func.isRequired,
};
