import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import classnames from 'classnames';

import './spr-binder-group.scss';

import { DynamicBackgroundText, useSprContext, useTabs } from '../../../../shared';
import { Button, SprPage, TabsNav, TabsNavItem } from '../../../../theme';
import { IconBack } from '../../../../theme/assets/img';
import { searchBinder } from '../../binder.services';

/**
 * Render the Spr binder group page
 * @returns {JSX.Element}
 * @constructor
 */
const SprBinderGroup = () => {
	const [binderList, setBinderList] = useState([]);
	const [loading, setLoading] = useState(true);
	const { abbreviation } = useParams();
	const { associatedLine, setCurrentBinder } = useSprContext();
	const { t } = useTranslation();
	const { activeId: stationType, tabsItemConfig } = useTabs('communication');
	const isAllManBinder =
		binderList?.length > 0 && binderList.every((binder) => binder?.type === 'man');

	const getBinderByAbbrev = () => {
		setLoading(true);
		searchBinder({
			associatedLine: associatedLine,
			status: 'published',
			sortBy: 'station_order',
			abbreviation,
			sortOrder: 'asc',
		})
			.then((response) => {
				if (response.data) {
					setBinderList(response.data);
					// Setup header binder abbreviation display
					if (Array.isArray(response.data) && response.data.length > 0) {
						const [firstBinder] = response.data;
						setCurrentBinder({ color: firstBinder?.color, abbreviation }, true);
					}
				}
			})
			.finally(() => setLoading(false));
	};

	const renderBinderLink = (binder) => {
		const { color, id, title, tech_id: techId, station_label } = binder;
		return (
			<li
				key={techId}
				className={classnames('spr-binder-group__binder', {
					'spr-binder-group__binder--man': isAllManBinder,
				})}
			>
				<Link
					className="binder__link"
					to={`/spr/binders/${techId}`}
					onClick={() => setCurrentBinder(binder, true)}
				>
					{isAllManBinder ? (
						<>
							<span className="binder__station">{station_label}</span>
							<span className="binder__text">
								<span className="binder__text__id">{id}</span>
								<span className="binder__text__title">{title}</span>
							</span>
						</>
					) : (
						<>
							<DynamicBackgroundText className="binder__id" text={id} color={color} />
							<span className="binder__title">{title}</span>
						</>
					)}
				</Link>
			</li>
		);
	};

	useEffect(getBinderByAbbrev, [associatedLine, abbreviation, setCurrentBinder, stationType]);

	const subHeader = (
		<div className="spr-biner-group__subheader">
			<span className="subheader__abbreviation">{abbreviation?.toUpperCase()}</span>
			<Link className="subheader__link" to="/spr/binders">
				<Button variant="primary" small icon={IconBack} />
				<span className="subheader__link__text">{t('binder:spr-binder-group.header.link')}</span>
			</Link>
		</div>
	);

	const renderedBinderList = useMemo(() => {
		if (isAllManBinder) {
			return binderList.filter((binder) => binder.station_type === stationType);
		}
		return binderList;
	}, [isAllManBinder, binderList, stationType]);

	return (
		<SprPage className="spr-binder-group" subheader={subHeader}>
			{isAllManBinder && (
				<TabsNav className="spr-binder-group__tabs">
					<TabsNavItem
						className="tabs__item"
						classNameActive="tabs__item--active"
						id="communication"
						{...tabsItemConfig}
					>
						<Trans i18nKey="binder:spr-binder-group.tabs.communication" />
					</TabsNavItem>
					<TabsNavItem
						className="tabs__item"
						classNameActive="tabs__item--active"
						id="connection"
						{...tabsItemConfig}
					>
						{t('binder:spr-binder-group.tabs.connection')}
					</TabsNavItem>
					<TabsNavItem
						className="tabs__item"
						classNameActive="tabs__item--active"
						id="avoidance"
						{...tabsItemConfig}
					>
						{t('binder:spr-binder-group.tabs.avoidance')}
					</TabsNavItem>
				</TabsNav>
			)}
			<h1 className="spr-binder-group__title">{t('binder:spr-binder-group.select-binder')}</h1>
			{!loading && (
				<ul className="spr-binder-group__list">
					{Array.isArray(renderedBinderList) && renderedBinderList.map(renderBinderLink)}
				</ul>
			)}
		</SprPage>
	);
};

export default SprBinderGroup;
