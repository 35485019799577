import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './next-button.scss';

import { offlineUser } from '../../../../../../config/offline';
import { useAppSetupContext } from '../../../../../../shared';
import { IconWrapper } from '../../../../../../theme';
import { ReactComponent as IconNext } from '../../../../../sheet/assets/icon-next.svg';
import { useRunProcessContext } from '../../../../components/context-run-process/context-run-process';

const NextButton = ({ className = '', onClick }) => {
	const { t } = useTranslation();

	const { user: currentUser } = useAppSetupContext();
	const { processInfo, cdrMode } = useRunProcessContext();

	const isCurrentUserProcessOwner = currentUser?.tech_id === processInfo?.owner;
	const isOfflineUser = currentUser?.tech_id === offlineUser.tech_id;

	if (isCurrentUserProcessOwner || cdrMode || isOfflineUser) {
		return (
			<button type="button" className={classNames('next-button', className)} onClick={onClick}>
				<IconWrapper className="next-button__icon" Component={IconNext} />
				<span>{t('process:spr-run-process.next-button')}</span>
			</button>
		);
	}
	return false;
};

export default NextButton;

NextButton.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func.isRequired,
};
