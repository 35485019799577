import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './railway-binder-row.scss';

import { buildTrainProcessBinderDetail } from '../../../../../../shared';

/**
 * Render a binde row
 * @param {object} props
 * @param {object} props.binder
 * @param {string} props.trainTechId
 * @param {string} props.materialTechId
   @param {string}  props.processId
 * @return {JSX.Element}
 * @constructor
 */
const RailwayBinderRow = ({ binder, trainTechId, materialTechId, processId }) => {
	const { t } = useTranslation();
	const { type, title, id, tech_id: techId } = binder;
	const encodedBinderId = encodeURIComponent(id);
	const modifier = {
		'binder--auto': type === 'train-auto',
		'binder--driver': type === 'train-driver',
		'binder--officer': type === 'train-officer',
	};

	let uri = trainTechId
		? `/railway/train/${trainTechId}/binder/${encodedBinderId}`
		: `/railway/material/${materialTechId}/binder/${encodedBinderId}`;
	if (processId) {
		uri = buildTrainProcessBinderDetail({ processId, binderTechnicalId: techId }, 'railway');
	}

	return (
		<li key={techId} className={classnames('binder', modifier)}>
			<Link className="binder__link" to={uri}>
				<span className="binder__type">{t(`train:binder-train-label.${type}`)}</span>
				<span className="binder__title">{title}</span>
				<span className="binder__id">{id}</span>
			</Link>
		</li>
	);
};

export default memo(RailwayBinderRow);

RailwayBinderRow.propTypes = {
	binder: PropTypes.shape({
		type: PropTypes.string,
		title: PropTypes.string,
		id: PropTypes.string,
		tech_id: PropTypes.string,
	}),
	trainTechId: PropTypes.string,
	processId: PropTypes.string,
	materialTechId: PropTypes.string,
};
