import {
	validateComment,
	validateNumber,
	validateResumeRatp,
	validateResumeRfn,
	validateSpeedRestrictionLineA,
	validateSpeedRestrictionRatpLineB,
	validateSpeedRestrictionRfnLineB,
	validateYesNo,
} from '../../utils/resume-form-validators';

const specificRestrictionFields = [
	{
		name: 'specific_restriction',
		type: 'radio',
		values: ['yes', 'no'],
		is_first_question: true,
		validator: validateYesNo,
	},
];

const speedRestrictionFields = [
	{
		name: 'has_speed_restriction',
		type: 'radio',
		values: ['yes', 'no'],
		is_first_question: true,
		validator: validateYesNo,
	},
	{
		name: 'speed_restriction',
		type: 'radioInput',
		values: ['1/10', '1/4', '1/2', '30km/h'],
		warning: true,
		validator: validateSpeedRestrictionLineA,
	},
];

const hlpResumeFields = [
	{
		name: 'hlp_resume',
		type: 'radio',
		values: ['yes', 'no'],
		is_first_question: true,
		validator: validateYesNo,
	},
	{
		name: 'troubleshooting_guide',
		type: 'radio',
		values: ['yes', 'no'],
		validator: validateYesNo,
	},
	{
		name: 'head_of_regulation_order',
		type: 'radio',
		values: ['yes', 'no'],
		validator: validateYesNo,
	},
];

const missionDeletedFields = [
	{
		name: 'mission_deleted',
		type: 'radio',
		values: ['yes', 'no'],
		is_first_question: true,
		validator: validateYesNo,
	},
	{
		name: 'comment',
		type: 'textarea',
		validator: validateComment,
	},
];

const headOfRegulationInformedFields = [
	{
		name: 'head_of_regulation_informed',
		type: 'radio',
		values: ['yes', 'no'],
		is_first_question: true,
		validator: validateYesNo,
	},
	{
		name: 'passenger_information',
		type: 'radio',
		values: ['yes', 'no'],
		validator: validateYesNo,
	},
];

const rescuedBogieFields = [
	{
		name: 'rescued_bogies_units',
		type: 'number',
		validator: validateNumber,
	},
];

const ratpNetworkFields = [
	{
		name: 'ratp_network',
		type: 'radio',
		values: ['yes', 'no'],
		is_first_question: true,
		validator: validateYesNo,
	},
	{
		name: 'ratp_resume_type',
		type: 'radio',
		values: ['travellers', 'hlp', 'assistance'],
		validator: validateResumeRatp,
	},
	{
		name: 'ratp_speed_restriction',
		type: 'radioInput',
		values: ['none', '1/10', '1/4', '1/2', '30km/h'],
		validator: validateSpeedRestrictionRatpLineB,
	},
	{
		name: 'head_of_regulation_informed',
		type: 'radio',
		values: ['yes', 'no'],
		validator: validateYesNo,
	},
];

const rfnNetworkFields = [
	{
		name: 'rfn_network',
		type: 'radio',
		values: ['yes', 'no'],
		is_first_question: true,
		validator: validateYesNo,
	},
	{
		name: 'rfn_resume_type',
		type: 'radio',
		values: ['travellers', 'hlp', 'assistance'],
		validator: validateResumeRfn,
	},
	{
		name: 'rfn_speed_restriction',
		type: 'radio',
		values: ['none', 'AUTOM', 'V120', 'ME 100', 'MA 80', '20km/h'],
		validator: validateSpeedRestrictionRfnLineB,
	},
	{
		name: 'driving_support_center_informed',
		type: 'radio',
		values: ['yes', 'no'],
		validator: validateYesNo,
	},
];

export {
	headOfRegulationInformedFields,
	hlpResumeFields,
	missionDeletedFields,
	ratpNetworkFields,
	rescuedBogieFields,
	rfnNetworkFields,
	specificRestrictionFields,
	speedRestrictionFields,
};
