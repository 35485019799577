import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './binder-sheet-list.scss';

import { usePopup } from '../../../../../../shared';
import { PopupCreateSheet } from '../../../../../sheet';
import { fetchSheetByBinder } from '../../../../../sheet/sheet.services';
import { addSheetsTitleRaw } from '../../../../../sheet/utils/add-sheet-title-raw-utils/add-sheet-title-raw-utils';
import { isTrainBinderType } from '../../../../utils/binder-type';

import {
	BinderDefaultSheetList,
	BinderManSheetList,
	BinderThemeSheetList,
} from './components/sheet-list-type';

/**
 * BinderSheetList component
 * @param {object} props
 * @param {string} props.className
 * @param {object} props.binder
 * @param {object} props.analysisResult
 * @returns
 */
const BinderSheetList = ({ className, binder, analysisResult }) => {
	const { tech_id: binderId, binder_subtitle: binderSubtitle } = binder;
	const [allSheetList, setAllSheetList] = useState([]);
	const [sheetList, setSheetList] = useState([]);
	const [sheetSearch, setSheetSearch] = useState('');
	const [showSheetList, setShowSheetList] = useState(false);
	const isTrainBinder = isTrainBinderType(binder?.type);
	const createSheetPopupControl = usePopup();

	const retrieveSheets = () => {
		if (binderId) {
			fetchSheetByBinder(binderId)
				.then((response) => {
					const sheetListWithTitleRaw = addSheetsTitleRaw(response?.data);
					setAllSheetList(sheetListWithTitleRaw);
					setSheetList(response?.data);
				})
				.finally(() => setShowSheetList(true));
		}
	};

	/**
	 * Filter Front end on Id and TitleRaw (value from title draftjs)
	 */
	const searchSheets = () => {
		const sheetListSpread = [...allSheetList];
		if (sheetSearch) {
			const sheetSearchLC = sheetSearch.toLowerCase();
			const sheetListFiltered = sheetListSpread.filter(
				(sheet) =>
					sheet.number_search?.toLowerCase().includes(sheetSearchLC) ||
					sheet.titleRaw?.toLowerCase().includes(sheetSearchLC)
			);
			setSheetList(sheetListFiltered);
		} else {
			setSheetList(sheetListSpread);
		}
	};

	const renderSheetListType = (binderType) => {
		if (!binderType) return null;

		const handleSearch = (e) => setSheetSearch(e.target.value);

		if (binderType === 'man') {
			return (
				<BinderManSheetList
					sheets={sheetList}
					binder={binder}
					search={sheetSearch}
					analysisResult={analysisResult}
					onSearch={handleSearch}
					refreshSheetList={retrieveSheets}
				/>
			);
		} else if (binderType === 'theme') {
			return (
				<BinderThemeSheetList
					sheets={sheetList}
					binder={binder}
					search={sheetSearch}
					analysisResult={analysisResult}
					onSearch={handleSearch}
					refreshSheetList={retrieveSheets}
				/>
			);
		} else {
			// train or sheet type binders
			return (
				<BinderDefaultSheetList
					sheets={sheetList}
					onSearch={handleSearch}
					sheetSearch={sheetSearch}
					retrieveSheets={retrieveSheets}
					analysisResult={analysisResult}
					createSheetPopupControl={createSheetPopupControl}
					loading={!showSheetList}
					binder={binder}
				/>
			);
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(searchSheets, [sheetSearch]);
	useEffect(retrieveSheets, [binderId]);

	const modifierClassName = {
		'binder-sheet-list--man': binder?.type === 'man',
		'binder-sheet-list--theme': binder?.type === 'theme',
		'binder-sheet-list--train': isTrainBinder,
		'binder-sheet-list--sheet': binder?.type === 'sheet',
	};

	return (
		<div className={classnames('binder-sheet-list', modifierClassName, className)}>
			<PopupCreateSheet
				isTrainBinder={isTrainBinder}
				popupControl={createSheetPopupControl}
				binder={binder}
				defaultColor={binder?.color}
				binderSubtitle={binderSubtitle}
				// retrieve sheets to set correct sorting order
				onCreate={retrieveSheets}
			/>
			{renderSheetListType(binder?.type)}
		</div>
	);
};

export default BinderSheetList;

BinderSheetList.propTypes = {
	binder: PropTypes.shape({
		status: PropTypes.string,
		tech_id: PropTypes.string,
		binder_subtitle: PropTypes.string,
		type: PropTypes.oneOf(['sheet', 'theme', 'man', 'train-driver', 'train-officer', 'train-auto']),
		color: PropTypes.string,
		id: PropTypes.string,
		abbreviation: PropTypes.string,
		associated_line: PropTypes.array,
	}),
	className: PropTypes.string,
	analysisResult: PropTypes.shape({
		sheetsWarning: PropTypes.arrayOf(PropTypes.string),
	}),
};
