import React from 'react';

import './dotted-card.scss';

/**
 * Render the DottedCard component
 * @returns {JSX.Element}
 */
const DottedCard = () => {
	return (
		<span className="dotted-card">
			<span>...</span>
		</span>
	);
};

export default DottedCard;
