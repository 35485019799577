import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './burger-menu.scss';

import { buildUserName, useAppSetupContext } from '../../../shared';
import { logout } from '../../../shared/auth/auth.services';
import { Button, IconWrapper } from '../..';
import { Close, IconArrowRight, IconLogout, IconTools, Menu } from '../../assets/img';

import BurgerMenuProcessList from './burger-menu-process-list/burger-menu-process-list';

/**
 * @typedef Item
 * @prop {string} name
 * @prop {string} label
 * @prop {string} link
 * @prop {string} authorizedRole
 * @prop {object} icon
 * @prop {function} [onClick]
 */

/**
 * Render the a sheet list on the binder detail page
 * @param {Props} props
 * @param {Item[]} props.items
 * @param {ProcessList[]} props.processList
 * @param {string} props.processHistoryLink
 * @returns {JSX.Element}
 */
const BurgerMenu = ({ items, processList, processHistoryLink }) => {
	const [isActive, setIsActive] = useState(false);
	const { oktaAuth = {} } = useOktaAuth() || {};
	const { t } = useTranslation();
	const { user: currentUser } = useAppSetupContext();

	const handleLogout = () => logout(oktaAuth);

	const generateItems = (item) => {
		const { name, link, label = '', isOwner, onClick = () => {}, separator = false, icon } = item;

		if (separator) {
			return <hr key={name} className="burger-menu__list--separator" />;
		}

		const onClickHandler = () => {
			// in ui/ux hide burger when opening new train procedure popup
			if (name === 'start-new-process-train') setIsActive(false);
			onClick();
		};

		return (
			<li key={name} className="burger-menu__list__item" onClick={onClickHandler}>
				<Link className="burger-menu__list__label" to={link || '#'}>
					{icon && <IconWrapper className="list__item__icon" Component={icon} />}
					{isOwner && <IconWrapper className="list__item__icon" Component={IconTools} />}
					<span>{label}</span>
				</Link>
			</li>
		);
	};

	return (
		<>
			<div
				className={classNames('burger-menu__overlay', {
					'burger-menu__overlay--visible': isActive,
				})}
				onClick={() => setIsActive(false)}
			/>
			<div className="burger-menu">
				<Button
					variant="primary"
					type="button"
					className="burger-menu__cta-open__icon"
					onClick={() => setIsActive(true)}
					icon={Menu}
				/>
				<nav
					className={classNames('burger-menu__content', {
						'burger-menu__content--visible': isActive,
					})}
				>
					<button
						type="button"
						className="burger-menu__cta-close"
						onClick={() => setIsActive(false)}
					>
						<IconWrapper className="burger-menu__cta-close__icon" Component={Close} />
					</button>
					<ul className="burger-menu__list">{Array.isArray(items) && items.map(generateItems)}</ul>
					{processList?.length > 0 && <BurgerMenuProcessList processList={processList} />}
					<Link className="burger-menu__current-process__link" to={processHistoryLink}>
						<span>{t('theme:spr-page.menu-burger.manage-in-progress-processes')}</span>
						<IconWrapper className="current-process__icon" Component={IconArrowRight} />
					</Link>
					<div className="burger-menu__logout">
						<span className="burger-menu__logout__user">
							{currentUser?.tech_id
								? buildUserName(currentUser?.firstname, currentUser?.lastname, currentUser?.id)
								: t('user:user-offline')}
						</span>
						<span
							className="burger-menu__logout__item burger-menu__logout__cta"
							onClick={handleLogout}
							role="button"
						>
							<IconWrapper className="burger-menu__logout-icon" Component={IconLogout} />
							{t('theme:spr-page.menu-burger.logout')}
						</span>
						<Link className="burger-menu__logout__item" to="/legals">
							{t('theme:cdr-page.profile-button.legals')}
						</Link>
					</div>
				</nav>
			</div>
		</>
	);
};

BurgerMenu.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			label: PropTypes.string,
			authorizedRole: PropTypes.arrayOf(PropTypes.string),
			icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
			onClick: PropTypes.func,
		})
	),
	processList: PropTypes.array,
	processHistoryLink: PropTypes.string,
};

export default BurgerMenu;
