import { getNetworkState } from '../../../config/offline';
import { formatJsonDate } from '../../../shared';

import { dateFormatTime } from './date-format';

/**
 * @typedef Process
 * @prop {string} binder_tech_id
 * @prop {Date} created_at
 * @prop {string} description
 * @prop {Date} ended_at
 * @prop {object[]} history
 * @prop {number} line
 * @prop {string[]} owners_history
 * @prop {object} resume_conditions:
 * @prop {string} sheet_tech_id
 * @prop {string} started_at
 * @prop {string} tech_id
 * @prop {string} track
 * @prop {'train' | 'pcc'} type
 * @prop {'in-progress' | 'aborted' | 'closed'} status
 * @prop {string} [material_label]
 * @prop {string} [material_tech_id]
 * @prop {string} [train_id]
 * @prop {string} [train_tech_id]
 * @prop {string} [position_info]
 * @prop {'platform' | 'half-platform' | 'interstation' | 'other'} [position_type]
 * @prop {string} [link]
 * @prop {string} [label]
 */

const getBinderAbbreviation = (processHistory) =>
	processHistory?.[0]?.binder?.abbreviation?.toUpperCase();

/**
 * Add link and label properties to a process
 * @param {Process} process
 */
const addLabelAndLinkToProcess = (process = {}, prefix = 'spr') => {
	const {
		history: processHistory,
		tech_id: techId,
		started_at: startedAt,
		type,
		position_info,
		material_label,
	} = process;
	const link = `/${prefix}/process/run?processId=${techId}`;
	const time = formatJsonDate(startedAt, dateFormatTime);
	// Get binder abbrev
	const label =
		type === 'pcc'
			? `${getBinderAbbreviation(processHistory) || ''} - ${time}`
			: `${position_info || material_label} - ${time}`;
	return { label, link, ...process };
};

/**
 * Insert or update process in a process list
 * @param {Process[]} processList
 * @param {Process} processToInsert
 * @returns {Process[]}
 */
const insertProcess = (processList = [], processToInsert = {}) => {
	const processStoredIndex = processList.findIndex(
		(element) => element.tech_id === processToInsert.tech_id
	);
	// process is stored => replace the entry
	const newProcessList = [...processList];
	if (processStoredIndex !== -1) {
		newProcessList[processStoredIndex] = processToInsert;
	} else if (Array.isArray(processList) && processStoredIndex === -1) {
		newProcessList.push(processToInsert);
	}
	return newProcessList;
};

/**
 * add properties to each process then save the new process list to localStorage
 * @param {Process[]} processesToInsert
 * @param {{isNewList: boolean, processLinkPrefix: 'spr' | 'railway'}} [options = {}]
 */
const saveProcess = (processesToInsert, options = {}) => {
	const { processLinkPrefix = 'spr', isNewList } = options;
	const storedProcessList = isNewList ? [] : JSON.parse(localStorage.getItem('SprProcess')) || [];
	// insert all process to list then add label and link properties to each one
	const updatedProcessList = processesToInsert
		.reduce(insertProcess, storedProcessList)
		.filter((process) => process?.status === 'in-progress' && process?.started_at)
		.map((process) => addLabelAndLinkToProcess(process, processLinkPrefix));
	// Only update localStorage process list when online. This local storage process list is used for listing own processes in pcc side =/= offline train side
	if (getNetworkState() === 'online') {
		localStorage.setItem('SprProcess', JSON.stringify(updatedProcessList));
	}
	return updatedProcessList;
};

export { addLabelAndLinkToProcess, insertProcess, saveProcess };
