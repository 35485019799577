import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './results-sheet-recap.scss';

import { Button } from '../../../../../../../theme';
import { updateProcess } from '../../../../../process.services';
import ResultsSheetCard from '../results-sheet-card/results-sheet-card';

const cards = [
	{
		name: 'valve-maneuver',
		associated_step: 0,
	},
	{
		name: 'break-test',
		associated_step: 1,
	},
];

const ResultsSheetRecap = ({ setResultsSheetStep, processInfo, line, loadProcess }) => {
	const {
		results_sheet: formDataResultsSheet = {},
		damage_location: formDataDamageLocation = {},
		resume_rer: resumeRer = {},
	} = processInfo?.resume_conditions || {};
	const { t } = useTranslation();

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formattedData = {
			damage_location: formDataDamageLocation,
			resume_rer: resumeRer,
			results_sheet: {
				...formDataResultsSheet,
				step_completed: true,
			},
		};
		event.preventDefault();
		await updateProcess(
			processInfo.tech_id,
			{ resume_conditions: formattedData },
			{ action: 'updateProcessRerResume', line }
		);
		await loadProcess(processInfo?.tech_id);
	};

	const renderCards = ({ name, associated_step: associatedStep }) => {
		return (
			<div className="results-sheet__recap__cards-wrapper__card">
				<ResultsSheetCard
					associatedStep={associatedStep}
					name={name}
					setResultsSheetStep={setResultsSheetStep}
				/>
			</div>
		);
	};

	return (
		<div className="results-sheet__recap">
			<div className="results-sheet__recap__cards-wrapper">{cards.map(renderCards)}</div>
			<div className="results-sheet__recap__button-wrapper">
				<Button
					variant="primary"
					type="submit"
					onClick={handleSubmit}
					label={t('process:process-rer-resume.footer.confirm-results-sheet')}
					className="results-sheet__recap__button-wrapper__button"
				/>
			</div>
		</div>
	);
};

export default ResultsSheetRecap;

ResultsSheetRecap.propTypes = {
	setResultsSheetStep: PropTypes.func,
	processInfo: PropTypes.object,
	line: PropTypes.string,
	loadProcess: PropTypes.func,
};
