import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './error-down-page.scss';

import { LogoDigiproc } from '../../../../theme';
import { ReactComponent as LogoError } from '../../assets/img/logo-error.svg';

const ErrorDownPage = () => {
	const { t } = useTranslation();

	return (
		<main className="error-down-page">
			<header className="error-down-page__header">
				<Link to="/">
					<LogoDigiproc className="page__logo" />
				</Link>
			</header>
			<div className="error-down-page__content">
				<LogoError className="error-down-page__content__logo" />
				<div className="error-down-page__content__title">{t('error:error-down-page.title')}</div>
				<div className="error-down-page__content__content">
					{t('error:error-down-page.content')}
				</div>
			</div>
		</main>
	);
};

export default ErrorDownPage;
