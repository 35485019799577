import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import './popup-wrapper.scss';

const PopupWrapper = ({ children, visible }) => {
	if (visible) {
		return createPortal(
			<div className="popup-wrapper">{children}</div>,
			document.getElementById('modal-root')
		);
	}
	return null;
};

export default PopupWrapper;

PopupWrapper.propTypes = {
	visible: PropTypes.bool,
	children: PropTypes.node.isRequired,
};
