import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

export const getUniqueKey = (foggyKey) => {
	return foggyKey + uuidv4();
};

getUniqueKey.proptype = {
	goggykey: PropTypes.string.isRequired,
};
