import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-update-train.scss';

import { checkFormError } from '../../../../../../shared';
import { Button, ErrorMessage, PopupDefault } from '../../../../../../theme';
import { updateTrain } from '../../../../train.services';
import { createTrainForm, createTrainFormOnChange } from '../../../../utils/train-object-utils';
import {
	getFormTrainFieldMessageKey,
	validatePopupUpdateTrainForm,
} from '../../../../utils/validate-popup-train-form';
import FormTrain from '../form-train/form-train';

/**
 * Render the PopupUpdateTrain component
 * @param {object} props
 * @param {function} props.onSubmitSuccess Callback called after successful submit
 * @param {object} props.popupControl
 * @param {import("../../../../train.services").Train} props.train the train to update
 * @param {string} props.line the line of the current train-list parent page
 * @returns {JSX.Element}
 */
const PopupUpdateTrain = ({ onSubmitSuccess = () => {}, popupControl, line, train = {} }) => {
	const { t } = useTranslation();
	/**@type {[import("../../../../train.services").Train, React.Dispatch<React.SetStateAction<Train>>]} */
	const [formData, setFormData] = useState({});
	const [networkError, setNetworkError] = useState(false);
	const formError = useMemo(() => validatePopupUpdateTrainForm(formData), [formData]);
	const formErrorMessage = useMemo(() => getFormTrainFieldMessageKey(formError), [formError]);

	const reInitForm = () => {
		setFormData(createTrainForm(train));
		setNetworkError(false);
	};

	const handleChange = (event) => {
		setFormData(createTrainFormOnChange(formData, event));
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if (!checkFormError(formError)) {
			const newTrain = { ...formData };
			reInitForm();
			updateTrain(train.tech_id, newTrain).then(popupControl.hide).then(onSubmitSuccess);
		}
	};

	useEffect(reInitForm, [train]);

	return (
		<PopupDefault
			className="popup-update-train__wrapper"
			popupControl={popupControl}
			title={t('train:popup.update-train.title')}
			onClose={reInitForm}
		>
			<form className="popup-update-train__form" onSubmit={handleSubmit}>
				<div className="popup-update-train__form-wrapper">
					<FormTrain
						form={formData}
						validation={formError}
						errorMessage={formErrorMessage}
						onChange={handleChange}
						extendedBinderStatus
						update
						line={line}
					/>
				</div>
				<div className="popup-update-train__controller">
					<Button
						variant="primary"
						className="popup-update-train__submit"
						type="submit"
						disabled={checkFormError(formError)}
						label={t('train:popup.form.submit')}
					/>
				</div>
				{networkError && (
					<ErrorMessage className="popup-update-train__network-error">
						{t('error.default')}
					</ErrorMessage>
				)}
			</form>
		</PopupDefault>
	);
};

export default PopupUpdateTrain;

PopupUpdateTrain.propTypes = {
	onSubmitSuccess: PropTypes.func,
	popupControl: PropTypes.shape({
		hide: PropTypes.func,
		show: PropTypes.func,
		visible: PropTypes.bool,
	}),
	line: PropTypes.number.isRequired,
	train: PropTypes.shape({
		id: PropTypes.string,
		material_type_id: PropTypes.string,
		brake_system: PropTypes.string,
		car_number: PropTypes.number,
		binder_auto: PropTypes.string,
		binder_driver: PropTypes.string,
		binder_officer: PropTypes.string,
		brake_isolation: PropTypes.bool,
		engine_system: PropTypes.bool,
	}).isRequired,
};
