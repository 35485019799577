import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './appendix-form-row.scss';

import { Dropdown, IconWrapper } from '../../../../../../../../theme';
import { ReactComponent as IconTrash } from '../../../../../../../../theme/assets/img/icon-trash.svg';

const AppendixFormRow = ({
	sheetIdParam,
	onDeleteRow,
	index,
	updateContent,
	binderAppendixSheetList,
}) => {
	const { t } = useTranslation();

	const [selectedAppendixId, setSelectedAppendixId] = useState(sheetIdParam || '');

	const handleSelectSheet = (event) => {
		const sheetId = event.target.value;
		const selectedAppendix = binderAppendixSheetList.find(
			(appendix) => appendix.sheet_id === sheetId
		);
		const { number_search, sheet_id, color: sheetColor } = selectedAppendix;
		const params = { sheetNumber: number_search, sheetId: sheet_id, sheetColor };
		setSelectedAppendixId(sheet_id);
		updateContent(index, params);
	};

	const dropdownOptions = useMemo(() => {
		return binderAppendixSheetList.map((appendix) => {
			const { sheet_id: sheetId, number_search: numberSearch, titleRaw } = appendix;
			return (
				<option key={sheetId} value={sheetId}>
					{`${numberSearch.toUpperCase()} - ${titleRaw}`}
				</option>
			);
		});
	}, [binderAppendixSheetList]);

	return (
		<li className="appendix-form-row">
			<div className="appendix-form-row__head">
				<span className="appendix-form-row__head__badge">{index + 1}</span>
				<button className="appendix-form-row__delete" type="button" onClick={onDeleteRow}>
					<IconWrapper
						className="appendix-form-row__delete__icon"
						Component={IconTrash}
						tooltip={t('sheet:sheet-details.appendix-form.delete-tooltip')}
					/>
				</button>
			</div>
			<div>
				<label className="appendix-form-row__label">
					{t('sheet:sheet-details.appendix-form.appendix-label')}
				</label>
				<div className="appendix-form-row__dropdown">
					<Dropdown
						className="appendix-form-row__dropdown-type"
						onChange={handleSelectSheet}
						value={selectedAppendixId}
						placeholder={t('sheet:sheet-details.appendix-form.dropdown-placeholder')}
						placeholderValue="{}"
						disabledPlaceholder={false}
						disabled={!binderAppendixSheetList}
					>
						{dropdownOptions}
					</Dropdown>
				</div>
			</div>
		</li>
	);
};

export default AppendixFormRow;

AppendixFormRow.propTypes = {
	sheetIdParam: PropTypes.string,
	onDeleteRow: PropTypes.func,
	index: PropTypes.number,
	updateContent: PropTypes.func,
	binderAppendixSheetList: PropTypes.array,
};
