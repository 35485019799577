import { requiredFieldValidator } from '../../../../../shared';
import { sheetNumberValidator } from '../../../utils/form-utils';

/**
 * Validate the sheet form content
 * @param form The form data to validate
 * @param {any[]} availableSheetList
 * @return {{sheetNumber: string[], title: string[], type: string[]}}
 */
const validateCreateSheetForm = (form, availableSheetList = []) => {
	const { number, title, type } = form;
	const sheetNumberErrorList = [
		requiredFieldValidator(number),
		sheetNumberValidator(number, availableSheetList),
	].flat(1);

	return {
		number: sheetNumberErrorList,
		title: requiredFieldValidator(title),
		type: requiredFieldValidator(type),
	};
};

export default validateCreateSheetForm;
