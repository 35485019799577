import React from 'react';
import PropTypes from 'prop-types';

import './simple-link-sheetnumber.scss';

import { IconWrapper } from '../../../../../../../../../../theme';
import { IconArrowRight } from '../../../../../../../../../../theme/assets/img';
import { SheetType } from '../../../../../../../../index';
import resolveSheetColor from '../../../../../../../../utils/resolve-sheet-color';

/**
 * @param {Object} props
 * @returns
 */
const SimpleLinkSheetnumber = ({ content = {} }) => {
	const { sheetNumber, sheetColor: color, binderColor, sheetType } = content || {};

	// if type is not defined, then we have a specific sheet view
	const isOldSheetVersion = !sheetType;

	// find the current color to use for the sheet
	const sheetColor = resolveSheetColor(color, binderColor);

	return (
		<>
			<IconWrapper className="simple-link-template-view__icon" Component={IconArrowRight} />
			{sheetNumber && !isOldSheetVersion && (
				<SheetType
					className="simple-link-sheet-view__link"
					type={sheetType}
					sheetNumber={sheetNumber}
					color={sheetColor || binderColor}
				/>
			)}
			{sheetNumber && isOldSheetVersion && (
				<span className="simple-link-sheet-view__link__old-version">{sheetNumber}</span>
			)}
		</>
	);
};

export default SimpleLinkSheetnumber;

SimpleLinkSheetnumber.propTypes = {
	content: PropTypes.object,
};
