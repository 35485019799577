import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classname from 'classnames';
import PropTypes from 'prop-types';

import './binder-type-dropdown.scss';

import { Dropdown } from '../../../../theme';

/**
 * Render a binder type Dropdown
 * @param {object} props
 * @param {string} props.value
 * @param {string} props.name
 * @param {string} props.label
 * @param {string} props.placeholder
 * @param {function} props.onChange
 * @param {object} props.train
 * @returns {JSX.Element}
 */
const BinderTypeDropdown = ({
	className,
	value,
	defaultValue = '',
	name,
	label,
	placeholder,
	onChange,
	disabled,
	typeList = [],
}) => {
	const { t } = useTranslation();

	const renderOption = () => {
		return typeList.map((type) => {
			return (
				<option key={type} value={type} className="binder-type__content">
					{t(`binder:binder-type.${type}`)}
				</option>
			);
		});
	};

	useEffect(() => {
		// Set sheet as default value if no value provided
		if (!value && typeList.length === 1) {
			onChange({ target: { name, value: typeList[0] } });
		} else if (!value) {
			onChange({ target: { name, value: defaultValue } });
		}
		// ignore onChange dependency to avoid infinite render
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name, value, defaultValue]);

	return (
		<Dropdown
			className={classname('binder-type-dropdown', className)}
			value={value}
			name={name}
			onChange={onChange}
			label={label}
			placeholder={placeholder}
			disabled={typeList.length === 1 ? true : disabled}
		>
			{renderOption()}
		</Dropdown>
	);
};

BinderTypeDropdown.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	typeList: PropTypes.arrayOf(PropTypes.string).isRequired,
	defaultValue: PropTypes.string,
};

export default BinderTypeDropdown;
