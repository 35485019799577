import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-confirm-waiting-binder.scss';

import { formatJsonDate } from '../../../../../shared';
import { PopupConfirm } from '../../../../../theme';
import { dateFormatFull } from '../../../../process/utils';

/**
 * PopupConfirmWaitingBinder component
 * @param {object} props
 * @param {object} props.binder
 * @param {object} props.popupControl
 * @param {function} props.handleConfirm
 * @returns {JSX.Element}
 */
const PopupConfirmWaitingBinder = (props) => {
	const { handleConfirm, binder, popupControl } = props;
	const { t } = useTranslation();

	return (
		<PopupConfirm
			popupControl={popupControl}
			onConfirm={handleConfirm}
			title={t('binder:popup.update-status.title-publish')}
			ctaConfirmLabel="binder:popup.update-status.cta-confirm-label"
		>
			<div className="popup-confirm-waiting">
				<p className="popup-confirm-waiting__text">
					{t('binder:popup.update-status.text-waiting.start')}
					<span className="text__info"> {binder.id}</span>
				</p>
				<p className="popup-confirm-waiting__text">
					{t('binder:popup.update-status.text-waiting.date')}
					<span className="text__info"> {formatJsonDate(binder.published_at, dateFormatFull)}</span>
				</p>
				<br />
				<p className="popup-confirm-waiting__text">
					{t('binder:popup.update-status.text-waiting.type')}
					<span className="text__info">
						{t(`binder:popup.update-status.text-waiting.${binder?.is_rectification}`)}{' '}
						{binder.description && `(${binder.description})`}
					</span>
				</p>
				<br />
				<p className="popup-confirm-waiting__text">
					{t('binder:popup.update-status.text-waiting.end')}
				</p>
			</div>
		</PopupConfirm>
	);
};

PopupConfirmWaitingBinder.propTypes = {
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired,
		visible: PropTypes.bool.isRequired,
	}).isRequired,
	handleConfirm: PropTypes.func,
	binder: PropTypes.shape({
		id: PropTypes.string.isRequired,
		published_at: PropTypes.string,
		is_rectification: PropTypes.bool,
		description: PropTypes.string,
	}).isRequired,
};

export default PopupConfirmWaitingBinder;
