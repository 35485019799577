import { TOTP } from '@otplib/core';
import { createDigest } from '@otplib/plugin-crypto-js';
import { Buffer } from 'buffer';

import {
	getStoredOfflineAuthJwt,
	removeStoredJwtSecret,
	removeStoredOfflineAuthJwt,
	setStoredOfflineAuthJwt,
} from '../../domains/user/utils/auth-offline-utils';

import { getAuthItem, setAuthItem } from './auth-storage';

const totp = new TOTP({ createDigest });
window.Buffer = window.Buffer || Buffer;

/**
 * @typedef {{
 *  digits: number,
 *  step: number,
 *  window: number,
 *  algorithm: string,
 *  totpSecret: string }} OtpConfigType
 */

/**
 * Check if provided code is valid according to config
 * @param {OtpConfigType} totpConfig
 * @param {string} code
 * @return {boolean}
 */
const verifyCode = (totpConfig, code) => {
	if (!totpConfig) {
		return false;
	}
	const { totpSecret, digits, step, window, algorithm } = totpConfig;
	totp.options = { digits, step, window, algorithm, epoch: Date.now() };
	return totp.check(code, totpSecret);
};

/**
 * Get remaining validity time for the current opt password
 * @param {OtpConfigType} totpConfig
 * @return {number}
 */
const getRemainingTime = (totpConfig = {}) => {
	const { digits, step, window, algorithm } = totpConfig;
	totp.options = { digits, step, window, algorithm, epoch: Date.now() };
	return totp.timeRemaining();
};

/**
 * The otp config storage key
 * @type {string}
 */
const otpConfigStorageKey = 'otp-config';

/**
 * Get stored otp config
 * @return {Promise<OtpConfigType|null>}
 */
const getStoredOtpConfig = () => getAuthItem(otpConfigStorageKey);

/**
 * Set stored otp config
 * @param {OtpConfigType} otpConfig
 * @return {Promise<string|null>}
 */
const setStoredOtpConfig = async (otpConfig) => setAuthItem(otpConfigStorageKey, otpConfig);

/**
 * Generate and store an offline auth token from otp config
 * @param user
 * @return {Promise<void>}
 */
const setupOfflineAuth = async (user) => setStoredOfflineAuthJwt(user);

/**
 * Check if the stored offline auth token validity
 * @return {Promise<boolean>}
 */
const verifyOfflineAuth = () => getStoredOfflineAuthJwt();

/**
 * Perform logout informations cleanup
 * @return {Promise<void>}
 */
const logoutOffline = async () => {
	await removeStoredOfflineAuthJwt();
	await removeStoredJwtSecret();
	localStorage.clear();
};

export {
	getRemainingTime,
	getStoredOtpConfig,
	logoutOffline,
	setStoredOtpConfig,
	setupOfflineAuth,
	verifyCode,
	verifyOfflineAuth,
};
