import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import './task-loader.scss';

import { useRunProcessContext } from '../../../../components/context-run-process/context-run-process';
import { isActiveTask } from '../../utils/task-list-utils';
import ActionTaskView from '../action-task-view/action-task-view';
import GenericTaskView from '../generic-task-view/generic-task-view';
import MultipleChoiceTaskView from '../multiple-choice-task-view/multiple-choice-task-view';
import MultipleLinkTaskView from '../multiple-link-task-view/multiple-link-task-view';
import MultipleLinkTrainTaskView from '../multiple-link-train-task-view/multiple-link-train-task-view';
import QuestionTaskView from '../question-task-view/question-task-view';
import ReturnSummaryTaskView from '../return-summary-task-view/return-summary-task-view';
import TableTaskView from '../table-task-view/table-task-view';

const TaskLoader = ({ task }) => {
	const { handleNext, processHist, isTrainBinder } = useRunProcessContext();
	const taskRef = useRef();

	const components = {
		note: GenericTaskView,
		action: ActionTaskView,
		call: GenericTaskView,
		systemEffect: GenericTaskView,
		step: GenericTaskView,
		question: QuestionTaskView,
		resume: GenericTaskView,
		simpleLink: GenericTaskView,
		multipleLink: isTrainBinder ? MultipleLinkTrainTaskView : MultipleLinkTaskView,
		multipleChoice: MultipleChoiceTaskView,
		table: TableTaskView,
		mrfIntervention: GenericTaskView,
		pccInstruction: GenericTaskView,
		officerIntervention: GenericTaskView,
		returnSummary: ReturnSummaryTaskView,
	};

	const getActiveTask = (activeRootTask) => {
		if (activeRootTask?.type === 'question' && activeRootTask?.choice) {
			const { content, choice } = activeRootTask;
			const newTask =
				[...content[choice]?.items, content[choice]['end-item']].find(isActiveTask) ||
				content[choice]['end-item'];
			newTask.isSubNode = true;
			return newTask;
		}

		if (
			activeRootTask?.type === 'multipleChoice' &&
			activeRootTask.content.choices.some((choice) => choice.chosen && !choice.isDone)
		) {
			const { content } = activeRootTask;
			const newTask = content?.subNodes.find(isActiveTask);
			newTask.isSubNode = true;
			return newTask;
		}

		return activeRootTask;
	};

	/**
	 * Get the last returned version of the provided task, return undefined if there is no previous version
	 * @param processHistory The process history to search in
	 * @param returnableTask
	 * @return {Object|undefined}
	 */
	const findTaskPreviousVersion = (processHistory = [], returnableTask = {}) => {
		const previousTaskVersions = processHistory.filter(
			(historyTask) => historyTask?.id === returnableTask?.id
		);
		return previousTaskVersions.reverse()[0];
	};

	const activeTask = getActiveTask(task);

	const handleClickNext = () => {
		handleNext();
	};

	const renderActiveTask = () => {
		let ComponentName = components[activeTask?.type];
		const activeTaskPreviousVersion = findTaskPreviousVersion(processHist, activeTask);
		if (ComponentName) {
			return (
				<li ref={taskRef} className="task-loader">
					<ComponentName
						task={activeTask}
						onClickNext={handleClickNext}
						previousTaskInfo={activeTaskPreviousVersion}
					/>
				</li>
			);
		}
		return false;
	};

	const handleScrollToActiveTask = () => {
		if (taskRef?.current) {
			const timeoutRef = setTimeout(() => {
				taskRef.current.scrollIntoView(true);
			}, 100);
			return () => clearTimeout(timeoutRef);
		}
	};

	// Wait for render before scrolling to task
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useLayoutEffect(handleScrollToActiveTask, [activeTask?.id, taskRef?.current]);

	return renderActiveTask();
};

export default TaskLoader;

TaskLoader.propTypes = {
	task: PropTypes.shape({
		type: PropTypes.string,
		choice: PropTypes.any,
		content: PropTypes.shape({
			choices: PropTypes.arrayOf(
				PropTypes.shape({
					chosen: PropTypes.bool,
					isDone: PropTypes.bool,
				})
			),
			subNodes: PropTypes.array,
			items: PropTypes.array,
			'end-item': PropTypes.object,
		}),
		id: PropTypes.string,
	}).isRequired,
};
