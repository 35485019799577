import { differenceInMilliseconds } from 'date-fns';

import { getCacheVersionEnv } from '../../../config';

import { getEntity, setEntity } from './entity-utils';

/**
 * Refresh activity rate during synchronisation
 *
 * Set to 1 second
 * @type {number}
 */
const activityUpdateRateTime = 1000;

/**
 * Get the current status of a line
 * @param {string} line Current line
 * @return {Promise<{
 *      data: { valid: boolean, date: Date, cacheVersion: string }
 *      metadata: { lastSyncDate: Date, ref: string }
 * }>}
 */
const getStatus = async (line) => {
	const defaultData = {
		state: 'failed',
		lastSyncDate: new Date(0),
		lastActivityDate: new Date(0),
		cacheVersion: '',
	};
	const defaultMetadata = { ref: line };

	try {
		const status = await getEntity('status', line, line);

		return {
			data: { ...defaultData, ...status.data },
			metadata: { ...defaultMetadata, ...status.metadata },
		};
	} catch {
		return { data: defaultData, metadata: defaultMetadata };
	}
};

/**
 * Update the last activity date
 * @param {string} line Current line
 * @returns {Promise<void>}
 */
const updateStatusActivityDate = async (line) => {
	const status = await getEntity('status', line, line);
	status.data.lastActivityDate = new Date();
	await setEntity('status', line, line, status.data, status.metadata);
};

/**
 * Set the current status of a line
 * @param {string} line Current line
 * @param {'success'|'in-progress'|'failed'} state The current synchronisation state
 */
const setStatus = async (line, state) => {
	const metadata = { ref: line };
	const cacheVersion = getCacheVersionEnv();

	try {
		const storedStatus = await getEntity('status', line, line);
		const data = {
			...storedStatus.data,
			state,
			lastSyncDate: new Date(),
		};

		// only update cache version on success
		if (state === 'success') data.cacheVersion = cacheVersion;

		return setEntity('status', line, line, data, metadata);
	} catch {
		const data = {
			state,
			lastSyncDate: new Date(),
			cacheVersion: state === 'success' ? cacheVersion : '',
		};
		return setEntity('status', line, line, data, metadata);
	}
};

/**
 * Check if last activity is recent
 * @param {{ data: { lastActivityDate: Date }, metadata: Object }} status
 * @returns {boolean}
 */
const isLastActivityRecent = (status) => {
	const { lastActivityDate } = status.data;
	const lastActivityAge = differenceInMilliseconds(Date.now(), lastActivityDate);
	return lastActivityAge < 2 * activityUpdateRateTime;
};

export {
	activityUpdateRateTime,
	getStatus,
	isLastActivityRecent,
	setStatus,
	updateStatusActivityDate,
};
