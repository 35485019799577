import React, { useEffect } from 'react';

import './return-summary-task-view.scss';

import { IconWrapper } from '../../../../../../theme';
import { IconArrowRight } from '../../../../../../theme/assets/img';
import BinderSignalChoice from '../../../../../binder/pages/spr-binder-detail/components/binder-signal-choice/binder-signal-choice';
import EndSheet from '../../../../../process/assets/img-end-sheet.svg';
import CdrRunEnd from '../../../../../process/components/cdr-run-end/cdr-run-end';
import { useRunProcessContext } from '../../../../components/context-run-process/context-run-process';
const ReturnTemplateView = () => {
	const { currentSheetData, isBinderSignalChoiceVisible, handleSignalChoice, viewTablet } =
		useRunProcessContext();
	const binderId = currentSheetData.binder_tech_id;

	const binder = { tech_id: binderId };

	useEffect(() => {
		handleSignalChoice();
	}, [handleSignalChoice]);

	return (
		<div>
			{viewTablet && (
				<div className="return-summary-task-view__content_tablet">
					<div className="return-summary-task-view__actions">
						<IconWrapper className="return-summary-task-view__icon" Component={IconArrowRight} />
						<span className="return-summary-task-view__text">Sommaire</span>
					</div>

					<li className="cpr-run-process__end">
						<CdrRunEnd img={EndSheet} i18nLabel="process:cdr-run-process.return-summary" />
					</li>
				</div>
			)}
			{isBinderSignalChoiceVisible && !viewTablet && (
				<BinderSignalChoice binder={binder} onConfirm={handleSignalChoice} />
			)}
		</div>
	);
};

export default ReturnTemplateView;
