import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './line-dropdown.scss';

import { CustomDropdown, IconWrapper } from '../../../../theme';
import { iconLines } from '../../utils/associated-line-icon-utils/associated-line-icon.utils';
import { userLineScope } from '../../utils/user-associated-line-scope';

/**
 * Render the line dropdown
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const LineDropdown = ({
	className = '',
	lines = userLineScope,
	onChange = () => {},
	textPlaceholder,
}) => {
	const { t } = useTranslation();

	const renderLineOption = (line) => {
		const icon = iconLines[line] || false;
		return (
			<div className="line-dropdown__option">
				<IconWrapper className="line-dropdown__option__icon" Component={icon} />
				<span className="line-dropdown__option__text">{t('user:offline-auth.line', { line })}</span>
			</div>
		);
	};

	const renderSelectedLine = (line) => {
		const icon = iconLines[line] || false;
		if (line === '') {
			return <span className="line-dropdown__placeholder">{textPlaceholder}</span>;
		} else {
			return (
				<div className="line-dropdown__option">
					<IconWrapper className="line-dropdown__option__icon" Component={icon} />
					<span className="line-dropdown__option__text">
						{t('user:offline-auth.line', { line })}
					</span>
				</div>
			);
		}
	};

	return (
		<CustomDropdown
			defaultValue={lines?.length === 1 ? lines[0] : ''}
			className={classnames(className, 'line-dropdown')}
			options={lines}
			name="line"
			renderOptionValue={renderLineOption}
			renderActiveValue={renderSelectedLine}
			disabled={lines?.length <= 1}
			onChange={onChange}
		/>
	);
};

export default LineDropdown;

LineDropdown.propTypes = {
	className: PropTypes.string,
	lines: PropTypes.arrayOf(PropTypes.number),
	onChange: PropTypes.func,
	textPlaceholder: PropTypes.string.isRequired,
};
