import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './train-trouble-form-input-textarea.scss';

const TrainTroubleFormInputTextarea = ({ step = '', substep = '', checkedValue, onChange }) => {
	const { t } = useTranslation();

	return (
		<div>
			<span className={'train-trouble-form__input-textarea__title'}>
				{t(`process:process-rer-resume.${step}.${substep}`)}
			</span>
			<label>
				<textarea
					className="train-trouble-form__input-textarea"
					placeholder={t(`process:process-rer-resume.${step}.${substep}-placeholder`)}
					name={substep}
					value={checkedValue}
					rows={3}
					maxLength={140}
					required={false}
					onChange={onChange}
				/>
			</label>
		</div>
	);
};

export default TrainTroubleFormInputTextarea;

TrainTroubleFormInputTextarea.propTypes = {
	step: PropTypes.string,
	substep: PropTypes.string,
	checkedValue: PropTypes.string,
	onChange: PropTypes.func,
};
