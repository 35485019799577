import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './train-trouble-form-train-elements.scss';

import { IconDottedLine, IconTrainHead, IconTrainTail } from '../../../../../../theme/assets/img';

const TrainTroubleFormTrainElements = ({
	direction,
	children,
	trainCompositionForm,
	fullPage = true,
	readOnly = false,
	group = '',
}) => {
	const { t } = useTranslation();
	const isLeftDirection = direction === 'left';
	const isRightDirection = direction === 'right';
	const isDamageLocation = group === 'damage-location';

	const initClassname = () =>
		classNames('process-rer-resume__train-elements', {
			'process-rer-resume__train-elements--not-full-page':
				!fullPage && !readOnly && isDamageLocation,
			'process-rer-resume__train-elements--read-only': readOnly && isDamageLocation,
		});

	return (
		<div className={initClassname()}>
			{isLeftDirection && !!trainCompositionForm && (
				<div className="process-rer-resume__train-elements__top-label-container">
					<span
						className={classNames(
							'process-rer-resume__train-elements__label-container__title__first-element',
							{
								'process-rer-resume__train-elements__label-container__title__first-element--read-only':
									readOnly,
							}
						)}
					>
						{t('process:process-rer-resume.train-elements.first-element')}
					</span>
					<div className="process-rer-resume__train-elements__label-container__dotted-line">
						<IconTrainTail fill="currentColor" role="img" aria-hidden="true" focusable="false" />
						<span className="process-rer-resume__train-elements__label-container__title">
							{t('process:process-rer-resume.train-elements.train-head')}
						</span>
						<IconDottedLine
							fill="currentColor"
							role="img"
							aria-hidden="true"
							focusable="false"
							className="process-rer-resume__train-elements__label-container__dotted-line__icon"
						/>
					</div>
				</div>
			)}
			{isRightDirection && !!trainCompositionForm && (
				<div className="process-rer-resume__train-elements__top-label-container">
					<span className="process-rer-resume__train-elements__label-container__title__first-element">
						{t('process:process-rer-resume.train-elements.first-element')}
					</span>
					<div className="process-rer-resume__train-elements__label-container__dotted-line">
						<IconDottedLine
							fill="currentColor"
							role="img"
							aria-hidden="true"
							focusable="false"
							className="process-rer-resume__train-elements__label-container__dotted-line__icon"
						/>
						<span className="process-rer-resume__train-elements__label-container__title">
							{t('process:process-rer-resume.train-elements.train-head')}
						</span>
						<IconTrainHead fill="currentColor" role="img" aria-hidden="true" focusable="false" />
					</div>
				</div>
			)}
			{children}
			{isRightDirection && !!trainCompositionForm && (
				<div className="process-rer-resume__train-elements__bottom-label-container">
					<IconTrainTail fill="currentColor" role="img" aria-hidden="true" focusable="false" />
					<span className="process-rer-resume__train-elements__label-container__title">
						{t('process:process-rer-resume.train-elements.train-tail')}
					</span>
					<IconDottedLine
						fill="currentColor"
						role="img"
						aria-hidden="true"
						focusable="false"
						className="process-rer-resume__train-elements__label-container__dotted-line__icon"
					/>
				</div>
			)}
			{isLeftDirection && !!trainCompositionForm && (
				<div className="process-rer-resume__train-elements__bottom-label-container">
					<IconDottedLine
						fill="currentColor"
						role="img"
						aria-hidden="true"
						focusable="false"
						className="process-rer-resume__train-elements__label-container__dotted-line__icon"
					/>
					<span className="process-rer-resume__train-elements__label-container__title">
						{t('process:process-rer-resume.train-elements.train-tail')}
					</span>
					<IconTrainHead fill="currentColor" role="img" aria-hidden="true" focusable="false" />
				</div>
			)}
		</div>
	);
};

export default TrainTroubleFormTrainElements;

TrainTroubleFormTrainElements.propTypes = {
	direction: PropTypes.string,
	children: PropTypes.node,
	trainCompositionForm: PropTypes.object,
	fullPage: PropTypes.bool,
	readOnly: PropTypes.bool,
	group: PropTypes.string,
};
