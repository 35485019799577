import { parseJsonString } from '../../../../../theme/components/editor-rendering/utils';

/**
 * add a bulletpoint to action text
 * @param {object} content
 * @param {string} bulletPoint
 * @returns
 */
const addBulletPointToText = (content, bulletPoint) => {
	if (!bulletPoint) {
		return content;
	}
	const res = parseJsonString(content);
	if (Array.isArray(res?.blocks) && res?.blocks.length > 0 && res?.blocks[0]?.text) {
		res.blocks[0].text = `${bulletPoint} ${res?.blocks[0].text}`;
	}
	return JSON.stringify(res);
};

export default addBulletPointToText;
