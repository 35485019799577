import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './filter-role-label.scss';

import { useAppSetupContext } from '../../../../../../shared';
import { formatRoleName } from '../../../../utils/format-role-name';

/**
 * Render the FilterRoleLabel components
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.role
 * @returns {JSX.Element}
 */
const FilterRoleLabel = ({ className, role }) => {
	const { user: currentUser } = useAppSetupContext();
	const { origin: userOrigin } = currentUser || {};
	const { t } = useTranslation();

	return (
		<label className={classnames(className, 'filter-role-label')} htmlFor={role}>
			<span className="filter-role-label__label">
				{t(`user:admin-user-list.list.user-info.role.${formatRoleName(role, userOrigin)}`)}
			</span>
		</label>
	);
};

export default FilterRoleLabel;

FilterRoleLabel.propTypes = {
	className: PropTypes.string,
	role: PropTypes.string.isRequired,
};
