import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import './railway-sheet-view.scss';

import { buildTrainProcessBinderDetail, LoadWrapper } from '../../../../shared';
import { SprPage } from '../../../../theme';
import { fetchBinderByTechId } from '../../../binder/binder.services';
import ProcessTrainBreadcrumbSubheader from '../../../process/components/process-train-breadcrumb-subheader/process-train-breadcrumb-subheader';
import ProcessTrainSubheader from '../../../process/components/process-train-subheader/process-train-subheader';
import SheetPreview from '../../components/sheet-preview/sheet-preview';
import { useSheetContentContext } from '../../context/sheet-content-context';
import { SheetModeContextProvider } from '../../context/sheet-mode-context';
import { fetchSheetByBinderAndNumber } from '../../sheet.services';
import resolveSheetColor from '../../utils/resolve-sheet-color';
import { useSheetReload } from '../../utils/use-sheet-reload';

const RailwaySheetViewInner = () => {
	const { t } = useTranslation();
	const [pageLoading, setPageLoading] = useState(true);
	const { sheetNumber, binderId, processId } = useParams();
	const { sheetData = {} } = useSheetContentContext();
	const [binderData, setBinderData] = useState({});
	const { reloadSheetData } = useSheetReload();

	const sheetColor =
		binderData?.type !== 'man' &&
		resolveSheetColor(sheetData?.color, binderData?.color, sheetData?.theme_color);

	const loadSheetForPage = async () => {
		try {
			setPageLoading(true);
			const { data: sheetFound } = await fetchSheetByBinderAndNumber(binderId, sheetNumber);
			const { data: binder } = await fetchBinderByTechId(binderId);

			if (sheetFound) reloadSheetData(sheetFound);
			if (binder) setBinderData(binder);
		} finally {
			setPageLoading(false);
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => loadSheetForPage(), [sheetNumber, binderId]);

	const SubHeader = (
		<>
			<ProcessTrainSubheader />
			<ProcessTrainBreadcrumbSubheader
				showLinkPathDisplay
				returnLink={buildTrainProcessBinderDetail(
					{ processId, binderTechnicalId: binderId },
					'spr',
					true
				)}
				returnLabel={t('process:process-train-breadcrumb-subheader.sheets')}
			/>
		</>
	);

	return (
		<SprPage className="process-binder-detail" subheader={SubHeader}>
			<LoadWrapper className="sheet-detail__loader" loading={pageLoading}>
				<SheetPreview
					readOnly
					binderType={binderData?.type}
					sheetNumber={sheetData.number}
					sheetType={sheetData.type}
					sheetColor={sheetColor}
				/>
			</LoadWrapper>
		</SprPage>
	);
};

const RailwaySheetView = () => (
	<DndProvider backend={HTML5Backend}>
		<SheetModeContextProvider viewType={'train'} readOnly>
			<RailwaySheetViewInner />
		</SheetModeContextProvider>
	</DndProvider>
);

export default RailwaySheetView;
