import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './binder-list-content.scss';

import { arraySortAscending, formatJsonDate, usePopup } from '../../../../../../shared';
import { Button, SubwayLineNumber } from '../../../../../../theme';
import { ReactComponent as IconDuplicate } from '../../../../../../theme/assets/img/icon-duplicate.svg';
import { dateFormatFullDate } from '../../../../../process/utils';
import { IconReturnArrow } from '../../../../assets';
import PopupCopyBinder from '../../../../components/popup/popup-copy-binder/popup-copy-binder';
import { BinderStatus, binderStatusRules } from '../../../../index';
import DeleteBinderButton from '../delete-binder-button/delete-binder-button';

/**
 * BinderListContent component
 * @param {{className: string, searchBinders: function, train: object, binders: object[]}} props
 * @returns {JSX.Element}
 */
const BinderListContent = ({ className, binders, searchBinders, train }) => {
	const { t } = useTranslation();
	const popupCopyBinderControl = usePopup();
	const [copyBinder, setCopyBinder] = useState({});

	const subtitleDisplayStatus = binderStatusRules.hideSubtitle;

	const handlePopupCopyBinderVisible = (e, binder) => {
		e.preventDefault();
		setCopyBinder(binder);
		popupCopyBinderControl.show();
	};

	const generateBinderRow = (binder) => {
		const {
			tech_id,
			associated_line = [],
			id,
			title,
			subtitle,
			status,
			changed_description,
			is_rectification,
			published_at,
			published_temp_at,
			edition_changed_description,
			material_label,
		} = binder;

		const linesSorted = arraySortAscending(associated_line);

		// is_rectification can be null, false or true, if it's null then we don't know if the binder is a rectification then display edition_changed_description
		const edition = is_rectification === false ? changed_description : edition_changed_description;

		const isBinderToDelete = binder?.status === 'cancelled';

		return (
			<li key={`${id}${subtitle}`} className="list__item">
				<Link className="list__item__link" to={`/binders/${tech_id}`} key={tech_id}>
					{train && <span className="item__cell item__cell--material">{material_label}</span>}
					{!train && Array.isArray(associated_line) && (
						<span className="item__cell item__cell--line-number">
							<SubwayLineNumber lineNumber={associated_line[0]} />
						</span>
					)}
					<span className="item__cell item__cell--id">{id}</span>
					<span className="item__cell item__cell--title-wrapper">
						<span className="item__cell item__cell--title">{title}</span>
						{!subtitleDisplayStatus.includes(status) && (
							<span className="item__cell item__cell--subtitle">{subtitle}</span>
						)}
					</span>
					{train && Array.isArray(associated_line) && (
						<span className="item__cell item__cell--line-number">
							{linesSorted.map((line) => (
								<SubwayLineNumber key={line} lineNumber={line} />
							))}
						</span>
					)}
					<span className="item__cell item__cell--status">
						<BinderStatus status={status} />
					</span>
					<span className="item__cell item__cell--edit">{edition}</span>
					<span className="item__cell item__cell--fix">
						{is_rectification && changed_description}
					</span>
					<span className="item__cell item__cell--publish">
						{formatJsonDate(published_at, dateFormatFullDate) ||
							published_temp_at ||
							t('binder:binder-list.list.undefined-date')}
					</span>
				</Link>
				<span className="item__cell item__cell--cta">
					{status !== 'cancelled' && (
						<Button
							className="cta__icon"
							variant="minimal"
							icon={IconDuplicate}
							onClick={(e) => {
								handlePopupCopyBinderVisible(e, binder);
							}}
							iconTooltipContent={t('binder:binder-list.tooltip.copy')}
						/>
					)}
					{isBinderToDelete && (
						<DeleteBinderButton binder={binder} className="cta__icon" onDelete={searchBinders} />
					)}
					{binder.parent !== null &&
						binder.status === 'work-in-progress' &&
						binder.associated_line[0] !== binder.parent_line &&
						!binder.type.startsWith('train') && <IconReturnArrow />}
				</span>
			</li>
		);
	};

	return (
		<ul
			className={classnames('binder-list-page__list', className, {
				'binder-list-page__list--train': train,
			})}
		>
			<PopupCopyBinder
				popupControl={popupCopyBinderControl}
				onSubmitSuccess={searchBinders}
				binder={copyBinder}
				onClose={() => setCopyBinder({})}
				train={train}
			/>
			<li className="list__header">
				{train && (
					<span className="list__header-item">
						{t('binder:binder-list.list.header-label.material')}
					</span>
				)}
				{!train && (
					<span className="list__header-item">
						{t('binder:binder-list.list.header-label.line')}
					</span>
				)}
				<span className="list__header-item">{t('binder:binder-list.list.header-label.id')}</span>
				<span className="list__header-item">{t('binder:binder-list.list.header-label.title')}</span>
				{train && (
					<span className="list__header-item">
						{t('binder:binder-list.list.header-label.line')}
					</span>
				)}

				<span className="list__header-item">
					{t('binder:binder-list.list.header-label.status')}
				</span>
				<span className="list__header-item">{t('binder:binder-list.list.header-label.edit')}</span>
				<span className="list__header-item">{t('binder:binder-list.list.header-label.fix')}</span>
				<span className="list__header-item">
					{t('binder:binder-list.list.header-label.publication')}
				</span>
			</li>
			{Array.isArray(binders) && binders.length > 0 && binders.map(generateBinderRow)}
		</ul>
	);
};

export default BinderListContent;

BinderListContent.propTypes = {
	className: PropTypes.string,
	binders: PropTypes.arrayOf(PropTypes.shape({})),
	searchBinders: PropTypes.func,
	train: PropTypes.bool,
};
