/**
 * Check if the provided formError contain at least an error
 * @param formError
 * @return {boolean}
 */
const checkFormError = (formError = {}) => {
	const isErrorList = Object.values(formError);
	return isErrorList.reduce(
		(errorInForm, curFieldError) => errorInForm || curFieldError.length !== 0,
		false
	);
};

/**
 * Validate if the provided value is within range (limits included)
 * @param {number} val
 * @param {number} min
 * @param {number} max
 * @returns {string[]}
 */
const checkRange = (val, min, max) =>
	val < min ? ['out-of-range-min'] : val > max ? ['out-of-range-max'] : [];

/**
 * Check if the provided value is truthy
 * @param {any} value
 * @return {string[]}
 */
const requiredFieldValidator = (value) => (!value && typeof value !== 'boolean' ? ['falsy'] : []);

/**
 * Validate if the provided value is a number
 * @param {any} value
 * @returns {string[]}
 */
const numberValidator = (value) =>
	typeof value !== 'number' || Number.isNaN(value) || !Number.isFinite(value)
		? ['not-a-number']
		: [];

/**
 * Return true when errorName is in errorList
 * @param {string} errorName The error to search
 * @param {string[]} [errorList=[]] The error list
 * @returns {boolean}
 */
const hasFieldError = (errorName, errorList = []) => errorList.includes(errorName);

export { checkFormError, checkRange, hasFieldError, numberValidator, requiredFieldValidator };
