import { ReactComponent as M0 } from '../../../../theme/assets/img/icon-line/icon-m0.svg';
import { ReactComponent as disabledM0 } from '../../../../theme/assets/img/icon-line/icon-m0-grey.svg';
import { ReactComponent as M1 } from '../../../../theme/assets/img/icon-line/icon-m1.svg';
import { ReactComponent as disabledM1 } from '../../../../theme/assets/img/icon-line/icon-m1-grey.svg';
import { ReactComponent as M2 } from '../../../../theme/assets/img/icon-line/icon-m2.svg';
import { ReactComponent as disabledM2 } from '../../../../theme/assets/img/icon-line/icon-m2-grey.svg';
import { ReactComponent as M3 } from '../../../../theme/assets/img/icon-line/icon-m3-bis.svg';
import { ReactComponent as disabledM3 } from '../../../../theme/assets/img/icon-line/icon-m3-bis-grey.svg';
import { ReactComponent as M4 } from '../../../../theme/assets/img/icon-line/icon-m4.svg';
import { ReactComponent as disabledM4 } from '../../../../theme/assets/img/icon-line/icon-m4-grey.svg';
import { ReactComponent as M5 } from '../../../../theme/assets/img/icon-line/icon-m5.svg';
import { ReactComponent as disabledM5 } from '../../../../theme/assets/img/icon-line/icon-m5-grey.svg';
import { ReactComponent as M6 } from '../../../../theme/assets/img/icon-line/icon-m6.svg';
import { ReactComponent as disabledM6 } from '../../../../theme/assets/img/icon-line/icon-m6-grey.svg';
import { ReactComponent as M7 } from '../../../../theme/assets/img/icon-line/icon-m7-bis.svg';
import { ReactComponent as disabledM7 } from '../../../../theme/assets/img/icon-line/icon-m7-bis-grey.svg';
import { ReactComponent as M8 } from '../../../../theme/assets/img/icon-line/icon-m8.svg';
import { ReactComponent as disabledM8 } from '../../../../theme/assets/img/icon-line/icon-m8-grey.svg';
import { ReactComponent as M9 } from '../../../../theme/assets/img/icon-line/icon-m9.svg';
import { ReactComponent as disabledM9 } from '../../../../theme/assets/img/icon-line/icon-m9-grey.svg';
import { ReactComponent as M10 } from '../../../../theme/assets/img/icon-line/icon-m10.svg';
import { ReactComponent as disabledM10 } from '../../../../theme/assets/img/icon-line/icon-m10-grey.svg';
import { ReactComponent as M11 } from '../../../../theme/assets/img/icon-line/icon-m11.svg';
import { ReactComponent as disabledM11 } from '../../../../theme/assets/img/icon-line/icon-m11-grey.svg';
import { ReactComponent as M12 } from '../../../../theme/assets/img/icon-line/icon-m12.svg';
import { ReactComponent as disabledM12 } from '../../../../theme/assets/img/icon-line/icon-m12-grey.svg';
import { ReactComponent as M13 } from '../../../../theme/assets/img/icon-line/icon-m13.svg';
import { ReactComponent as disabledM13 } from '../../../../theme/assets/img/icon-line/icon-m13-grey.svg';
import { ReactComponent as M14 } from '../../../../theme/assets/img/icon-line/icon-m14.svg';
import { ReactComponent as disabledM14 } from '../../../../theme/assets/img/icon-line/icon-m14-grey.svg';
import { ReactComponent as rerA } from '../../../../theme/assets/img/icon-line/icon-rerA.svg';
import { ReactComponent as disabledRerA } from '../../../../theme/assets/img/icon-line/icon-rerA-grey.svg';
import { ReactComponent as rerB } from '../../../../theme/assets/img/icon-line/icon-rerB.svg';
import { ReactComponent as disabledRerB } from '../../../../theme/assets/img/icon-line/icon-rerB-grey.svg';
import { ReactComponent as VA } from '../../../../theme/assets/img/icon-line/icon-va.svg';
import { ReactComponent as disabledVA } from '../../../../theme/assets/img/icon-line/icon-va-grey.svg';

const iconLines = {
	VA: VA,
	0: M0,
	1: M1,
	2: M2,
	3: M3,
	4: M4,
	5: M5,
	6: M6,
	7: M7,
	8: M8,
	9: M9,
	10: M10,
	11: M11,
	12: M12,
	13: M13,
	14: M14,
	A: rerA,
	B: rerB,
};

const iconLinesDisabled = {
	0: disabledM0,
	1: disabledM1,
	2: disabledM2,
	3: disabledM3,
	4: disabledM4,
	5: disabledM5,
	6: disabledM6,
	7: disabledM7,
	8: disabledM8,
	9: disabledM9,
	10: disabledM10,
	11: disabledM11,
	12: disabledM12,
	13: disabledM13,
	14: disabledM14,
	A: disabledRerA,
	B: disabledRerB,
	VA: disabledVA,
};

export { iconLines, iconLinesDisabled };
