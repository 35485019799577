import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './task-list-drawer.scss';

import { formatJsonDate, useSprContext } from '../../../../../../shared';
import getSignalInfo from '../../../../../../shared/utils/get-signal-info';
import { Drawer, EditorRendering } from '../../../../../../theme';
import { isTrainBinderType } from '../../../../../binder/utils/binder-type';
import { SheetType } from '../../../../../sheet';
import HistoryTaskLoader from '../../../../components/history-task-loader/history-task-loader';
import { dateFormatTime } from '../../../../utils';

/**
 * @typedef Task
 * @prop {string} id
 * @prop {object} content
 * @prop {string} type
 * @prop {("yes" | "no")|object} [choice]
 * @prop {boolean} [active]
 * @prop {boolean} [hidden]
 */

/**
 * TaskListDrawer component
 * @param {object} props
 * @param {Task[]} props.taskList
 * @param {string} props.color
 * @param {string} props.sheetNumber
 * @param {string} props.sheetType
 * @param {Date} props.startTime
 * @param {number} props.lastCanceledTaskPosition
 * @param {function} props.handleJumpBack
 * @param {object} props.processInfo
 * @param {string} props.binderType
 * @param {string} props.signalTechId
 * @returns {JSX.Element}
 */
const TaskListDrawer = ({
	taskList,
	color,
	sheetNumber,
	sheetTitle,
	sheetType,
	startTime,
	lastCanceledTaskPosition,
	handleJumpBack,
	processInfo,
	binderType,
	signalTechId,
}) => {
	const { signalList } = useSprContext();
	const showDropDownToggle = taskList.some(
		({ hidden, type }) => !hidden && type !== 'history-start'
	);
	const isTrainBinder = isTrainBinderType(binderType);

	const generateTaskDisplay = (task) =>
		!task?.hidden ? (
			<HistoryTaskLoader
				key={`${task?.id}${task?.finishedAt}`}
				className="task-list-drawer__content__task"
				task={task}
				lastCanceledTaskPosition={lastCanceledTaskPosition}
				handleJumpBack={handleJumpBack(task)}
				processInfo={processInfo}
				isTrainBinder={isTrainBinder}
			/>
		) : null;

	const Title = (
		<SheetType
			className="task-list-drawer__header__sheet-number"
			color={color}
			type={sheetType}
			signalInfo={getSignalInfo(signalList, signalTechId)}
			sheetNumber={sheetNumber}
			binderType={binderType}
			isBinderTrain={isTrainBinder}
		/>
	);

	const Subtitle =
		typeof sheetTitle === 'string' ? (
			<span className="task-list-drawer__header__sheet-title">{sheetTitle}</span>
		) : (
			<EditorRendering className="task-list-drawer__header__sheet-title" content={sheetTitle} />
		);
	return (
		<Drawer
			className={classnames('task-list-drawer', { 'task-list-drawer--train': isTrainBinder })}
			title={Title}
			leftText={Subtitle}
			rightText={formatJsonDate(startTime, dateFormatTime)}
			showDropDownToggle={showDropDownToggle}
			drawerStatus={true}
		>
			<ul>{taskList.map(generateTaskDisplay)}</ul>
		</Drawer>
	);
};

export default TaskListDrawer;

TaskListDrawer.propTypes = {
	taskList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	color: PropTypes.string,
	sheetNumber: PropTypes.string,
	sheetTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	sheetType: PropTypes.string,
	startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	lastCanceledTaskPosition: PropTypes.number,
	handleJumpBack: PropTypes.func,
	processInfo: PropTypes.object,
	binderType: PropTypes.string,
	signalTechId: PropTypes.string,
};
