import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-create-signal.scss';

import { checkFormError } from '../../../../../../../../../shared';
import { Button, PopupDefault } from '../../../../../../../../../theme';
import { createBinderSignal } from '../../../../../../../binder-signal.services';
import { validateSignalForm } from '../../../../../utils/signal-form-utils';
import FormSignal from '../../form-signal/form-signal';

/**
 * Render the popup to create man binder signal
 * @param {object} props
 * @param {object} props.popupControl
 * @param {string} props.binderTechId
 * @param {function} props.onCreate
 * @returns {JSX.Element}
 */
const PopupCreateSignal = ({ popupControl, binderTechId, signalList, onCreate = () => {} }) => {
	const [form, setForm] = useState({});
	const { t } = useTranslation();

	const formError = useMemo(() => validateSignalForm(form, signalList), [form, signalList]);

	const handleChange = (event) => {
		setForm((previousForm) => {
			const { name, value } = event.target;
			return { ...previousForm, [name]: value };
		});
	};

	const handleSubmit = () => {
		if (!checkFormError(formError)) {
			createBinderSignal(binderTechId, form)
				.then(() => {
					setForm({});
					popupControl.hide();
				})
				.then(onCreate);
		}
	};

	return (
		<PopupDefault
			className="popup-create-signal__wrapper"
			popupControl={popupControl}
			title={t('binder:popup.create-signal.title')}
		>
			<div className="popup-create-signal">
				<FormSignal
					className="popup-create-signal__form"
					form={form}
					validation={formError}
					onChange={handleChange}
				/>
				<Button
					variant="primary"
					className="popup-create-signal__submit"
					onClick={handleSubmit}
					disabled={checkFormError(formError)}
					label={t('binder:popup.create-signal.validate')}
				/>
			</div>
		</PopupDefault>
	);
};

export default PopupCreateSignal;

PopupCreateSignal.propTypes = {
	binderTechId: PropTypes.string.isRequired,
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired,
	}).isRequired,
	onCreate: PropTypes.func,
	signalList: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			origin: PropTypes.string.isRequired,
			destination: PropTypes.string.isRequired,
		})
	).isRequired,
};
