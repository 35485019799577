import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAppSetupContext } from '../app-setup-context/app-setup-context';

const CdrNetworkWrapper = ({ children }) => {
	const history = useHistory();

	const { networkStatus } = useAppSetupContext();

	const redirectToNetworkDown = useCallback(() => {
		history.push('/error-network');
	}, [history]);

	const redirectToServerDown = useCallback(() => {
		history.push('/error-down');
	}, [history]);

	useEffect(() => {
		window.addEventListener('server-error', redirectToServerDown);

		return () => {
			window.removeEventListener('server-error', redirectToServerDown);
		};
	}, [redirectToServerDown]);

	// listener on offline event to redirect to error network page
	useEffect(() => {
		if (networkStatus === 'offline') {
			redirectToNetworkDown();
		}
	}, [redirectToNetworkDown, networkStatus]);

	// first check if navigator is offline
	useEffect(() => {
		if (!navigator.onLine) {
			redirectToNetworkDown();
		}
	}, [redirectToNetworkDown]);

	return <>{children}</>;
};

export default CdrNetworkWrapper;

CdrNetworkWrapper.propTypes = {
	children: PropTypes.node.isRequired,
};
