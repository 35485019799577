import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-edit-user.scss';

import { useEffectDidUpdate } from '../../../../../../shared';
import { Button, ErrorMessage, PopupDefault } from '../../../../../../theme';
import { FormUser } from '../../../../index';
import { fetchUserById, updateUser } from '../../../../user.services';
import validateUserForm from '../../../../utils/validate-user-form';

/**
 * PopupControl object
 * @typedef {object} PopupControl
 * @prop {boolean} visible - is popup visible
 * @prop {function} setVisible - set is popup visible
 * @prop {function} show - show popup
 * @prop {function} hide - hide popup
 */

/**
 * popup to edit user
 * @param {object} props
 * @param {PopupControl} props.popupControl
 * @param {function} props.onSubmitPurgeUser
 * @param {function} props.onSubmitSuccess
 * @param {object} props.userToEdit
 * @param {string} props.userRole
 * @returns {JSX.Element}
 */
const PopupEditUser = ({
	popupControl,
	onSubmitPurgeUser = () => {},
	onSubmitSuccess = () => {},
	userToEdit,
	userRole,
}) => {
	const { t } = useTranslation();
	const [formUser, setFormUser] = useState({});
	const [networkError, setNetworkError] = useState(false);
	const [formValid, setFormValid] = useState(false);
	const [checkboxPurge, setCheckboxPurge] = useState(false);

	const fetchExistingUserById = () => {
		if (userToEdit?.id) {
			fetchUserById(userToEdit.id)
				.then((response) => {
					setNetworkError(false);
					const { id, firstname, lastname, role, associated_line, tech_id, active, main_line } =
						response?.data || {};
					const lineForm = main_line ? [main_line] : associated_line;

					setFormUser({
						id,
						firstname,
						lastname,
						role,
						associated_line: lineForm,
						tech_id,
						active,
					});
				})
				.catch((error) => {
					console.error(error);
					setNetworkError(true);
				});
		}
	};

	const init = () => {
		if (popupControl.visible) {
			fetchExistingUserById();
			setCheckboxPurge(false);
		}
	};

	const handleRoleAndAssociatedLine = (selectedRole, calculateLines) => {
		const newFormUser = {
			...formUser,
			role: selectedRole,
			associated_line: calculateLines,
		};
		setFormUser(newFormUser);
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		const newFormUser = { ...formUser, [name]: value };
		setFormUser(newFormUser);
	};

	const handleActiveChange = (active) => {
		const newFormUser = { ...formUser, active };
		setFormUser(newFormUser);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (checkboxPurge) {
			popupControl.hide();
			onSubmitPurgeUser(userToEdit);
		} else {
			updateUser(formUser.tech_id, formUser)
				.then(() => {
					popupControl.hide();
					onSubmitSuccess();
					setNetworkError(false);
				})
				.catch((error) => {
					console.error(error);
					setNetworkError(true);
				});
		}
	};

	const handleCancel = () => {
		setFormUser({});
		setNetworkError(false);
	};

	const handleLineChange = (selectedLines = []) => {
		// sort selectedLines before send form to the db
		const sortedSelectedLines = selectedLines.sort(
			(selectedLine1, selectedLine2) => selectedLine1 - selectedLine2
		);
		setFormUser((oldUser) => ({
			...oldUser,
			associated_line: sortedSelectedLines,
		}));
	};

	const formValidation = () => {
		const { isValid } = validateUserForm(formUser, false);
		setFormValid(isValid);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(init, [userToEdit?.id, popupControl.visible]);
	useEffectDidUpdate(formValidation, formUser, [formUser]);

	return (
		<PopupDefault
			popupControl={popupControl}
			title={t('user:popup.edit-user.title')}
			onClose={handleCancel}
		>
			<form className="popup-edit-user" onSubmit={handleSubmit}>
				<div className="popup-edit-user__form-wrapper">
					<FormUser
						form={{ ...formUser, id: userToEdit?.id }}
						onChange={handleChange}
						onActiveChange={handleActiveChange}
						onRoleChange={handleRoleAndAssociatedLine}
						onLineChange={handleLineChange}
						userRole={userRole}
						update
					/>
					{(userRole === 'administrator' || userRole === 'admin-rer') && (
						<div className="popup-edit-user__form-purge">
							<span className="popup-edit-user__form-purge__title">
								{t('user:popup.edit-user.purge.title')} :
							</span>
							<div className="popup-edit-user__form-purge__checkbox">
								<input
									type="checkbox"
									name="purge"
									onChange={() => setCheckboxPurge(!checkboxPurge)}
									checked={checkboxPurge}
								/>
								<label className="popup-edit-user__form-purge__label">
									<div className="popup-edit-user__form-purge__label-title">
										{t('user:popup.edit-user.purge.label')}
									</div>
									{t('user:popup.edit-user.purge.content')}
								</label>
							</div>
						</div>
					)}
				</div>
				<div className="popup-edit-user__controller">
					<Button
						variant="primary"
						className="popup-edit-user__submit"
						type="submit"
						disabled={!formValid && !checkboxPurge}
						label={t('user:popup.create-user.submit')}
					/>
				</div>
				{networkError && (
					<ErrorMessage className="popup-edit-user__network-error">
						{t('error.default')}
					</ErrorMessage>
				)}
			</form>
		</PopupDefault>
	);
};

export default PopupEditUser;

PopupEditUser.propTypes = {
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired,
		visible: PropTypes.bool.isRequired,
	}).isRequired,
	onSubmitPurgeUser: PropTypes.func,
	onSubmitSuccess: PropTypes.func,
	userToEdit: PropTypes.shape({
		id: PropTypes.string.isRequired,
		lastname: PropTypes.string.isRequired,
		firstname: PropTypes.string.isRequired,
		tech_id: PropTypes.string.isRequired,
		creation_date: PropTypes.string,
		role: PropTypes.string,
		active: PropTypes.bool,
		associated_line: PropTypes.arrayOf(PropTypes.string),
		main_line: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}).isRequired,
	userRole: PropTypes.string.isRequired,
};
