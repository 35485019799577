import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-delete-signal.scss';

import { PopupConfirm } from '../../../../../../../../../theme';
import { deleteBinderSignalByTechId } from '../../../../../../../binder-signal.services';
import SignalTitle from '../../signal-title/signal-title';

/**
 * Render the popup to delete man binder's signal
 * @param {object} props
 * @param {object} props.popupControl
 * @param {string} props.binderTechId
 * @param {string} props.signalTechId
 * @param {function} props.onDelete
 * @returns {JSX.Element}
 */
const PopupDeleteSignal = ({ popupControl, binderTechId, signal = {}, onDelete = () => {} }) => {
	const { tech_id: signalTechId, title, origin, destination } = signal;

	const { t } = useTranslation();

	const handleDelete = () => {
		if (binderTechId && signalTechId) {
			deleteBinderSignalByTechId(binderTechId, signalTechId).then(() => {
				onDelete();
				popupControl.hide();
			});
		}
	};

	return (
		<PopupConfirm
			className="popup-delete-signal__wrapper"
			popupControl={popupControl}
			onConfirm={handleDelete}
			title={t('binder:binder-detail.popup-delete-signal.title')}
			ctaConfirmLabel="sheet:popup.delete.cta-confirm-label"
		>
			<div className="popup-delete-signal">
				<h1 className="popup-delete-signal__title">
					{t('binder:binder-detail.popup-delete-signal.text.title')}
				</h1>
				<p className="popup-delete-signal__question">
					{t('binder:binder-detail.popup-delete-signal.text.question')}
				</p>
				<SignalTitle
					className="popup-delete-signal__signal"
					title={title}
					origin={origin}
					destination={destination}
				/>
				<p className="popup-delete-signal__note">
					{t('binder:binder-detail.popup-delete-signal.text.note')}
				</p>
			</div>
		</PopupConfirm>
	);
};

export default PopupDeleteSignal;

PopupDeleteSignal.propTypes = {
	binderTechId: PropTypes.string.isRequired,
	signal: PropTypes.shape({
		tech_id: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		origin: PropTypes.string.isRequired,
		destination: PropTypes.string.isRequired,
	}).isRequired,
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired,
	}).isRequired,
	onDelete: PropTypes.func,
};
