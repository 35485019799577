import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './form-binder.scss';

import { useAppSetupContext } from '../../../../../shared';
import { fetchMaterial } from '../../../../../shared/material.services';
import {
	ColorChooser,
	Dropdown,
	ErrorMessage,
	LineSelector,
	Textarea,
	TextField,
} from '../../../../../theme';
import {
	setupLineScopeAllowed,
	userMetroScope,
	userRerScope,
} from '../../../../user/utils/user-associated-line-scope';
import { fetchStation } from '../../../binder.services';
import binderColorList from '../../../utils/binder-color-list.json';
import {
	binderPccTypeList,
	binderTrainTypeList,
	binderTrainTypeListRer,
} from '../../../utils/binder-type';
import BinderTypeDropdown from '../../binder-type-dropdown/binder-type-dropdown';
import MaterialDropdown from '../../material-dropdown/material-dropdown';
import StationDropdown from '../../station-dropdown/station-dropdown';
import StationTypeDropdown from '../../station-type-dropdown/station-type-dropdown';

/**
 * FormBinder props
 * @typedef {object} FormBinderProps
 * @property {object} form - form information
 * @property {object} validation - validation of form input per field
 * @property {object} errorMessage - form error messages per field
 * @property {function} onChange - change handler
 * @property {string} extendedBinderStatus
 * @property {boolean} [hasCopyBinderId] - set on copy train popup to inform if id is changed
 * @property {boolean} [create]
 * @property {boolean} [update]
 * @property {boolean} [copy]
 * @property {boolean} [train]
 */

/**
 * FormBinder component
 * @param {FormBinderProps} props
 * @returns {JSX.Element}
 */
const FormBinder = (props) => {
	const {
		form,
		validation,
		errorMessage,
		create,
		update,
		copy,
		train,
		onChange,
		extendedBinderStatus = false,
		hasCopyBinderId = false,
		hasEditBinderId = false,
		initialLineNumber,
	} = props;
	const { t } = useTranslation();

	const { user: currentUser } = useAppSetupContext();
	const { role: userRole, origin: userOrigin } = currentUser || {};
	const [lineOptions, setLineOptions] = useState([]);
	const [materialList, setMaterialList] = useState([]);
	const [stationList, setStationList] = useState([]);

	const handleTitleChange = (event) => {
		const { value, name } = event.target;
		// Replace linebreak on copy/paste action
		if (typeof value === 'string') {
			const title = value.replace(/[\n\r]+/g, '-');
			onChange({ target: { name, value: title } });
		} else {
			onChange(event);
		}
	};

	const findMaterialOriginById = useCallback(() => {
		const materialSelected = materialList.find((material) => material.tech_id === form?.material);
		return materialSelected.origin;
	}, [form?.material, materialList]);

	// filter associate line list by user's perimeter
	const setupLineOptionsAllowed = () => {
		const lineOptionsAllowed = setupLineScopeAllowed(currentUser);
		setLineOptions(lineOptionsAllowed);
	};

	const onChangeMaterial = () => {
		// if user is administrator the lines options depend on the selected material (rer or mts)
		if (form?.material && userRole === 'administrator') {
			const selectedMaterialOrigin = findMaterialOriginById();
			if (selectedMaterialOrigin === 'rer') {
				setLineOptions(userRerScope);
			} else if (selectedMaterialOrigin === 'mts') {
				setLineOptions(userMetroScope);
			}
		}
	};

	const getMaterialList = () => {
		const origin = userRole !== 'administrator' ? userOrigin : '';
		if (train) {
			fetchMaterial(origin).then((response) => {
				setMaterialList(response?.data);
			});
		}
	};

	const getStationList = () => {
		if ((create || update) && form?.lineNumber && form?.type === 'man') {
			fetchStation(form?.lineNumber).then((response) => {
				setStationList(response.data);

				// Avoid unwanted field reset on loading current binder associated_line's station
				if (create && !response.data.every((station) => station.line === form.lineNumber)) {
					onChange({ target: { name: 'stationType', value: '' } });
					onChange({ target: { name: 'station', value: '' } });
				}
			});
		} else {
			setStationList([]);
		}
	};

	const filteredTypeList = () => {
		if (train && userOrigin === 'mts') {
			return binderTrainTypeList;
		} else if (train && userOrigin === 'rer') {
			return binderTrainTypeListRer;
		} else {
			return binderPccTypeList;
		}
	};

	useEffect(setupLineOptionsAllowed, [currentUser]);
	useEffect(onChangeMaterial, [currentUser, form.material, findMaterialOriginById, userRole]);
	useEffect(getMaterialList, [train, currentUser, userOrigin, userRole]);
	// ignore onChange dependency to avoid infinite render
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(getStationList, [create, update, form?.lineNumber, form?.type]);

	const renderLineOptions = (line) => (
		<option key={line} value={line}>
			{line}
		</option>
	);

	const isDisabledIdField = () => {
		if (form.lineNumber && initialLineNumber) {
			return copy && !train && form.lineNumber === initialLineNumber && !train;
		}
		return false;
	};

	return (
		<div className={classnames('form-binder', { 'form-binder--train': train })}>
			<div className="form-binder__wrapper">
				{train && (
					<MaterialDropdown
						className="form-binder__material"
						placeholder={t('binder:popup.form.field.material.placeholder')}
						label={t('binder:popup.form.field.material.label')}
						name="material"
						value={form.material}
						onChange={onChange}
						materialList={materialList}
						disabled={copy || update}
					/>
				)}
				<BinderTypeDropdown
					className="form-binder__type"
					name="type"
					label={t('binder:popup.form.field.type.label')}
					placeholder={t('binder:popup.form.field.type.placeholder')}
					value={form.type}
					onChange={onChange}
					disabled={update || copy}
					defaultValue={!train ? 'sheet' : ''}
					typeList={filteredTypeList()}
				/>
				{!train && (create || copy) && (
					<Dropdown
						className="form-binder__line"
						name="lineNumber"
						label={t('binder:popup.form.field.line-number.label')}
						placeholder={t('binder:popup.form.field.line-number.placeholder')}
						value={form.lineNumber}
						onChange={onChange}
					>
						{lineOptions.map(renderLineOptions)}
					</Dropdown>
				)}
				{(create || update) && form?.type === 'man' && (
					<>
						<StationDropdown
							className="form-binder__station"
							stationList={stationList}
							name="station"
							value={form?.station}
							placeholder={t('binder:popup.form.field.station.placeholder')}
							defaultValue=""
							label={t('binder:popup.form.field.station.label')}
							onChange={onChange}
							disabled={!form.lineNumber}
						/>
						<StationTypeDropdown
							className="form-binder__station-type"
							name="stationType"
							value={form?.stationType}
							placeholder={t('binder:popup.form.field.station-type.placeholder')}
							defaultValue=""
							label={t('binder:popup.form.field.station-type.label')}
							onChange={onChange}
							disabled={!form.lineNumber}
						/>
					</>
				)}
				<TextField
					name="id"
					label={t('binder:popup.form.field.binder.label')}
					value={form.id}
					invalid={form.id !== undefined && validation.id.length !== 0}
					helperText={t(errorMessage.id) || t('binder:popup.form.field.binder.helper-text')}
					onChange={onChange}
					disabled={isDisabledIdField()}
				/>
				{extendedBinderStatus && (
					<ErrorMessage className="popup-form__existing-binder">
						{t('binder:popup.create-binder.existing-binder')}
					</ErrorMessage>
				)}
				{hasCopyBinderId && (
					<ErrorMessage className="popup-form__existing-binder">
						{t('binder:popup.copy-binder.new-train-binder')}
					</ErrorMessage>
				)}
				{hasEditBinderId && (
					<ErrorMessage className="popup-form__existing-binder">
						{t('binder:popup.edit-title.existing-binder')}
					</ErrorMessage>
				)}
				<Textarea
					name="title"
					label={t('binder:popup.form.field.title.label')}
					value={form.title || ''}
					onChange={handleTitleChange}
					preventNewLine
				/>
				<Textarea
					name="subtitle"
					label={t('binder:popup.form.field.temporary-sub-title.label')}
					helperText={t(errorMessage.subtitle)}
					value={form.subtitle || ''}
					onChange={onChange}
					invalid={form.subtitle !== undefined && validation.subtitle.length !== 0}
					preventNewLine
				/>
				{!train && (
					<TextField
						name="abbreviation"
						label={t('binder:popup.form.field.abbreviation.label')}
						helperText={t(errorMessage.abbreviation)}
						value={form.abbreviation || ''}
						onChange={onChange}
						invalid={form.abbreviation !== undefined && validation.abbreviation.length !== 0}
					/>
				)}
				<ColorChooser
					colorList={binderColorList}
					name="color"
					value={form.color}
					onChange={onChange}
					label={t('binder:popup.form.field.binder-color.label')}
					large={update && !train}
				/>
			</div>
			{train && (
				<div className="form-binder__wrapper">
					<span className="form-binder__multiple-line-label">
						{t('binder:popup.form.field.multiple-line.label')}
					</span>
					<LineSelector
						onChange={onChange}
						name="lineNumber"
						value={form.lineNumber}
						lineList={lineOptions}
					/>
				</div>
			)}
		</div>
	);
};

FormBinder.propTypes = {
	form: PropTypes.shape({
		abbreviation: PropTypes.string,
		binderColor: PropTypes.string,
		color: PropTypes.string,
		id: PropTypes.string,
		lineNumber: PropTypes.arrayOf(PropTypes.number),
		material: PropTypes.string,
		subtitle: PropTypes.string,
		title: PropTypes.string,
		type: PropTypes.string,
		station: PropTypes.string,
		stationType: PropTypes.string,
	}).isRequired,
	validation: PropTypes.shape({
		abbreviation: PropTypes.array.isRequired,
		color: PropTypes.array,
		id: PropTypes.array.isRequired,
		lineNumber: PropTypes.array,
		subtitle: PropTypes.array.isRequired,
		title: PropTypes.array.isRequired,
		type: PropTypes.array,
		station: PropTypes.array,
		stationType: PropTypes.array,
	}).isRequired,
	errorMessage: PropTypes.shape({
		abbreviation: PropTypes.string,
		color: PropTypes.string,
		id: PropTypes.string,
		lineNumber: PropTypes.string,
		subtitle: PropTypes.string,
		title: PropTypes.string,
		type: PropTypes.string,
	}).isRequired,
	onChange: PropTypes.func.isRequired,
	extendedBinderStatus: PropTypes.string,
	create: PropTypes.bool,
	update: PropTypes.bool,
	copy: PropTypes.bool,
	train: PropTypes.bool,
	hasCopyBinderId: PropTypes.bool,
	hasEditBinderId: PropTypes.bool,
	initialLineNumber: PropTypes.number,
};

export default FormBinder;
