import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './dropdown-menu-item.scss';

import { IconWrapper } from '../../../';

const DropdownMenuItem = ({
	className,
	icon,
	label,
	onClick = () => {},
	href,
	target = '_self',
	hideMenu,
}) => {
	const handleClick = () => {
		onClick();
		hideMenu();
	};

	const content = (
		<>
			{icon && <IconWrapper className="dropdown-menu-item__icon" Component={icon} />}
			<span className="dropdown-menu-item__label">{label}</span>
		</>
	);

	return (
		<li className="dropdown-menu-item__wrapper">
			{href && (
				<a
					className={classNames('dropdown-menu-item', className)}
					href={href}
					target={target}
					rel="noopener noreferrer"
				>
					{content}
				</a>
			)}
			{!href && (
				<button
					className={classNames('dropdown-menu-item', className)}
					type="button"
					onClick={handleClick}
				>
					{content}
				</button>
			)}
		</li>
	);
};

export default DropdownMenuItem;

DropdownMenuItem.propTypes = {
	className: PropTypes.string,
	icon: PropTypes.elementType,
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	href: PropTypes.string,
	target: PropTypes.string,
	hideMenu: PropTypes.func,
};
