import localForage from 'localforage';

import {
	getItemGenerator,
	getKeysGenerator,
	removeItemGenerator,
	setItemGenerator,
} from '../localforage';

const localforageOption = {
	name: 'digiproc',
	storeName: 'digiproc_offline_cache',
	description: 'Storage for digiproc offline mode',
	driver: localForage.INDEXEDDB,
};

const offlineCache = localForage.createInstance(localforageOption);

/**
 * Get offline cache item
 * @type {(function(string): Promise<*>)}
 */
const getItem = getItemGenerator(offlineCache);

/**
 * Set offline cache item
 * @type {function(string, *): Promise<*>}
 */
const setItem = setItemGenerator(offlineCache);
/**
 * Remove offline cache item
 * @type {function(string): Promise<*>}
 */
const removeItem = removeItemGenerator(offlineCache);

/**
 * Get offline cache keys
 * @type {function(void): Promise<*>}
 */
const getKeys = getKeysGenerator(offlineCache);

export { getItem, getKeys, offlineCache, removeItem, setItem };
