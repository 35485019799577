import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './question-task-view.scss';

import { EditorRendering, IconWrapper } from '../../../../../../theme';
import ImageListView from '../../../../../../theme/components/image-list-view/image-list-view';
import { IconReturn } from '../../../../assets';
import { useRunProcessContext } from '../../../../components/context-run-process/context-run-process';

/**
 * @param {object} props
 * @param {{content: {images: object[]}, note: string, text: string}} props.task
 * @param {{choice: string}} props.previousTaskInfo
 * @returns {JSX.Element}
 */
const QuestionTaskView = ({ task = {}, previousTaskInfo = {} }) => {
	const { content = {} } = task;
	const { note, text, images } = content;
	const { choice: previousChoice } = previousTaskInfo;
	const { handleQuestionTaskAnswer, isTrainBinder } = useRunProcessContext();
	const { t } = useTranslation();

	const handleClickYes = () => handleQuestionTaskAnswer('yes');
	const handleClickNo = () => handleQuestionTaskAnswer('no');

	return (
		<div className="question-task-view">
			<div
				className={classNames('question-task-view__text', {
					'question-task-view__text--train': isTrainBinder,
				})}
			>
				<EditorRendering className={'question-task-view__text__content'} content={text} />
			</div>
			{(note || images?.length > 0) && (
				<div className="question-task-view__note">
					{note && (
						<EditorRendering className={'question-task-view__note__content'} content={note} />
					)}
					{images?.length > 0 && (
						<ImageListView className="text-template-view__images" images={images} />
					)}
				</div>
			)}
			<div
				className={classNames('question-task-view__answer', {
					'question-task-view__answer--train': isTrainBinder,
				})}
			>
				<button type="button" className="question-task-view__yes" onClick={handleClickYes}>
					{t('process:history-view.question.yes')}
					{previousChoice === 'yes' && (
						<IconWrapper className="question-task-view__icon-return" Component={IconReturn} />
					)}
				</button>
				<button type="button" className="question-task-view__no" onClick={handleClickNo}>
					{t('process:history-view.question.no')}
					{previousChoice === 'no' && (
						<IconWrapper className="question-task-view__icon-return" Component={IconReturn} />
					)}
				</button>
			</div>
		</div>
	);
};

export default QuestionTaskView;

QuestionTaskView.propTypes = {
	previousTaskInfo: PropTypes.shape({
		choice: PropTypes.bool,
	}),
	task: PropTypes.shape({
		content: PropTypes.shape({
			images: PropTypes.arrayOf(PropTypes.object),
			note: PropTypes.string,
			text: PropTypes.string,
		}),
	}),
};
