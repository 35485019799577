import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const RestrictionSummary = ({ resumeRer, isProcessRecap }) => {
	const { t } = useTranslation();

	const { specific_restriction: specificRestriction } = resumeRer?.specific_restriction_group || {};
	const { speed_restriction: speedRestriction, has_speed_restriction: hasSpeedRestriction } =
		resumeRer?.speed_restriction_group || {};
	const {
		hlp_resume: hlpResume,
		troubleshooting_guide: troubleshootingGuide,
		head_of_regulation_order: headOfRegulationOrder,
	} = resumeRer?.hlp_resume_group || {};
	const { mission_deleted: missionDeleted, comment } = resumeRer?.mission_deleted_group || {};
	const {
		head_of_regulation_informed: headOfRegulationInformed,
		passenger_information: passengerInformation,
	} = resumeRer?.head_of_regulation_informed_group || {};

	const hasSpecificRestriction = specificRestriction === 'yes';
	const speedRestrictionValue =
		hasSpeedRestriction === 'yes' ? speedRestriction : hasSpeedRestriction;
	const isHlpResume = hlpResume === 'yes';

	return (
		<div className={classNames({ 'rer-resume-summary__step': !isProcessRecap })}>
			<span
				className={classNames('rer-resume-summary__step__title', {
					'rer-resume-summary__step__title--bold': isProcessRecap,
				})}
			>
				{t('process:process-rer-resume.resume-rer.title')}
			</span>
			<div>
				{!hasSpecificRestriction && (
					<div className={'rer-resume-summary__substep'}>
						<span className="rer-resume-summary__substep__title">
							{t('process:process-rer-resume.resume-rer.substep.specific_restriction')} :
						</span>
						<span
							className={classNames('rer-resume-summary__substep__value', {
								'rer-resume-summary__substep__value--lighter': isProcessRecap,
							})}
						>
							{t(`process:process-rer-resume.resume-rer.${specificRestriction}`)}
						</span>
					</div>
				)}
				{hasSpecificRestriction && (
					<div>
						<div className={'rer-resume-summary__substep'}>
							<span className="rer-resume-summary__substep__title">
								{t('process:process-rer-resume.resume-rer.substep.speed_restriction')} :
							</span>
							<span
								className={classNames('rer-resume-summary__substep__value', {
									'rer-resume-summary__substep__value--lighter': isProcessRecap,
								})}
							>
								{speedRestrictionValue}
							</span>
						</div>
						<div className={'rer-resume-summary__substep'}>
							<span className="rer-resume-summary__substep__title">
								{t('process:process-rer-resume.resume-rer.substep.hlp_resume')} :
							</span>
							<span
								className={classNames('rer-resume-summary__substep__value', {
									'rer-resume-summary__substep__value--lighter': isProcessRecap,
								})}
							>
								{t(`process:process-rer-resume.resume-rer.${hlpResume}`)}
							</span>
							{isHlpResume && (
								<div className={'rer-resume-summary__substep--secondary'}>
									<span
										className={classNames('rer-resume-summary__substep__title--secondary', {
											'rer-resume-summary__substep__title--secondary--lighter': isProcessRecap,
										})}
									>
										{t('process:process-rer-resume.resume-rer.troubleshooting_guide')} :
									</span>
									<span
										className={classNames('rer-resume-summary__substep__value', {
											'rer-resume-summary__substep__value--lighter': isProcessRecap,
										})}
									>
										{t(`process:process-rer-resume.resume-rer.${troubleshootingGuide}`)}
									</span>
								</div>
							)}
							{isHlpResume && (
								<div className={'rer-resume-summary__substep--secondary'}>
									<span
										className={classNames('rer-resume-summary__substep__title--secondary', {
											'rer-resume-summary__substep__title--secondary--lighter': isProcessRecap,
										})}
									>
										{t('process:process-rer-resume.resume-rer.head_of_regulation_order')} :
									</span>
									<span
										className={classNames('rer-resume-summary__substep__value', {
											'rer-resume-summary__substep__value--lighter': isProcessRecap,
										})}
									>
										{t(`process:process-rer-resume.resume-rer.${headOfRegulationOrder}`)}
									</span>
								</div>
							)}
						</div>
						<div className={'rer-resume-summary__substep'}>
							<span className="rer-resume-summary__substep__title">
								{t('process:process-rer-resume.resume-rer.substep.mission_deleted')} :
							</span>
							<span
								className={classNames('rer-resume-summary__substep__value', {
									'rer-resume-summary__substep__value--lighter': isProcessRecap,
								})}
							>
								{t(`process:process-rer-resume.resume-rer.${missionDeleted}`)}
							</span>
							<div className={'rer-resume-summary__substep'}>
								<span
									className={classNames('rer-resume-summary__substep__title--secondary--bold', {
										'rer-resume-summary__substep__title--secondary--lighter': isProcessRecap,
									})}
								>
									{t('process:process-rer-resume.resume-rer.comment')}
								</span>
								<span
									className={classNames('rer-resume-summary__substep__value', {
										'rer-resume-summary__substep__value--lighter': isProcessRecap,
									})}
								>
									{comment}
								</span>
							</div>
						</div>
					</div>
				)}
				<div className={'rer-resume-summary__substep'}>
					<span className="rer-resume-summary__substep__title">
						{t('process:process-rer-resume.resume-rer.substep.head_of_regulation_informed')} :
					</span>
					<span
						className={classNames('rer-resume-summary__substep__value', {
							'rer-resume-summary__substep__value--lighter': isProcessRecap,
						})}
					>
						{t(`process:process-rer-resume.resume-rer.${headOfRegulationInformed}`)}
					</span>
					<div className={'rer-resume-summary__substep--secondary'}>
						<span
							className={classNames('rer-resume-summary__substep__title--secondary', {
								'rer-resume-summary__substep__title--secondary--lighter': isProcessRecap,
							})}
						>
							{t('process:process-rer-resume.resume-rer.passenger_information')}
						</span>
						<span
							className={classNames('rer-resume-summary__substep__value', {
								'rer-resume-summary__substep__value--lighter': isProcessRecap,
							})}
						>
							{t(`process:process-rer-resume.resume-rer.${passengerInformation}`)}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RestrictionSummary;

RestrictionSummary.propTypes = {
	resumeRer: PropTypes.object,
	isProcessRecap: PropTypes.bool,
};
