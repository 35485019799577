import axios from 'axios';

import { httpNetStatus } from '../axios';

import { ServerReachableEvent, ServerUnreachableEvent } from './network-state-utils';

const checkNetworkContext = { ready: true };
/**
 * Check if application is online, display an offline message in console otherwise
 * @return {Promise<number>}
 */
const checkNetwork = async function (timeout = 3000) {
	if (this.ready) {
		const source = axios.CancelToken.source();
		const timeoutId = setTimeout(() => {
			source.cancel('Application is offline');
			this.ready = true;
		}, timeout);

		try {
			this.ready = false;
			const ping = await httpNetStatus.get('/status', { timeout, withCredentials: false });
			if (ping) {
				clearTimeout(timeoutId);
				window.dispatchEvent(ServerReachableEvent);
				const { status } = ping;
				this.ready = true;
				return status;
			} else {
				window.dispatchEvent(ServerUnreachableEvent);
				this.ready = true;
				return false;
			}
		} catch (err) {
			if (axios.isCancel(err)) {
				window.dispatchEvent(ServerUnreachableEvent);
				console.info('Application is offline', err);
			} else {
				throw err;
			}
			this.ready = true;
		}
	}
};

export default checkNetwork.bind(checkNetworkContext);
