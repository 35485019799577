import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';

import './process-resume.scss';

import {
	buildSprProcessUri,
	checkFormError,
	LoadWrapper,
	useRailwayOnlineReturn,
	useSprContext,
} from '../../../../shared';
import { getUniqueKey } from '../../../../shared/utils/string-utils';
import { Button, IconWrapper, SprPage } from '../../../../theme';
import { IconCheck } from '../../../binder/assets';
import { getTrainByTechId } from '../../../train/train.services';
import { IconAdd, IconArrowLeft, IconSubtract } from '../../assets';
import { getProcessByTechId, updateProcess } from '../../process.services';
import { validateProcessResumeForm } from '../../utils/validate-resume-form';

const fields = [
	{
		name: 'position_supply',
		key: 'position-supply',
		type: 'radio',
		values: ['normal', 'direct'],
	},
	{
		name: 'engine_units',
		key: 'engine-units',
		type: 'number',
	},
	{
		name: 'bogie_car_units',
		key: 'bogie-car-units',
		type: 'number',
	},
	{
		name: 'resume',
		key: 'resume',
		type: 'list',
		values: ['voyageurs', 'hlp', 'secours'],
	},
];

const ProcessResume = () => {
	const history = useHistory();
	const { processId } = useParams();
	const [processInfo, setProcessInfo] = useState({});
	const [trainInfo, setTrainInfo] = useState({});
	const [isInfoLoading, setIsInfoLoading] = useState(true);
	const [form, setForm] = useState({
		position_supply: '',
		engine_units: null,
		bogie_car_units: null,
		resume: '',
	});

	const { associatedLine, side } = useSprContext();
	useRailwayOnlineReturn(false);
	const { t } = useTranslation();

	const formError = useMemo(
		() => validateProcessResumeForm(form, trainInfo?.car_number, trainInfo?.brake_system),
		[form, trainInfo?.car_number, trainInfo?.brake_system]
	);
	const isUnchanged = useMemo(
		() => JSON.stringify(form) === JSON.stringify(processInfo?.resume_conditions),
		[processInfo?.resume_conditions, form]
	);
	const isSubmitBlocked = useMemo(
		() => isUnchanged || checkFormError(formError),
		[isUnchanged, formError]
	);
	const returnLink = buildSprProcessUri({ processId }, 'railway');

	const loadTrain = useCallback(async (trainTechId) => {
		try {
			setIsInfoLoading(true);
			if (trainTechId) {
				const { data: trainFound } = await getTrainByTechId(trainTechId);
				setTrainInfo(trainFound);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsInfoLoading(false);
		}
	}, []);

	const loadProcess = useCallback(async (procesTechId) => {
		try {
			setIsInfoLoading(true);
			if (procesTechId) {
				const { data: processFound } = await getProcessByTechId(procesTechId, {
					extendTrain: true,
				});
				setProcessInfo(processFound);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsInfoLoading(false);
		}
	}, []);

	useEffect(() => loadProcess(processId), [processId, loadProcess]);
	useEffect(() => loadTrain(processInfo?.train_tech_id), [processInfo?.train_tech_id, loadTrain]);

	// set process default resume conditions
	useEffect(() => {
		if (!form?.position_supply && !!Object.values(processInfo?.resume_conditions || {})?.length) {
			setForm(processInfo?.resume_conditions);
		}
	}, [form, processInfo?.resume_conditions]);

	const SubHeader = (
		<>
			<div className="process-resume__header__top">
				<span className="process-resume__title">{t('process:process-resume.title')}</span>
			</div>
			<div className="process-resume__header__bottom">
				<Link to={returnLink} className="back-to-current-step">
					<IconWrapper className="back-to-current-step__icon" Component={IconArrowLeft} />
					<span className="back-to-current-step__text">
						{t('process:spr-run-process.come-back-list-sheet')}
					</span>
				</Link>
			</div>
		</>
	);

	const handleChange = useCallback((event) => {
		const { name, value } = event.target;
		setForm((oldFormData) => {
			const newFormData = { ...oldFormData };
			return { ...newFormData, [name]: value };
		});
	}, []);

	const renderRadio = (fieldName, options) => {
		return (
			<ul key={fieldName} className="process-resume__radio">
				{options.map((option) => (
					<li key={option} className="process-resume__radio-item">
						<input
							type="radio"
							id={option}
							name={fieldName}
							value={option}
							checked={form[fieldName] === option.toString()}
							className="process-resume__radio-item__input"
							onChange={handleChange}
						/>
						<label htmlFor={option} className="process-resume__radio-item__label">
							{t(`process:process-resume.${option}`)}
						</label>
					</li>
				))}
			</ul>
		);
	};

	const renderList = (fieldName, options) => {
		return (
			<ul className="process-resume__list">
				{options.map((option) => {
					const checked = form?.[fieldName] === option;
					return (
						<li
							className={classNames('process-resume__list-item', {
								'process-resume__list-item--checked': checked,
							})}
							key={`${getUniqueKey(option)}`}
						>
							<label htmlFor={option} className="process-resume__list-item__wrapper">
								<input
									className="process-resume__list-item__input"
									id={option}
									name={fieldName}
									type="radio"
									checked={checked}
									value={option}
									onChange={handleChange}
								/>
								<span className="process-resume__list-item__text">{option}</span>
								{checked && (
									<IconWrapper className="process-resume__list-item__check" Component={IconCheck} />
								)}
							</label>
						</li>
					);
				})}
			</ul>
		);
	};

	const renderNumber = (name, value) => {
		const { car_number, brake_system } = trainInfo || {};

		const incrementedFormError = validateProcessResumeForm(
			{ ...form, [name]: value + 1 },
			car_number,
			brake_system
		);
		const decrementedFormError = validateProcessResumeForm(
			{ ...form, [name]: value - 1 },
			car_number,
			brake_system
		);

		const disableIncrement = incrementedFormError[name].includes('out-of-range-max');
		const disableDecrement = decrementedFormError[name].includes('out-of-range-min');

		const handleClick = (valueChange) =>
			handleChange({ target: { name: name, value: (value || 0) + valueChange } });

		return (
			<div className="process-resume__operator__container">
				{Number.isInteger(value) ? (
					<>
						<IconWrapper
							className={classNames('process-resume__operator', {
								'process-resume__operator--disabled': disableDecrement,
							})}
							Component={IconSubtract}
							onClick={() => handleClick(-1)}
						/>
						<span className="process-resume__number">{value}</span>
						<IconWrapper
							className={classNames('process-resume__operator', {
								'process-resume__operator--disabled': disableIncrement,
							})}
							Component={IconAdd}
							onClick={() => handleClick(1)}
						/>
					</>
				) : (
					<button
						type="default"
						onClick={() => handleClick(0)}
						className="process-resume__no-number"
					>
						{t('process:process-resume.enter-value')}
					</button>
				)}
			</div>
		);
	};

	const renderField = ({ name, key, type, values }) => {
		if (key === 'bogie-car-units') {
			key = `${trainInfo?.brake_system}-units`;
		}

		const Label = (
			<span className="process-resume__label">{t(`process:process-resume.${key}`)}</span>
		);
		let Input = null;

		const renderers = {
			radio: renderRadio,
			number: renderNumber,
			list: renderList,
		};

		const render = renderers[type];
		Input = render(name, values || form[name]);
		return (
			<div key={key} className="process-resume__input-row">
				{Label}
				{Input}
			</div>
		);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!isSubmitBlocked) {
			await updateProcess(
				processId,
				{ resume_conditions: form },
				{ action: 'updateResumeConditions', line: associatedLine }
			);
			history.push(returnLink);
		}
	};

	return (
		<LoadWrapper className="spr-run-process__loader" loading={isInfoLoading}>
			<SprPage
				className={'spr-run-process--resume'}
				subheader={SubHeader}
				allowLineSwitch={side === 'train'}
			>
				<div className="process-resume__fields">{fields.map(renderField)}</div>
				<div className="process-resume__info-resume">{t('process:process-resume.info-resume')}</div>
				<Button
					variant="primary"
					type="submit"
					disabled={isSubmitBlocked}
					onClick={handleSubmit}
					label={t('process:process-resume.confirm')}
					className="process-resume__confirm"
				/>
			</SprPage>
		</LoadWrapper>
	);
};

export default ProcessResume;
