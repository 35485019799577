import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './action-task-view.scss';

import { EditorRendering, IconWrapper, ImageListView } from '../../../../../../theme';
import { Close, IconCheckboxUnchecked } from '../../../../../../theme/assets/img';
import getActionBullet from '../../../../../sheet/components/sheet-preview/components/template/template-view/text-template-view/utils/get-action-bullet';
import { useRunProcessContext } from '../../../../components/context-run-process/context-run-process';
import addBulletPointToText from '../../utils/add-bullet-point-to-text';
import AppendixTaskView from '../appendix-task-view/appendix-task-view';

/**
 * Action Task view on process run page
 * @param {object} props
 */
const ActionTaskView = ({ task = {} }) => {
	const { content, optionalInfos } = task;
	const { t } = useTranslation();
	const { handleNext, getTaskPosition = () => {}, isTrainBinder } = useRunProcessContext();
	const position = useMemo(
		() => (isTrainBinder ? getTaskPosition(task) : null),
		[task, getTaskPosition, isTrainBinder]
	);

	const handleNextButton = () => {
		const infoAction = { ...(position && { position }) };
		handleNext({ infoAction });
	};

	const handleNotApplicableButton = () => {
		const infoAction = { notApplicable: true, ...(position && { position }) };
		handleNext({ infoAction });
	};

	// multipleChoice subheader info
	const renderMultipleChoiceStep = (info) => {
		if (info?.choiceTitle) {
			const modifier = { 'generic-task-view__subheader--odd': optionalInfos?.index % 2 === 1 };
			const className = classnames('generic-task-view__subheader', modifier);
			return <div className={className}>{optionalInfos?.choiceTitle}</div>;
		}
	};

	const editorContent =
		isTrainBinder && Number.isInteger(position)
			? addBulletPointToText(content?.text, getActionBullet(task?.isSubNode, position, '-'))
			: content?.text;

	return (
		<div className="action-task-view">
			{optionalInfos?.parentType === 'multipleChoice' && renderMultipleChoiceStep(optionalInfos)}
			<div className="action-task-view__content">
				<button type="button" onClick={handleNextButton} className="action-task-view__checkbox">
					<IconWrapper className="action-task-view__icon" Component={IconCheckboxUnchecked} />
				</button>
				{content?.text && (
					<EditorRendering className="action-task-view__text" content={editorContent} />
				)}
				<button className="action-task-view__button" onClick={handleNotApplicableButton}>
					<IconWrapper className="action-task-view__button__icon" Component={Close} />
					{t('process:history-view.action.button-not-applicable')}
				</button>
			</div>
			{content?.images?.length > 0 && (
				<ImageListView className="text-template-view__images" images={content?.images} />
			)}
			{content?.appendixes?.length > 0 && (
				<AppendixTaskView
					className="text-template-view__appendixes"
					appendixes={content?.appendixes}
				/>
			)}
		</div>
	);
};

export default ActionTaskView;

ActionTaskView.propTypes = {
	task: PropTypes.shape({
		optionalInfos: PropTypes.shape({
			parentType: PropTypes.string,
			choiceTitles: PropTypes.string,
			index: PropTypes.number,
		}),
		content: PropTypes.shape({
			text: PropTypes.string,
			images: PropTypes.array,
		}).isRequired,
	}),
};
