import qs from 'qs';

/**
 * Build the uri to run process starting on the specified sheet / process
 * @param {Object} [info]
 * @param {string} [info.processId]
 * @param {string} [info.binderId]
 * @param {string} [info.binderSubtitle]
 * @param {string} [info.sheetNumber]
 * @param {string} [info.trainTechId] train binder extra id
 * @param {'railway'|'spr'} [prefix = "spr"]
 * @param {boolean} [read = false]
 * @returns {string} `${prefix}/process/run?${qs.stringify(info)}`
 */
const buildSprProcessUri = (info = {}, prefix = 'spr', read = false) => {
	return read
		? `/${prefix}/read/process/run?${qs.stringify(info)}`
		: `/${prefix}/process/run?${qs.stringify(info)}`;
};

/**
 * Build the uri to run process starting on the specified sheet / process
 * @param {string} processId
 * @param {string} binderTechnicalId
 * @param {string} sheetNumber
 * @returns {string} `/spr/process/${processId}/binders/${binderTechnicalId}/sheet/${sheetNumber}`
 */
const buildSprProcessSheetUri = (processId, binderTechnicalId, sheetNumber) => {
	return `/spr/process/${processId}/binders/${binderTechnicalId}/sheet/${sheetNumber}`;
};

export { buildSprProcessSheetUri, buildSprProcessUri };
