import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './results-sheet-form.scss';

import { checkFormError, useSprContext } from '../../../../../shared';
import { updateProcess } from '../../../process.services';
import { validateProcessResultsSheetForm } from '../../../utils';
import TrainTroubleFormFooter from '../shared/train-trouble-form-footer/train-trouble-form-footer';
import TrainTroubleFormNumberSelector from '../shared/train-trouble-form-number-selector/train-trouble-form-number-selector';

import ResultsSheetCard from './components/results-sheet-card/results-sheet-card';
import ResultsSheetRadioButtonWithInput from './components/results-sheet-radio-buton-with-input/results-sheet-radio-button-with-input';
import ResultsSheetRadioButton from './components/results-sheet-radio-button/results-sheet-radio-button';
import ResultsSheetRecap from './components/results-sheet-recap/results-sheet-recap';
import ResultsSheetTable from './components/results-sheet-table/results-sheet-table';
import ResultsSheetFormFields from './utils/results-sheet-form-fields';

const initLineA = {
	valve_maneuver_group: {
		validated: false,
		valve_maneuver: '',
		affected_equipment: [],
		isolated_compressor_units: 0,
		isolated_emd_units: 0,
	},
	break_test_group: {
		validated: false,
		break_test: '',
		break_emd_units: 0,
		ziscov_position: '',
		speed_decrease: {},
		zalidi_position: {
			choice: '',
			pressure: '',
		},
		resume: '',
	},
};

const initLineB = {
	valve_maneuver_group: {
		validated: false,
		valve_maneuver: '',
		affected_equipment: [],
		isolated_compressor_units: 0,
		isolated_emd_units: 0,
		kadi_position: {
			choice: '',
			pressure: '',
		},
	},
	break_test_group: {
		validated: false,
		break_test: '',
		isolated_break_bogies_units: 0,
		break_bogies_units: 0,
	},
};

const ResultsSheetForm = (props) => {
	const { t } = useTranslation();
	const { loadProcess, line, processInfo, resultsSheetStep, setResultsSheetStep } = props;
	const {
		results_sheet: formDataResultsSheet = {},
		damage_location: formDataDamageLocation = {},
		resume_rer: formDataResumeRer,
	} = processInfo?.resume_conditions || {};

	// Init the form object regarding the current line
	const initObject = line === 'A' ? initLineA : initLineB;
	const [resultsSheetForm, setResultsSheetForm] = useState({ ...initObject });

	const { valve_maneuver_group: valveManeuverGroup, break_test_group: breakTestGroup } =
		resultsSheetForm;
	const { affected_equipment: affectedEquipment, isolated_emd_units: isolatedEmdUnits } =
		valveManeuverGroup || {};

	// if the parent radio button of each part have the value "yes" display the rest of the fields if not, just enabled the validate button
	const parentSelected =
		resultsSheetStep === 0
			? valveManeuverGroup?.valve_maneuver === 'yes'
			: breakTestGroup?.break_test === 'yes';

	// find the fields to display regarding the current line and results sheet step
	const fields = ResultsSheetFormFields(line, resultsSheetStep);

	const { currentBinder } = useSprContext();
	const { material_label, materialLabel } = currentBinder || {};
	const material = materialLabel ?? material_label;
	const trainComposition = formDataDamageLocation?.train_composition;

	const formError = useMemo(
		() =>
			validateProcessResultsSheetForm(
				resultsSheetForm,
				line,
				resultsSheetStep,
				parentSelected,
				trainComposition,
				material
			),
		[resultsSheetForm, line, resultsSheetStep, parentSelected, trainComposition, material]
	);
	const isSubmitBlocked = useMemo(() => checkFormError(formError), [formError]);

	const affectedEmdNumber = affectedEquipment.filter((el) => el.includes('EMD')).length;
	const wrongEmdNumber = affectedEmdNumber !== isolatedEmdUnits;
	const emdWarningMessage = wrongEmdNumber
		? t('process:process-rer-resume.results-sheet.emd-warning-message')
		: '';

	const initResultsSheetForm = () => {
		const {
			valve_maneuver_group: formDataValveManeuverGroup,
			break_test_group: formDataBreakTestGroup,
		} = formDataResultsSheet || {};
		let formattedData;
		if (!formDataBreakTestGroup?.validated) {
			formattedData = {
				...initObject,
				valve_maneuver_group: formDataValveManeuverGroup,
			};
		} else {
			formattedData = {
				break_test_group: formDataBreakTestGroup,
				valve_maneuver_group: formDataValveManeuverGroup,
			};
		}
		if (Object.keys(formDataResultsSheet).length > 0) {
			setResultsSheetForm(formattedData);
		}
	};

	const findCurrentResultsSheetStep = () => {
		const {
			break_test_group: formDataBreakTestGroup,
			valve_maneuver_group: formDataValveManeuverGroup,
		} = formDataResultsSheet || {};
		// if the formData object contains results sheet and the second part has been validated step is 1
		if (!formDataBreakTestGroup?.validated && formDataValveManeuverGroup?.validated) {
			setResultsSheetStep(1);
		} else if (!formDataBreakTestGroup?.validated && !formDataValveManeuverGroup?.validated) {
			setResultsSheetStep(0);
		} else if (formDataBreakTestGroup?.validated && formDataValveManeuverGroup?.validated) {
			setResultsSheetStep(2);
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(findCurrentResultsSheetStep, [processInfo]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(initResultsSheetForm, []);

	const handleChange = (event) => {
		const { group, name, value } = event.target;
		const groupToUpdate =
			group === 'valve_maneuver_group' ? { ...valveManeuverGroup } : { ...breakTestGroup };
		const object = {
			...groupToUpdate,
			[name]: value,
		};
		setResultsSheetForm((oldFormData) => {
			const newFormData = { ...oldFormData };
			return { ...newFormData, [group]: object };
		});
	};

	const handleSubmit = async (event) => {
		let formattedData;
		if (breakTestGroup.break_test === '') {
			formattedData = {
				damage_location: formDataDamageLocation,
				resume_rer: formDataResumeRer,
				results_sheet: {
					...initObject,
					valve_maneuver_group: {
						...valveManeuverGroup,
						validated: true,
					},
				},
			};
		} else {
			formattedData = {
				damage_location: formDataDamageLocation,
				resume_rer: formDataResumeRer,
				results_sheet: {
					break_test_group: {
						...breakTestGroup,
						validated: true,
					},
					valve_maneuver_group: {
						...valveManeuverGroup,
						validated: true,
					},
				},
			};
		}
		event.preventDefault();
		if (!checkFormError(formError)) {
			await updateProcess(
				processInfo.tech_id,
				{ resume_conditions: formattedData },
				{ action: 'updateProcessRerResume', line }
			);
		}
		loadProcess(processInfo?.tech_id);
	};

	const handleReset = (event) => {
		event.preventDefault();
		const currentGroup = resultsSheetStep === 0 ? 'valve_maneuver_group' : 'break_test_group';
		setResultsSheetForm((oldFormData) => {
			const newFormData = { ...oldFormData };
			return { ...newFormData, [currentGroup]: initObject[currentGroup] };
		});
	};

	const renderField = (field) => {
		const { name, key, type, values, step } = field;
		const currentGroup = step === 0 ? 'valve_maneuver_group' : 'break_test_group';
		const firstPartFields = name === 'valve_maneuver' || name === 'break_test';
		const label = t(`process:process-rer-resume.results-sheet.${key}`);
		const renderers = {
			radio: (
				<ResultsSheetRadioButton
					handleChange={handleChange}
					resultsSheetFormData={resultsSheetForm}
					options={values}
					field={field}
					currentGroup={currentGroup}
				/>
			),
			radioInput: (
				<ResultsSheetRadioButtonWithInput
					resultsSheetStep={resultsSheetStep}
					line={line}
					handleChange={handleChange}
					resultsSheetFormData={resultsSheetForm}
					options={values}
					field={field}
					currentGroup={currentGroup}
				/>
			),
			number: (
				<TrainTroubleFormNumberSelector
					label={label}
					handleChange={handleChange}
					value={resultsSheetForm[currentGroup][name]}
					fieldName={field?.name}
					emdWarningMessage={emdWarningMessage}
					currentGroup={currentGroup}
					trainComposition={trainComposition}
					material={material}
				/>
			),
			table: (
				<ResultsSheetTable
					handleChange={handleChange}
					resultsSheetFormData={resultsSheetForm}
					formDataDamageLocation={formDataDamageLocation}
					line={line}
					field={field}
					currentGroup={currentGroup}
				/>
			),
		};

		const render = renderers[type];
		return (
			<div key={key} className="results-sheet__form__fields__input-row">
				{firstPartFields && render}
				{!firstPartFields && parentSelected && render}
			</div>
		);
	};

	return (
		<div className="results-sheet__form">
			{resultsSheetStep !== 2 && (
				<div className="results-sheet__form__fields">
					{resultsSheetStep === 1 && (
						<ResultsSheetCard
							associatedStep={0}
							name={'valve-maneuver'}
							setResultsSheetStep={setResultsSheetStep}
						/>
					)}
					{parentSelected ? fields.map(renderField) : fields.slice(0, 1).map(renderField)}
					<TrainTroubleFormFooter
						line={line}
						handleReset={handleReset}
						isSubmitBlocked={isSubmitBlocked}
						handleSubmit={handleSubmit}
						fieldName={resultsSheetStep === 2 ? 'results-sheet' : ''}
						fullPage={!parentSelected}
					/>
				</div>
			)}
			{resultsSheetStep === 2 && (
				<ResultsSheetRecap
					setResultsSheetStep={setResultsSheetStep}
					processInfo={processInfo}
					line={line}
					loadProcess={loadProcess}
				/>
			)}
		</div>
	);
};

export default ResultsSheetForm;

ResultsSheetForm.propTypes = {
	trainComposition: PropTypes.shape({}),
	loadProcess: PropTypes.func,
	line: PropTypes.string,
	processInfo: PropTypes.shape({
		resume_conditions: PropTypes.shape({}),
		tech_id: PropTypes.string,
	}),
	resultsSheetStep: PropTypes.number,
	setResultsSheetStep: PropTypes.func,
};
