import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getStoredOtpConfig, setStoredOtpConfig, verifyOfflineAuth } from '../../../config/otp';
import { railwayAccessRoles } from '../../../domains/user';
import { LoadingPage } from '../../../theme';
import { getTotpConfig } from '../../auth/auth.services';
import useAuth from '../../custom-hook/use-auth';
import useCSRF from '../../custom-hook/use-csrf';
import useNetworkStatus from '../../custom-hook/use-network-status';

const AppSetupContext = createContext({});

/**
 * Context Setup network status, authentication and csrf security
 * Note: Edit carefully
 * @param {object} children
 * @return {JSX.Element}
 * @constructor
 */
const AppSetupProvider = ({ children }) => {
	const [otpConfig, setOtpConfig] = useState();
	const history = useHistory();
	const {
		online,
		loading: networkStatusLoading,
		networkStatus,
		appNetworkStatus,
		updateNetworkStatus,
	} = useNetworkStatus();

	const {
		user,
		loading: authLoading,
		isOfflineUserVerified,
		setUser,
	} = useAuth(online, networkStatusLoading);
	const { loading: CSRFLoading } = useCSRF(online, networkStatusLoading || authLoading);
	const appSetupLoading = networkStatusLoading || authLoading || CSRFLoading;

	/**
	 * @returns {Promise<boolean>} was the default value successfully set
	 */
	const setDefaultStoredOtpConfig = useCallback(async () => {
		const storedTotpConfig = await getStoredOtpConfig();
		if (storedTotpConfig) {
			setOtpConfig(storedTotpConfig);
			return true;
		}
		return false;
	}, [setOtpConfig]);

	const fetchOtpConfig = useCallback(async () => {
		if (!networkStatusLoading && !authLoading) {
			try {
				const response = await getTotpConfig();
				const { data: fetchedOtpConfig } = response;
				// Erase stored otp config only when online
				if (online && fetchedOtpConfig) {
					await setStoredOtpConfig(fetchedOtpConfig);
					setOtpConfig(fetchedOtpConfig);
				} else {
					throw Error('no config received');
				}
			} catch {
				const isDefaultOtpConfigSet = await setDefaultStoredOtpConfig();
				if (isDefaultOtpConfigSet) {
					return;
				} else if (!online) {
					history.replace('/no-cache');
				} else {
					history.replace('/error-network');
				}
			}
		}
	}, [authLoading, history, networkStatusLoading, online, setDefaultStoredOtpConfig]);

	const setupOtp = useCallback(async () => {
		if (!authLoading) {
			if (railwayAccessRoles.includes(user.role)) {
				await fetchOtpConfig();
				const isOfflineAuthValid = await verifyOfflineAuth();
				if (!online && !isOfflineAuthValid) {
					history.replace('/totp/code');
				}
			}
		}
	}, [authLoading, history, online, fetchOtpConfig, user.role]);

	useEffect(() => {
		setDefaultStoredOtpConfig();
		setupOtp();
	}, [setupOtp, setDefaultStoredOtpConfig]);

	return (
		<AppSetupContext.Provider
			value={{
				otpConfig,
				online,
				networkStatus,
				appNetworkStatus,
				updateNetworkStatus,
				user,
				isOfflineUserVerified,
				loading: appSetupLoading,
				setUser,
			}}
		>
			{appSetupLoading ? <LoadingPage /> : children}
		</AppSetupContext.Provider>
	);
};

/**
 * App setup context hook
 * @return {{
 *  otpConfig: OtpConfigType,
 *  online: boolean,
 *  networkStatus: "online"|"offline"|null,
 *  appNetworkStatus: "online"|"offline"|null,
 *  updateNetworkStatus: (isOnline: bool) => void,
 *  user: { firstname: string, lastname: string, id: string }
 * }}
 */
const useAppSetupContext = () => useContext(AppSetupContext);

export { AppSetupContext, AppSetupProvider, useAppSetupContext };

AppSetupProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
