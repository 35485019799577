import React, { useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './dropdown.scss';

import { IconWrapper } from '../../../';
import { ReactComponent as ArrowDown } from '../../../assets/img/arrow-down.svg';

const Dropdown = ({
	children,
	className,
	value = '',
	name,
	onChange,
	label,
	placeholder = '',
	placeholderValue = '',
	disabledPlaceholder = true,
	disabled,
}) => {
	const selectRef = useRef();

	const initClassName = (baseClassName) =>
		classNames(baseClassName, className, { [`${baseClassName}--disabled`]: disabled });

	const handleLabelClick = () => {
		selectRef.current.focus();
	};

	return (
		<div className={initClassName('dropdown')}>
			<div className="dropdown__label" onClick={handleLabelClick}>
				{label}
			</div>
			<select
				ref={selectRef}
				className="dropdown__select"
				value={value || placeholderValue}
				name={name}
				onChange={onChange}
				disabled={disabled}
			>
				{placeholder && (
					<option
						value={placeholderValue}
						disabled={value !== placeholderValue && disabledPlaceholder}
					>
						{placeholder}
					</option>
				)}
				{children}
			</select>
			<IconWrapper className="dropdown__arrow" Component={ArrowDown} />
		</div>
	);
};

export default Dropdown;

Dropdown.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	placeholderValue: PropTypes.string,
	disabledPlaceholder: PropTypes.bool,
	disabled: PropTypes.bool,
	children: PropTypes.node,
};
