import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-line-switch.scss';

import { iconLines } from '../../../../../domains/user/utils/associated-line-icon-utils/associated-line-icon.utils';
import { IconWrapper, PopupConfirm } from '../../../../../theme';
import { ReactComponent as IconArrow } from '../../../../../theme/assets/img/icon-arrow-right.svg';
import { useSprContext } from '../../../../';

/**
 * Render the popup to confirm line switch
 * @param {object} props
 * @param {function} props.onConfirm
 * @param {number | string} props.targetLine
 * @param {object} props.popControl
 * @return {JSX.Element}
 * @constructor
 */
const PopupSwitchLine = ({ onConfirm, onCancel, onClose, targetLine, popupControl }) => {
	const { associatedLine } = useSprContext();
	const { t } = useTranslation();

	return (
		<PopupConfirm
			className="popup-switch-line"
			popupControl={popupControl}
			onConfirm={onConfirm}
			onCancel={onCancel}
			onClose={onClose}
			title={t('theme:spr-page.popup-line-switch.title')}
		>
			<h1 className="popup-switch-line__title">{t('theme:spr-page.popup-line-switch.text.0')}</h1>
			<p className="popup-switch-line__question">{t('theme:spr-page.popup-line-switch.text.1')}</p>
			<div className="popup-switch-line__line">
				<IconWrapper className="popup-switch-line__origin" Component={iconLines[associatedLine]} />
				<IconWrapper className="popup-switch-line__arrow" Component={IconArrow} />
				<IconWrapper className="popup-switch-line__target" Component={iconLines[targetLine]} />
			</div>
			<p className="popup-switch-line__note">
				{t('theme:spr-page.popup-line-switch.text.2', { line: targetLine })}
			</p>
		</PopupConfirm>
	);
};

PopupSwitchLine.propTypes = {
	popupControl: PropTypes.shape({
		hide: PropTypes.func,
		show: PropTypes.func,
		visible: PropTypes.bool,
	}),
	onConfirm: PropTypes.func.isRequired,
	targetLine: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default PopupSwitchLine;

PopupSwitchLine.propTypes = {
	popupControl: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired,
	targetLine: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onCancel: PropTypes.func,
	onClose: PropTypes.func,
};
