const initLineA = {
	specific_restriction_group: {
		validated: false,
		specific_restriction: '',
	},
	speed_restriction_group: {
		validated: false,
		has_speed_restriction: '',
		speed_restriction: '',
	},
	hlp_resume_group: {
		validated: false,
		hlp_resume: '',
		troubleshooting_guide: '',
		head_of_regulation_order: '',
	},
	mission_deleted_group: {
		validated: false,
		mission_deleted: '',
		comment: '',
	},
	head_of_regulation_informed_group: {
		validated: false,
		head_of_regulation_informed: '',
		passenger_information: '',
	},
};

const initShortLineA = {
	specific_restriction_group: {
		validated: false,
		specific_restriction: 'no',
	},
	speed_restriction_group: {
		validated: true,
		has_speed_restriction: '',
		speed_restriction: '',
	},
	hlp_resume_group: {
		validated: true,
		hlp_resume: '',
		troubleshooting_guide: '',
		head_of_regulation_order: '',
	},
	mission_deleted_group: {
		validated: true,
		mission_deleted: '',
		comment: '',
	},
	head_of_regulation_informed_group: {
		validated: false,
		head_of_regulation_informed: '',
		passenger_information: '',
	},
};

const initLineB = {
	rescued_bogie_group: {
		validated: false,
		rescued_bogies_units: 0,
	},
	ratp_network_group: {
		validated: false,
		ratp_network: '',
		ratp_resume_type: '',
		ratp_speed_restriction: '',
		head_of_regulation_informed: '',
	},
	rfn_network_group: {
		validated: false,
		rfn_network: '',
		rfn_resume_type: '',
		rfn_speed_restriction: '',
		driving_support_center_informed: '',
	},
};

const initShortLineB = {
	rescued_bogie_group: {
		validated: true,
		rescued_bogies_units: 0,
	},
	ratp_network_group: {
		validated: false,
		ratp_network: '',
		ratp_resume_type: '',
		ratp_speed_restriction: '',
		head_of_regulation_informed: '',
	},
	rfn_network_group: {
		validated: false,
		rfn_network: '',
		rfn_resume_type: '',
		rfn_speed_restriction: '',
		driving_support_center_informed: '',
	},
};

export { initLineA, initLineB, initShortLineA, initShortLineB };
