import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './cdr-run-process.scss';

import EndSheet from '../../assets/img-end-sheet.svg';
import UnfinishedProcess from '../../assets/img-unfinished-process.svg';
import RunProcessHistory from '../../pages/spr-run-process/components/run-process-history/run-process-history';
import TaskLoader from '../../pages/spr-run-process/components/task-loader/task-loader';
import CdrRunEnd from '../cdr-run-end/cdr-run-end';
import { useRunProcessContext } from '../context-run-process/context-run-process';

/**
 * Render CdrRunProcess Component
 * @param {object} props
 * @param {string} props.className
 * @returns {JSX.Element}
 */
const CdrRunProcess = (props) => {
	const { className } = props;
	const { getActiveTask, getLastTask, processHist, deadEnd, setViewTablet } =
		useRunProcessContext();
	const activeTask = getActiveTask();

	/**
	 * Check if the provided task is a link to an other sheet.
	 * @param taskToCheck The task to check
	 * @return {boolean} true for MultipleLink and SimpleLink of type `sheet` and `other-sheet`
	 */
	const isLinkToOtherSheet = (taskToCheck) => {
		const isSimpleLinkStep =
			taskToCheck?.type === 'simpleLink' && taskToCheck?.content?.type === 'step';
		const isLink = ['simpleLink', 'multipleLink'].includes(taskToCheck?.type);
		return isSimpleLinkStep ? false : isLink;
	};
	setViewTablet(true);

	const lastHistoryTask = getLastTask(processHist);
	const isEndSheet = isLinkToOtherSheet(lastHistoryTask) || lastHistoryTask?.type === 'termination';

	const renderEndItem = () => {
		if (isEndSheet) {
			return (
				<li className="cpr-run-process__end">
					<CdrRunEnd img={EndSheet} i18nLabel="process:cdr-run-process.sheet-end" />
				</li>
			);
		}
		if (deadEnd) {
			return (
				<li className="cpr-run-process__end">
					<CdrRunEnd img={UnfinishedProcess} i18nLabel="process:cdr-run-process.dead-end" />
				</li>
			);
		}
		return false;
	};

	return (
		<div className={classNames(className, 'cdr-run-process')}>
			<ul className="cpr-run-process__list">
				<RunProcessHistory />
				{!isEndSheet && activeTask && <TaskLoader task={activeTask} />}
				{renderEndItem()}
			</ul>
		</div>
	);
};

export default CdrRunProcess;

CdrRunProcess.propTypes = {
	className: PropTypes.string,
};
