import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Editor, ErrorMessage, PopupConfirm } from '../../../../../../../../theme';
import { updateComment } from '../../../../../../comment.services';

/**
 * @param {object} props
 * @param {object} props.content
 * @param {number} props.commentId
 * @param {function} props.retrieveCommentsList
 * @param {object} props.toolbarConfig
 * @param {number} props.binderIteration
 * @param {object} props.popupControl
 * @returns {JSX.Element}
 */
const PopupEditComment = ({
	commentId,
	content,
	retrieveCommentsList,
	toolbarConfig,
	binderIteration,
	popupControl,
}) => {
	const { t } = useTranslation();
	const [newComment, setNewComment] = useState('');
	const [initComment, setInitComment] = useState('');
	const [networkError, setNetworkError] = useState(false);

	const handleChange = (event) => {
		const { value } = event.target;
		setNewComment(value);
	};

	const isFormValid = () => newComment;

	const handleConfirm = () => {
		const commentNewData = {
			content: JSON.stringify(newComment),
			binder_iteration: binderIteration,
		};
		updateComment(commentId, commentNewData)
			.then(retrieveCommentsList)
			.catch((error) => {
				setNetworkError(true);
				throw error;
			});
	};

	const handleCancel = () => {
		setNewComment('');
	};

	const loadComment = () => {
		setInitComment(JSON.parse(content));
	};

	useEffect(loadComment, [content]);

	return (
		<PopupConfirm
			popupControl={popupControl}
			title={t('sheet:sheet-details.comments.pop-up.edit-comment.title')}
			className="popup-binder-edit-title__wrapper"
			onConfirm={handleConfirm}
			onClose={handleCancel}
			onCancel={handleCancel}
			ctaConfirmLabel={t('sheet:sheet-details.comments.pop-up.edit-comment.cta-confirm-label')}
			ctaConfirmDisabled={!isFormValid()}
		>
			<form className="comment-edit-form" onSubmit={(event) => event.preventDefault()}>
				<Editor
					className="comment-edit-form__editor"
					name="comment"
					value={initComment || null}
					toolbarConfig={toolbarConfig}
					onChange={handleChange}
				/>
				{networkError && <ErrorMessage>{t('error.default')}</ErrorMessage>}
			</form>
		</PopupConfirm>
	);
};

export default PopupEditComment;

PopupEditComment.propTypes = {
	commentId: PropTypes.number,
	retrieveCommentsList: PropTypes.func,
	content: PropTypes.object,
	toolbarConfig: PropTypes.object,
	popupControl: PropTypes.object,
	binderIteration: PropTypes.number,
};
