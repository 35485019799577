import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './appendix-task-view.scss';

import { useSprContext } from '../../../../../../shared';
import { Button, IconWrapper } from '../../../../../../theme';
import { IconArrowRight, IconOpenSheet } from '../../../../../../theme/assets/img';
import { useRunProcessContext } from '../../../../components/context-run-process/context-run-process';

const AppendixTaskView = ({ className, appendixes = [] }) => {
	const hasAppendix =
		appendixes.length > 0 && appendixes[0].sheetNumber !== '' && appendixes[0].sheetId !== '';

	const history = useHistory();
	const { side } = useSprContext();
	const urlStartPath = side === 'train' ? 'railway' : 'spr';
	const { processTechId, processInfo } = useRunProcessContext();
	const { binder_tech_id: binderId } = processInfo || {};

	const renderAppendixTask = (appendix) => {
		const { sheetNumber } = appendix;
		const url = `/${urlStartPath}/binders/${binderId}/process/${processTechId}/sheet?sheetNumber=${sheetNumber}`;
		return (
			<li key={appendix?.appendixId} className="appendix-task-view__item">
				<Button
					className={'appendix-task-view__item__button'}
					variant={'secondary'}
					type="button"
					onClick={() => history.push(url)}
					icon={IconOpenSheet}
					label={appendix.sheetNumber.toUpperCase()}
					iconPositionRight={true}
				/>
			</li>
		);
	};

	if (hasAppendix) {
		return (
			<ul className={classNames('appendix-task-view', className)}>
				<IconWrapper className="appendix-task-view__item__icon" Component={IconArrowRight} />
				<div className={'appendix-task-view__list'}>{appendixes.map(renderAppendixTask)}</div>
			</ul>
		);
	}

	return null;
};

export default AppendixTaskView;

AppendixTaskView.propTypes = {
	className: PropTypes.string,
	appendixes: PropTypes.arrayOf(
		PropTypes.shape({
			appendixId: PropTypes.string,
			sheetNumber: PropTypes.string,
			sheetId: PropTypes.string,
		})
	).isRequired,
};
