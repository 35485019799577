import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './binder-status.scss';

import { IconWrapper } from '../../../../theme';
import { ReactComponent as leftArrowIcon } from '../../../../theme/assets/img/icon-arrow-long-left.svg';
import { ReactComponent as rightArrowIcon } from '../../../../theme/assets/img/icon-arrow-long-right.svg';
import binderStatusList from '../../utils/binder-status-list.json';

/**
 * Render a binder status component with status name and color circle
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.status
 * @param {"left" | "right"} props.arrow
 * @param {bool} props.showArrow
 * @returns {JSX.Element}
 */
const BinderStatus = ({ className = '', status, arrow, showArrow }) => {
	const { t } = useTranslation();

	if (!binderStatusList.includes(status)) {
		return null;
	}

	return (
		<div className="action">
			{showArrow && (
				<IconWrapper
					className={classNames('action__icon', { 'action__icon--hidden': !arrow })}
					Component={arrow === 'left' ? leftArrowIcon : rightArrowIcon}
				/>
			)}
			<span
				className={classNames('binder-status', className, {
					[`binder-status--${status}`]: binderStatusList.includes(status),
				})}
			>
				{t(`binder:binder-status.${status}`)}
			</span>
		</div>
	);
};

BinderStatus.propTypes = {
	className: PropTypes.string,
	status: PropTypes.string.isRequired,
	arrow: PropTypes.string,
	showArrow: PropTypes.bool,
};

export default BinderStatus;
