import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-fail-waiting-binder.scss';

import { PopupConfirm } from '../../../../../theme';

/**
 * PopupFailWaitingBinder component
 * @param {object} props.popupControl
 * @returns {JSX.Element}
 */
const PopupFailWaitingBinder = (props) => {
	const { popupControl } = props;
	const { t } = useTranslation();

	return (
		<PopupConfirm
			popupControl={popupControl}
			title={t('binder:popup.update-status.title-publish')}
			error
			ctaConfirmLabel="binder:popup.update-status.text-waiting-fail-understand"
			className="popup-fail-waiting"
		>
			<p>{t('binder:popup.update-status.text-waiting-fail.0')}</p>
			<p className="popup-fail-waiting__text">
				{t('binder:popup.update-status.text-waiting-fail.1')}
				<span className="popup-fail-waiting__bold">
					{' '}
					{t('binder:popup.update-status.text-waiting-fail.2')}
				</span>
			</p>
			<p>
				{t('binder:popup.update-status.text-waiting-fail.3')}
				<span className="popup-fail-waiting__blue">
					{t('binder:popup.update-status.text-waiting-fail.4')}
				</span>
				{t('binder:popup.update-status.text-waiting-fail.5')}
			</p>
		</PopupConfirm>
	);
};

PopupFailWaitingBinder.propTypes = {
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired,
		visible: PropTypes.bool.isRequired,
	}).isRequired,
};

export default PopupFailWaitingBinder;
