import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './dropdown-menu.scss';

import { ReactComponent as IconProfile } from '../../assets/img/icon-profile.svg';
import { Button } from '../../index';

const DropdownMenu = ({ className, items = [], right, visible, setVisible }) => {
	const initClassName = (baseClassName) =>
		classNames(baseClassName, className, { [`${baseClassName}--open`]: visible });

	return (
		<>
			<div className={initClassName('dropdown-menu')}>
				<Button
					variant="minimal"
					className="dropdown-menu__button"
					icon={IconProfile}
					onClick={() => setVisible(!visible)}
				/>
				<ul className={classNames('dropdown-menu__list', { 'dropdown-menu__list--right': right })}>
					{items}
				</ul>
			</div>
			{visible && <div className="dropdown-menu--overlay" onClick={() => setVisible(false)} />}
		</>
	);
};

export default DropdownMenu;

DropdownMenu.propTypes = {
	className: PropTypes.string,
	items: PropTypes.arrayOf(PropTypes.object).isRequired,
	right: PropTypes.bool,
	visible: PropTypes.bool,
	setVisible: PropTypes.func,
};
