import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-confirm-revoke-binder.scss';

import { PopupConfirm } from '../../../../../theme';

/**
 * PopupConfirmRevokeBinder component
 * @param {object} props
 * @param {object} props.binder
 * @param {object} props.popupControl
 * @param {function} props.handleConfirm
 * @returns {JSX.Element}
 */
const PopupConfirmRevokeBinder = (props) => {
	const { handleConfirm, binder, popupControl } = props;
	const { t } = useTranslation();

	return (
		<PopupConfirm
			popupControl={popupControl}
			onConfirm={handleConfirm}
			title={t('binder:popup.update-status.title-revoke')}
			ctaConfirmLabel="binder:popup.update-status.cta-confirm-label"
		>
			<div className="popup-confirm-revoke">
				<p className="popup-confirm-revoke__text">
					{t('binder:popup.update-status.text-revoke.start')} :{' '}
				</p>
				<div className="popup-confirm-revoke__binder-info">
					<span className="binder-info__number">
						{binder.id} - {binder.title}
					</span>
				</div>
				<p className="popup-confirm-revoke__text">
					{t('binder:popup.update-status.text-revoke.end')}
				</p>
			</div>
		</PopupConfirm>
	);
};

PopupConfirmRevokeBinder.propTypes = {
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired,
		visible: PropTypes.bool.isRequired,
	}).isRequired,
	handleConfirm: PropTypes.func,
	binder: PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
	}).isRequired,
};

export default PopupConfirmRevokeBinder;
