import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './error-network-page.scss';

import { LogoDigiproc } from '../../../../theme';
import { ReactComponent as LogoNetwork } from '../../assets/img/logo-network.svg';

const ErrorNetworkPage = () => {
	const { t } = useTranslation();

	return (
		<main className="error-network-page">
			<header className="error-network-page__header">
				<Link to="/">
					<LogoDigiproc className="page__logo" />
				</Link>
			</header>
			<div className="error-network-page__content">
				<LogoNetwork className="error-network-page__content__logo" />
				<div className="error-network-page__content__title">{t('error:network-page.title')}</div>
				<div className="error-network-page__content__content">
					{t('error:network-page.content')}
				</div>
			</div>
		</main>
	);
};

export default ErrorNetworkPage;
