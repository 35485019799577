import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './tabs-nav-item.scss';

/**
 * @param {object} props
 * @param {number} props.id
 * @param {number} props.activeTab
 * @param {function} props.onClick
 * @param {string} props.className
 * @param {string} props.classNameActive
 * @param {boolean} props.disabled
 * @returns {JSX.Element}
 */
const TabsNavItem = ({
	id,
	children,
	activeTab,
	onClick,
	className,
	classNameActive,
	disabled,
}) => {
	const initClassName = () => {
		const modifier = {
			'tabs-nav-items--active': id === activeTab,
			'tabs-nav-items--disabled': disabled,
		};

		// Handle only one active class
		let customActiveClass = '';
		if (classNameActive) {
			customActiveClass = { [classNameActive]: id === activeTab };
		}

		return classNames('tabs-nav-items', className, customActiveClass, modifier);
	};

	return (
		<button type="button" className={initClassName()} onClick={onClick(id)} disabled={disabled}>
			<div className="tabs-nav-items__wrapper">{children}</div>
		</button>
	);
};

export default TabsNavItem;

TabsNavItem.propTypes = {
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	activeTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onClick: PropTypes.func,
	className: PropTypes.string,
	classNameActive: PropTypes.string,
	disabled: PropTypes.bool,
	children: PropTypes.node,
};
