import { useState } from 'react';

const useTabs = (defaultTabId) => {
	const [activeTab, setActiveTab] = useState(defaultTabId);

	const handleClick = (id) => () => setActiveTab(id);

	const tabsPanelConfig = { activeTab };
	const tabsItemConfig = {
		onClick: handleClick,
		activeTab,
	};

	return { activeId: activeTab, tabsItemConfig, tabsPanelConfig };
};

export default useTabs;
