import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './filter-label-line.scss';

import { SubwayLineNumber } from '../../../index';

/**
 * Render the FilterTagLine components
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.line
 * @returns {JSX.Element}
 */
const FilterLabelLine = ({ className, line }) => {
	const { t } = useTranslation();

	return (
		<span className={classnames(className, 'filter-label-line')}>
			<SubwayLineNumber className="filter-label-line__icon" lineNumber={line} />
			{t('theme:filter-tag.line', { line })}
		</span>
	);
};

export default FilterLabelLine;

FilterLabelLine.propTypes = {
	className: PropTypes.string,
	line: PropTypes.string.isRequired,
};
