import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './card-block-template.scss';

/**
 * Render the CardBlockTemplate
 * @param {object}props
 * @param {string} props.mainText
 * @param {string} props.classname
 * @param {string} props.linkTo
 * @param {string} props.title
 * @param {string} props.subtitle
 * @param {string} props.color
 * @param {boolean} props.isBinderGroup
 * @return {JSX.Element}
 * @constructor
 */
const CardBlockTemplate = (props) => {
	const {
		classname,
		mainText,
		linkTo,
		title,
		isBinderGroup,
		subTitle,
		color,
		onClick = () => {},
	} = props;

	return (
		<li className={classNames('card', classname)}>
			<Link
				className={classNames('card__link', { 'card__link--group': isBinderGroup })}
				to={linkTo}
				onClick={onClick}
			>
				<div className="card__shortname" style={{ background: color }}>
					<div className="card__shortname__label">{mainText}</div>
				</div>
				<span className="card__folder-ui" />
				<span className="card__folder-ui" />
				{!isBinderGroup && (
					<>
						<div className="card__title">{title}</div>
						<div className="card__subtitle">{subTitle}</div>
					</>
				)}
			</Link>
		</li>
	);
};

export default CardBlockTemplate;

CardBlockTemplate.propTypes = {
	onClick: PropTypes.func,
	classname: PropTypes.string,
	mainText: PropTypes.string,
	linkTo: PropTypes.string,
	title: PropTypes.string,
	isBinderGroup: PropTypes.bool,
	subTitle: PropTypes.string,
	color: PropTypes.string,
};
