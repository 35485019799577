import { httpOnline } from '../../../config';

/**
 * Get all train for the provided line
 * Only use in offline synchronize process
 * @param {string} line Current line
 * @return {{ data: any }[]}
 */
const getTrainForSync = async (line) => {
	const response = await httpOnline.get('/train', { params: { line } });

	return response.data?.map((train) => ({ data: train }));
};

/**
 * Get all published binder with a train type for the provided line
 * Only use in offline synchronize process
 * @param {string} line Current line
 * @return {{ data: any }[]}
 */
const getBinderForSync = async (line) => {
	const filter = {
		status: 'published',
		associatedLine: line,
		type: ['train-officer', 'train-driver', 'train-auto'],
	};

	const response = await httpOnline.get('/binder', { params: filter });
	return response?.data?.map((binder) => ({ data: binder }));
};

/**
 * Get all sheet linked to provided binder tech ids
 * Only use in offline synchronize process
 * @param binderIds
 * @return {{ data: any }[]}
 */
const getSheetForSync = async (binderIds) => {
	const responseList = await Promise.all(
		binderIds.map(async (id) => httpOnline.get(`/binder/${id}/sheet`))
	);
	return responseList
		?.map((response) => response.data)
		.flat(1)
		.map((sheet) => ({ data: sheet }));
};

/**
 * Get all images reference from sheet list
 * @param {string[]} sheetIds sheet technical ids
 * @returns {Promise<{ ref: string, sheetRef: string }[]>}
 */
const getImagesRefForSync = async (sheetIds) => {
	const responseList = await Promise.all(
		sheetIds.map(async (id) => httpOnline.get(`/sheet/${id}/image`))
	);
	return responseList
		?.map((response, pos) =>
			response.data.map((imageId) => ({ ref: imageId, sheetRef: sheetIds[pos] }))
		)
		.flat(1);
};

/**
 * Get image content by reference
 * @param {string} imageRef The image reference
 * @returns {Promise<{data: Blob}>}
 */
const getImageByRef = async (imageRef) =>
	httpOnline.get(`/image/${imageRef}`, { responseType: 'blob', params: { tag: 'light' } });

/**
 * Fetch all images from api with sheet references
 * @param {CacheUpdateAction[]} actionList
 * @returns {Promise<{ data: Blob, metadata: { ref: string } }[]>}
 */
const fetchImage = async (actionList) => {
	const images = [];

	for (let i = 0; i < actionList.length; i += 10) {
		const subActionList = actionList.slice(i, i + 10);

		images.push(
			await Promise.all(
				subActionList.map(async ({ ref }) => {
					const light = await getImageByRef(ref);
					return { metadata: { ref }, data: light.data };
				})
			)
		);
	}

	return images.flat(1);
};

/**
 * Publish a pending process stored in cache to api
 * @param {CacheEntity} processCacheEntry
 * @returns {Promise<void>}
 */
const publishProcess = async (processCacheEntry) => {
	const { ref, method, pendingState = {} } = processCacheEntry.metadata;
	const pendingFieldList = Object.keys(pendingState).filter((fieldName) => pendingState[fieldName]);
	const pendingData = Object.fromEntries(
		pendingFieldList.map((fieldName) => [fieldName, processCacheEntry.data[fieldName]])
	);

	if (method === 'post') {
		await httpOnline.post('/process', pendingData);
	} else if (method === 'put') {
		const query = { action: 'syncOfflineProcess' };
		await httpOnline.put(`/process/${ref}`, pendingData, { params: query });
	}
};

export {
	fetchImage,
	getBinderForSync,
	getImageByRef,
	getImagesRefForSync,
	getSheetForSync,
	getTrainForSync,
	publishProcess,
};
