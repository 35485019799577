const displayDeletePopUpKey = 'displayDeletePopUp';

const getDisplayDeletePopup = () => {
	return sessionStorage.getItem(displayDeletePopUpKey) === 'false';
};

const setDisplayDeletePopup = (isPopupVisible) =>
	sessionStorage.setItem(displayDeletePopUpKey, `${isPopupVisible}`);

const removeDisplayDeletePopupKey = () => sessionStorage.removeItem(`${displayDeletePopUpKey}`);

export { getDisplayDeletePopup, removeDisplayDeletePopupKey, setDisplayDeletePopup };
