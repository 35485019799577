import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-delete-binder.scss';

import { PopupConfirm, SquareColorIcon } from '../../../../../theme';
import { deleteBinderByBinderTechId } from '../../../binder.services';

/**
 * Render the popup to delete binder
 * @param {object} props
 * @param {object} props.popupControl
 * @param {object} props.binder
 * @param {function} props.onDelete
 * @returns {JSX.Element}
 */
const PopupDeleteBinder = (props) => {
	const { popupControl = {}, binder = {}, onDelete = () => {} } = props;

	const { t } = useTranslation();

	const handleDelete = () => {
		if (binder?.tech_id) {
			deleteBinderByBinderTechId(binder.tech_id).then(() => {
				popupControl.hide();
				onDelete();
			});
		}
	};

	return (
		<PopupConfirm
			popupControl={popupControl}
			onConfirm={handleDelete}
			title={t('binder:binder-list.popup-delete-binder.title')}
			ctaConfirmLabel={t('binder:binder-list.popup-delete-binder.cta-confirm-label')}
			warning
			className="popup-delete-binder__wrapper"
		>
			<div className="popup-delete-binder">
				<h1 className="popup-delete-binder__title">
					{t('binder:binder-list.popup-delete-binder.text.title')}
				</h1>
				<p className="popup-delete-binder__question">
					{t('binder:binder-list.popup-delete-binder.text.question')}
				</p>
				<div className="popup-delete-binder__binder">
					<SquareColorIcon color={binder?.color} />
					<span>
						{t('binder:binder-list.popup-delete-binder.text.binderInfos', {
							id: binder?.id,
							title: binder?.title,
						})}
					</span>
				</div>
				<p className="popup-delete-binder__note">
					{t('binder:binder-list.popup-delete-binder.text.note')}
				</p>
			</div>
		</PopupConfirm>
	);
};

PopupDeleteBinder.propTypes = {
	binder: PropTypes.shape({
		title: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		tech_id: PropTypes.string.isRequired,
	}).isRequired,
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired,
	}).isRequired,
	onDelete: PropTypes.func.isRequired,
};

export default PopupDeleteBinder;
