import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './template-wrapper.scss';

import { IconWrapper } from '../../../../../../../theme';
import { ReactComponent as IconArrowLeft } from '../../../../../../../theme/assets/img/icon-arrow-short-left.svg';
import { ReactComponent as IconArrowRight } from '../../../../../../../theme/assets/img/icon-arrow-short-right.svg';
import { useSheetContentContext } from '../../../../../context/sheet-content-context';
import { useSheetModeContext } from '../../../../../context/sheet-mode-context';
import AddNewNode from '../add-new-node/add-new-node';

import TemplateActions from './template-actions/template-actions';
import TemplateDrag from './template-drag/template-drag';

const TemplateWrapper = ({
	id,
	className,
	template,
	location,
	currentPosition,
	allowInsertAfter,
	children,
	hideBorder,
	reference,
	dragRef,
	isDragging,
	processCurrentTemplate,
}) => {
	const { activeLocation, setActiveLocation } = useSheetContentContext();
	const { readOnly, viewType } = useSheetModeContext();

	const updateActiveTemplate = () => {
		if (!readOnly && template?.id) {
			setActiveLocation(location);
		}
	};

	const wrapperClassName = classNames('template-wrapper', className, {
		'template-wrapper--active': activeLocation?.id === template?.id && !hideBorder && !readOnly,
		'template-wrapper--hidden': isDragging,
	});

	// spr : highlight the current template with an arrow at left
	const highlightCurrentArrowLeft = () => {
		// No display for question sub template in 'no' side (handle multipleChoice)
		if (processCurrentTemplate?.id === location.id && location.questionSide !== 'no') {
			return (
				<div className="template-wrapper__spr-current-template-left">
					<IconWrapper className="spr-current-template__icon" Component={IconArrowRight} />
				</div>
			);
		}
		return false;
	};
	// spr : highlight the current template with an arrow at right
	const highlightCurrentArrowRight = () => {
		// No display for question sub template in 'yes' side (handle multipleChoice)
		if (processCurrentTemplate?.id === location.id && location.questionSide !== 'yes') {
			return (
				<div className="template-wrapper__spr-current-template-right">
					<IconWrapper className="spr-current-template__icon" Component={IconArrowLeft} />
				</div>
			);
		}
		return false;
	};

	const handleClick = (event) => {
		event.stopPropagation();
		updateActiveTemplate();
	};

	return (
		<>
			<div id={id} ref={reference} className={wrapperClassName} onClick={handleClick}>
				{highlightCurrentArrowLeft()}
				<TemplateActions
					className="template-wrapper__actions"
					readOnly={readOnly}
					viewType={viewType}
					template={template}
					location={location}
				/>
				{dragRef && reference && (
					<TemplateDrag
						className="template-wrapper__drag"
						ref={dragRef}
						readOnly={readOnly}
						type={template?.type}
					/>
				)}
				{children}
				{highlightCurrentArrowRight()}
			</div>
			{!readOnly && allowInsertAfter && (
				<AddNewNode position={currentPosition + 1} location={location} />
			)}
		</>
	);
};

export default TemplateWrapper;

TemplateWrapper.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	template: PropTypes.object,
	location: PropTypes.object,
	currentPosition: PropTypes.number,
	allowInsertAfter: PropTypes.bool,
	children: PropTypes.node,
	hideBorder: PropTypes.bool,
	reference: PropTypes.any,
	dragRef: PropTypes.any,
	isDragging: PropTypes.bool,
	processCurrentTemplate: PropTypes.bool,
};
