import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './burger-menu-process-list.scss';

import BurgerMenuProcessItem from '../burger-menu-process-item.js/burger-menu-process-item';

/**
 * Render the burger menu current processes list
 * @param {Props} props
 * @param {ProcessList[]} props.processList
 * @returns {JSX.Element}
 */

const BurgerMenuProcessList = ({ processList }) => {
	const { t } = useTranslation();
	const ownerProcessList = processList.filter((processItem) => processItem.isProcessOwner);

	const generateProcessItem = (item, index) => (
		<BurgerMenuProcessItem processItem={item} key={index} />
	);

	return (
		<div className="burger-menu__current-process">
			<span className="burger-menu__current-process__label">
				{t('theme:spr-page.menu-burger.my-current-process')}
			</span>
			<ul className="burger-menu__current-process__list">
				{ownerProcessList.map(generateProcessItem)}
			</ul>
		</div>
	);
};

export default BurgerMenuProcessList;

BurgerMenuProcessList.propTypes = {
	processList: PropTypes.arrayOf(PropTypes.object).isRequired,
};
