import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-run-analysis.scss';

import { IconWrapper, PopupDefault } from '../../../../../../theme';
import { ReactComponent as IconClose } from '../../../../../../theme/assets/img/close.svg';
import { ReactComponent as IconArrowRight } from '../../../../../../theme/assets/img/icon-arrow-right.svg';
import { ReactComponent as IconCheck } from '../../../../../../theme/assets/img/icon-check.svg';

const PopupRunAnalysis = ({ analysisResult, popupControl }) => {
	const { t } = useTranslation();

	const renderCycleValue = (cycle) => {
		return (
			<div className="cycle">
				{cycle.map((value, index) => {
					return (
						<div className="cycle__block" key={`${value}-${+new Date()}`}>
							<span className="cycle__sheet-number"> {value.number} </span>
							{index + 1 !== cycle.length ? (
								<IconWrapper
									className="popup-run-analysis__body__icon--arrow"
									Component={IconArrowRight}
								/>
							) : (
								<IconWrapper
									className="popup-run-analysis__body__icon--close"
									Component={IconClose}
								/>
							)}
						</div>
					);
				})}
			</div>
		);
	};

	return (
		<PopupDefault
			popupControl={popupControl}
			title={t('sheet:popup.run-analysis.title')}
			className="popup-run-analysis"
		>
			<div className="popup-run-analysis__body">
				{!analysisResult?.hasAllPathClosed && (
					<div className="popup-run-analysis__body__not-closed">
						{t('sheet:popup.run-analysis.not-closed')}
						<IconWrapper className="popup-run-analysis__body__icon--close" Component={IconClose} />
					</div>
				)}
				{analysisResult?.hasLinkTargetingPreviousPhase && (
					<div className="popup-run-analysis__body__phase-cycle">
						{t('sheet:popup.run-analysis.phase-cycle')}
						<IconWrapper className="popup-run-analysis__body__icon--close" Component={IconClose} />
					</div>
				)}
				{analysisResult?.hasCycles?.length > 0 && (
					<div className="popup-run-analysis__body__has-cycle">
						<span>{t('sheet:popup.run-analysis.has-cycle')}</span>
						{analysisResult.hasCycles.map(renderCycleValue)}
					</div>
				)}
				{analysisResult?.hasAllPathClosed &&
					!analysisResult?.hasLinkTargetingPreviousPhase &&
					analysisResult?.hasCycles.length === 0 && (
						<div className="popup-run-analysis__body__ok">
							<span>{t('sheet:popup.run-analysis.ok')}</span>
							<IconWrapper
								className="popup-run-analysis__body__icon--arrow"
								Component={IconCheck}
							/>
						</div>
					)}
			</div>
		</PopupDefault>
	);
};

export default PopupRunAnalysis;

PopupRunAnalysis.propTypes = {
	analysisResult: PropTypes.object,
	popupControl: PropTypes.object,
};
