import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './otp-input.scss';

import OtpInputField from '../otp-input-field/otp-input-field';

/**
 * Render an OtpInput (6 digit code)
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const OtpInput = ({ className = '', onChange = () => {}, error = false }) => {
	const [internalValue, setInternalValue] = useState(Array(6).fill(''));
	const [focused, setFocused] = useState(0);

	const triggerParentChange = (valueList) => onChange(valueList.join(''));

	const updateValue = (value, position) => (list) => {
		const newInternalValue = [...list];
		newInternalValue[position] = value;
		triggerParentChange(newInternalValue);
		return newInternalValue;
	};

	const deleteFocusedValue = () => {
		setInternalValue(updateValue('', focused));
	};

	const focusPrevious = focused > 0 ? focused - 1 : focused;
	const focusNext = focused < internalValue.length - 1 ? focused + 1 : focused;

	const handleKeyDown = (event) => {
		const { key, shiftKey } = event;

		if (key === 'ArrowLeft') {
			event.preventDefault();
			setFocused(focusPrevious);
		} else if (key === 'ArrowRight') {
			event.preventDefault();
			setFocused(focusNext);
		} else if (key === 'Delete') {
			event.preventDefault();
			deleteFocusedValue();
		} else if (key === 'Backspace') {
			event.preventDefault();
			deleteFocusedValue();
			setFocused(focusPrevious);
		}

		// Tab custom handle to sync internal focused state
		else if (key === 'Tab') {
			// Handle focus leaving OtpInput
			if (focused > 0 && focused < internalValue.length - 1) {
				event.preventDefault();
			}
			setFocused(shiftKey ? focusPrevious : focusNext);
		}
	};

	const handleFieldChange = (event, position) => {
		setInternalValue(updateValue(event.target.value, position));
		setFocused(focusNext);
	};

	const handlePaste = (event) => {
		event.preventDefault();
		event.stopPropagation();
		const pasteData = event.clipboardData.getData('Text').trim();
		if (pasteData.length === 6 && /\d{6}/.test(pasteData)) {
			const newInternalValue = pasteData.split('');
			triggerParentChange(newInternalValue);
			setInternalValue(newInternalValue);
		}
	};

	const handleClick = (position) => setFocused(position);

	return (
		<div className={classnames(className, 'otp-input')} onPaste={handlePaste}>
			{internalValue.map((value, position) => (
				<OtpInputField
					key={position}
					className="otp-input__field"
					position={position}
					onKeyDown={(event) => handleKeyDown(event)}
					focus={focused === position}
					value={value}
					onChange={(event) => handleFieldChange(event, position)}
					onClick={() => handleClick(position)}
					error={error}
				/>
			))}
		</div>
	);
};

export default OtpInput;

OtpInput.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
	error: PropTypes.bool,
};
