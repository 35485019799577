import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './simple-link-step-view.scss';

import { IconWrapper } from '../../../../../../../../../../theme';
import { IconArrowRight } from '../../../../../../../../../../theme/assets/img';
import { useSheetContentContext } from '../../../../../../../../context/sheet-content-context';

const SimpleLinkStepView = ({ content, visible }) => {
	const { nodeList } = useSheetContentContext();
	const [stepNumber, setStepNumber] = useState('');

	const { t } = useTranslation();

	const getPhaseNumber = () => {
		if (content?.step) {
			const nodeStep = nodeList.find((value) => value.id === content.step);
			setStepNumber(nodeStep?.content?.step);
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(getPhaseNumber, [content?.step]);

	return (
		<div
			className={classNames('simple-link-phase-view__content', {
				'simple-link-phase-view__content--visible': visible,
			})}
		>
			<IconWrapper className="simple-link-template-view__icon" Component={IconArrowRight} />
			<span className="simple-link-phase-view__text">
				{`${t('sheet:sheet-details.template.simpleLink.step')} ${stepNumber}`}
			</span>
		</div>
	);
};

export default SimpleLinkStepView;

SimpleLinkStepView.propTypes = {
	content: PropTypes.object,
	visible: PropTypes.bool,
};
