import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { isBefore, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import './popup-publish-binder.scss';

import { checkFormError, formatJsonDate } from '../../../../../shared';
import {
	Button,
	DateChooser,
	ErrorMessage,
	PopupDefault,
	TextField,
	TimeChooser,
} from '../../../../../theme';
import { dateFormatDateMonth, dateFormatFullDateMonth } from '../../../../process/utils';
import { updateBinder } from '../../../binder.services';
import {
	getPublishBinderMessageKey,
	validatePopupPublishBinder,
} from '../../../utils/validate-popup-publish-binder';

/**
 * Render the PopupPublishBinder component
 * @param props.onSubmitSuccess Callback called after successful submit
 * @param props.binder
 * @param props.existingBinderList
 * @param props.popupControl
 * @returns {JSX.Element}
 */
const PopupPublishBinder = (props) => {
	const { onSubmitSuccess = () => {}, binder, existingBinderList, popupControl } = props;
	const { t } = useTranslation();

	const [formData, setFormData] = useState({});
	const [networkError, setNetworkError] = useState(false);
	const [rectificationNumber, setRectificationNumber] = useState(1);

	// only prevent the changed_description field duplication
	const formError = useMemo(
		() => validatePopupPublishBinder(formData, binder, existingBinderList),
		[formData, binder, existingBinderList]
	);
	const formErrorMessage = useMemo(() => getPublishBinderMessageKey(formError), [formError]);

	const reInitFormValidation = () => {
		setNetworkError(false);
	};

	const initRectificationNumber = () => {
		if (formData) {
			const nbRectification =
				existingBinderList.filter(
					({ parent, status }) =>
						parent === formData.parent && (status === 'published' || status === 'revoke')
				).length + 1;
			setRectificationNumber(nbRectification);
		}
	};

	const handleChangeDate = (date) => {
		// Keep existing time when changing date
		const currentDate = formData.published_at ? new Date(formData.published_at) : new Date();
		const newDate = new Date(date);
		newDate.setHours(currentDate.getHours());
		newDate.setMinutes(currentDate.getMinutes());
		setFormData({ ...formData, published_at: newDate });
	};

	const handleChangeTime = (time) => {
		// Keep existing date when changing time
		const currentDate = formData.published_at ? new Date(formData.published_at) : new Date();
		const newDate = new Date(currentDate);
		newDate.setHours(time.getHours());
		newDate.setMinutes(time.getMinutes());
		setFormData({ ...formData, published_at: newDate });
	};

	const handleChangeRadio = (event) => {
		// convert publish type to boolean
		const { value } = event.target;
		let result = value.toLowerCase() === 'true';
		// generate publish description
		if (result) {
			formData.changed_description = `Rectif. n°${rectificationNumber} (${formatJsonDate(new Date().toDateString(), dateFormatDateMonth)})`;
		} else {
			const dateStr = formatJsonDate(new Date(), dateFormatFullDateMonth);
			// Set uppercase on first letter
			formData.changed_description = dateStr.charAt(0).toUpperCase() + dateStr.slice(1);
		}
		// reset publish type condition
		if (result === formData.is_rectification) {
			result = null;
			formData.changed_description = '';
		}
		setFormData({ ...formData, is_rectification: result });
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleCancel = () => {
		reInitFormValidation();
		popupControl.hide();
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		const { ...binderData } = formData;
		reInitFormValidation();
		updateBinder(binder.tech_id, binderData)
			.then((response) => {
				setNetworkError(false);
				onSubmitSuccess(response?.data);
			})
			.then(popupControl.hide);
	};

	const setFormDataValues = () => {
		if (binder && formData) {
			const formDataUpdated = { ...formData };
			formDataUpdated.id = binder.id;
			formDataUpdated.title = binder.title;
			formDataUpdated.subtitle = binder.subtitle;
			formDataUpdated.abbreviation = binder.abbreviation;
			formDataUpdated.binderColor = binder.color;
			formDataUpdated.published_at = binder.published_at ? new Date(binder.published_at) : null;
			formDataUpdated.is_rectification = binder.is_rectification;
			formDataUpdated.changed_description = binder.changed_description;
			formDataUpdated.parent = binder.parent;
			// Reset date and time picker if passed
			if (isBefore(parseISO(binder.published_at), new Date())) {
				formDataUpdated.published_at = null;
			}
			setFormData(formDataUpdated);
		}
	};

	const initRadioClassName = () => {
		const isDisable = !existingBinderList?.find(({ status }) => status === 'published');
		return classnames('publish-type__label', { 'publish-type__label--disabled': isDisable });
	};

	const generateRadioButton = () => {
		return (
			<ul className="fields__item">
				<li key="edition" className="radio__item">
					<input
						type="radio"
						id="edition"
						name="is_rectification"
						className="publish-type__input"
						value={false}
						checked={typeof formData.is_rectification === 'boolean' && !formData.is_rectification}
						onChange={handleChangeRadio}
						onClick={handleChangeRadio}
					/>
					<label htmlFor="edition" className="publish-type__label">
						{t('binder:popup.publish-binder.edition-label')}
					</label>
				</li>
				<li key="rectification" className="radio__item">
					<input
						type="radio"
						id="rectification"
						name="is_rectification"
						className="publish-type__input"
						value={true}
						disabled={!existingBinderList?.find(({ status }) => status === 'published')}
						checked={formData.is_rectification}
						onChange={handleChangeRadio}
						onClick={handleChangeRadio}
					/>
					<label htmlFor="rectification" className={initRadioClassName()}>
						{t('binder:popup.publish-binder.rectification-label')}
					</label>
				</li>
			</ul>
		);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(setFormDataValues, [binder, popupControl.visible]);

	useEffect(initRectificationNumber, [formData, existingBinderList]);

	const isDateTimeDisabled =
		binder.published_at &&
		formData.published_at === binder.published_at &&
		isBefore(parseISO(binder.published_at), new Date()) &&
		binder.status !== 'waiting';

	return (
		<PopupDefault
			popupControl={popupControl}
			title={t('binder:popup.publish-binder.title')}
			onClose={reInitFormValidation}
		>
			<form className="popup-publish-binder" onSubmit={handleSubmit}>
				<div className="popup-publish-binder__form-wrapper">
					<h2 className="form-wrapper__subtitle">
						1/ {t('binder:popup.publish-binder.define-date-time')}
					</h2>
					<div className="form-wrapper__fields">
						<DateChooser
							disabled={isDateTimeDisabled}
							className="fields__item"
							label={t('binder:popup.publish-binder.date-label')}
							value={formData.published_at || null}
							minDate={new Date()}
							onChange={handleChangeDate}
						/>
						<TimeChooser
							disabled={isDateTimeDisabled}
							className="fields__item"
							label={t('binder:popup.publish-binder.time-label')}
							value={formData.published_at || null}
							onChange={handleChangeTime}
						/>
						<span className="fields__reset-button" onClick={() => handleChangeDate(null)}>
							{t('binder:popup.publish-binder.reset-date-time')}
						</span>
					</div>
					<h2 className="form-wrapper__subtitle">
						2/ {t('binder:popup.publish-binder.define-type')}
					</h2>
					<div className="form-wrapper__fields">
						{generateRadioButton()}
						<TextField
							className="fields__item"
							name="changed_description"
							label={t('binder:popup.publish-binder.description-label')}
							helperText={t(formErrorMessage.changed_description)}
							value={formData.changed_description}
							onChange={handleChange}
							invalid={formError.changed_description.length !== 0}
						/>
					</div>
				</div>
				<div className="popup-publish-binder__controller">
					<Button
						variant="minimal"
						className="popup-publish-binder__buttons"
						type="button"
						onClick={handleCancel}
						label={t('popup.confirm.cta-label-cancel')}
					/>
					<Button
						variant="primary"
						className="popup-publish-binder__buttons"
						type="submit"
						disabled={checkFormError(formError)}
						label={t('binder:popup.form.field.submit')}
					/>
				</div>
				{networkError && (
					<ErrorMessage className="popup-publish-binder__network-error">
						{t('error.default')}
					</ErrorMessage>
				)}
			</form>
		</PopupDefault>
	);
};

PopupPublishBinder.propTypes = {
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired,
		visible: PropTypes.bool,
	}).isRequired,
	binder: PropTypes.shape({
		tech_id: PropTypes.string,
		id: PropTypes.string,
		title: PropTypes.string,
		subtitle: PropTypes.string,
		abbreviation: PropTypes.string,
		color: PropTypes.string,
		published_at: PropTypes.string,
		is_rectification: PropTypes.bool,
		changed_description: PropTypes.string,
		parent: PropTypes.string,
		status: PropTypes.string,
	}),
	existingBinderList: PropTypes.arrayOf(
		PropTypes.shape({
			tech_id: PropTypes.string,
			changed_description: PropTypes.string,
			status: PropTypes.string,
		})
	),
	onSubmitSuccess: PropTypes.func,
};

export default PopupPublishBinder;
