import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
	getHours,
	getMinutes,
	parseISO,
	setHours,
	setMilliseconds,
	setMinutes,
	setSeconds,
} from 'date-fns';
import PropTypes from 'prop-types';

import './time-chooser.scss';

import { dateFormatTime } from '../../../../domains/process/utils';
import { formatJsonDate } from '../../../../shared';
import { ReactComponent as IconTime } from '../../../assets/img/icon-time.svg';
import { IconWrapper } from '../../../index';

const TimeChooser = ({ value, label, onChange, className, disabled }) => {
	const classes = classNames('time-chooser', className);
	const { t } = useTranslation();
	const [showTime, setShowTime] = useState(false);
	const [tempTime, setTempTime] = useState({ hours: '00', minutes: '00' });

	const handleTimeClick = () => {
		if (value) {
			const date = typeof value === 'string' ? parseISO(value) : value;
			setTempTime({
				hours: String(getHours(date)).padStart(2, '0'),
				minutes: String(getMinutes(date)).padStart(2, '0'),
			});
		}
		setShowTime(true);
	};

	const handleConfirm = () => {
		const hours = parseInt(tempTime.hours, 10);
		const minutes = parseInt(tempTime.minutes, 10);

		let updatedValue = value !== null ? value : new Date();

		updatedValue = setHours(updatedValue, hours);
		updatedValue = setMinutes(updatedValue, minutes);
		updatedValue = setSeconds(updatedValue, 0);
		updatedValue = setMilliseconds(updatedValue, 0);

		const currentDate = new Date();
		if (currentDate > updatedValue) {
			onChange(currentDate);
		} else {
			onChange(updatedValue);
		}

		setShowTime(false);
	};

	const generateOptions = (start, end) => {
		const options = [];
		for (let i = start; i <= end; i++) {
			options.push(String(i).padStart(2, '0'));
		}
		return options;
	};

	const hours = generateOptions(0, 23);
	const minutes = generateOptions(0, 59);

	useEffect(() => {
		const currentDate = new Date();
		if (value?.getDate() === currentDate.getDate() && value?.getHours() < currentDate.getHours()) {
			onChange(currentDate);
		}
	}, [value, onChange]);

	return (
		<div className={classes}>
			{showTime && !disabled && (
				<div className="time-chooser__dropdown">
					<div className="time-chooser__selectors">
						<select
							className="time-chooser__select"
							value={tempTime.hours}
							onChange={(e) => setTempTime({ ...tempTime, hours: e.target.value })}
							aria-label="hours"
							name="hours"
						>
							{hours.map((hour) => (
								<option key={hour} value={hour}>
									{hour}
								</option>
							))}
						</select>
						<span className="time-chooser__separator">:</span>
						<select
							className="time-chooser__select"
							value={tempTime.minutes}
							onChange={(e) => setTempTime({ ...tempTime, minutes: e.target.value })}
							aria-label="minutes"
							name="minutes"
						>
							{minutes.map((minute) => (
								<option key={minute} value={minute}>
									{minute}
								</option>
							))}
						</select>
					</div>
					<button type="button" className="time-chooser__confirm-btn" onClick={handleConfirm}>
						{t('popup.confirm.cta-label-confirm')}
					</button>
				</div>
			)}
			<button
				type="button"
				onClick={handleTimeClick}
				className="time-chooser__field"
				disabled={disabled}
			>
				{value ? formatJsonDate(value, dateFormatTime) : label}
				<IconWrapper className="time-chooser__field__icon" Component={IconTime} />
			</button>
		</div>
	);
};

TimeChooser.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
	label: PropTypes.string,
	onChange: PropTypes.func,
	className: PropTypes.string,
	disabled: PropTypes.bool,
};

export default TimeChooser;
