const excludeTypes = ['determination', 'm'];
const requiredForFilterTypes = ['child', 'parent', 'resume'];
const isFilterRequiredCheck = (sheetType) =>
	requiredForFilterTypes.includes(sheetType?.toLowerCase());

/**
 * filter out "determination", "m"  sheets if "child", "parent", "resume"  present
 * @param {object} sheets
 * @param {'type'|'sheetType'} sheetTypeProperty
 * @returns {object[]} sheets with optionaly the m and d sheets filtered
 */
export const filterSheetByType = (sheets = [], sheetTypeProperty = 'sheetType') => {
	if (Array.isArray(sheets)) {
		const isFilter = sheets.some((sheet) => isFilterRequiredCheck(sheet?.[sheetTypeProperty]));
		return isFilter ? sheets.filter((s) => !excludeTypes.includes(s?.[sheetTypeProperty])) : sheets;
	} else {
		return [];
	}
};

/**
 * filter out "determination", "m"  sheets if "child", "parent", "resume"  present
 * @param {{sheet: {type: string}}} previousSheets
 * @param {{type: string}} currentSheetData
 * @returns {object[]} sheets with optionaly the m and d sheets filtered
 */
export const filterPreviousSheetsByType = (previousSheets = [], currentSheetData = {}) => {
	if (Array.isArray(previousSheets)) {
		const isFilter =
			requiredForFilterTypes.includes(currentSheetData.type) ||
			previousSheets.some((previousSheet) => isFilterRequiredCheck(previousSheet?.sheet?.type));
		return isFilter
			? previousSheets.filter((previousSheet) => !excludeTypes.includes(previousSheet?.sheet?.type))
			: previousSheets;
	} else {
		return [];
	}
};
