import React, { useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './toggle-switch.scss';

/**
 * @typedef Props
 * @prop {string} className
 * @prop {string} sliderClassName
 * @prop {boolean} isChecked
 * @prop {function} handleOnChange
 * @prop {boolean} disabled
 */

/**
 * Render the SubwayLineNumber components
 * @param {Props} props
 * @returns {JSX.Element}
 */
const ToggleSwitch = ({ className, isChecked, handleOnChange, disabled }) => {
	const classNameModifier = useMemo(
		() => ({
			'toggle-switch--checked': isChecked,
			'toggle-switch--disable': disabled,
		}),
		[isChecked, disabled]
	);

	return (
		<label className={classnames('toggle-switch', classNameModifier, className)}>
			<input
				className="toggle-switch__input"
				type="checkbox"
				checked={isChecked}
				onChange={handleOnChange}
				disabled={disabled}
			/>
			<span className="toggle-switch__slider" />
		</label>
	);
};

export default ToggleSwitch;

ToggleSwitch.propTypes = {
	className: PropTypes.string,
	isChecked: PropTypes.bool,
	handleOnChange: PropTypes.func,
	disabled: PropTypes.bool,
};
