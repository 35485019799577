import React, { forwardRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './template-drag.scss';

import { IconWrapper } from '../../../../../../../../theme';
import { ReactComponent as IconDrag } from '../../../../../../../../theme/assets/img/icon-drag.svg';

const TemplateDrag = ({ className, readOnly, type }, ref) => {
	if (!readOnly) {
		return (
			<div ref={ref} className={classNames(`template-drag template-drag--${type}`, className)}>
				<IconWrapper className="template-drag__icon" Component={IconDrag} />
			</div>
		);
	}

	return null;
};

export default forwardRef(TemplateDrag);

TemplateDrag.propTypes = {
	className: PropTypes.string,
	readOnly: PropTypes.bool,
	type: PropTypes.string,
};
