const csrfRequestHeader = 'csrf-token';
const csrfStorageName = 'csrf-token';

const getCSRFToken = () => localStorage.getItem(csrfStorageName);

const saveCSRFToken = (token) => localStorage.setItem(csrfStorageName, token);

const removeCSRFToken = () => localStorage.removeItem(csrfStorageName);

export { csrfRequestHeader, getCSRFToken, removeCSRFToken, saveCSRFToken };
