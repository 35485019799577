import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './process-unfinished-page.scss';

import { IconWrapper, LogoDigiproc } from '../../../../theme';
import { ReactComponent as IconArrowLeft } from '../../../process/assets/icon-arrow-left.svg';
import { ReactComponent as LogoUnfinished } from '../../../process/assets/img-unfinished-process.svg';

const ProcessUnfinishedPage = () => {
	const { t } = useTranslation();

	return (
		<main className="process-unfinished-page">
			<header className="process-unfinished-page__header">
				<Link to="/">
					<LogoDigiproc className="page__logo" />
				</Link>
			</header>
			<div className="process-unfinished-page__content">
				<LogoUnfinished className="process-unfinished-page__content__logo" />
				<div className="process-unfinished-page__content__title">
					{t('error:process-unfinished-page.title')}
				</div>
				<Link to="/" className="process-unfinished-page__content__redirect">
					<IconWrapper className="back-to-current-step__icon" Component={IconArrowLeft} />
					<span className="back-to-current-step__text">
						{t('error:process-unfinished-page.button')}
					</span>
				</Link>
			</div>
		</main>
	);
};

export default ProcessUnfinishedPage;
