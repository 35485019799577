import { useEffect, useState } from 'react';
import axios from 'axios';

const useAppEnv = () => {
	const [env, setEnv] = useState({});

	useEffect(() => {
		axios
			.get('/app-env.json')
			.then((rsp) => {
				if (rsp?.data) {
					setEnv(rsp.data);
				}
			})
			.catch(() => {
				setEnv({ env: 'dev' });
			});
	}, [setEnv]);

	return env;
};

export { useAppEnv };
