import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import '../../resume-form-block.scss';

import TrainTroubleFormInputTextarea from '../../../../shared/train-trouble-fom-input-textarea/train-trouble-form-input-textarea';
import TrainTroubleFormFooter from '../../../../shared/train-trouble-form-footer/train-trouble-form-footer';
import TrainTroubleFormRadioButton from '../../../../shared/train-trouble-form-radio-button/train-trouble-form-radio-button';
import ValidatedSubstepCard from '../../../../shared/validated-substep-card/validated-substep-card';
import { missionDeletedFields } from '../../../utils/resume-form-fields';

const ResumeFormStepFourLineA = ({
	resumeStep,
	handleChange,
	handleReset,
	handleSubmit,
	resumeForm,
	backToSubstep,
}) => {
	const { t } = useTranslation();

	const fields = [...missionDeletedFields];
	const { mission_deleted: missionDeleted, comment } = resumeForm?.mission_deleted_group || {};

	const isSubstepValidated = resumeForm?.mission_deleted_group.validated;

	const className = classNames({ 'resume-form-block': resumeStep === 3 });

	const handleSubmitSubstep = () => {
		const updatedResumeForm = {
			...resumeForm,
			mission_deleted_group: { ...resumeForm.mission_deleted_group, validated: true },
		};
		handleSubmit(updatedResumeForm);
	};

	const checkFormValidity = () => {
		const formErrorList = fields.map((field) => {
			const { name: fieldName } = field;
			return field.validator(resumeForm?.mission_deleted_group[fieldName]);
		});
		return formErrorList?.every((el) => el === true);
	};

	const isFormValid = checkFormValidity();

	return (
		<div className={className}>
			{resumeStep > 3 && isSubstepValidated && (
				<ValidatedSubstepCard
					title={t('process:process-rer-resume.resume-rer.substep.mission_deleted')}
					backToSubstep={() => backToSubstep(3)}
				/>
			)}
			{resumeStep === 3 && (
				<div className={className}>
					<TrainTroubleFormRadioButton
						title={t('process:process-rer-resume.resume-rer.mission_deleted')}
						step={'resume-rer'}
						substep={fields[0].name}
						options={fields[0].values}
						isFirstQuestion={fields[0].is_first_question}
						checkedValue={missionDeleted}
						onChange={handleChange}
					/>
					<TrainTroubleFormInputTextarea
						step={'resume-rer'}
						substep={fields[1].name}
						checkedValue={comment}
						onChange={handleChange}
					/>
					,
				</div>
			)}
			{resumeStep === 3 && (
				<TrainTroubleFormFooter
					handleReset={handleReset}
					handleSubmit={handleSubmitSubstep}
					isSubmitBlocked={!isFormValid}
				/>
			)}
		</div>
	);
};

export default ResumeFormStepFourLineA;

ResumeFormStepFourLineA.propTypes = {
	resumeStep: PropTypes.number,
	handleChange: PropTypes.func,
	handleReset: PropTypes.func,
	handleSubmit: PropTypes.func,
	resumeForm: PropTypes.object,
	backToSubstep: PropTypes.func,
};
