import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './train-element-table-rows.scss';

import { usePopup } from '../../../../../../../shared';
import DamageLocationPopup from '../damage-location-popup/damage-location-popup';

const TrainElementTableRows = ({
	setDamageFormData,
	car,
	carIndex,
	damageFormData,
	fieldName,
	line,
	readOnly,
}) => {
	const { equipment_involved: equipmentInvolved } = damageFormData;
	const { key: carKey, content: carContent, header: carHeader } = car;
	const carObject = equipmentInvolved?.find((selectedCar) => selectedCar.name === carKey);
	const isTableVoid = car.key === '';
	const isLineB = line === 'B';

	const initRowClassname = (selectedCell, isSimpleCell = false) =>
		classNames('train-element-table__elements__car__row__item', {
			'train-element-table__elements__car__row__item--selected':
				selectedCell && !isSimpleCell && !isLineB && !readOnly,
			'train-element-table__elements__car__row__item--selected-read-only':
				selectedCell && !isSimpleCell && !isLineB && readOnly,
			'train-element-table__elements__car__row__item--selected-line-B':
				selectedCell && !isSimpleCell && isLineB && !readOnly,
			'train-element-table__elements__car__row__item--selected-line-B-read-only':
				selectedCell && !isSimpleCell && isLineB && readOnly,
			'train-element-table__elements__car__row__item--simple-cell': isSimpleCell && !readOnly,
			'train-element-table__elements__car__row__item--simple-cell-read-only':
				isSimpleCell && readOnly,
			'train-element-table__elements__car__row__item--double-cell-line-B':
				!isSimpleCell && isLineB && !readOnly,
			'train-element-table__elements__car__row__item--double-cell-line-B-read-only':
				!isSimpleCell && isLineB && readOnly,
			'train-element-table__elements__car__row__item--double-cell':
				!isSimpleCell && !isLineB && !readOnly,
			'train-element-table__elements__car__row__item--double-cell-read-only':
				!isSimpleCell && !isLineB && readOnly,
			'train-element-table__elements__car__row__item--simple-cell-selected':
				selectedCell && isSimpleCell && !readOnly,
			'train-element-table__elements__car__row__item--simple-cell-selected-read-only':
				selectedCell && isSimpleCell && readOnly,
		});

	const initItemWrapperClassname = (hasWhiteSpace) =>
		classNames('train-element-table__elements__car__row__item__wrapper', {
			'train-element-table__elements__car__row__item__wrapper--white-space':
				isLineB && hasWhiteSpace,
		});

	const initDividerClassname = () =>
		classNames('train-element-table__elements__element__car__divider', {
			'train-element-table__elements__element__car__divider--void-table-line-B':
				isTableVoid && isLineB,
			'train-element-table__elements__element__car__divider--void-table-line-A':
				isTableVoid && !isLineB,
			'train-element-table__elements__element__car__divider--line-A': !isTableVoid && !isLineB,
			'train-element-table__elements__element__car__divider--line-B': !isTableVoid && isLineB,
		});

	const popupDamageLocationControl = usePopup();

	const displayPopup = () => {
		return popupDamageLocationControl.show();
	};

	const handleChange = () => {
		setDamageFormData((oldFormData) => {
			const newFormData = { ...oldFormData };
			return { ...newFormData, equipment_involved: equipmentInvolved };
		});
	};

	// if a cell is selected verify if the cell has to be added or deleted
	const handleClick = async (value) => {
		// if the current car object exists in the form, verify if the cell has to be added or deleted
		if (carObject) {
			const { content } = carObject;
			const isContentValueExist = content.includes(value);

			// if the cell selected doesn't exist in the form add it to the car content
			if (!isContentValueExist) {
				content.push(value);
				handleChange();
			} else {
				// if the cell selected already exists in the form delete it from the car content
				const contentToDelete = content.findIndex((selectedCell) => selectedCell === value);
				await content.splice(contentToDelete, 1);
				handleChange();

				// Once the selected cell is deleted verify if it was the only item and then if the content is now void
				// If then content is void, delete the entire car object from the equipment_involved
				if (content.length === 0) {
					const carToDelete = equipmentInvolved.findIndex(
						(selectedCar) => selectedCar.name === carObject.name
					);
					equipmentInvolved.splice(carToDelete, 1);
					handleChange();
				}
			}
		} else {
			// if the current car object doesn't exist in the form adds the information to equipment_involved
			const formattedCarObject = {
				name: carKey,
				content: [value],
			};
			displayPopup();
			equipmentInvolved.push(formattedCarObject);
			handleChange();
		}
	};

	const generateContentRows = (items) => {
		const isSimpleCell = items?.length === 1;
		return (
			<ul>
				<li className="train-element-table__elements__car__row">
					{items.map((item, indexCell) => generateCells(item, indexCell, isSimpleCell))}
				</li>
			</ul>
		);
	};

	const generateRows = () => {
		return (
			<div className="train-element-table__elements__element__car">
				{carIndex !== 0 && <div className={initDividerClassname()} />}
				<div className="train-element-table__elements__element__car__container">
					<div
						className={classNames('train-element-table__elements__element__car__row--header', {
							'train-element-table__elements__element__car__row--header--read-only': readOnly,
						})}
					>
						<button
							className={classNames(
								'train-element-table__elements__element__car__row__header__title',
								{
									'train-element-table__elements__element__car__row__header__title--read-only':
										readOnly,
								}
							)}
							onClick={carObject && !readOnly ? displayPopup : () => {}}
						>
							{`${carHeader} ${carObject?.train_number !== undefined ? carObject?.train_number : ''}`}
						</button>
					</div>
					{carContent.map(generateContentRows)}
				</div>
			</div>
		);
	};

	const generateCells = (item, indexCell, isSimpleCell) => {
		const hasWhiteSpace = /\s/g.test(item);
		// give the car name, the cell index and the value as ID to make a difference between the same values for the entire element
		const cellId = `${carKey}${indexCell}${item}`;

		// give the index cell to the value to be able to identify which item in a row is selected
		const value = `${indexCell}${item}`;

		// find if the value exists in formData and if the train number has been completed, if not don't select the item
		const isTrainNumberExists = carObject?.train_number?.length > 0;
		const selected = carObject?.content?.includes(value) && isTrainNumberExists;

		return (
			<li className={initRowClassname(selected, isSimpleCell)}>
				<label className={initItemWrapperClassname(hasWhiteSpace)} htmlFor={cellId}>
					<input
						className="input-field__train-element-table"
						id={cellId}
						name={fieldName}
						type="table"
						checked={selected}
						defaultValue={value}
						onClick={!readOnly ? () => handleClick(value) : () => {}}
					/>
					<span className="train-element-table__elements__car__row__item__wrapper__text">
						{item}
					</span>
				</label>
			</li>
		);
	};

	return (
		<ul className="drive-log-row-container">
			{generateRows()}
			{popupDamageLocationControl.show && (
				<DamageLocationPopup
					popupControl={popupDamageLocationControl}
					equipmentInvolved={equipmentInvolved}
					carKey={carKey}
				/>
			)}
		</ul>
	);
};

export default TrainElementTableRows;

TrainElementTableRows.propTypes = {
	setDamageFormData: PropTypes.func.isRequired,
	car: PropTypes.object.isRequired,
	carIndex: PropTypes.number.isRequired,
	damageFormData: PropTypes.object.isRequired,
	fieldName: PropTypes.string.isRequired,
	line: PropTypes.string.isRequired,
	readOnly: PropTypes.bool,
};
