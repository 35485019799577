import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './form-process-train.scss';

import { unpadStartNumberInside } from '../../../../shared';
import { Dropdown, SubwayLineNumber, TextField } from '../../../../theme';
import { fetchStation, searchBinder } from '../../../binder/binder.services';
import { binderTrainTypeList } from '../../../binder/utils/binder-type';
import { fetchSheetByBinder } from '../../../sheet/sheet.services';

/**
 * Render the FormTrain component
 * @param {object} props.form
 * @param {object} props.validation
 * @param {string} props.errorMessage
 * @param {function} props.handleChange
 * @param {string} props.line
 * @param {bool} [props.update=false]
 * @returns {JSX.Element}
 */

const SHEET_CLASSNAME = 'form-process-train__wrapper--capitalize';

const FormProcessTrain = ({
	form,
	validation,
	errorMessage,
	handleChange,
	line,
	trainList,
	update = false,
}) => {
	const { t } = useTranslation();

	const positionTypeList = ['platform', 'half-platform', 'interstation', 'other'];
	const trackList = ['track1', 'track2'];
	const [stationList, setStationList] = useState([]);
	const [binderList, setBinderList] = useState([]);
	const [sheetList, setSheetList] = useState([]);

	const getStationList = () => {
		fetchStation(line).then((response) => {
			setStationList(response?.data);
		});
	};

	const getBinderTrainList = () => {
		const currentTrain = trainList.find((train) => train.tech_id === form?.train_tech_id);
		if (currentTrain) {
			const params = {
				id: [
					currentTrain.binder_auto,
					currentTrain.binder_driver,
					currentTrain.binder_officer,
				].filter((n) => n),
				status: ['published'],
				type: binderTrainTypeList,
				sortList: ['train-driver', 'train-officer', 'train-auto'],
				sortBy: 'type',
			};
			searchBinder(params).then(({ data = [] }) => {
				if (data.length) {
					setBinderList(data);
					if (data.length === 1) {
						handleChange({ target: { name: 'binder_tech_id', value: data[0].tech_id } });
					}
				}
			});
		}
	};
	// cdr point / spr -
	const getSheetList = () => {
		if (form?.binder_tech_id) {
			const params = {
				sortList: ['m', 'determination'],
				sortBy: ['sheet.type', 'sheet.number'],
				sortOrder: 'asc',
			};
			fetchSheetByBinder(form.binder_tech_id, params).then(({ data = [] }) => {
				setSheetList(data);
			});
		}
	};

	const generateRadioButtons = (fieldName, optionValue, useTranslate, disabled = false) => {
		const checked = form[fieldName] === optionValue.toString();
		return (
			<li key={optionValue} className="form-process-train__radio-item">
				<input
					type="radio"
					id={optionValue}
					name={fieldName}
					value={optionValue}
					checked={checked}
					onChange={handleChange}
					className="form-process-train__radio-item__input"
					disabled={disabled}
				/>
				<label htmlFor={optionValue} className="form-process-train__radio-item__label">
					{useTranslate
						? t(`process:popup.create-process-train.form.track.${optionValue}`)
						: optionValue}
				</label>
			</li>
		);
	};

	const renderStationOptions = (interstation) => {
		if (stationList) {
			if (interstation) {
				const orderedStations = form.track === 'track1' ? stationList : [...stationList].reverse();
				return orderedStations.map(
					(station, index) =>
						orderedStations[index + 1] && (
							<option
								value={`${station.label} - ${orderedStations[index + 1].label}`}
								key={station.label}
							>
								{`${station.label} - ${orderedStations[index + 1].label}`}
							</option>
						)
				);
			} else {
				return stationList.map((station) => (
					<option value={station.label} key={station.label}>
						{station.label}
					</option>
				));
			}
		}
	};

	const renderOptions = (value, label, className = '', isUppercase = false) => {
		const labelRendered = isUppercase ? label.toUpperCase() : label;
		return (
			<option
				value={value}
				key={value}
				className={classNames('form-process-train__wrapper', className)}
			>
				{labelRendered}
			</option>
		);
	};

	/**
	 * disableBinderField must be disabled when :
	 *
	 * binders : when updating or when only one binder or no train selected in form
	 */
	const disableBinderField = update || !form.train_tech_id || binderList?.length < 2;
	/**
	 * disableSheetField must be disabled when :
	 *
	 * sheets : on creating and when no or one sheet or no binder selected in form
	 */
	const disableSheetField = !update && (!form.binder_tech_id || sheetList?.length <= 1);

	useEffect(getStationList, [line]);
	useEffect(getBinderTrainList, [form?.train_tech_id, handleChange, trainList]);
	useEffect(getSheetList, [form?.binder_tech_id, form?.sheet_tech_id]);

	return (
		<div className="form-process-train">
			<div className="form-process-train__wrapper">
				<span className="form-process-train__label">
					{t('process:popup.create-process-train.localisation')}
				</span>
				<div className="form-process-train__line">
					<span className="line__text">
						<SubwayLineNumber lineNumber={line} />
						{`${t('process:popup.create-process-train.line')} ${line}`}
					</span>
				</div>
				<Dropdown
					name="position_type"
					placeholder={t('process:popup.create-process-train.form.position-type.label')}
					value={form.position_type}
					onChange={handleChange}
					disabledPlaceholder
					disabled={update}
				>
					{positionTypeList.map((pos) =>
						renderOptions(pos, t(`process:popup.create-process-train.form.position-type.${pos}`))
					)}
				</Dropdown>
				{(form.position_type === 'platform' ||
					form.position_type === 'half-platform' ||
					update) && (
					<>
						<Dropdown
							name="position_info"
							placeholder={t('process:popup.create-process-train.form.position-info-station')}
							value={form.position_info}
							onChange={handleChange}
							disabledPlaceholder
							disabled={update}
						>
							{renderStationOptions()}
						</Dropdown>
						<TextField
							name="track"
							label={t('process:popup.create-process-train.form.track.label-platform')}
							value={form.track}
							invalid={validation.track.length !== 0}
							helperText={t(errorMessage.track)}
							onChange={handleChange}
							disabled={update}
						/>
					</>
				)}
				{form.position_type === 'interstation' && (
					<>
						<span className="form-process-train__radio-label">
							{t('process:popup.create-process-train.form.track.label-interstation')}
						</span>
						<ul className="form-process-train__radio">
							{trackList.map((track) => generateRadioButtons('track', track, true, update))}
						</ul>
						<Dropdown
							name="position_info"
							placeholder={t('process:popup.create-process-train.form.position-info-interstation')}
							value={form.position_info}
							onChange={handleChange}
							disabledPlaceholder
							disabled={!form.track || update}
						>
							{renderStationOptions(true)}
						</Dropdown>
					</>
				)}
				{form.position_type === 'other' && (
					<TextField
						name="position_info"
						label={t('process:popup.create-process-train.form.other-info')}
						value={form.position_info}
						invalid={validation.position_info.length !== 0}
						helperText={t(errorMessage.position_info)}
						onChange={handleChange}
					/>
				)}
				<span className="form-process-train__label">
					{t('process:popup.create-process-train.description')}
				</span>
				<TextField
					name="description"
					label={t('process:popup.create-process-train.form.description')}
					value={form.description}
					invalid={validation.description.length !== 0}
					helperText={t(errorMessage.description)}
					onChange={handleChange}
					disabled={update}
				/>
			</div>
			<div className="form-process-train__wrapper">
				<span className="form-process-train__label">
					{t('process:popup.create-process-train.process')}
				</span>
				<Dropdown
					name="train_tech_id"
					placeholder={t('process:popup.create-process-train.form.train')}
					value={form.train_tech_id}
					onChange={handleChange}
					disabledPlaceholder
					disabled={update}
				>
					{trainList.map((train) => renderOptions(train.tech_id, train.id))}
				</Dropdown>
				<Dropdown
					name="binder_tech_id"
					placeholder={t('process:popup.create-process-train.form.binder.label')}
					value={form.binder_tech_id}
					onChange={handleChange}
					disabledPlaceholder={false}
					disabled={disableBinderField}
				>
					{binderList.map((binder) =>
						renderOptions(
							binder.tech_id,
							t(`process:popup.create-process-train.form.binder.${binder.type}`)
						)
					)}
				</Dropdown>
				<Dropdown
					name="sheet_tech_id"
					placeholder={t('process:popup.create-process-train.form.sheet')}
					value={form.sheet_tech_id}
					onChange={handleChange}
					disabledPlaceholder={false}
					disabled={disableSheetField}
				>
					{sheetList.map((sheet) =>
						renderOptions(
							sheet.sheet_id,
							unpadStartNumberInside(sheet.number),
							SHEET_CLASSNAME,
							true
						)
					)}
				</Dropdown>
			</div>
		</div>
	);
};

export default FormProcessTrain;

FormProcessTrain.propTypes = {
	form: PropTypes.shape({
		line: PropTypes.number,
		type: PropTypes.string,
		position_type: PropTypes.string,
		position_info: PropTypes.string,
		track: PropTypes.string,
		description: PropTypes.string,
		train_tech_id: PropTypes.string,
		binder_tech_id: PropTypes.string,
		sheet_tech_id: PropTypes.string,
	}).isRequired,
	validation: PropTypes.shape({
		position_type: PropTypes.array,
		position_info: PropTypes.array,
		track: PropTypes.array,
		description: PropTypes.array,
		train_tech_id: PropTypes.array,
		binder_tech_id: PropTypes.array,
	}).isRequired,
	errorMessage: PropTypes.shape({
		position_type: PropTypes.string,
		position_info: PropTypes.string,
		track: PropTypes.string,
		description: PropTypes.string,
		train_tech_id: PropTypes.string,
		binder_tech_id: PropTypes.string,
		sheet_tech_id: PropTypes.string,
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	line: PropTypes.number.isRequired,
	update: PropTypes.bool,
	trainList: PropTypes.array,
};
