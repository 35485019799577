import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './digiproc-loader.scss';

const DigiprocLoader = ({ className }) => {
	return (
		<div className={classNames('digiproc-loader', className)}>
			{/* Add Loader points */}
			{[...Array(12)].map((_elm, pos) => (
				<div key={pos} />
			))}
		</div>
	);
};

export default DigiprocLoader;

DigiprocLoader.propTypes = {
	className: PropTypes.string,
};
