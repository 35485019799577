import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-change-owner.scss';

import { unpadStartNumberInside } from '../../../../shared';
import { buildUserName } from '../../../../shared/utils/build-user-name';
import { PopupConfirm } from '../../../../theme';
import { useRunProcessContext } from '../context-run-process/context-run-process';

/**
 * Render the popup to change owner
 * @param {object} props
 * @param {object} props.popupControl
 * @param {function} props.onConfirm
 * @returns {JSX.Element}
 */
const PopupChangeOwner = ({ popupControl, onConfirm }) => {
	const { currentSheetData, getOwnerLabel } = useRunProcessContext() || {};
	const { t } = useTranslation();

	const [userLabel, setUserLabel] = useState('');

	const findUserLabel = () => {
		const user = getOwnerLabel();
		if (user) {
			setUserLabel(buildUserName(user.firstname, user.lastname, user.id));
		} else {
			setUserLabel(t('user:user-deleted'));
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(findUserLabel, [currentSheetData]);

	return (
		<PopupConfirm
			popupControl={popupControl}
			title={t('process:spr-run-process.change-owner-popup.title')}
			ctaConfirmLabel={t('process:spr-run-process.change-owner-popup.submit')}
			onConfirm={onConfirm}
			error
		>
			<div className="popup-change-owner">
				<Trans
					parent="span"
					className="popup-change-owner__text"
					i18nKey="process:spr-run-process.change-owner-popup.content"
					values={{
						name: userLabel,
						sheetNumber: unpadStartNumberInside(currentSheetData?.number),
					}}
				>
					<strong className="popup-change-owner__text--strong" />
					<strong className="popup-change-owner__text--strong popup-change-owner__text--sheet-number" />
				</Trans>
			</div>
		</PopupConfirm>
	);
};

export default PopupChangeOwner;

PopupChangeOwner.propTypes = {
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired,
		show: PropTypes.func.isRequired,
		visible: PropTypes.bool.isRequired,
	}).isRequired,
	onConfirm: PropTypes.func.isRequired,
};
