/**
 * @typedef Task
 * @prop {string} id
 * @prop {object} content
 * @prop {string} type
 * @prop {("yes" | "no")|object} [choice]
 * @prop {boolean} [active]
 */

/**
 * @typedef HistoryEntry
 * @prop {object} id
 * @prop {object} content
 * @prop {string} type
 * @prop {object} binder
 * @prop {string} binder.id
 * @prop {string} binder.techId
 * @prop {string} binder.color
 * @prop {string} binder.abbreviation
 * @prop {string} binder.subtitle
 * @prop {number} binder.associatedLine
 * @prop {object} sheet
 * @prop {string} sheet.number
 * @prop {string} sheet.status
 * @prop {string} sheet.techId
 * @prop {string} sheet.title
 * @prop {string} sheet.type
 * @prop {Date} finishedAt
 * @prop {boolean} returnable
 * @prop {boolean} canceled
 * @prop {boolean} isFirstCanceled
 * @prop {("yes" | "no")|object} [choice]
 * @prop {Task} [parent]
 */

/**
 * Return true if the provided task is active
 * @param {Task} task
 * @returns {boolean}
 */
const isActiveTask = (task) => task?.active;

/**
 * Merge all subtask of a question template's branch in a single Task list
 * @param {{"end-item": Task, items: Task[]}} templateQuestionContentAnswer
 * @returns {Task[]}
 */
const buildSubTaskList = (templateQuestionContentAnswer) => {
	if (!templateQuestionContentAnswer) {
		return [];
	}
	return [
		...(templateQuestionContentAnswer.items || []),
		templateQuestionContentAnswer['end-item'],
	];
};

/**
 * @param {Task[]} subTaskList
 * @returns {{"end-item": Task, items: Task[]}}
 */
const extractQuestionContentFromSubTaskList = (subTaskList) => {
	const items = subTaskList.slice(0, subTaskList.length - 1);
	const endItem = subTaskList[subTaskList.length - 1];
	return { items, 'end-item': endItem };
};

/**
 * @param {Task[]} taskList
 * @returns {Task[]}
 */
const getAllTask = (taskList = []) => {
	return taskList.reduce((acc, cur) => {
		if (cur.type === 'question' && cur.content) {
			const yesSubTasks = cur.content.yes ? buildSubTaskList(cur.content.yes) : [];
			const noSubTasks = cur.content.no ? buildSubTaskList(cur.content.no) : [];
			return [...acc, cur, ...yesSubTasks, ...noSubTasks];
		} else if (cur.type === 'multipleChoice' && cur.content) {
			return [...acc, cur, ...(cur.content.subNodes || [])];
		}
		return [...acc, cur];
	}, []);
};

/**
 * @param {Task[]} taskList
 * @returns {Task[]}
 */
const setAllTaskAsInactive = (taskList = []) => {
	return JSON.parse(JSON.stringify(taskList, (key, val) => (key === 'active' ? undefined : val)));
};

/**
 * @param {Task[]} taskList
 * @param {string} taskId
 * @returns {Task | undefined}
 */
const getTaskById = (taskList, taskId) => {
	if (!taskList || !taskId) {
		return undefined;
	}
	const expandedTaskList = getAllTask(taskList);
	return expandedTaskList.find((task) => task?.id === taskId);
};

/**
 * @param {Task[]} taskList
 * @returns {Task | undefined}
 */
const getActiveRootTask = (taskList = []) => taskList.find(isActiveTask);

/**
 * @callback logTaskCallback
 * @param {Task} task
 * @param {Task} parentTask
 */

/**
 * @callback goToNextRootTaskResult
 * @param {Task[]} taskList
 * @param {Task | undefined}parentTask
 * @param {*} shouldLog
 * @return {Task[]}
 */

/**
 *
 * @param {logTaskCallback} logTask
 * @returns {goToNextRootTaskResult}
 */
const goToNextRootTask =
	(logTask) =>
	(taskList = [], parentTask, shouldLog) => {
		const taskListCopy = JSON.parse(JSON.stringify(taskList));
		const activeTaskPos = taskListCopy?.findIndex(isActiveTask);
		let newActiveTaskPos = activeTaskPos + 1;
		let optionalInfos;

		if (activeTaskPos !== -1) {
			const activeTask = taskListCopy[activeTaskPos];
			activeTask.active = false;
			// Never log question node when leaving it <- already logged when choice done
			if (activeTask?.type !== 'question' && shouldLog) {
				logTask(activeTask, parentTask);
			}
		}

		// set multipleChoice info to subNode
		if (parentTask?.type === 'multipleChoice') {
			const choicesSelected = parentTask?.content?.choices?.filter((choice) => choice?.chosen);
			const choiceIndex = choicesSelected?.findIndex((choice) => choice?.chosen && !choice?.isDone);
			optionalInfos = {
				index: choiceIndex,
				choiceTitle: choicesSelected[choiceIndex]?.title,
				parentType: parentTask?.type,
				parentId: parentTask?.id,
			};

			// set index to 0 if activeTaskPos reach the end of subNodes
			if (activeTaskPos + 1 === taskListCopy?.length) {
				newActiveTaskPos = 0;
			}
		}

		if (taskListCopy[newActiveTaskPos]) {
			taskListCopy[newActiveTaskPos] = {
				...taskListCopy[newActiveTaskPos],
				active: true,
				optionalInfos,
			};
		}
		return taskListCopy;
	};

/**
 * @callback moveToNextSubTaskResult
 * @param {Task} task
 * @param {*} [shouldLog = true]
 */

/**
 * @param {logTaskCallback} logTask
 * @returns {moveToNextSubTaskResult}
 */
const moveToNextSubTask =
	(logTask) =>
	(task, shouldLog = true) => {
		const { type, choice } = task;
		if (type === 'question' && task?.content && task?.content[choice]) {
			let subTaskList = buildSubTaskList(task?.content[choice]);
			subTaskList = goToNextRootTask(logTask)(subTaskList, task, shouldLog);
			task.content[choice] = {
				...task.content[choice],
				...extractQuestionContentFromSubTaskList(subTaskList),
			};
		}

		if (type === 'multipleChoice') {
			let subTaskList = task?.content.subNodes;
			subTaskList = goToNextRootTask(logTask)(subTaskList, task, shouldLog);
			task.content.subNodes = [...subTaskList];
		}
	};

/**
 * @callback goToNextTaskResult
 * @param {Task[]} sheetContent
 * @param {*} [shouldLog=true]
 * @returns {Task[]}
 */

/**
 * @param {logTaskCallback} logTask
 * @returns {goToNextTaskResult}
 */
const goToNextTask =
	(logTask) =>
	(sheetContent, shouldLog = true) => {
		let newSheetContent = JSON.parse(JSON.stringify(sheetContent));
		const activeTaskPos = newSheetContent.findIndex(isActiveTask);
		if (activeTaskPos !== -1) {
			const activeTask = newSheetContent[activeTaskPos];
			const { type } = activeTask;
			// Handle moving into question sub task list
			if (type === 'question') {
				moveToNextSubTask(logTask)(activeTask, shouldLog);
			} else if (
				type === 'multipleChoice' &&
				activeTask.content.choices?.some((value) => value.chosen && !value.isDone)
			) {
				handleMultipleChoiceNextTask(logTask)(activeTask, shouldLog);
			} else {
				newSheetContent = goToNextRootTask(logTask)(newSheetContent, undefined, shouldLog);
			}
		} else {
			// Set first task as active
			newSheetContent[0].active = true;
		}
		return newSheetContent;
	};

/**
 * If there is stll isDone flag set to false, proceed
 * In case of subnode task specific value, use of "addElementToChild" attribut on multipleChoice task
 * 	to give the data to the child
 * @param {logTaskCallback} logTask
 * @returns {Function}
 */
const handleMultipleChoiceNextTask = (logTask) => (activeTask, shouldLog) => {
	const { choices, subNodes = [] } = activeTask.content;

	// Find subNodes active
	const indexActive = subNodes.findIndex((value) => value.active);
	const subNodeActive = subNodes[indexActive];
	// check if subNodes Active is the last one in order to set isDone to true thanks to the title set in optionalInfos
	if (indexActive + 1 === subNodes.length && subNodeActive) {
		const choiceActive = subNodeActive?.optionalInfos;
		const choiceDone = choices.find((choice) => choice.title === choiceActive.choiceTitle);
		choiceDone.isDone = true;
	}

	// go to next sub task if there is still a flash isDone to false, else log the last subTask to history
	if (choices.some((value) => value.chosen && !value.isDone)) {
		moveToNextSubTask(logTask)(activeTask, shouldLog);
	} else {
		logTask(subNodes[indexActive], activeTask);
	}
};

/**
 * @param {Task[]} sheetContent
 * @param {Task} task
 * @returns {Task[]}
 */
const goToSelectedTask = (sheetContent, task) => {
	const { id: taskId, parent: taskParent } = task;
	// Set current active task as inactive
	let newSheetContent = setAllTaskAsInactive(sheetContent);

	// Set target task to active
	const newActiveTask = getTaskById(newSheetContent, taskId);
	if (newActiveTask) {
		// Handle recover for subNodes
		if (taskParent) {
			handleSubNodeSelectedTask(taskParent, newActiveTask, newSheetContent, task);
		}

		newActiveTask.active = true;

		// Handle recover for choice (question / multipleLink)
		if (task.type === 'question' || task.type === 'multipleLink') {
			newActiveTask.choice = task.choice;
		}

		// Handle recover for choice (multipleChoice)
		if (task.type === 'multipleChoice') {
			newActiveTask.content.choices = task.content.choices;
		}
	}

	return [...newSheetContent];
};

/**
 * Handle Recover informations for selected SubTask
 * @param {Task} taskParent
 * @param {Task} newActiveTask
 * @param {Task[]} newSheetContent
 * @param {Task} task
 */
const handleSubNodeSelectedTask = (taskParent, newActiveTask, newSheetContent, task) => {
	if (taskParent.type === 'question') {
		// Handle recover for node in question yes/no
		const sheetParentTask = getTaskById(newSheetContent, taskParent.id);
		sheetParentTask.choice = taskParent.choice;
		sheetParentTask.active = true;
	} else if (taskParent.type === 'multipleChoice') {
		// recover choices and optionalInfos for subTask in MultipleChoice
		const sheetParentTask = getTaskById(newSheetContent, taskParent.id);
		sheetParentTask.content.choices = taskParent.content.choices;
		sheetParentTask.active = true;
		newActiveTask.optionalInfos = task.optionalInfos;
	}
};

/**
 * Build Process object for creation
 * @param {HistoryEntry} taskLog
 * @param {string} sheetId
 * @param {string} currentUserId
 * @param {string} binderTechId
 * @param {{type: "pcc"|"train", trainTechId: string, line: string, isTrainBinder: boolean, started_at: boolean}} options
 */
const buildProcess = (taskLog, sheetId, currentUserId, binderTechId, options = {}) => {
	const { trainTechId: train_tech_id, line, isTrainBinder, started_at } = options;
	const type = isTrainBinder ? 'train' : 'pcc';
	return {
		history: [taskLog],
		owner: currentUserId,
		sheet_tech_id: sheetId,
		binder_tech_id: binderTechId,
		type,
		...(train_tech_id && { train_tech_id }),
		...(line && { line }),
		...(started_at && { started_at }),
	};
};

/**
 * check if all chosen choices are done
 * @param {object[]} choices
 * @returns
 */
const areAllChosenChoicesDone = (choices = []) => {
	if (!Array.isArray(choices)) return false;
	const choicesChecked = choices?.filter((choice) => choice?.chosen);
	return choicesChecked?.length > 0 && choicesChecked?.every((choice) => choice?.isDone);
};

export {
	areAllChosenChoicesDone,
	buildProcess,
	buildSubTaskList,
	extractQuestionContentFromSubTaskList,
	getActiveRootTask,
	getTaskById,
	goToNextRootTask,
	goToNextTask,
	goToSelectedTask,
	isActiveTask,
	moveToNextSubTask,
	setAllTaskAsInactive,
};
