import React from 'react';
import Flatpickr from 'react-flatpickr';
import { getHours, getMinutes, setHours, setMinutes } from 'date-fns';
import { French } from 'flatpickr/dist/l10n/fr.js';
import PropTypes from 'prop-types';

import 'flatpickr/dist/themes/material_green.css';
import './date-chooser.scss';

import { ReactComponent as IconCalendar } from '../../../assets/img/icon-calendar.svg';
import { IconWrapper } from '../../../index';

const DateChooser = ({ value, onChange, label }) => {
	const handleChange = (newDates) => {
		if (newDates[0]) {
			let originValue = new Date();
			if (value) {
				originValue = value;
			}

			let newValue;
			if (newDates[0] < new Date()) {
				newValue = setHours(newDates[0], getHours(new Date()));
				newValue = setMinutes(newValue, getMinutes(new Date()));
			} else {
				newValue = setHours(newDates[0], getHours(originValue));
				newValue = setMinutes(newValue, getMinutes(originValue));
			}
			onChange(newValue);
		}
	};

	return (
		<div className="date-chooser fields__item">
			<Flatpickr
				value={value}
				options={{
					minDate: 'today',
					dateFormat: 'd\\/m\\/Y',
					locale: French,
					firstDayOfWeek: 2,
					wrap: true,
				}}
				onChange={handleChange}
			>
				<div className="date-chooser__field">
					<input
						data-input
						className="date-chooser__field__input"
						type="text"
						placeholder={label}
					/>
					<IconWrapper data-toggle className="date-chooser__field__icon" Component={IconCalendar} />
				</div>
			</Flatpickr>
		</div>
	);
};

export default DateChooser;

DateChooser.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	label: PropTypes.string,
};
