import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import './document-waiting.scss';

import { Button, CdrPage, DigiprocLoader, IconWrapper } from '../../../../theme';
import { CdrHomeIcon } from '../../../../theme/assets/img';
import { getDocument, getDocumentDetail } from '../../document.service';

const DocumentWaiting = () => {
	const { id: documentTechId } = useParams();
	const history = useHistory();
	const { pathname } = useLocation();
	const urlElements = pathname.split('/');
	const urlSuffix = urlElements[urlElements.length - 1];
	const { t } = useTranslation();
	const [networkError, setNetworkError] = useState(false);
	const [generationError, setGenerationError] = useState(false);
	const [uri, setUri] = useState('');

	const isError = networkError || generationError;

	const btnBackLabel =
		urlSuffix === 'sheet'
			? t('sheet:sheet-pdf.back-to-sheet')
			: t('binder:binder-pdf.back-to-binder');

	const updateBinderPdfStatus = useCallback(async () => {
		try {
			if (!uri && !generationError) {
				const response = await getDocumentDetail(documentTechId);
				setUri(response?.data?.uri);
				setGenerationError(response?.data?.status === 'error');
				setNetworkError(false);
			}
		} catch (error) {
			if (error?.response?.status === 400 || error?.response?.status === 404) {
				history.push('/404');
			} else {
				console.error(error);
				setNetworkError(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [documentTechId, uri]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		// Don't wait 5s if document ready (for document already generated
		updateBinderPdfStatus();
		const intervalID = setInterval(updateBinderPdfStatus, 5000);
		return () => clearInterval(intervalID);
	}, [updateBinderPdfStatus]);

	const downloadFile = async () => {
		const response = await getDocument(documentTechId);
		const [, filename] = response.headers['content-disposition'].split('filename=');
		const fileURL = URL.createObjectURL(response.data);
		const link = document.createElement('a');
		link.download = filename;
		link.href = fileURL;
		link.click();
	};

	return (
		<CdrPage className="binder-pdf-page">
			<div className="binder-pdf-page__content">
				{networkError && (
					<p className="binder-pdf-page__text">{t('binder:binder-pdf.network-error')}</p>
				)}
				{generationError && (
					<p className="binder-pdf-page__text">{t('binder:binder-pdf.generation-error')}</p>
				)}
				{!isError && !uri && (
					<>
						<p className="binder-pdf-page__text">{t('binder:binder-pdf.binder-pdf-in-progress')}</p>
						<DigiprocLoader className="binder-pdf-page__loader" />
					</>
				)}
				{!isError && uri && (
					<>
						<IconWrapper Component={CdrHomeIcon} className="binder-pdf-page__icon" />
						<p className="binder-pdf-page__text">{t('binder:binder-pdf.binder-pdf-available')}</p>
						<Button
							onClick={() => downloadFile()}
							variant="primary"
							label={t('binder:binder-pdf.access-document')}
							className="binder-pdf-page__button"
						/>
						<Button variant="minimal" onClick={() => history.goBack()} label={btnBackLabel} />
					</>
				)}
			</div>
		</CdrPage>
	);
};

export default DocumentWaiting;
