import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './not-found-page.scss';

import { IconWrapper, LogoDigiproc } from '../../../../theme';
import { ReactComponent as IconArrowLeft } from '../../../process/assets/icon-arrow-left.svg';
import { ReactComponent as Logo404 } from '../../assets/img/logo-404.svg';

const NotFoundPage = () => {
	const { t } = useTranslation();

	return (
		<main className="not-found-page">
			<header className="not-found-page__header">
				<Link to="/">
					<LogoDigiproc className="page__logo" />
				</Link>
			</header>
			<div className="not-found-page__content">
				<Logo404 className="not-found-page__content__logo" />
				<div className="not-found-page__content__title">{t('error:not-found-page.title')}</div>
				<div className="not-found-page__content__content">{t('error:not-found-page.content')}</div>
				<Link to="/" className="not-found-page__content__redirect">
					<IconWrapper className="back-to-current-step__icon" Component={IconArrowLeft} />
					<span className="back-to-current-step__text">{t('error:not-found-page.button')}</span>
				</Link>
			</div>
		</main>
	);
};

export default NotFoundPage;
