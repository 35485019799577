const trainCompositionVoidLineA = [
	{
		element1: [
			{
				header: '',
				key: '',
				content: [
					['', ''],
					['', ''],
					['', ''],
				],
			},
			{
				header: '',
				key: '',
				content: [
					['', ''],
					['', ''],
					['', ''],
				],
			},
			{
				header: '',
				key: '',
				content: [
					['', ''],
					['', ''],
					['', ''],
				],
			},
			{
				header: '',
				key: '',
				content: [
					['', ''],
					['', ''],
					['', ''],
				],
			},
			{
				header: '',
				key: '',
				content: [
					['', ''],
					['', ''],
					['', ''],
				],
			},
		],
	},
];

export default trainCompositionVoidLineA;
