import i18next from '../../config/i18next';

import RoleGuardedRoute from './components/role-guarded-route';
import i18nUser from './i18n/fr.json';
import AdminUserList from './pages/admin-user-list/admin-user-list';
import FormUser from './pages/admin-user-list/components/form-user/form-user';
import PopupAddUser from './pages/admin-user-list/components/popup-add-user/popup-add-user';
import PopupEditUser from './pages/admin-user-list/components/popup-edit-user/popup-edit-user';
import PopupPurgeUser from './pages/admin-user-list/components/popup-purge-user/popup-purge-user';
import LegalsPage from './pages/legals-page/legals-page';
import UserHomePage from './pages/user-home/user-home';
import {
	adminAccessRoles,
	binderManagementAccesRoles,
	railwayAccessRoles,
	sprAccessRoles,
} from './utils/page-access-roles';
import userRoleMap from './utils/user-role-map.json';
export { default as ProgressBar } from './components/progress-bar/progress-bar';
export { default as GenerateOfflinePassword } from './pages/generate-offline-password/generate-offline-password';
export { default as OfflineAuth } from './pages/offline-auth/offline-auth';

const userRoleList = Object.values(userRoleMap);

i18next.addResourceBundle('fr', 'user', i18nUser);

export {
	adminAccessRoles,
	AdminUserList,
	binderManagementAccesRoles,
	FormUser,
	LegalsPage,
	PopupAddUser,
	PopupEditUser,
	PopupPurgeUser,
	railwayAccessRoles,
	RoleGuardedRoute,
	sprAccessRoles,
	UserHomePage,
	userRoleList,
	userRoleMap,
};
