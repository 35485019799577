import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
const SheetModeContext = createContext({});

/**
 * sheet mode context provider
 * @param {object} props
 * @param {boolean} props.readOnly
 * @param {"spr" | "railway"} props.viewType
 * @returns
 */
const SheetModeContextProvider = ({ readOnly: initialReadOnlyMode, viewType, children }) => {
	const [forceReadMode, setForceReadMode] = useState(initialReadOnlyMode);

	return (
		<SheetModeContext.Provider
			value={{
				readOnly: forceReadMode,
				initialReadOnlyMode,
				setForceReadMode,
				viewType,
			}}
		>
			{children}
		</SheetModeContext.Provider>
	);
};

/**
 * sheet mode context hook
 * @returns {{
 * 	readOnly: boolean,
 *	initialReadOnlyMode: boolean
 *	setForceReadMode: function
 *	viewType: string
 * }}
 */
const useSheetModeContext = () => useContext(SheetModeContext);

export { SheetModeContext, SheetModeContextProvider, useSheetModeContext };

SheetModeContextProvider.propTypes = {
	children: PropTypes.node,
	readOnly: PropTypes.bool,
	viewType: PropTypes.oneOf(['spr', 'railway']),
};
