import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './resume-form-step-three-line-B.scss';
import '../../resume-form-block.scss';

import TrainTroubleFormFooter from '../../../../shared/train-trouble-form-footer/train-trouble-form-footer';
import TrainTroubleFormRadioButton from '../../../../shared/train-trouble-form-radio-button/train-trouble-form-radio-button';
import ValidatedSubstepCard from '../../../../shared/validated-substep-card/validated-substep-card';
import { initLineB } from '../../../utils/init-resume-form';
import { rfnNetworkFields } from '../../../utils/resume-form-fields';

const ResumeFormStepThreeLineB = ({
	resumeStep,
	handleChange,
	handleReset,
	handleSubmit,
	resumeForm,
	hasEmergencyLightOn,
	backToSubstep,
	handleBackClick,
}) => {
	const { t } = useTranslation();

	const fields = [...rfnNetworkFields];
	const {
		rfn_network: rfnNetwork,
		rfn_resume_type: rfnResumeType,
		rfn_speed_restriction: rfnSpeedRestriction,
		driving_support_center_informed: drivingSupportCenterInformed,
	} = resumeForm?.rfn_network_group || {};

	const className = classNames({ 'resume-form-block': resumeStep === 2 });

	const isSubstepValidated = resumeForm?.rfn_network_group.validated;
	const isRfnNetwork = resumeForm?.rfn_network_group.rfn_network === 'yes';
	const resumeOptions = hasEmergencyLightOn ? ['travellers', 'assistance'] : ['travellers', 'hlp'];
	const speedOptions = hasEmergencyLightOn ? fields[2].values.slice(1) : fields[2].values;

	const handleSubmitSubstep = () => {
		let updatedResumeForm;
		if (isRfnNetwork) {
			updatedResumeForm = {
				...resumeForm,
				rfn_network_group: { ...resumeForm.rfn_network_group, validated: true },
			};
		} else {
			updatedResumeForm = {
				...resumeForm,
				rfn_network_group: { ...initLineB.rfn_network_group, rfn_network: 'no', validated: true },
			};
		}
		handleSubmit(updatedResumeForm);
	};

	const checkFormValidity = () => {
		let fieldsList = fields;
		if (!isRfnNetwork) {
			fieldsList = fields.slice(0, 1);
		}
		const formErrorList = fieldsList.map((field) => {
			const { name: fieldName } = field;
			return field.validator(resumeForm?.rfn_network_group[fieldName]);
		});
		return formErrorList?.every((el) => el === true);
	};

	const isFormValid = checkFormValidity();

	return (
		<div className={className}>
			<div className={'process-resume__validated-substep'}>
				{resumeStep > 2 && isSubstepValidated && (
					<ValidatedSubstepCard
						title={t('process:process-rer-resume.resume-rer.substep.rfn_network')}
						backToSubstep={() => backToSubstep(2)}
					/>
				)}
			</div>
			{resumeStep === 2 && (
				<div className={className}>
					<TrainTroubleFormRadioButton
						title={t('process:process-rer-resume.resume-rer.rfn_network')}
						step={'resume-rer'}
						substep={fields[0].name}
						options={fields[0].values}
						isFirstQuestion={fields[0].is_first_question}
						checkedValue={rfnNetwork}
						onChange={handleChange}
					/>
					{isRfnNetwork && (
						<div>
							<TrainTroubleFormRadioButton
								title={t('process:process-rer-resume.resume-rer.rfn_resume_type')}
								step={'resume-rer'}
								substep={fields[1].name}
								options={resumeOptions}
								isFirstQuestion={fields[1].is_first_question}
								checkedValue={rfnResumeType}
								onChange={handleChange}
							/>
							<TrainTroubleFormRadioButton
								title={t('process:process-rer-resume.resume-rer.rfn_speed_restriction')}
								step={'resume-rer'}
								substep={fields[2].name}
								options={speedOptions}
								isFirstQuestion={fields[2].is_first_question}
								checkedValue={rfnSpeedRestriction}
								onChange={handleChange}
							/>
							<TrainTroubleFormRadioButton
								title={t('process:process-rer-resume.resume-rer.driving_support_center_informed')}
								step={'resume-rer'}
								substep={fields[3].name}
								options={fields[3].values}
								isFirstQuestion={fields[3].is_first_question}
								checkedValue={drivingSupportCenterInformed}
								onChange={handleChange}
							/>
							{hasEmergencyLightOn && (
								<div className={'resume-form__reminder'}>
									{t('process:process-rer-resume.resume-rer.bogie-reminder')}
								</div>
							)}
						</div>
					)}
				</div>
			)}
			{resumeStep === 2 && (
				<TrainTroubleFormFooter
					line={'B'}
					handleBackClick={handleBackClick}
					handleReset={handleReset}
					handleSubmit={handleSubmitSubstep}
					isSubmitBlocked={!isFormValid}
				/>
			)}
		</div>
	);
};

export default ResumeFormStepThreeLineB;

ResumeFormStepThreeLineB.propTypes = {
	resumeStep: PropTypes.number,
	handleChange: PropTypes.func,
	handleReset: PropTypes.func,
	handleSubmit: PropTypes.func,
	resumeForm: PropTypes.object,
	hasEmergencyLightOn: PropTypes.bool,
	backToSubstep: PropTypes.func,
	handleBackClick: PropTypes.func,
};
