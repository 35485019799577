import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './search-bar.scss';

import { ReactComponent as IconSearch } from '../../assets/img/icon-search.svg';
import { IconWrapper } from '../../index';

/**
 * Render the SearchBar component
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.value
 * @param {string} props.placeholder
 * @param {function} props.onChange
 * @returns {JSX.Element}
 * @constructor
 */
const SearchBar = ({
	name = '',
	className = '',
	value = '',
	onChange = () => {},
	placeholder = '',
	disabled = false,
}) => {
	const initClassName = () =>
		classnames(className, 'search-bar', {
			'search-bar--disabled': disabled,
		});

	return (
		<div className={initClassName()}>
			<IconWrapper className="search-bar__icon" Component={IconSearch} />
			<input
				className="search-bar__field"
				type="search"
				name={name}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				disabled={disabled}
			/>
		</div>
	);
};
export default SearchBar;

SearchBar.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
};
