import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import './popup-process-offline.scss';

import { ReactComponent as IconArrowLeft } from '../../../domains/process/assets/icon-arrow-left.svg';
import ProcessHistoryViewWrapper from '../../../domains/process/pages/process-history-view/process-history-view';
import { ReactComponent as IconOffline } from '../../../theme/assets/img/icon-offline.svg';
import { ReactComponent as IconTools } from '../../../theme/assets/img/icon-tools.svg';
import IconWrapper from '../../../theme/components/icon-wrapper/icon-wrapper';
import PopupOffline from '../../../theme/components/popup/popup-offline/popup-offline';

const PopupProcessOffline = ({ popupControl }) => {
	const { t } = useTranslation();
	const history = useHistory();

	const [processSelected, setProcessSelected] = useState(null);
	const processList = JSON.parse(localStorage.getItem('SprProcess')) || [];

	const dismissOfflinePopup = () => {
		popupControl.hide();
		history.push('/');
	};

	const generateItems = (item, index) => {
		const { label = '' } = item;
		return (
			<li key={index} className="popup-process-offline__list-item">
				<IconWrapper className="popup-process-offline__list-item__icon" Component={IconTools} />
				<span className="popup-process-offline__list-item__label">{label}</span>
				<span
					className="popup-process-offline__list-item__link"
					onClick={() => setProcessSelected(item)}
				>
					{t('process:popup.popup-offline.label-history')}
				</span>
			</li>
		);
	};

	// using an event listener inside the popup so the closing popup event triggered is concerned by itself
	useEffect(() => {
		window.addEventListener('online', dismissOfflinePopup);
		return () => window.removeEventListener('online', dismissOfflinePopup);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<PopupOffline
			popupControl={popupControl}
			icon={IconOffline}
			title={t('process:popup.popup-offline.title')}
		>
			<div className="popup-process-offline">
				{!processSelected && Array.isArray(processList) && (
					<div className="popup-process-offline__list">
						<div className="popup-process-offline__list__title">
							{t('process:popup.popup-offline.title-list')}
						</div>
						<ul className="popup-process-offline__list-items">{processList.map(generateItems)}</ul>
					</div>
				)}
				{processSelected && (
					<div className="popup-process-offline__history">
						<div onClick={() => setProcessSelected(null)}>
							<IconWrapper
								className="popup-process-offline__history__icon"
								Component={IconArrowLeft}
							/>
							<span className="popup-process-offline__history__text">
								{t('process:history-view.back-to-current-step')}
							</span>
						</div>
						<div className="popup-process-offline__history__title">
							{t('process:popup.popup-offline.title-history')}
						</div>
						<ProcessHistoryViewWrapper processOffline={processSelected} />
					</div>
				)}
			</div>
		</PopupOffline>
	);
};

export default PopupProcessOffline;

PopupProcessOffline.propTypes = {
	popupControl: PropTypes.object.isRequired,
};
