import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './image-form-row.scss';

import { IconWrapper, Textarea } from '../../../../../../../../theme';
import { ReactComponent as IconTrash } from '../../../../../../../../theme/assets/img/icon-trash.svg';
import ImageFormFileInput from '../image-form-file-input/image-form-file-input';

const ImageFormRow = ({
	imageId,
	title,
	description,
	onUpload,
	onTitleChange,
	onDescriptionChange,
	onFileChange,
	onDeleteImage,
	onDeleteRow,
	selectedFile,
	errorHelperText,
	index,
}) => {
	const { t } = useTranslation();

	const handleUpload = () => {
		onUpload();
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(handleUpload, [selectedFile]);

	return (
		<li className="image-form-row">
			<div className="image-form__head">
				<span className="image-form__head__badge">{index + 1}</span>
				<button className="image-form-row__delete" type="button" onClick={onDeleteRow}>
					<IconWrapper
						className="image-form-row__delete__icon"
						Component={IconTrash}
						tooltip={t('sheet:sheet-details.image-form.delete-tooltip')}
					/>
				</button>
			</div>
			<span className="image-form-row__image__label">
				{t('sheet:sheet-details.image-form.image-field-label')}
			</span>
			<ImageFormFileInput
				className="image-form-row__image"
				value={selectedFile}
				imageId={imageId}
				onChange={onFileChange}
				onDelete={onDeleteImage}
				errorHelperText={errorHelperText}
			/>
			<span className="image-form-row__title__label">
				{t('sheet:sheet-details.image-form.title-field-label')}
			</span>
			<Textarea
				className="image-form-row__title"
				name="title"
				onChange={onTitleChange}
				value={title}
				preventNewLine
			/>
			<span className="image-form-row__description__label">
				{t('sheet:sheet-details.image-form.description-field-label')}
			</span>
			<Textarea
				className="image-form-row__description"
				name="description"
				onChange={onDescriptionChange}
				value={description}
			/>
		</li>
	);
};

export default ImageFormRow;

ImageFormRow.propTypes = {
	imageId: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	onUpload: PropTypes.func,
	onTitleChange: PropTypes.func,
	onDescriptionChange: PropTypes.func,
	onFileChange: PropTypes.func,
	onDeleteImage: PropTypes.func,
	onDeleteRow: PropTypes.func,
	selectedFile: PropTypes.shape({}),
	errorHelperText: PropTypes.string,
	index: PropTypes.number,
};
