import React from 'react';
import { useTranslation } from 'react-i18next';
import Proptypes from 'prop-types';

import { usePopup } from '../../../../../../../../shared';
import { Button } from '../../../../../../../../theme';
import { IconTrash } from '../../../../../../../../theme/assets/img';
import { PopupDeleteSheet } from '../../../../../../../sheet';

/**
 * Render the DeleteSheetButton component
 * @param {object} props
 * @param {object} props.sheet
 * @param {function} props.onDelete
 * @param {string} props.defaultColor
 * @returns {JSX.Element}
 */
const DeleteSheetButton = ({ sheet, onDelete = () => {}, defaultColor }) => {
	const { t } = useTranslation();
	const popupDeleteSheetControl = usePopup();

	return (
		<>
			<PopupDeleteSheet
				popupControl={popupDeleteSheetControl}
				sheet={sheet}
				onConfirm={onDelete}
				defaultColor={defaultColor}
			/>
			<Button
				variant="minimal"
				icon={IconTrash}
				iconTooltipContent={t('binder:binder-detail.sheet-list.icon-label.trash')}
				onClick={popupDeleteSheetControl.show}
			/>
		</>
	);
};

export default DeleteSheetButton;

DeleteSheetButton.propTypes = {
	sheet: Proptypes.object.isRequired,
	onDelete: Proptypes.func,
	defaultColor: Proptypes.string,
};
