import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './simple-link-template-view.scss';

import {
	buildSprProcessSheetUri,
	createSheetLink,
	SheetLinkWrapper,
} from '../../../../../../../../shared';
import { IconWrapper } from '../../../../../../../../theme';
import { IconArrowRight } from '../../../../../../../../theme/assets/img';
import { useSheetModeContext } from '../../../../../../context/sheet-mode-context';
import TemplateWrapper from '../../template-wrapper/template-wrapper';

import SimpleLinkOtherSheetView from './components/simple-link-other-sheet-view/simple-link-other-sheet-view';
import SimpleLinkSheetView from './components/simple-link-sheet-view/simple-link-sheet-view';
import SimpleLinkStepView from './components/simple-link-step-view/simple-link-step-view';

/**
 * simple link template view
 * @param {object} props
 * @param {{content: {type: string, sheetNumber: string, binder?: {techId: string}, sheetColor, binderColor}}} props.template
 * @param {number} props.currentPosition
 * @param {object} props.location
 * @param {boolean} props.isTrainBinder
 * @returns {JSX.Element}
 */
const SimpleLinkTemplateView = ({ template, currentPosition, location, isTrainBinder }) => {
	const params = useParams();
	const { pathname } = useLocation();
	const { viewType } = useSheetModeContext();
	const content = template?.content || {};
	const {
		type,
		sheetNumber,
		sheetId,
		binder = {},
		actionPosition: jumpToActionPosition = null,
	} = content;

	const SimpleLinkView = ({ type: linkType }) => {
		const SimpleLinkProps = { content, isTrainBinder };
		if (linkType === 'step') return <SimpleLinkStepView {...SimpleLinkProps} />;
		if (linkType === 'sheet') return <SimpleLinkSheetView {...SimpleLinkProps} />;
		if (linkType === 'other-sheet') return <SimpleLinkOtherSheetView {...SimpleLinkProps} />;
		return null;
	};

	SimpleLinkView.propTypes = {
		type: PropTypes.string,
	};

	const createSimpleLinkSheetUri = () => {
		const { binderId, processId } = params;
		const { type: linkType, sheetNumber: linkSheetNumber, binder: linkBinder = {} } = content;
		const binderTechId = linkType === 'sheet' ? binderId : linkBinder?.techId;
		return buildSprProcessSheetUri(processId, binderTechId, linkSheetNumber);
	};

	const isLink = pathname.startsWith('/spr/process') && viewType === 'train' && type !== 'step';
	const uri = isLink ? createSimpleLinkSheetUri() : '#';
	// color is null because it is not included in content instead it is resolved via the sheetcolormap
	const sheetLink = isLink
		? createSheetLink(uri, sheetId, sheetNumber, binder?.tech_id, null, jumpToActionPosition)
		: {};

	return (
		<TemplateWrapper
			id={template.id}
			className={classNames('simple-link-template-view', {
				'simple-link-template-view--link': isLink,
			})}
			template={template}
			location={location}
			currentPosition={currentPosition}
		>
			{!type && (
				<IconWrapper className="simple-link-template-view__icon" Component={IconArrowRight} />
			)}
			<SheetLinkWrapper isLink={isLink} sheetLink={sheetLink}>
				<SimpleLinkView type={type} />
			</SheetLinkWrapper>
		</TemplateWrapper>
	);
};

export default SimpleLinkTemplateView;

SimpleLinkTemplateView.propTypes = {
	template: PropTypes.shape({
		id: PropTypes.string,
		content: PropTypes.shape({
			type: PropTypes.string,
			sheetNumber: PropTypes.string,
			sheetColor: PropTypes.string,
			binderColor: PropTypes.string,
			sheetType: PropTypes.string,
			binder: PropTypes.shape({
				techId: PropTypes.string,
			}),
		}),
	}),
	currentPosition: PropTypes.number,
	location: PropTypes.object,
	isTrainBinder: PropTypes.bool,
};
