import React from 'react';
import ReactDOM from 'react-dom';

import './config/index';

import App from './app';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
	onUpdate: async (registration) => {
		try {
			if (registration?.waiting) {
				// Unregister the service worker safely
				await navigator.serviceWorker.ready;
				await registration.unregister();
				// Reload the page after a short delay
				setTimeout(() => {
					window.location.reload();
				}, 300);
			}
		} catch (error) {
			console.error('Error during service worker update:', error);
		}
	},
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
