import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './train-element-table.scss';

import TrainTroubleFormTrainElements from '../../../shared/train-trouble-form-train-elements/train-trouble-form-train-elements';
import {
	trainCompositionLineA,
	trainCompositionLineB,
	trainCompositionVoidLineA,
	trainCompositionVoidLineB,
} from '../../../utils';
import TrainElementTableRows from '../train-element-table-rows/train-element-table-rows';

const TrainElementTable = ({
	fieldName,
	damageFormData = {},
	setDamageFormData,
	line,
	areParentsSelected,
	readOnly = false,
}) => {
	const { train_composition: trainCompositionForm, direction } = damageFormData;
	const { t } = useTranslation();
	const [trainElements, setTrainElements] = useState([]);
	const title = areParentsSelected
		? 'process:process-rer-resume.damage-location.equipment-involved'
		: 'process:process-rer-resume.damage-location.equipment-involved-disabled';

	const generateCar = (car, index) => (
		<div>
			<TrainElementTableRows
				car={car}
				carIndex={index}
				damageFormData={damageFormData}
				fieldName={fieldName}
				setDamageFormData={setDamageFormData}
				line={line}
				readOnly={readOnly}
			/>
		</div>
	);

	const generateTable = (element) => {
		const { element1, element2 } = element || {};
		const elementToDisplay = element1 || element2;

		return (
			<div className="train-element-table__elements__element">
				{elementToDisplay?.map((car, index) => {
					return generateCar(car, index);
				})}
			</div>
		);
	};

	useEffect(() => {
		if (line === 'A' && areParentsSelected) {
			setTrainElements(trainCompositionLineA);
		} else if (line === 'B' && areParentsSelected) {
			setTrainElements(trainCompositionLineB);
		} else {
			setTrainElements(line === 'A' ? trainCompositionVoidLineA : trainCompositionVoidLineB);
		}
	}, [line, damageFormData, areParentsSelected]);

	const generateElements = () => {
		const isMultipleTrainComposition = trainCompositionForm === 'UM';

		return (
			<div className="train-element-table__elements">
				<div
					className={classNames('train-element-table__elements__wrapper', {
						'train-element-table__elements__wrapper--read-only': readOnly,
					})}
				>
					<TrainTroubleFormTrainElements
						direction={direction}
						trainCompositionForm={trainCompositionForm}
						fullPage={false}
						readOnly={readOnly}
						group={'damage-location'}
					>
						<div className="train-element-table__elements__wrapper__element">
							{generateTable(trainElements[0])}
						</div>
						{isMultipleTrainComposition && direction && (
							<div className="train-element-table__elements__wrapper__element">
								<span className="train-element-table__elements__wrapper__element__second-title">
									{t('process:process-rer-resume.train-elements.second-element')}
								</span>
								{generateTable(trainElements[1])}
							</div>
						)}
					</TrainTroubleFormTrainElements>
				</div>
			</div>
		);
	};

	return (
		<div
			className={classNames('damage-location__form__fields__table-form', {
				'damage-location__form__fields__table-form--disabled': !areParentsSelected,
			})}
		>
			{!readOnly && (
				<div
					className={classNames('damage-location__form__fields__table-form__title-wrapper', {
						'damage-location__form__fields__table-form__title-wrapper--disabled':
							!areParentsSelected,
					})}
				>
					<span
						className={classNames('damage-location__form__fields__table-form__title', {
							'damage-location__form__fields__table-form__title--disabled': !areParentsSelected,
						})}
					>
						{t(title)}
					</span>
				</div>
			)}
			<div
				className={classNames('damage-location__form__fields__table-form__table', {
					'damage-location__form__fields__table-form__table--void': !areParentsSelected,
				})}
			>
				<div className="train-element-table">{generateElements()}</div>
			</div>
		</div>
	);
};

export default TrainElementTable;

TrainElementTable.propTypes = {
	fieldName: PropTypes.string.isRequired,
	damageFormData: PropTypes.object,
	setDamageFormData: PropTypes.func,
	line: PropTypes.string,
	areParentsSelected: PropTypes.bool,
	readOnly: PropTypes.bool,
};
