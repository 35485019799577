import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './legals-page.scss';

import { useAppSetupContext } from '../../../../shared';
import { CdrPage, IconWrapper, SprPage } from '../../../../theme';
import { ReactComponent as IconArrowLeft } from '../../../process/assets/icon-arrow-left.svg';

const LegalsPage = () => {
	const { t } = useTranslation();
	const { user: currentUser } = useAppSetupContext();
	const lineOperatorRoles = ['line-operator-supervisor', 'admin-line', 'line-operator'];

	const renderPageFunctionRole = (content) => {
		if (lineOperatorRoles.includes(currentUser.role)) {
			return (
				<SprPage className="legals-page" mainInfosNotVisible subheader={legalsPageSprSubHeader()}>
					{content}
				</SprPage>
			);
		} else {
			return (
				<CdrPage className="legals-page" subheader={legalsPageCdrSubHeader()}>
					{content}
				</CdrPage>
			);
		}
	};

	const legalsPageSprSubHeader = () => (
		<div className="legals-page__head__spr">
			<span className="legals-page__head__title">{t('user:legals.title')}</span>
			<Link to="/spr/binders" className="legals-page__head__redirect">
				<IconWrapper className="back-to-current-step__icon" Component={IconArrowLeft} />
				<span className="back-to-current-step__text">{t('user:legals.button-spr-redirect')}</span>
			</Link>
		</div>
	);

	const legalsPageCdrSubHeader = () => (
		<div className="legals-page__head">
			<span className="legals-page__head__title">{t('user:legals.title')}</span>
			<Link to="/" className="legals-page__head__redirect">
				<IconWrapper className="back-to-current-step__icon" Component={IconArrowLeft} />
				<span className="back-to-current-step__text">{t('user:legals.button-cdr-redirect')}</span>
			</Link>
		</div>
	);

	const legalsPageContent = () => (
		<div className="legals-page__content">
			<div className="legals-page__content__text">
				<Trans
					parent="span"
					i18nKey="user:legals.content"
					values={{ email: t('user:legals.email') }}
				>
					<a className="legals-page__content--link" href="mailto:cil-ratp@ratp.fr">
						{t('user:legals.email')}
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						className="legals-page__content--link"
						href="https://urbanweb.ratp.net/jcms/pr_569324/delegation-a-la-protection-des-donnees"
					>
						{t('user:legals.delegation-protect-data')}
					</a>
				</Trans>
			</div>
		</div>
	);

	return renderPageFunctionRole(legalsPageContent());
};

export default LegalsPage;
