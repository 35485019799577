import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './simple-link-sheet-view.scss';

import SimpleLinkSheetnumber from '../simple-link-sheetnumber/simple-link-sheetnumber';

const SimpleLinkSheetView = ({ content, isTrainBinder }) => {
	const { actionId, actionPosition } = content || {};

	const { t } = useTranslation();

	return (
		<div className="simple-link-sheet-view">
			<div className="simple-link-sheet-view__content">
				<SimpleLinkSheetnumber content={content} />
			</div>
			{isTrainBinder && (
				<div className="simple-link-sheet-view__informations">
					{actionId && (
						<p>
							{t('sheet:sheet-details.template.simpleLink.action-option', {
								bullet: actionPosition + 1,
							})}
						</p>
					)}
				</div>
			)}
		</div>
	);
};

export default SimpleLinkSheetView;

SimpleLinkSheetView.propTypes = {
	content: PropTypes.object,
	isTrainBinder: PropTypes.bool,
};
