import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './form-user.scss';

import { useAppSetupContext } from '../../../../../../shared';
import {
	Dropdown,
	ErrorMessage,
	LineSelector,
	TextField,
	ToggleSwitch,
} from '../../../../../../theme';
import { formatRoleName } from '../../../../utils/format-role-name';
import {
	defineLinesScopeByRole,
	hasAllLines,
	setupLineScopeAllowed,
} from '../../../../utils/user-associated-line-scope.js';

import formUserRoleConfig from './form-user-role-config';

/**
 * Form used in create/edit user
 * @param {object} props
 * @param {object} props.form
 * @param {function} props.onChange
 * @param {function} props.onActiveChange
 * @param {function} props.onRoleChange
 * @param {function} props.onLineChange
 * @param {boolean} props.isUserExist
 * @param {boolean} props.update
 * @param {string} props.idValidation
 * @returns {JSX.Element}
 */
const FormUser = ({
	form,
	onChange,
	onActiveChange,
	isUserExist,
	onRoleChange,
	onLineChange,
	update,
	idValidation,
}) => {
	const { user: currentUser } = useAppSetupContext();
	const { role: userRole, origin: userOrigin } = currentUser || {};
	const { t } = useTranslation();
	const [associatedLinesAllowed, setAssociatedLinesAllowed] = useState([]);
	const [userId, setUserId] = useState(form.id || '');

	const editableRoleForCurrentUser = formUserRoleConfig[userRole]?.editableRole || [];

	// Disable associatedLine field when selected role have all associated line in its scope
	const disableAssociatedLine = formUserRoleConfig[form?.role]?.allAssociatedLine;

	// Disable role dropdown when user can't edit a single role at least
	const isDropdownDisabled = editableRoleForCurrentUser?.length === 0;
	const canSelectAllLines =
		form?.role !== 'train-agent' && (hasAllLines(currentUser) || userRole === 'administrator');

	/**
	 * get default line scope by role
	 * @param {string} selectedRole
	 * @return {String[]}
	 */
	const getCalculatedLine = (selectedRole) => {
		const userLinesAllowed = formUserRoleConfig[selectedRole]?.linesAllowed;
		const hasAllAssociatedLines = formUserRoleConfig[selectedRole]?.allAssociatedLine;
		// enforce line scope on all line for selected role according to config
		if (hasAllAssociatedLines) {
			onLineChange([...userLinesAllowed]);
			return [...userLinesAllowed];
		}
		if (formUserRoleConfig[selectedRole]?.hasMainLine) {
			return [];
		}
		return form?.associated_line || [];
	};

	const setupAssociatedLineScopeByRole = (selectedRole) => {
		const calculatedLines = getCalculatedLine(selectedRole);
		onRoleChange(selectedRole, calculatedLines);
	};

	const handleRoleChange = (e) => {
		const selectedRole = e.target.value;
		// Update line scope for the selected role
		setupAssociatedLineScopeByRole(selectedRole);
	};

	const handleSingleCheck = (e) => {
		const { value: selectedLine } = e.target;
		if (formUserRoleConfig[form?.role]?.hasMainLine) {
			// only one line can be selected
			onLineChange([selectedLine[selectedLine.length - 1]]);
		} else {
			onLineChange(selectedLine);
		}
	};

	const handleAllCheck = (e) => {
		const { checked: isChecked } = e.target;
		const userLinesAllowed = defineLinesScopeByRole(currentUser, form?.role);
		const newArrayOfLines = isChecked ? [...userLinesAllowed] : [];
		onLineChange(newArrayOfLines);
	};

	const handleActiveChange = (e) => {
		const { checked } = e.target;
		onActiveChange(checked);
	};

	// filter checkbox list by user's perimeter
	const setupAssociatedLineCheckboxAllowed = (user) => {
		const lineOptionsAllowed = setupLineScopeAllowed(user);
		setAssociatedLinesAllowed(lineOptionsAllowed);
	};

	useEffect(() => {
		setupAssociatedLineCheckboxAllowed(currentUser);
	}, [currentUser, form?.role]);

	return (
		<>
			<TextField
				name="id"
				label={update ? '' : t('user:popup.form.field.user-id')}
				value={userId}
				onChange={(e) => setUserId(e.target.value.toUpperCase())}
				handleBlur={onChange}
				disabled={update}
				helperText={idValidation}
				invalid={idValidation}
			/>
			{isUserExist && (
				<ErrorMessage className="popup-form__existing-user">
					{t('user:popup.create-user.existing-user-id')}
				</ErrorMessage>
			)}
			<TextField
				name="lastname"
				label={update ? '' : t('user:popup.form.field.lastname')}
				value={form.lastname}
				onChange={onChange}
			/>
			<TextField
				name="firstname"
				label={update ? '' : t('user:popup.form.field.firstname')}
				value={form.firstname}
				onChange={onChange}
			/>
			<Dropdown
				name="role"
				label={update ? '' : t('user:popup.form.field.role')}
				placeholder={t('user:popup.form.field.role')}
				value={form.role}
				onChange={handleRoleChange}
				disabled={isDropdownDisabled}
			>
				{!isDropdownDisabled &&
					editableRoleForCurrentUser.map((role) => {
						return (
							<option key={role} value={role}>
								{t(`user:admin-user-list.list.user-info.role.${formatRoleName(role, userOrigin)}`)}
							</option>
						);
					})}
			</Dropdown>

			<div className="form-user__associated-line">
				<div className="form-user__associated-line__head">
					<span className="form-user__associated-line__head__title">
						{t('user:admin-user-list.list.perimeter')}
					</span>
					{canSelectAllLines && (
						<div className="form-user__associated-line__toggle">
							<span className="form-user__associated-line__toggle__label">
								{t('user:admin-user-list.list.all-lines')}
							</span>
							<ToggleSwitch
								isChecked={hasAllLines(form, currentUser) || false}
								handleOnChange={handleAllCheck}
							/>
						</div>
					)}
				</div>
				<LineSelector
					value={form.associated_line}
					onChange={handleSingleCheck}
					lineList={associatedLinesAllowed}
					disabled={disableAssociatedLine}
				/>
			</div>

			{update && editableRoleForCurrentUser.includes(form.role) && (
				<div className="form-user__toggle-switch">
					<span className="form-user__toggle-switch__label">
						{t('user:admin-user-list.list.user-info.status')}:
					</span>
					<ToggleSwitch
						isChecked={form.active}
						className="form-user__toggle-switch__checkbox"
						handleOnChange={handleActiveChange}
					/>
					<span className="form-user__toggle-switch__status">
						{form.active
							? t('user:admin-user-list.list.user-info.active')
							: t('user:admin-user-list.list.user-info.disable')}
					</span>
				</div>
			)}
		</>
	);
};

export default FormUser;

FormUser.propTypes = {
	idValidation: PropTypes.string,
	form: PropTypes.object.isRequired,
	isUserExist: PropTypes.bool,
	update: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	onActiveChange: PropTypes.func,
	onRoleChange: PropTypes.func.isRequired,
	onLineChange: PropTypes.func.isRequired,
};
