import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { csrf } from '../../config';
import { setupCsrfProtection } from '../auth/auth.services';

/**
 * Setup csrf configuration when not loading
 * @param online the network state
 * @param loading The parent loading state
 * @return {{loading: boolean}}
 */
const useCSRF = (online, loading) => {
	const history = useHistory();
	const [csrfTokenReady, setCsrfTokenReady] = useState(false);

	const setupCSRF = useCallback(async () => {
		if (!online) return;
		try {
			const response = await setupCsrfProtection();
			const csrfToken = response.headers['csrf-token'];
			csrf.saveCSRFToken(csrfToken);
		} catch (error) {
			console.error('Erreur CSRF:', error);
			csrf.removeCSRFToken();
			throw error;
		}
	}, [online]);

	useEffect(() => {
		if (!loading && online) {
			setCsrfTokenReady(false);
			setupCSRF()
				.catch(() => history.push('/error-access'))
				.finally(() => setCsrfTokenReady(true));
		}
	}, [history, loading, online, setupCSRF]);

	return { loading: online && !csrfTokenReady };
};

export default useCSRF;
