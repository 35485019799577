import { getItem, getKeys, removeItem } from '../../../config/offline';
import { unpadStartNumberInside } from '../..';

/**
 * Create a key format for offline cache
 * @param entity Entity name
 * @param {string} line Current line
 * @param ref Entity reference
 */
const createKey = (entity, line, ref) => {
	return `${entity}/${line}/${ref}`;
};

/**
 * Get the id of a key
 * @param key The full key with entity and id info
 * @returns {{ref: string, line: string, entity: string}}
 */
const parseKey = (key) => {
	const [entity, line, ref] = key.split('/');
	return { entity, line, ref };
};

/**
 * Get all cache key for an entity
 * @param entity The entity name
 * @param [line]
 * @return {Promise<string[]>}
 */
const getEntityKeys = async (entity, line) => {
	const keys = await getKeys();
	// Handle no line provided by returning all entity
	const startValue = line === undefined ? entity : `${entity}/${line}/`;
	return keys.filter((key) => key.startsWith(startValue));
};

/**
 * Get all entity items by line
 * @param entity The entity name to get all items
 * @param [line]
 */
const getAllEntity = async (entity, line) => {
	const entityKeys = await getEntityKeys(entity, line);
	return Promise.all(entityKeys.map(getItem));
};

/**
 * Get all entity ids
 * @param entity Entity name
 * @param {string} [line] Current line
 * @return {Promise<string[]>}
 */
const getEntityIds = async (entity, line) => {
	// get all cache key filtered by entity and line
	const entityKeys = await getEntityKeys(entity, line);
	return entityKeys.map((key) => parseKey(key).ref);
};

/**
 * Remove all entity from cache by line
 * @param {string} entity Entity name
 * @param {string} line
 * @returns {Promise<void>}
 */
const purgeAllEntity = async (entity, line) => {
	const entityKeys = await getEntityKeys(entity, line);
	await Promise.all(entityKeys.map(removeItem));
};

/**
 * Return true when entity match filter or when no filter provided
 * @param entityMetadata Entity metadata
 * @param [filterList]
 * @param [entity]
 * @returns {boolean|*}
 */
const isEntityMatchFilter = (entityMetadata, filterList = [], entity) => {
	if (filterList.length === 0) {
		return true;
	}

	return filterList.reduce((isMatchFilter, filterEntry) => {
		const [filterName, filterValue] = filterEntry;

		let entityValue = entityMetadata[filterName];

		//handle unpadding sheet numbers
		if (entity === 'sheet' && filterName === 'number') {
			entityValue = unpadStartNumberInside(entityValue);
		}

		if (Array.isArray(entityValue)) {
			return entityValue.filter((value) => filterValue.includes(value)).length > 0;
		}

		return filterValue.includes(entityValue) && isMatchFilter;
	}, true);
};

/**
 * Filter a list of items
 * @param {{data: Object, metadata: Object }[]} entities The list of items to filter
 * @param [includeParam] The list of params the items need to include
 * @param {string} [entity] The entity name
 *
 * @example
 * entities = [
 *   { data: ..., metadata: { color: "red", number: 1 } },
 *   { data: ..., metadata: { color: "green", number: 1 } },
 *   { data: ..., metadata: { color: "green", number: 2 } },
 *   { data: ..., metadata: { color: "green", number: 3 } }
 * ]
 * filter = { number: [1, 2], color: "green" }
 * result = filterEntity(entities, filter)
 *
 * // console.info(result)
 * // [ { data: ..., metadata: {number: 1, color: "green"} }, { data: ..., metadata: { number: 2, color: "green" } } ]
 */
const filterEntity = (entities, includeParam = {}, entity) => {
	// Transform all filter value into array
	const includeParamEntries = Object.entries(includeParam);
	const filterList = includeParamEntries.map(([key, value]) => [key, [].concat(value)]);

	return entities.filter(({ metadata }) => isEntityMatchFilter(metadata, filterList, entity));
};

export {
	createKey,
	filterEntity,
	getAllEntity,
	getEntityIds,
	getEntityKeys,
	parseKey,
	purgeAllEntity,
};
