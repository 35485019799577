import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './overlay.scss';

import { ReactComponent as IconSpinner } from '../../assets/img/icon-spinner.svg';
import { IconWrapper } from '../../index';

const Overlay = ({ text, visible }) => {
	return (
		<div className={classNames('overlay', { visible })}>
			<span className="overlay__text">
				<IconWrapper className="overlay__text__icon" Component={IconSpinner} />
				{text}
			</span>
		</div>
	);
};

export default Overlay;

Overlay.propTypes = {
	text: PropTypes.string,
	visible: PropTypes.bool,
};
