import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './load-wrapper.scss';

import { DigiprocLoader } from '../../../theme';

/**
 * @param {object} props
 * @param {string} props.className
 * @param {boolean} props.loading
 * @returns {JSX.Element}
 */
const LoadWrapper = ({ className, loading = false, children = false }) => {
	if (loading) {
		return (
			<div className={classNames('load-wrapper', className)}>
				<DigiprocLoader className="load-wrapper__loader" />
			</div>
		);
	}

	return children;
};

export default LoadWrapper;

LoadWrapper.propTypes = {
	className: PropTypes.string,
	loading: PropTypes.bool,
	children: PropTypes.node,
};
