import i18next from '../../config/i18next';

import BinderStatus from './components/binder-status/binder-status';
import { FormBinder, PopupBinderEditTitle, PopupCreateBinder } from './components/popup';
import i18nBinder from './i18n/fr.json';
import BinderByMaterialList from './pages/binder-by-material-list/binder-by-material-list';
import BinderDetail from './pages/binder-detail/binder-detail';
import BinderList from './pages/binder-list/binder-list';
import SlBinderDetail from './pages/sl-binder-detail/sl-binder-detail';
import SprBinderDetail from './pages/spr-binder-detail/spr-binder-detail';
import SprBinderGroup from './pages/spr-binder-group/spr-binder-group';
import SprBinderList from './pages/spr-binder-list/spr-binder-list';
import binderColorList from './utils/binder-color-list.json';
import binderStatusList from './utils/binder-status-list.json';
import binderStatusRules from './utils/binder-status-rules.json';
i18next.addResourceBundle('fr', 'binder', i18nBinder);

export {
	BinderByMaterialList,
	binderColorList,
	BinderDetail,
	BinderList,
	BinderStatus,
	binderStatusList,
	binderStatusRules,
	FormBinder,
	PopupBinderEditTitle,
	PopupCreateBinder,
	SlBinderDetail,
	SprBinderDetail,
	SprBinderGroup,
	SprBinderList,
};
