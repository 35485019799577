import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import '../../resume-form-block.scss';

import TrainTroubleFormFooter from '../../../../shared/train-trouble-form-footer/train-trouble-form-footer';
import TrainTroubleFormRadioButton from '../../../../shared/train-trouble-form-radio-button/train-trouble-form-radio-button';
import ValidatedSubstepCard from '../../../../shared/validated-substep-card/validated-substep-card';
import { headOfRegulationInformedFields } from '../../../utils/resume-form-fields';

const ResumeFormStepFiveLineA = ({
	resumeStep,
	handleChange,
	handleReset,
	handleSubmit,
	resumeForm,
	backToSubstep,
}) => {
	const { t } = useTranslation();

	const fields = [...headOfRegulationInformedFields];
	const {
		head_of_regulation_informed: headOfRegulationInformed,
		passenger_information: passengerInformation,
	} = resumeForm?.head_of_regulation_informed_group || {};

	const isSubstepValidated = resumeForm?.head_of_regulation_informed_group.validated;

	const className = classNames({ 'resume-form-block': resumeStep === 4 });

	const checkFormValidity = () => {
		const formErrorList = fields.map((field) => {
			const { name: fieldName } = field;
			return field.validator(resumeForm?.head_of_regulation_informed_group[fieldName]);
		});
		return formErrorList?.every((el) => el === true);
	};

	const isFormValid = checkFormValidity();

	const handleSubmitSubstep = () => {
		const updatedResumeForm = {
			...resumeForm,
			head_of_regulation_informed_group: {
				...resumeForm.head_of_regulation_informed_group,
				validated: true,
			},
		};
		handleSubmit(updatedResumeForm);
	};

	return (
		<div className={className}>
			{resumeStep > 4 && isSubstepValidated && (
				<ValidatedSubstepCard
					title={t('process:process-rer-resume.resume-rer.substep.head_of_regulation_informed')}
					backToSubstep={() => backToSubstep(4)}
				/>
			)}
			{resumeStep === 4 && (
				<div className={className}>
					<TrainTroubleFormRadioButton
						title={t('process:process-rer-resume.resume-rer.head_of_regulation_informed')}
						step={'resume-rer'}
						substep={fields[0].name}
						options={fields[0].values}
						isFirstQuestion={fields[0].is_first_question}
						checkedValue={headOfRegulationInformed}
						onChange={handleChange}
					/>
					<TrainTroubleFormRadioButton
						title={t('process:process-rer-resume.resume-rer.passenger_information')}
						step={'resume-rer'}
						substep={fields[1].name}
						options={fields[1].values}
						isFirstQuestion={fields[1].is_first_question}
						checkedValue={passengerInformation}
						onChange={handleChange}
					/>
				</div>
			)}
			{resumeStep === 4 && (
				<TrainTroubleFormFooter
					handleReset={handleReset}
					handleSubmit={handleSubmitSubstep}
					isSubmitBlocked={!isFormValid}
				/>
			)}
		</div>
	);
};

export default ResumeFormStepFiveLineA;

ResumeFormStepFiveLineA.propTypes = {
	resumeStep: PropTypes.number,
	handleChange: PropTypes.func,
	handleReset: PropTypes.func,
	handleSubmit: PropTypes.func,
	resumeForm: PropTypes.object,
	backToSubstep: PropTypes.func,
};
