import { useCallback, useMemo } from 'react';

import {
	findParentTemplate,
	hasMultipleActions,
	mapTemplatePositionByType,
} from '../../domains/sheet/utils/template-position-utils';

/**
 * hook to determine the position of actions in a sheet
 * @param {object[]} nodeList list of nodes with template information
 * @returns
 */
const useTemplatesAndPositions = (nodeList = []) => {
	const positionMap = useMemo(
		() => (Array.isArray(nodeList) ? mapTemplatePositionByType(nodeList) : {}),
		[nodeList]
	);
	const parentTemplates = useMemo(
		() =>
			Array.isArray(nodeList)
				? nodeList.filter((item) => item.content?.yes?.items || item.content?.no?.items)
				: [],
		[nodeList]
	);
	return [positionMap, parentTemplates];
};

/**
 * get the position of a task within it's surrounding sheet
 * @callback getTemplateTypePositionCallback
 * @param {string} templateId
 * @returns {number}
 */

/**
 * get the position of a task within it's surrounding sheet
 * @callback getSubTemplateTypePositionCallback
 * @param {{id: string, type: string, isSubNode: boolean}} templateToLoad
 * @returns {number}
 */

/**
 * get the position of a nested task depending on it being a subnode or not
 * @callback getTaskPositionCallback
 * @param {{id: string, type: string, isSubNode: boolean}} templateToLoad
 * @returns {number}
 */

/**
 * hook to determine the position of actions in a sheet
 * @param {object[]} nodeList list of nodes with template information
 * @param {object[]} nodeHistoryList list of history nodes with template information
 */
export const useTemplateTypePosition = (nodeList = [], nodeHistoryList = []) => {
	const [positionMap, parentTemplates] = useTemplatesAndPositions(nodeList);
	const [historyPositionMap, parentHistoryTemplates] = useTemplatesAndPositions(nodeHistoryList);

	/** @type {getTemplateTypePositionCallback} */
	const getTemplateTypePosition = useCallback(
		(templateId) => {
			const position = positionMap?.[templateId] || historyPositionMap?.[templateId];
			return Number.isInteger(position) ? position : NaN;
		},
		[positionMap, historyPositionMap]
	);

	/** @type {getSubTemplateTypePositionCallback} */
	const getSubTemplateTypePosition = useCallback(
		(templateToLoad) => {
			let items = [];
			const templateResult =
				findParentTemplate(parentTemplates, templateToLoad, 'yes') ||
				findParentTemplate(parentTemplates, templateToLoad, 'no') ||
				findParentTemplate(parentHistoryTemplates, templateToLoad, 'yes') ||
				findParentTemplate(parentHistoryTemplates, templateToLoad, 'no');
			if (Array.isArray(templateResult)) {
				const [parentTemplate, key] = templateResult;
				if (key === 'yes') {
					items = parentTemplate.content.yes.items;
				} else if (key === 'no') {
					items = parentTemplate.content.no.items;
				}
				if (hasMultipleActions(parentTemplate?.content, key)) {
					const subTemplatePositionMap = mapTemplatePositionByType(items);
					return subTemplatePositionMap[templateToLoad?.id];
				} else {
					return '';
				}
			}
			return NaN;
		},
		[parentTemplates, parentHistoryTemplates]
	);

	/** @type {getTaskPositionCallback} */
	const getTaskPosition = useCallback(
		(task) => {
			if (task?.isSubNode) {
				return getSubTemplateTypePosition(task);
			} else {
				return getTemplateTypePosition(task?.id);
			}
		},
		[getTemplateTypePosition, getSubTemplateTypePosition]
	);

	return { getTemplateTypePosition, getSubTemplateTypePosition, getTaskPosition };
};
