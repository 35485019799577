import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './simple-link-step-form.scss';

import { Dropdown } from '../../../../../../../../../../theme';
import { useSheetContentContext } from '../../../../../../../../context/sheet-content-context';

/**
 * Render the SimpleLinkStepForm component
 * @param {object} props
 * @return {JSX.Element}
 * @constructor
 */
const SimpleLinkStepForm = ({ dropdownValue, handleDropdownChange }) => {
	const { t } = useTranslation();
	const { nodeList } = useSheetContentContext();
	const [dropdownOptions, setDropdownOptions] = useState();

	const filterStepNodeList = (node) => node.type === 'step';

	const setPhaseOptions = () => {
		const nodeOptions = nodeList.filter(filterStepNodeList);

		const options = nodeOptions.map((val) => (
			<option value={val.id} key={val.id}>
				{`${t('sheet:sheet-details.template.simpleLink.step')} ${val.content.step}`}
			</option>
		));

		setDropdownOptions(options);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(setPhaseOptions, [nodeList]);

	return (
		<div className="simple-link-phase-form">
			<label className="simple-link-phase-form__label">
				{t('sheet:sheet-details.template.simpleLink.step-label')}
			</label>
			<Dropdown
				className="simple-link-phase-form__dropdown-type"
				onChange={handleDropdownChange}
				value={dropdownValue}
				placeholder={t('sheet:sheet-details.template.simpleLink.placeholder-dropdown-step')}
			>
				{dropdownOptions}
			</Dropdown>
		</div>
	);
};

export default SimpleLinkStepForm;

SimpleLinkStepForm.propTypes = {
	dropdownValue: PropTypes.string.isRequired,
	handleDropdownChange: PropTypes.func.isRequired,
};
