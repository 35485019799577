import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-confirm-work-in-progress-binder.scss';

import { Button, PopupConfirm } from '../../../../../theme';

/**
 * PopupConfirmWorkInProgressBinder component
 * @param {object} props
 * @param {boolean} props.loading
 * @param {object} props.popupControl
 * @param {function} props.handlePrintPdf
 * @param {function} props.handleConfirm
 * @returns {JSX.Element}
 */
const PopupConfirmWorkInProgressBinder = ({
	handleConfirm,
	handlePrintPdf,
	loading,
	popupControl,
}) => {
	const { t } = useTranslation();

	return (
		<PopupConfirm
			popupControl={popupControl}
			onConfirm={handleConfirm}
			title={t('binder:popup.update-status.title-work-in-progress')}
			ctaConfirmLabel="binder:popup.update-status.cta-confirm-label"
		>
			<div className="popup-confirm-work-in-progress">
				<p className="popup-confirm-work-in-progress__text">
					{t('binder:popup.update-status.text-work-in-progress.start')}
				</p>
				<br />
				<p className="popup-confirm-work-in-progress__text">
					{t('binder:popup.update-status.text-work-in-progress.end')}
				</p>
			</div>
			<Button
				className="popup-download-pdf"
				variant="secondary"
				type="button"
				onClick={() => handlePrintPdf(false)}
				label={t('binder:popup.update-status.text-work-in-progress.download-pdf')}
				loading={loading}
			/>
		</PopupConfirm>
	);
};

PopupConfirmWorkInProgressBinder.propTypes = {
	loading: PropTypes.bool,
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired,
		visible: PropTypes.bool.isRequired,
	}).isRequired,
	handlePrintPdf: PropTypes.func,
	handleConfirm: PropTypes.func,
};

export default PopupConfirmWorkInProgressBinder;
