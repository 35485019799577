import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './pop-up-edit-process-train.scss';

import { checkFormError } from '../../../../shared';
import { Button, ErrorMessage, PopupDefault } from '../../../../theme';
import { fetchTrain } from '../../../train/train.services';
import { getProcessByTechId, updateProcess } from '../../process.services';
import {
	getFormProcessTrainFieldMessageKey,
	validatePopupEditProcessTrain,
} from '../../utils/validate-popup-process-train-form';
import FormProcessTrain from '../form-process-train/form-process-train';

/**
 * Render the popup to edit process trainr
 * @param {object} props
 * @param {object} props.popupControl
 * @param {function} props.onSubmitSuccess
 * @param {number} props.line
 * @param {object} props.process
 * @returns {JSX.Element}
 */
const PopupEditProcessTrain = ({ onSubmitSuccess = () => {}, popupControl, line, process }) => {
	const { t } = useTranslation();
	const { tech_id: processId, sheet_tech_id: sheetId } = process;
	const [formData, setFormData] = useState({ line, type: 'train' });
	const [networkError, setNetworkError] = useState(false);
	const [trainList, setTrainList] = useState([]);

	const formError = validatePopupEditProcessTrain(formData);
	const formErrorMessage = getFormProcessTrainFieldMessageKey(formError);

	const getTrainList = () => {
		fetchTrain('', { line, is_active: true }).then((response) => {
			if (response?.data) {
				setTrainList(response?.data);
			}
		});
	};

	const getCurrentProcessData = () => {
		if (processId) {
			// get train infos from process
			getProcessByTechId(processId, { extendOwner: false }).then((response) => {
				if (response && response?.data) {
					const {
						binder_tech_id,
						position_type,
						position_info,
						track,
						description,
						sheet_tech_id,
						train_tech_id,
					} = response.data;
					setFormData({
						binder_tech_id,
						position_type,
						position_info,
						track,
						description,
						sheet_tech_id,
						train_tech_id,
					});
				}
			});
		}
	};

	const initForm = () => {
		setFormData({ line, type: 'train' });
		setNetworkError(false);
	};

	const handleChange = useCallback((event) => {
		const { name, value } = event.target;
		setFormData((oldFormData) => {
			const newFormData = { ...oldFormData };
			return { ...newFormData, [name]: value };
		});
	}, []);

	const handleSubmit = (event) => {
		event.preventDefault();

		if (!checkFormError(formError)) {
			initForm();

			const updatedProcess = {
				...process,
				binder_tech_id: formData.binder_tech_id,
				sheet_tech_id: formData.sheet_tech_id,
			};

			updateProcess(
				process.tech_id,
				{
					binder_tech_id: updatedProcess.binder_tech_id,
					sheet_tech_id: updatedProcess.sheet_tech_id,
				},
				{ action: 'updateBinderSheetData' }
			)
				.then(popupControl.hide)
				.then(onSubmitSuccess)
				.catch(() => setNetworkError(true));
		}
	};

	useEffect(getTrainList, [line]);

	useEffect(getCurrentProcessData, [processId, sheetId]);

	return (
		<PopupDefault
			className="popup-edit-train__wrapper"
			popupControl={popupControl}
			title={t('process:popup.create-process-train.title')}
			onClose={initForm}
		>
			<form className="popup-edit-train__form" onSubmit={handleSubmit}>
				<div className="popup-create-train__form-wrapper">
					<FormProcessTrain
						form={formData}
						validation={formError}
						errorMessage={formErrorMessage}
						handleChange={handleChange}
						line={line}
						trainList={trainList}
						update
					/>
				</div>
				<div className="popup-edit-train__controller">
					<Button
						variant="primary"
						className="popup-create-train__submit"
						type="submit"
						disabled={checkFormError(formError)}
						label={t('train:popup.form.submit')}
					/>
				</div>
				{networkError && (
					<ErrorMessage className="popup-edit-train__network-error">
						{t('error.default')}
					</ErrorMessage>
				)}
			</form>
		</PopupDefault>
	);
};

export default PopupEditProcessTrain;

PopupEditProcessTrain.propTypes = {
	popupControl: PropTypes.object,
	onSubmitSuccess: PropTypes.func,
	line: PropTypes.number.isRequired,
	process: PropTypes.shape({
		sheet_tech_id: PropTypes.string,
		tech_id: PropTypes.string.isRequired,
	}).isRequired,
};
