import { http } from '../config';

const materialRootUrl = '/material';

/**
 * Get a list of material
 * @param {string} origin
 */
const fetchMaterial = async (origin = '') =>
	http.get(materialRootUrl, {
		params: { origin },
	});

export { fetchMaterial };
