import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './damage-location-choice-button.scss';

import { getUniqueKey } from '../../../../../../../shared/utils/string-utils';
import {
	IconLeftArrowWithCircle,
	IconRightArrowWithCircle,
} from '../../../../../../../theme/assets/img';

const DamageLocationChoiceButton = ({ fieldName, formData, handleChange, options }) => {
	return (
		<ul className="damage-location__form__choice-button">
			{options.map((option) => {
				const isTrainComposition = ['UM', 'US'].includes(option);
				const selected = formData?.[fieldName] === option;
				return (
					<li
						className={classNames('damage-location__form__choice-button__item', {
							'damage-location__form__choice-button__item--selected': selected,
						})}
						key={`${getUniqueKey(option)}`}
					>
						<label htmlFor={option} className="damage-location__form__choice-button__item__wrapper">
							<input
								className="input-field__choice-button"
								id={option}
								name={fieldName}
								type="choice"
								checked={selected}
								value={option}
								onClick={handleChange}
							/>
							{option === 'left' && (
								<IconLeftArrowWithCircle
									fill={selected ? '#0009A4' : '#2A2A2E'}
									role="img"
									aria-hidden="true"
									focusable="false"
								/>
							)}
							{option === 'right' && (
								<IconRightArrowWithCircle
									fill={selected ? '#0009A4' : '#2A2A2E'}
									role="img"
									aria-hidden="true"
									focusable="false"
								/>
							)}
							{isTrainComposition && (
								<span
									className={classNames(
										'damage-location__form__choice-button__item__wrapper__text',
										{
											'damage-location__form__choice-button__item__wrapper__text--selected':
												selected,
										}
									)}
								>
									{option}
								</span>
							)}
						</label>
					</li>
				);
			})}
		</ul>
	);
};

export default DamageLocationChoiceButton;

DamageLocationChoiceButton.propTypes = {
	fieldName: PropTypes.string.isRequired,
	formData: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired,
};
