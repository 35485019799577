import { checkRange, numberValidator, requiredFieldValidator } from '../../../shared';

/**
 * @typedef ProcessResumeFormData
 * @prop {string} position_supply
 * @prop {number} engine_units
 * @prop {number} bogie_car_units
 * @prop {string} resume
 */

/**
 * @typedef ProcessResumeFormError
 * @prop {string[]} position_supply
 * @prop {string[]} engine_units
 * @prop {string[]} bogie_car_units
 * @prop {string[]} resume
 */

/**
 * Validate resume form data
 * @param {ProcessResumeFormData} form form data to validate
 * @param {number} carNumber number of cars on train
 * @param {'bogie'|'car'} brakeSystem number of cars on train
 * @returns {ProcessResumeFormError}
 */
const validateProcessResumeForm = (form, carNumber = 1, brakeSystem = 'bogie') => {
	const { position_supply, engine_units, bogie_car_units, resume } = form;

	// min max
	const engineUnitsRangeMin = 0;
	const engineUnitsRangeMax = Math.floor(carNumber / 2) + 1;
	const carUnitsRangeMin = 0;
	const carUnitsRangeMax = brakeSystem === 'car' ? carNumber : 2 * carNumber;

	return {
		position_supply: requiredFieldValidator(position_supply),
		engine_units: []
			.concat(checkRange(engine_units, engineUnitsRangeMin, engineUnitsRangeMax))
			.concat(numberValidator(engine_units)),
		bogie_car_units: []
			.concat(checkRange(bogie_car_units, carUnitsRangeMin, carUnitsRangeMax))
			.concat(numberValidator(bogie_car_units)),
		resume: requiredFieldValidator(resume),
	};
};

export { validateProcessResumeForm };
