import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './subway-line-number.scss';

import { iconLines } from '../../../domains/user/utils/associated-line-icon-utils/associated-line-icon.utils';
import { IconWrapper } from '../../';

/**
 * Render the SubwayLineNumber components
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.lineNumber
 * @returns {JSX.Element}
 */
const SubwayLineNumber = ({ className, lineNumber }) => {
	const classes = classnames(className, 'subway-line-number');
	return <IconWrapper className={classes} Component={iconLines[lineNumber]} />;
};

export default SubwayLineNumber;

SubwayLineNumber.propTypes = {
	className: PropTypes.string,
	lineNumber: PropTypes.string,
};
