const validateTrainComposition = (choice) => {
	const trainCompoChoices = ['US', 'UM'];
	return trainCompoChoices.includes(choice) ? [] : ['not-valid-train-compo'];
};

const validateDirection = (choice) => {
	const directions = ['left', 'right'];
	return directions.includes(choice) ? [] : ['not-valid-direction'];
};

const validateEmergencyLights = (choice) => {
	const emergencyLightsChoices = ['yes', 'no', ''];
	return emergencyLightsChoices.includes(choice) ? [] : ['not-valid-emergency-lights'];
};

const validateMissionCode = (code) => {
	const regex = /^[a-zA-Z-0-9]{0,6}$/;
	const valueEmpty = !code || code === '';
	const validRegex = regex.test(code);
	const valid = valueEmpty || validRegex;
	return valid ? [] : ['not-valid-mission-code'];
};

const validateEquipmentInvolved = (equipmentInvolved) => {
	return equipmentInvolved?.length === 0 ? ['empty-equipment-involved'] : [];
};

/**
 * Validate damage location form data
 * @param {Object} form form data to validate
 * @returns {Object}
 */
const validateProcessDamageLocationForm = (form) => {
	const { emergency_lights, train_composition, direction, equipment_involved, mission_code } = form;

	return {
		emergency_lights: validateEmergencyLights(emergency_lights),
		train_composition: validateTrainComposition(train_composition),
		direction: validateDirection(direction),
		equipment_involved: validateEquipmentInvolved(equipment_involved),
		mission_code: validateMissionCode(mission_code),
	};
};

export default validateProcessDamageLocationForm;
