import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './signal-title.scss';

import { IconWrapper } from '../../../../../../../../theme';
import { IconArrowRight } from '../../../../../../../../theme/assets/img';

/**
 * Render the signal title
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.origin
 * @param {string} props.destination
 * @returns {JSX.Element}
 */
const SignalTitle = ({ className, title, origin, destination }) => {
	if (!title || !origin || !destination) {
		return null;
	}

	return (
		<span className={classnames('signal-title', className)}>
			{title} / {origin}
			<IconWrapper
				className={classnames('signal-title__icon', className)}
				Component={IconArrowRight}
			/>
			{destination}
		</span>
	);
};

export default SignalTitle;

SignalTitle.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	origin: PropTypes.string.isRequired,
	destination: PropTypes.string.isRequired,
};
