import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useSprContext } from '../../../../../../../shared';
import BreakTestLineBSummary from '../break-test-line-B-summary/break-test-line-B-summary';
import DamageLocationSummary from '../damage-location-summary/damage-location-summary';
import NetworkSummary from '../network-summary/network-summary';
import ValveManeuverSummary from '../valve-maneuver-summary/valve-maneuver-summary';

const RerResumeSummaryLineB = ({ tableField, processInfo, isProcessRecap }) => {
	const { t } = useTranslation();

	const {
		damage_location: damageLocation,
		results_sheet: resultsSheet = {},
		resume_rer: resumeRer,
		has_emergency_lights_on: hasEmergencyLightsOn,
	} = processInfo?.resume_conditions || {};
	const { break_test_group: breakTestGroup = {} } = resultsSheet || {};
	const { rescued_bogie_group, ratp_network_group, rfn_network_group } = resumeRer || {};
	const { rescued_bogies_units: rescuedBogiesUnits } = rescued_bogie_group || {};

	const { currentBinder } = useSprContext();
	const { material_label, materialLabel } = currentBinder || {};
	const material = materialLabel ?? material_label;
	const trainComposition = damageLocation?.train_composition;

	return (
		<div>
			{!hasEmergencyLightsOn && isProcessRecap && (
				<div>
					<span
						className={classNames('rer-resume-summary__step__title', {
							'rer-resume-summary__step__title--bold': isProcessRecap,
						})}
					>
						{t('process:process-rer-resume.damage-location.title')}
					</span>
					<DamageLocationSummary
						line={'B'}
						damageLocation={damageLocation}
						material={material}
						tableField={tableField}
						isProcessRecap={isProcessRecap}
					/>
				</div>
			)}
			{!hasEmergencyLightsOn && (
				<div className={classNames({ 'rer-resume-summary__step': !isProcessRecap })}>
					<span
						className={classNames('rer-resume-summary__step__title', {
							'rer-resume-summary__step__title--bold': isProcessRecap,
						})}
					>
						{t('process:process-rer-resume.results-sheet.title')}
					</span>

					<ValveManeuverSummary
						line={'B'}
						damageLocation={damageLocation}
						resultsSheet={resultsSheet}
						trainComposition={trainComposition}
						material={material}
						tableField={tableField}
						isProcessRecap={isProcessRecap}
					/>

					<BreakTestLineBSummary
						breakTestGroup={breakTestGroup}
						trainComposition={trainComposition}
						material={material}
						isProcessRecap={isProcessRecap}
					/>
				</div>
			)}

			{hasEmergencyLightsOn && (
				<div className={'rer-resume-summary__substep--secondary'}>
					<span
						className={classNames('rer-resume-summary__substep__title--secondary', {
							'rer-resume-summary__substep__title--secondary--lighter': isProcessRecap,
						})}
					>
						{t('process:process-rer-resume.resume-rer.rescued_bogies_units')}
					</span>
					<div className={'rer-resume-summary__substep__value'}>
						<span
							className={classNames('rer-resume-summary__substep__value', {
								'rer-resume-summary__substep__value--lighter': isProcessRecap,
							})}
						>
							{rescuedBogiesUnits}
						</span>
					</div>
				</div>
			)}

			<div className={classNames({ 'rer-resume-summary__step': !isProcessRecap })}>
				<span
					className={classNames('rer-resume-summary__step__title', {
						'rer-resume-summary__step__title--bold': isProcessRecap,
					})}
				>
					{t('process:process-rer-resume.resume-rer.title')}
				</span>
			</div>
			<NetworkSummary networkInfo={ratp_network_group} isProcessRecap={isProcessRecap} />
			<NetworkSummary networkInfo={rfn_network_group} isProcessRecap={isProcessRecap} />
		</div>
	);
};

export default RerResumeSummaryLineB;

RerResumeSummaryLineB.propTypes = {
	tableField: PropTypes.object,
	processInfo: PropTypes.object,
	isProcessRecap: PropTypes.bool,
};
