import { hasFieldError, requiredFieldValidator } from '../../../shared';

/**
 * Validate the create theme popup's form
 * @param form
 * @param themeList
 * @param currentTheme
 * @return {{color: string[], title: string[]}}
 */
const validateThemeForm = (form, themeList, currentTheme) => {
	const { title } = form;

	const titleErrors = [
		requiredFieldValidator(title),
		themeTitleValidator(title, themeList, currentTheme),
	].flat();

	return {
		title: titleErrors,
		color: [],
	};
};

/**
 * Check if the provided theme title already exist in themeList
 * @param {string} themeTitle
 * @param {{title: string}[]} themeList The theme list
 * @param {{title: string}} currentTheme
 * @returns {string[]}
 */
const themeTitleValidator = (themeTitle = '', themeList, currentTheme) => {
	const title = themeTitle.trim();
	const titleExist = !!themeList?.find(
		(theme) => currentTheme?.title !== theme.title && theme.title === title
	);
	return titleExist ? ['invalid'] : [];
};

const getFormThemeFieldMessageKey = (formError) => ({
	title: hasFieldError('invalid', formError?.title)
		? 'binder:popup.form-theme.error.existing-binder-theme'
		: '',
	color: '',
});

export { getFormThemeFieldMessageKey, validateThemeForm };
