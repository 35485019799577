import { v4 as uuid } from 'uuid';

/**
 * Remove image from basic template (without subTemplate)
 * @param template
 * @return {*}
 */
const removeImageFromBasicTemplate = (template) => {
	if (template?.content?.images) {
		template.content.images = [];
	}
	return template;
};

/**
 * Remove image from provided template included image in sub nodes
 * @param template The template to process
 */
const removeImageFromTemplate = (template) => {
	const isTemplateWithImages = template?.content?.images;
	const isTemplateWithSubNodes = Array.isArray(template?.content?.subNodes);
	const isTemplateQuestion = template?.type === 'question';

	if (!isTemplateWithImages && !isTemplateWithSubNodes && !isTemplateQuestion) {
		return template;
	}

	const templateCopy = JSON.parse(JSON.stringify(template));

	// Template with subNodes
	if (isTemplateWithSubNodes) {
		templateCopy.content.subNodes = templateCopy?.content?.subNodes.map((subTemplateCopy) => {
			if (subTemplateCopy?.content?.images) {
				subTemplateCopy.content.images = [];
			}
			return subTemplateCopy;
		});

		return templateCopy;
	}

	// Template question
	if (template?.type === 'question') {
		templateCopy.content.yes.items = templateCopy.content.yes.items.map(
			removeImageFromBasicTemplate
		);
		templateCopy.content.no.items = templateCopy.content.no.items.map(removeImageFromBasicTemplate);
	}

	// Template with images (including question template)
	return removeImageFromBasicTemplate(templateCopy);
};

const templateCopiedStorageName = 'templateCopied';

/**
 * Save Provided template as copied template in local storage
 * @param template
 */
const setTemplateCopiedInLocalStorage = (template) => {
	const cleanedTemplate = removeImageFromTemplate(template);
	localStorage.setItem(templateCopiedStorageName, JSON.stringify(cleanedTemplate));
};

/**
 * Get copied template from local storage
 */
const getTemplateCopiedInLocalStorage = () =>
	JSON.parse(localStorage.getItem(templateCopiedStorageName));

/**
 * Insert Copied template in provided node list
 * @param template The template to insert
 */
const copyTemplate = (template) => {
	const result = {
		id: uuid(),
		type: template.type,
		content: JSON.parse(JSON.stringify(template.content)),
	};

	if (template.type === 'question') {
		const contentYes = template.content.yes;
		const contentNo = template.content.no;

		if (contentYes.items) {
			contentYes.items.map((elem) => {
				elem.id = uuid();
				return elem;
			});
		}
		if (contentNo.items) {
			contentNo.items.map((elem) => {
				elem.id = uuid();
				return elem;
			});
		}
		contentYes['end-item'].id = uuid();
		contentNo['end-item'].id = uuid();

		if (
			contentYes['end-item'].type === 'simpleLink' ||
			contentYes['end-item'].type === 'multipleLink'
		) {
			contentYes['end-item'].type = 'next';
			delete contentYes['end-item'].content;
		}
		if (
			contentNo['end-item'].type === 'simpleLink' ||
			contentNo['end-item'].type === 'multipleLink'
		) {
			contentNo['end-item'].type = 'next';
			delete contentNo['end-item'].content;
		}
	}

	if (template.type === 'multipleChoice') {
		template.content.subNodes.map((elem) => {
			elem.id = uuid();
			return elem;
		});
	}

	return result;
};

/**
 * Insert a template in provide "question" node
 * @param copiedSubTemplate
 * @param question Template of type "question" in which template will be inserted
 */
const addCopiedSubTemplate = (copiedSubTemplate, question) => {
	question.content.yes.items = question?.content?.yes.items.map((item) => {
		if (item.active) {
			item.type = copiedSubTemplate.type;
			item.content = copiedSubTemplate.content;
		}
		return item;
	});
	question.content.no.items = question?.content?.no.items.map((item) => {
		if (item.active) {
			item.type = copiedSubTemplate.type;
			item.content = copiedSubTemplate.content;
		}
		return item;
	});
	return question;
};

/**
 * Return true if both end-item are not of type "next"
 * @param template
 * @param {string} template.type
 * @param {{ "end-item": { type: string } }} template.content.yes
 * @param {{ "end-item": { type: string } }} template.content.no
 * @return {boolean}
 */
const hasNoNextTemplatesInBothYesAndNo = (template) => {
	if (template?.type === 'question') {
		//if no "next" template in yes AND no , means there are end-templates in both
		const { yes = {}, no = {} } = template?.content || {};
		return yes['end-item']?.type !== 'next' && no['end-item']?.type !== 'next';
	}
	return false;
};

/**
 * Return true if both end-item are of type "next"
 * @param template
 * @param {string} template.type
 * @param {{ "end-item": { type: string } }} template.content.yes
 * @param {{ "end-item": { type: string } }} template.content.no
 * @return {boolean}
 */
const hasNextTemplatesInBothYesAndNo = (template) => {
	if (template?.type === 'question') {
		const { yes = {}, no = {} } = template?.content || {};
		return yes['end-item']?.type === 'next' && no['end-item']?.type === 'next';
	}
	return false;
};

export {
	addCopiedSubTemplate,
	copyTemplate,
	getTemplateCopiedInLocalStorage,
	hasNextTemplatesInBothYesAndNo,
	hasNoNextTemplatesInBothYesAndNo,
	setTemplateCopiedInLocalStorage,
};
