import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './history-question-task-view.scss';

import { EditorRendering } from '../../../../../../theme';
import ImageListView from '../../../../../../theme/components/image-list-view/image-list-view';

/**
 * history question template component
 * @param {object} props
 * @param {object} props.task
 * @param {object} props.className
 * @param {boolean} props.passed
 * @param {boolean} props.isTrainBinder
 * @returns {JSX.Element}
 */
const HistoryQuestionTaskView = ({ task = {}, className = '', passed, isTrainBinder }) => {
	const { content } = task;
	const { t } = useTranslation();

	const wrapperClassName = classNames('history-question-task-view', className, {
		'history-question-task-view--passed': passed,
		'history-question-task-view--train': isTrainBinder,
	});

	return (
		<div className={wrapperClassName}>
			<div className="history-question-task-view__header">
				<EditorRendering className="header__text" content={content?.text} />
				{content?.note && <EditorRendering className="header__note" content={content?.note} />}
				<ImageListView className="history-question-task-view__images" images={content?.images} />
			</div>
			<span className="history-question-task-view__choice">
				{t(`process:history-task.question.${task?.choice}`)}
			</span>
		</div>
	);
};

export default HistoryQuestionTaskView;

HistoryQuestionTaskView.propTypes = {
	task: PropTypes.shape({
		choice: PropTypes.string,
		content: PropTypes.shape({
			text: PropTypes.string,
			note: PropTypes.string,
			images: PropTypes.arrayOf(
				PropTypes.shape({
					imageId: PropTypes.string,
					title: PropTypes.string,
					description: PropTypes.string,
				})
			),
		}),
	}),
	className: PropTypes.string,
	passed: PropTypes.bool,
	isTrainBinder: PropTypes.bool,
};
