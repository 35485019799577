import React from 'react';

import './eye-triangle.scss';

import { IconEye } from '../../assets/img';
import IconWrapper from '../icon-wrapper/icon-wrapper';

const EyeTriangle = () => (
	<span className="eye-triangle">
		<IconWrapper className="eye-triangle__icon" Component={IconEye} />
	</span>
);

export default EyeTriangle;
