import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './termination-template-view.scss';

import TemplateWrapper from '../../template-wrapper/template-wrapper';

const TerminationTemplateView = ({ template, currentPosition, location }) => {
	const { t } = useTranslation();

	return (
		<TemplateWrapper
			id={template.id}
			className="termination-template"
			template={template}
			location={location}
			currentPosition={currentPosition}
			allowInsertAfter={false}
		>
			<div className="termination-template__title">
				{t('sheet:sheet-details.template.termination.title')}
			</div>
		</TemplateWrapper>
	);
};

export default TerminationTemplateView;

TerminationTemplateView.propTypes = {
	template: PropTypes.object,
	currentPosition: PropTypes.number,
	location: PropTypes.object,
};
