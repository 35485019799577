const binderTrainTypeListRer = ['train-driver'];
const binderTrainTypeList = ['train-driver', 'train-officer', 'train-auto'];
const binderPccTypeList = ['sheet', 'theme', 'man'];

const binderTypeList = [...binderPccTypeList, ...binderTrainTypeListRer, binderTrainTypeList];

const isPccBinderType = (binderType) => binderPccTypeList.includes(binderType);
const isTrainBinderType = (binderType) => binderTrainTypeList.includes(binderType);

export {
	binderPccTypeList,
	binderTrainTypeList,
	binderTrainTypeListRer,
	binderTypeList,
	isPccBinderType,
	isTrainBinderType,
};
