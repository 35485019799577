import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './spr-binder-detail-subheader.scss';

import { Button } from '../../../../../../theme';
import { IconBack } from '../../../../../../theme/assets/img';
import { IconMap } from '../../../../../process/assets';
import ProcessActionButton from '../../../../../process/pages/spr-run-process/components/action-button/process-action-button';

/**
 * SprBinderDetailSubheader
 * @param {{ binderTitle: string, binderType: string }} props
 * @returns
 */
const SprBinderDetailSubheader = ({ binderTitle, binderType, binderZoneLink }) => {
	const { t } = useTranslation();

	return (
		<>
			<div className="spr-binder-detail-page__subheader__title">
				<div className="binder-title">{binderTitle}</div>
			</div>
			<div className="spr-binder-detail-page__actions">
				<Link className="spr-binder-detail-page__come-back-binders" to="/spr/binders">
					<Button variant="primary" small icon={IconBack} type="button" />
					<span className="come-back-binders__label">
						{t('binder:spr-binder-detail.come-back-button')}
					</span>
				</Link>
				{binderType === 'man' && !!binderZoneLink && (
					<ProcessActionButton
						handleClick={() => window.open(binderZoneLink, '_blank', 'noopener,noreferrer')}
						label={t('sheet:run-process.cta-zone-link')}
						Icon={IconMap}
					/>
				)}
			</div>
		</>
	);
};

export default SprBinderDetailSubheader;

SprBinderDetailSubheader.propTypes = {
	binderTitle: PropTypes.string.isRequired,
	binderType: PropTypes.string,
	binderZoneLink: PropTypes.string,
};
