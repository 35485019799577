import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

/**
 * Format a json date
 * @param {string} jsonDate The Json date to format
 * @param {string} formatStr The date format
 * @param {string} fallbackStr The fallback string if jsonDate is falsy
 * @returns {string}
 */
const formatJsonDate = (jsonDate, formatStr, fallbackStr = '') =>
	jsonDate ? format(new Date(jsonDate), formatStr, { locale: fr }) : fallbackStr;

export default formatJsonDate;
