import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-type-suggestion-binder.scss';

import { PopupConfirm } from '../../../../../theme';

/**
 * PopupTypeSuggestionBinder component
 * @param {object} props
 * @param {object} props.binder
 * @param {object} props.popupControl
 * @returns {JSX.Element}
 */
const PopupTypeSuggestionBinder = (props) => {
	const { binder = {}, popupControl } = props;
	const {
		type_suggestion: typeSuggestion,
		type_suggestion_status: typeSuggestionStatus,
		type_suggestion_details: typeSuggestionDetails,
	} = binder;
	const { t } = useTranslation();

	const isAnalyseDone = typeSuggestionStatus === 'done';
	const isAnalyseError = typeSuggestionStatus === 'error';

	const addedSheets = typeSuggestionDetails?.filter((sheet) => sheet.type === 'added');
	const deletedSheets = typeSuggestionDetails?.filter((sheet) => sheet.type === 'deleted');
	const modifiedSheets = typeSuggestionDetails?.filter(
		(sheet) => sheet.type === null && sheet.currentPages !== sheet.previousPages
	);

	const generateSheetName = (sheet, showPagesDiff) => {
		return (
			<div className="popup-type-suggestion__line">
				<span className="popup-type-suggestion__bold">{` - ${sheet.id}`}</span>
				{showPagesDiff && (
					<span>
						{` - ${t('binder:popup.type-suggestion.edition-page-1')} ${sheet.currentPages} ${t('binder:popup.type-suggestion.edition-page-2')} ${sheet.previousPages} ${t('binder:popup.type-suggestion.edition-page-3')}`}
					</span>
				)}
			</div>
		);
	};

	return (
		<PopupConfirm
			popupControl={popupControl}
			title={t('binder:popup.type-suggestion.title')}
			ctaConfirmLabel="binder:popup.type-suggestion.button"
			className="popup-type-suggestion-binder"
		>
			<div className="popup-type-suggestion">
				{isAnalyseDone && typeSuggestion === 'rectification' && (
					<>
						<p>{t('binder:popup.type-suggestion.rectification-1')}</p>
						<p>{t('binder:popup.type-suggestion.rectification-2')}</p>
					</>
				)}
				{isAnalyseDone && typeSuggestion === 'edition' && typeSuggestionDetails?.length === 0 && (
					<>
						<p>{t('binder:popup.type-suggestion.not-published-1')}</p>
						<p>{t('binder:popup.type-suggestion.not-published-2')}</p>
					</>
				)}
				{isAnalyseDone && typeSuggestion === 'edition' && typeSuggestionDetails?.length > 0 && (
					<>
						<p>{t('binder:popup.type-suggestion.edition-1')}</p>
						<p>{t('binder:popup.type-suggestion.edition-2')}</p>
						{addedSheets?.length > 0 && (
							<div className="popup-type-suggestion__sheets">
								<p>{t('binder:popup.type-suggestion.edition-add')}</p>
								{addedSheets.map((sheet) => generateSheetName(sheet, false))}
							</div>
						)}
						{deletedSheets?.length > 0 && (
							<div className="popup-type-suggestion__sheets">
								<p>{t('binder:popup.type-suggestion.edition-del')}</p>
								{deletedSheets.map((sheet) => generateSheetName(sheet, false))}
							</div>
						)}
						{modifiedSheets?.length > 0 && (
							<div className="popup-type-suggestion__sheets">
								<p>{t('binder:popup.type-suggestion.edition-change')}</p>
								{modifiedSheets.map((sheet) => generateSheetName(sheet, true))}
							</div>
						)}
					</>
				)}
				{isAnalyseError && <p>{t('binder:popup.type-suggestion.error')}</p>}
			</div>
		</PopupConfirm>
	);
};

PopupTypeSuggestionBinder.propTypes = {
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired,
		visible: PropTypes.bool.isRequired,
	}).isRequired,
	binder: PropTypes.shape({
		type_suggestion: PropTypes.string,
		type_suggestion_status: PropTypes.string,
		type_suggestion_details: PropTypes.array,
	}),
};

export default PopupTypeSuggestionBinder;
