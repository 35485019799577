import React from 'react';

import './loading-page.scss';

import { DigiprocLoader } from '../../';

/**
 * Render a page with a spinner alone
 * @return {JSX.Element}
 * @constructor
 */
const LoadingPage = () => (
	<main className="loading-page">
		<DigiprocLoader className="loading-page__spinner" />
	</main>
);

export default LoadingPage;
