import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './history-change-owner-view.scss';

import { buildUserName } from '../../../../../../shared/utils/build-user-name';
import { IconWrapper } from '../../../../../../theme';
import { ReactComponent as IconOwner } from '../../../../assets/icon-owner.svg';

/**
 * Render HistoryChangeOwnerView component
 * @param {object} props
 * @param {Object} props.task
 * @param {String} props.className
 * @param {boolean} props.passed
 * @returns {JSX.Element}
 */
const HistoryChangeOwnerView = ({ task = {}, className, passed }) => {
	const { t } = useTranslation();

	const initClassName = (baseClassName) =>
		classNames(baseClassName, className, { [`${baseClassName}--passed`]: passed });

	const renderUserLabel = () => {
		const { firstname, lastname, id } = task?.userInfo;
		if (!id) {
			return t('user:user-deleted');
		}
		return buildUserName(firstname, lastname, id);
	};

	return (
		<div className={initClassName('history-change-owner-view')}>
			<IconWrapper className="history-text-task-view__icon" Component={IconOwner} />
			{t('process:history-task.change-owner', { name: renderUserLabel() })}
		</div>
	);
};

export default HistoryChangeOwnerView;

HistoryChangeOwnerView.propTypes = {
	task: PropTypes.shape({
		userInfo: PropTypes.shape({
			firstname: PropTypes.string,
			lastname: PropTypes.string,
			id: PropTypes.string,
		}),
	}),
	className: PropTypes.string,
	passed: PropTypes.bool,
};
