import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'qs';

import {
	addStoredSheetColor,
	addStoredSheetLink,
	getSheetTrainViewStorageContent,
	getUniqueSheetLinks,
	setStoredSheetLinks,
	setStoredSheetProcessLinks,
	sheetReadLinkContextProperties,
} from '../../../../../../shared/utils/sheet-read-utils';
import { getProcessByTechId } from '../../../../../process/process.services';
import { getTrainByTechId } from '../../../../../train/train.services';
import { useSheetContentContext } from '../../../../context/sheet-content-context';
const { sheetLinksVisited, sheetProcessLinks, sheetColors } = sheetReadLinkContextProperties;
const SheetReadContext = createContext({});

/**
 * @typedef SheetLink
 * @prop {string} uri
 * @prop {string} sheetId
 * @prop {string} sheetNumber
 * @prop {string} sheetNumberUnpadded
 * @prop {string} sheetTitle
 * @prop {string} [binderTechId]
 * @prop {string} [color]
 * @prop {number} [jumpToActionPosition]
 * @prop {"man"|"child"|"parent"|"determination"|"m"|"resume"} [sheetType]
 */

/**
 * @typedef SheetLinkContextContent
 * @prop {SheetLink[]} sheetLinksVisited
 * @prop {SheetLink[]} sheetProcessLinks
 * @prop {Object.<string, string>} sheetColors
 * @prop {object} currentProcess
 * @prop {object} currentTrain
 * @prop {object} currentBinder
 */

const SheetReadContextProvider = ({ children }) => {
	/** @type {[SheetLinkContextContent, (content: obj) => void]}  */
	const { processId: primaryProcessId } = useParams();
	const [process, setProcess] = useState({});
	const [train, setTrain] = useState({});
	const [binder, setBinder] = useState({});
	const [content, setContent] = useState(getSheetTrainViewStorageContent() || {});
	const [trainAndProcessLoading, setTrainAndProcessLoading] = useState(true);
	const { pathname, search } = useLocation();
	const { sheetNumber: currentSheetNumber } = useParams();
	const { sheetData = {} } = useSheetContentContext();
	const { processId: secondaryProcessId } =
		qs.parse(search || '', { ignoreQueryPrefix: true }) || {};
	const processId = primaryProcessId || secondaryProcessId;

	/**
	 * Load SheetReadContext stored content
	 */
	const updateContent = (key, value) => {
		setContent((previousContent) => ({ ...previousContent, [key]: value }));
	};

	/**
	 * Update sheet process link array
	 * @param {SheetLink[]} sheetLinks
	 */
	const addSheetProcessLinks = (sheetLinks) => {
		setStoredSheetProcessLinks(sheetLinks);
		updateContent(sheetProcessLinks, sheetLinks);
	};

	/**
	 * Update sheet link array
	 * @param {SheetLink} sheetLink
	 */
	const addSheetLink = (sheetLink) => {
		const uniqueSheetLinks = addStoredSheetLink(sheetLink);
		updateContent(sheetLinksVisited, uniqueSheetLinks);
	};

	/**
	 * prune the sheetlinks following the clicked link
	 * @param {SheetLink[]} oldSheetLinks
	 * @returns {SheetLink[]} unique sheet links
	 */
	const pruneFollowupSheetLinks = useCallback((oldSheetLinks = [], uriToStopAt) => {
		if (!uriToStopAt) return;
		const newSheetLinks = [];
		oldSheetLinks.some((sl) => {
			newSheetLinks.push(sl);
			return sl?.uri === uriToStopAt;
		});

		const uniqueSheetLinks = getUniqueSheetLinks(newSheetLinks, uriToStopAt);
		setStoredSheetLinks(uniqueSheetLinks);
		updateContent(sheetLinksVisited, uniqueSheetLinks);
	}, []);

	// add a sheet color to the map if the matching sheet number is given
	useEffect(() => {
		if (sheetData?.number === currentSheetNumber && !content?.sheetColors?.[pathname]) {
			const colorMap = addStoredSheetColor(pathname, sheetData?.color);
			updateContent(sheetColors, colorMap);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sheetData?.number]);

	// remove links after the one currently selected
	useEffect(() => {
		pruneFollowupSheetLinks(content?.sheetLinksVisited, pathname);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	const loadTrainAndProcess = useCallback(async (processTechId) => {
		setTrainAndProcessLoading(true);
		try {
			const { data: processFound = {} } = await getProcessByTechId(processTechId, {
				extendOwner: false,
				extendMaterial: true,
			});
			setProcess(processFound);
			if (processFound?.train_tech_id) {
				const { data: trainFound = {} } = await getTrainByTechId(processFound?.train_tech_id);
				setTrain(trainFound);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setTrainAndProcessLoading(false);
		}
	}, []);

	const continuousProcessReload = useCallback(async () => {
		if (processId) {
			const { data: processFound = {} } = await getProcessByTechId(processId, {
				extendOwner: false,
				extendMaterial: true,
			});
			setProcess(processFound);
		}
	}, [processId]);

	// reload both train and process once then keep reloading only process
	useEffect(() => {
		if (processId) {
			loadTrainAndProcess(processId);
		}
	}, [loadTrainAndProcess, processId]);

	useEffect(() => {
		const intervalID = setInterval(continuousProcessReload, 5000);
		return () => clearInterval(intervalID);
	}, [continuousProcessReload]);

	return (
		<SheetReadContext.Provider
			value={{
				sheetLinksVisited: content?.sheetLinksVisited || [],
				sheetProcessLinks: content?.sheetProcessLinks || [],
				sheetColors: content?.sheetColors || {},
				currentTrain: train || {},
				currentProcess: process || {},
				currentBinder: binder || {},
				addSheetLink,
				addSheetProcessLinks,
				setProcess,
				setTrain,
				setBinder,
				trainAndProcessLoading,
				loadTrainAndProcess,
			}}
		>
			{children}
		</SheetReadContext.Provider>
	);
};

/**
 * @returns {{
 *  sheetLinksVisited: SheetLink[],
 *  sheetProcessLinks: SheetLink[],
 *  sheetColors: Object.<string, string>,
 *  currentTrain: object,
 *  currentProcess: object,
 *  currentBinder: object,
 *  addSheetLink: (sheetLink: SheetLink) => void,
 *  addSheetProcessLinks: (sheetLinks: SheetLink[]) => void,
 *  setTrain: (train: object) => void,
 *  setProcess: (process: object) => void,
 *  setBinder: (Binder: object) => void,
 *  trainAndProcessLoading: boolean
 * }}
 */
const useSheetReadContext = () => useContext(SheetReadContext);

export { SheetReadContextProvider, useSheetReadContext };

SheetReadContextProvider.propTypes = {
	children: PropTypes.node,
};
