import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './multi-link-image-form.scss';

import { useDebouncedAction } from '../../../../../../../shared';
import { useSheetContentContext } from '../../../../../context/sheet-content-context';
import { deleteImage, uploadImage } from '../../../../../image.services';

import ImageFormFileInput from './image-form-file-input/image-form-file-input';

const MultipleLinkImageForm = ({ className, content, position }) => {
	const imageInputName = 'imageId';

	const { sheetId, binderId } = useParams();
	const { t } = useTranslation();

	const { setCurrentNodeContent, getCurrentActive, saveSheetContent, updateDelay } =
		useSheetContentContext();
	const debouncedSetCurrentNodeContent = useDebouncedAction(setCurrentNodeContent, updateDelay);
	const [image, setImage] = useState(content?.image || {});

	// Update Only image field in node content (keep other fields unchanged)
	const updateActiveNodeContent = (newImage) => {
		setImage(newImage);
		const { imageId, error } = newImage;

		const activeNode = getCurrentActive();
		const newActiveNodeContent = { ...activeNode?.content };
		const activeItem = newActiveNodeContent?.items?.[position];
		if (!error && activeItem) {
			activeItem.image = { imageId };
			debouncedSetCurrentNodeContent(newActiveNodeContent);
		}
	};

	// Update a single image block
	const updateImage = (name, value) => {
		const newImage = { ...image };
		newImage[name] = value;
		updateActiveNodeContent(newImage);
	};

	const handleDeleteImage = async () => {
		try {
			if (image?.imageId) {
				// An image is loaded
				const response = await deleteImage(image?.imageId);
				const { success } = response?.data;
				// Reset imageId
				updateActiveNodeContent({ imageId: false, file: false });
				return { success };
			} else {
				// No image to remove
				return { success: true };
			}
		} catch (error) {
			updateImage('error', t('sheet:sheet-details.image-form.network-error-delete-image'));
		}
	};

	const handleContextFormSync = () => {
		const activeTemplate = getCurrentActive();
		// init current content node and set history if it's a new template
		const newFormImage = activeTemplate?.content?.items?.[position]?.image;
		if (newFormImage) {
			// Load images from sheet data
			setImage(newFormImage);
		} else {
			setImage({});
		}
	};

	const handleUploadImage = () => async () => {
		const { file: imageFile, imageId, error: imageError } = image;
		if (imageFile && !imageId && !imageError) {
			try {
				updateImage('error', false);
				// Save the sheet here to ensure there is no orphan image left by the image cleanup process when sheet content is updated
				await saveSheetContent(binderId, sheetId);
				const response = await uploadImage(imageFile);
				const { success, id } = response?.data;
				if (success) {
					updateImage(imageInputName, id);
				}
			} catch (error) {
				updateImage('file', false);
				if (error?.response?.status === 400 || error?.response?.status === 413) {
					updateImage('error', t('sheet:sheet-details.image-form.invalid-format'));
				} else {
					updateImage('error', t('sheet:sheet-details.image-form.network-error'));
				}
				console.error(error);
			}
		}
	};

	const resetOnError = () => {
		if (image.error) {
			setImage({});
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(handleUploadImage(), [image?.file]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(handleContextFormSync, [content?.image]);

	return (
		<div className={classNames('multi-link-image-form', className)}>
			<span className="multi-link-image-form__label">
				{t('sheet:sheet-details.image-form.image-field-label')}
			</span>
			<span onClick={resetOnError}>
				<ImageFormFileInput
					className="image-form-row__image"
					value={image?.file}
					imageId={image?.imageId}
					errorHelperText={image?.error}
					onDelete={handleDeleteImage}
					onChange={(event) => {
						updateImage('file', event.target.files[0]);
					}}
				/>
			</span>
		</div>
	);
};

export default MultipleLinkImageForm;

MultipleLinkImageForm.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
	position: PropTypes.number,
};
