import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './delete-template-button.scss';

import { usePopup } from '../../../../../../../shared';
import { IconWrapper } from '../../../../../../../theme';
import { IconTrash } from '../../../../../../../theme/assets/img';
import { useSheetContentContext } from '../../../../../context/sheet-content-context';
import PopupStopDeleteStepTemplate from '../../../../../pages/sheet-detail/components/popup-delete-template/components/popup-stop-delete-step-template';
import PopupDeleteTemplate from '../../../../../pages/sheet-detail/components/popup-delete-template/popup-delete-template';
import { getDisplayDeletePopup } from '../../../../../pages/sheet-detail/utils/handle-session-storage';
import useStepTemplate from '../../../../../pages/sheet-detail/utils/use-step-template';

const DeleteTemplateButton = ({ template, className, location }) => {
	const popupDeleteTemplateControl = usePopup();
	const popupStopDeleteStepTemplateControl = usePopup();
	const { deleteStepTemplate } = useStepTemplate();
	const { nodeList } = useSheetContentContext();
	const { t } = useTranslation();

	const handleDeleteTemplate = (e) => {
		//find if there is simpleLink with the stepId we want to delete
		const stepInSimpleLinkInNodeListRoot = nodeList.find(
			(templateItem) => templateItem?.content?.step === template.id
		);

		//filter templates that are not questions => keep only the questions templates
		const noQuestionTemplates = (templateItem) =>
			templateItem?.content?.yes || templateItem?.content?.no;

		//get stepId from a question template
		const getStepId = (templateItem, questionType) => {
			return templateItem?.content?.[questionType]['end-item']?.content?.step;
		};

		//return an array of step-id if question template contains simpleLink with step
		const questionWithSimpleLinkContainingStep = nodeList
			.filter(noQuestionTemplates)
			.filter((templateItem) => getStepId(templateItem, 'yes') || getStepId(templateItem, 'no'))
			.map((templateItem) => {
				if (getStepId(templateItem, 'yes')) {
					return getStepId(templateItem, 'yes');
				}
				return getStepId(templateItem, 'no');
			});
		const stepInSimpleLinkInQuestion = questionWithSimpleLinkContainingStep.find(
			(templateItem) => templateItem === template.id
		);

		//find if the step to delete is linked to a simpleLink in nodeListRoot or question template
		const isStepLinkedToSimpleLink = stepInSimpleLinkInNodeListRoot || stepInSimpleLinkInQuestion;

		if (template.type === 'step' && isStepLinkedToSimpleLink) {
			popupStopDeleteStepTemplateControl.show();
		} else if (getDisplayDeletePopup()) {
			// prevent handleClick parent (wrapper)
			e.stopPropagation();
			deleteStepTemplate(location);
		} else {
			popupDeleteTemplateControl.show();
		}
	};

	return (
		<>
			<PopupStopDeleteStepTemplate popupControl={popupStopDeleteStepTemplateControl} />
			<PopupDeleteTemplate popupControl={popupDeleteTemplateControl} location={location} />
			<button
				className={classNames(className, 'delete-template-button')}
				type="button"
				onClick={handleDeleteTemplate}
			>
				<IconWrapper
					className="delete-template-button__icon"
					Component={IconTrash}
					tooltip={t('sheet:sheet-details.tooltip.delete')}
				/>
			</button>
		</>
	);
};

export default DeleteTemplateButton;

DeleteTemplateButton.propTypes = {
	template: PropTypes.object.isRequired,
	className: PropTypes.string,
	location: PropTypes.object.isRequired,
};
