import { requiredFieldValidator } from '../../../../../shared';

/**
 * @typedef {{title: string, origin: string, destination: string, tech_id: string}} SignalType
 */

/**
 * Check if the provided signal already exists in signalList
 * @param newSignal
 * @param {string} newSignal.title
 * @param {string} newSignal.origin
 * @param {string} newSignal.destination
 * @param {SignalType[]} signalList
 * @returns {string[]}
 */
const validateSignalNotExists = (newSignal, signalList = []) => {
	const {
		title: newTitle = '',
		origin: newOrigin = '',
		destination: newDestination = '',
		tech_id: newTechId,
	} = newSignal;

	const duplicateSignal = signalList?.find((signal) => {
		const { title = '', origin = '', destination = '', tech_id: techId } = signal;

		const isSameTitle = title.trim().toLowerCase() === newTitle.trim().toLowerCase();
		const isSameOrigin = origin.trim().toLowerCase() === newOrigin.trim().toLowerCase();
		const isSameDestination =
			destination.trim().toLowerCase() === newDestination.trim().toLowerCase();
		const isCurrentSignal = newTechId !== techId;

		return isSameTitle && isSameOrigin && isSameDestination && isCurrentSignal;
	});

	return duplicateSignal ? ['invalid'] : [];
};

/**
 * Validate a signal form
 * @param form
 * @param {string} form.title
 * @param {string} form.origin
 * @param {string} form.destination
 * @param {SignalType[]} signalList
 * @returns {{origin: string[], destination: string[], global: string[], title: string[]}}
 */
const validateSignalForm = (form, signalList) => ({
	title: requiredFieldValidator(form.title),
	origin: requiredFieldValidator(form.origin),
	destination: requiredFieldValidator(form.destination),
	global: validateSignalNotExists(form, signalList),
});

export { validateSignalForm, validateSignalNotExists };
