import qs from 'qs';
import { v4 as uuid } from 'uuid';

import { http, httpNoErrorRedirect } from '../../config';
import { getNetworkState, offlineUser } from '../../config/offline';
import {
	getEntity,
	getEntityProcessList,
	setEntity,
} from '../../shared/cache-access/entity/entity-utils';

import {
	getProcessStatus,
	setupHistoryBeforeSave,
} from './pages/spr-run-process/utils/task-history-utils';
import { saveProcess } from './utils/localstorage-process';
import {
	compareByDate,
	getOwnersFromProcessHistory,
	orderByHistoryLastUpdated,
} from './utils/process-services-utils';

const processRootUrl = '/process';

/**
 * @typedef TaskHistory
 * @prop {Object} binder
 * @prop {Object} sheet
 * @prop {string} id
 * @prop {string} type
 * @prop {string} [choice]
 * @prop {Object} [content]
 * @prop {Date} finishedAt
 */

/**
 * @typedef {object} ResumeConditions
 * @prop {"normal" | "direct"} position_supply
 * @prop {"travelers"| "hlp" | "secours"} resume
 * @prop {number} engine_units
 * @prop {number} bogie_car_units
 * @prop {object} last_update
 */

/**
 * @typedef {'in-progress' | 'aborted' | 'closed'} ProcessStatus
 */

/**
 * @typedef {object} Process
 * @prop {string} binder_tech_id
 * @prop {Date} created_at
 * @prop {string} description
 * @prop {Date} ended_at
 * @prop {object[]} history
 * @prop {number} line
 * @prop {string[]} owners_history
 * @prop {object} resume_conditions:
 * @prop {string} sheet_tech_id
 * @prop {string} started_at
 * @prop {string} tech_id
 * @prop {string} track
 * @prop {'train' | 'pcc'} type
 * @prop {ProcessStatus} status
 * @prop {string} [material_label]
 * @prop {string} [material_tech_id]
 * @prop {string} [train_id]
 * @prop {string} [train_tech_id]
 * @prop {string} [position_info]
 * @prop {'platform' | 'half-platform' | 'interstation' | 'other'} [position_type]
 * @prop {string} [link]
 * @prop {string} [label]
 * @prop {ResumeConditions} resume_conditions
 */

/**
 * Get all themes contained in the specified binder
 * @param {string} binderTechId The theme's binder technical id
 * @return {{status: ProcessStatus, type: 'train' | 'pcc'}}
 */
const getProcessMetadata = (process = {}) => {
	const { status = null, type = null } = process;
	return {
		...(status && { status }),
		...(type && { type }),
	};
};

/**
 * Create a new process instance
 * @param {Process} process
 * @param {{ isCurrentOwner: boolean }} params
 * @param {TaskHistory[]} process.history
 */
const createProcess = async (process, params) => {
	const newTechId = uuid();
	const { line, ...restParams } = params;
	const newProcess = { ...process, tech_id: newTechId };

	return http.post(processRootUrl, newProcess, {
		params: restParams,
		handleCacheSync: async (config, data, metadata) => {
			// only save created train process in cache with provided line param
			if (line && data?.type === 'train') {
				let storedProcess;
				const { ref } = metadata;
				try {
					storedProcess = await getEntity('process', ref);
				} catch {
					storedProcess = { data: { status: 'in-progress' } };
				}

				const newData = { created_at: new Date(), ...storedProcess.data, ...newProcess };

				if (!newData?.started_at || typeof newData?.started_at === 'boolean') {
					newData.started_at = new Date();
				}
				const newMetadata = { ...metadata, ...getProcessMetadata(newData) };

				const networkState = getNetworkState();

				// Set owner to null when creating offline process
				if (networkState === 'offline') {
					newData.owner = offlineUser.tech_id;
					newData.owners_history = getOwnersFromProcessHistory(newData?.history);
				}

				await setEntity('process', line, newTechId, newData, newMetadata);
			}
		},
		handleOfflineResponse: async () => {
			const storedProcess = await getEntity('process', newTechId);

			return storedProcess?.data;
		},
		ref: newTechId,
		line,
		entity: 'process',
	});
};

/**
 * Store the process updated to the localStorage for the offline mode
 * @param {Process} processResult
 */
const storeProcess = (processResult) => {
	saveProcess([processResult]);
};

/**
 * Update an existing process instance
 * @param {string} processTechId The process technical id
 * @param {{
 *     [history]: TaskHistory[],
 *     [resume_conditions]: ResumeConditions,
 *     [train_tech_id]: string
 * }} process The process's data
 * @param {{ [action]: 'startProcessTrain' | 'changeOwner' | 'updateResumeConditions', [line]: number | '' }} params
 */
const updateProcess = async (processTechId, process, params = {}) => {
	const processUpdateData = { ...process };
	if (process?.history) {
		processUpdateData.status = processUpdateData.status || getProcessStatus(process?.history);
		processUpdateData.history = setupHistoryBeforeSave(process?.history);
	}
	processUpdateData.ended_at = processUpdateData.status === 'closed';

	const { line, ...restParams } = params;

	const makeRequest = async () => {
		const response = await httpNoErrorRedirect.put(
			`${processRootUrl}/${processTechId}`,
			processUpdateData,
			{
				params: restParams,
				handleOfflineResponse: async () => {
					const storedProcess = await getEntity('process', processTechId);
					return [storedProcess.data];
				},
				handleCacheSync: async (config, data, metadata) => {
					const processFromResponse = Array.isArray(data) ? data[0] : data;
					// only save updated train process in cache
					if (processFromResponse.type !== 'pcc') {
						const { ref } = metadata;
						let storedProcess;
						try {
							storedProcess = await getEntity('process', ref);
						} catch {
							storedProcess = {
								data: { status: 'in-progress' },
							};
						}
						const newData = { ...storedProcess.data, ...processFromResponse };
						const newMetadata = { ...metadata, ...getProcessMetadata(newData) };

						if (newData?.ended_at && typeof newData?.ended_at === 'boolean') {
							newData.ended_at = new Date();
						}

						const networkState = getNetworkState();

						// Set owner to null when updating offline process
						if (networkState === 'offline') {
							newData.owner = offlineUser.tech_id;
							newData.owners_history = getOwnersFromProcessHistory(newData?.history);
						}
						await setEntity('process', line, ref, newData, newMetadata);
					}
				},
				ref: processTechId,
				line,
				entity: 'process',
			}
		);
		storeProcess(response.data[0]);
		return response;
	};

	// If the process is closed, we need to wait for the server to update the previous status
	if (processUpdateData.status === 'closed') {
		return new Promise((resolve) => {
			setTimeout(async () => {
				const response = await makeRequest();
				resolve(response);
			}, 1000);
		});
	}

	return makeRequest();
};

/**
 * Get a process instance by technical id
 * @param {string} processTechId The process technical id
 * @param {{extendOwner: boolean, extendMaterial: boolean, extendTrain: boolean}} options Set to option true to include details in the response
 * @return {Promise<import("axios").AxiosResponse<Process>>}
 */
const getProcessByTechId = async (processTechId, options = {}) => {
	// Check if requested data is pending in cache
	let cachedProcess = {};
	try {
		cachedProcess = await getEntity('process', processTechId, false, options);
	} catch {
		/* ignore error */
	}

	if (cachedProcess?.metadata?.isPending) {
		return { ...cachedProcess, fromCache: true };
	}

	return http.get(`${processRootUrl}/${processTechId}`, {
		params: options,
		handleOfflineResponse: async () => {
			const storedProcess = await getEntity('process', processTechId, false, options);
			return storedProcess.data;
		},
	});
};

/**
 * Merge api and cache process lists
 * @param {Process[]} apiProcessList
 * @param {Process[]} storedProcessList
 * @param {Object} currentUser
 * @return {Process[]>}
 */
const mergeOfflineOnlineProcess = (apiProcessList, storedProcessList = [], currentUser) => {
	const apiProcessTechIdList = apiProcessList.map((process) => process.tech_id);

	const filteredStoredProcessList =
		// Retrieve the stored processes that are not synchronised with API
		storedProcessList
			?.filter((process) => {
				const isApiDuplicate = apiProcessTechIdList.includes(process.tech_id);
				return !isApiDuplicate || process?.metadata?.isPending;
			})
			// Remove the stored processes that not belong to current user (can occur when multiple users made offline processes on the same device)
			.filter((process) => {
				const ownerList = [process.owner, ...(process.owners_history || [])];
				return ownerList.includes(offlineUser.tech_id) || ownerList.includes(currentUser.tech_id);
			});

	const storedProcessIds = filteredStoredProcessList?.map((cacheProcess) => cacheProcess.tech_id);
	// Remove api processes that have pending update in cache
	const filteredApiProcess = apiProcessList.filter(
		(apiProcess) => !storedProcessIds.includes(apiProcess.tech_id)
	);
	// Return the filtered API and stored processes
	return [...filteredApiProcess, ...filteredStoredProcessList];
};
/**
 * Get a list of processes
 * @param {{
 * status: ProcessStatus
 * orderBy: string
 * orderSort: 'DESC' | 'ASC'
 * filterSlFlag: boolean
 * }} params List of URL params to use in the request
 * @param {Object} currentUser
 * @return {Promise<import("axios").AxiosResponse<Process[]>>}
 */
const getProcessList = async (params = {}, currentUser = {}) => {
	const { associatedLine = null, status = null, type = null, orderBy, orderFlag } = params;

	// Get process list from cache
	const entityFilter = {
		...(associatedLine && { line: associatedLine }),
		...(status && { status }),
		...(type && { type }),
	};
	const storedProcessList = await getEntityProcessList(entityFilter, true);
	const formattedStoredProcesses = storedProcessList?.map((processCacheEntry) => ({
		...processCacheEntry.data,
		metadata: processCacheEntry.metadata,
	}));

	const response = await http.get(processRootUrl, {
		params,
		paramsSerializer: (queryParams) => qs.stringify(queryParams, { arrayFormat: 'repeat' }),
		handleOfflineResponse: () => {
			return formattedStoredProcesses;
		},
	});

	const networkState = getNetworkState();
	let result = response?.data || [];
	saveProcess(result);

	// Merge cache and server processes for online list
	if (networkState === 'online') {
		result = mergeOfflineOnlineProcess(result, formattedStoredProcesses, currentUser);
	}

	// Sort process list
	if (orderBy === 'started_at' || orderBy === 'created_at') {
		result.sort(compareByDate(orderBy));
	} else if (orderFlag === 'lastUpdated') {
		result = orderByHistoryLastUpdated(result);
	}

	return { data: result };
};

export { createProcess, getProcessByTechId, getProcessList, updateProcess };
