import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './error-message.scss';

/**
 * Render an error message
 * @param {string} className
 * @param {JSX.Element} children
 * @returns {JSX.Element}
 */
const ErrorMessage = ({ className, children }) => (
	<div className={classnames('error-message', className)}>{children}</div>
);

export default ErrorMessage;

ErrorMessage.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
