import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './multi-link-block.scss';

import { IconWrapper, Textarea, TextfieldAutocomplete } from '../../../../../../../../../../theme';
import { ReactComponent as IconTrash } from '../../../../../../../../../../theme/assets/img/icon-trash.svg';
import MultipleLinkImageForm from '../../../../image-form/multiple-link-image-form';
import MultiLinkCategory from '../multi-link-category/multi-link-category';

/**
 * Render the MultiLinkBlock component
 * @param {object} props
 * @returns {JSX.Element}
 * @constructor
 */
const MultiLinkBlock = ({
	position,
	block,
	showDelete,
	onDelete,
	onChange,
	sheetList,
	hasFocus,
	isTrainBinder,
	hasImage,
}) => {
	const { t } = useTranslation();
	const [content, setContent] = useState(block);

	const handleBlockChange = (event, setHistory = false) => {
		const { name, value } = event.target;
		setContent((previousContent) => {
			const newBlockContent = { ...previousContent, [name]: value };
			triggerParentChange(newBlockContent, setHistory);
			return newBlockContent;
		});
	};

	const triggerParentChange = (blockContent, setHistory) => {
		if (!blockContent?.deleted) {
			onChange(blockContent, setHistory);
		}
	};

	const getSuggestions = async (event = null) => {
		const data = (await searchExistingSheetByNumber(event)) || [];
		return data.map(renderLabelAutoCompletion);
	};

	const searchExistingSheetByNumber = async (value) => {
		if (value) {
			return sheetList.filter(
				(sheet) => sheet.number_search?.includes(value) || sheet.titleRaw?.includes(value)
			);
		} else {
			return sheetList;
		}
	};

	const renderLabelAutoCompletion = (sheet) => {
		const { number_search, titleRaw, color, type, binder_color: binderColor } = sheet;
		const lines = [{ type: 'label', text: `${number_search.toUpperCase()} - ${titleRaw}` }];
		const label = { className: 'multiLink-template-form__autocomplete', lines };
		return { value: number_search, label, color, type, binderColor };
	};

	const handleChoiceSheet = (info) => {
		handleBlockChange({ target: { name: 'sheetNumber', value: info.value } });
		handleBlockChange({ target: { name: 'sheetColor', value: info.color } });
		handleBlockChange({ target: { name: 'binderColor', value: info.binderColor } });
		handleBlockChange({ target: { name: 'sheetType', value: info.type } });
		// Mark selected sheet number as valid
		handleBlockChange({ target: { name: 'sheetNumberSelected', value: true } }, true);
	};

	const handleSearchSheet = (event) => {
		handleBlockChange(event);
		// Reset sheetNumber validation on search
		handleBlockChange({ target: { name: 'sheetNumberSelected', value: false } });
	};

	const formReload = () => {
		setContent((previousContent) => {
			const isChange = JSON.stringify(previousContent) !== JSON.stringify(block);
			return isChange ? block : previousContent;
		});
	};

	useEffect(formReload, [block]);

	return (
		<div className="multi-link-block">
			<MultiLinkCategory onChange={handleBlockChange} category={content.category} />
			<div className="multi-link-block__frame">
				<div className="multi-link-block__head">
					<span className="multi-link-block__badge">{position + 1}</span>
					{showDelete && (
						<button type="button" onClick={onDelete}>
							<IconWrapper
								className="multi-link-block__delete__icon"
								Component={IconTrash}
								tooltip={t('sheet:sheet-details.tooltip.delete')}
							/>
						</button>
					)}
				</div>
				<div className="multi-link-block__field">
					<label className="field__label">
						{position === 0
							? t('sheet:sheet-details.template.multipleLink.label-singular')
							: t('sheet:sheet-details.template.multipleLink.label-plural', {
									number: position + 1,
								})}
					</label>
					<Textarea
						hasFocus={!!hasFocus}
						className="field__input"
						name="text"
						value={content.text}
						onChange={handleBlockChange}
						onBlur={() => triggerParentChange(content, true)}
					/>
				</div>
				<div className="multi-link-block__field">
					<label className="field__label">
						{t('sheet:sheet-details.template.multipleLink.label-fiche-number')}
					</label>
					<TextfieldAutocomplete
						name="sheetNumber"
						className="field__input"
						value={content.sheetNumber}
						handleChange={handleSearchSheet}
						getSuggestions={getSuggestions}
						selectedValue={handleChoiceSheet}
					/>
				</div>
				{isTrainBinder && hasImage && (
					<MultipleLinkImageForm content={content} position={position} />
				)}
				{content.sheetNumber && !content.sheetNumberSelected && (
					<div className="multi-link-block__message">
						<span>{t('sheet:sheet-details.template.multipleLink.error-message')}</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default MultiLinkBlock;

MultiLinkBlock.propTypes = {
	hasFocus: PropTypes.bool,
	position: PropTypes.number.isRequired,
	block: PropTypes.shape({
		sheetNumber: PropTypes.string,
		text: PropTypes.string,
		category: PropTypes.string,
	}).isRequired,
	showDelete: PropTypes.bool.isRequired,
	onDelete: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	sheetList: PropTypes.arrayOf(
		PropTypes.shape({
			number_search: PropTypes.string,
			titleRaw: PropTypes.string,
		})
	).isRequired,
	isTrainBinder: PropTypes.bool,
	hasImage: PropTypes.bool,
};
