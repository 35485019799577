import React from 'react';
import PropTypes from 'prop-types';

import './multiple-link-task-view.scss';

import { IconWrapper } from '../../../../../../theme';
import { SheetType } from '../../../../../sheet';
import resolveSheetColor from '../../../../../sheet/utils/resolve-sheet-color';
import { IconReturn } from '../../../../assets';
import { useRunProcessContext } from '../../../../components/context-run-process/context-run-process';

/**
 * View of a multiple link task with navigation button to next sheets
 * @param {object} props
 * @returns {JSX.Element}
 */
const MultipleLinkTaskView = ({ task = {}, previousTaskInfo = {} }) => {
	const { choice: previousChoice } = previousTaskInfo;

	const { currentSheetData, handleMultipleLinkTaskAnswer } = useRunProcessContext();

	const generateLinks = (choice) => {
		const { text, category, sheetNumber, sheetColor: color, sheetType } = choice;
		const isPreviouslySelected =
			previousChoice?.sheetNumber === sheetNumber && previousChoice?.text === text;
		const sheetColor = resolveSheetColor(color, currentSheetData?.binder_color);

		return (
			<>
				{category && <span className="multiple-link-task__category">{category}</span>}
				<li key={sheetNumber} className="multiple-link-task__item">
					<button
						type="button"
						className="multiple-link-task__link"
						key={sheetNumber}
						onClick={() => handleMultipleLinkTaskAnswer(task, choice)}
					>
						<span className="multiple-link-task__description">{text}</span>
						<SheetType
							className="multiple-link-task__sheet"
							type={sheetType}
							sheetNumber={sheetNumber}
							color={sheetColor}
						/>
						{isPreviouslySelected && (
							<IconWrapper
								className="multiple-link-task__item__icon-return"
								Component={IconReturn}
							/>
						)}
					</button>
				</li>
			</>
		);
	};

	return <ul className="multiple-link-task">{task?.content?.items.map(generateLinks)}</ul>;
};

export default MultipleLinkTaskView;

MultipleLinkTaskView.propTypes = {
	task: PropTypes.object,
	previousTaskInfo: PropTypes.object,
};
