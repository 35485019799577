import React from 'react';
import PropTypes from 'prop-types';

import './validated-substep-card.scss';

import { Button } from '../../../../../../theme';
import { IconBackArrow, IconCheckWithCircle } from '../../../../../../theme/assets/img';

const ValidatedSubstepCard = ({ title, backToSubstep }) => {
	return (
		<div className="validated-substep-card">
			<div className="validated-substep-card__wrapper">
				<Button
					type="button"
					variant="minimal"
					icon={IconBackArrow}
					iconBig={true}
					className="validated-substep-card__wrapper__button"
					onClick={() => backToSubstep()}
				/>
				<span className="validated-substep-card__wrapper__title">{title}</span>
			</div>
			<IconCheckWithCircle
				role="img"
				aria-hidden="true"
				focusable="false"
				className="validated-substep-card__wrapper__icon"
			/>
		</div>
	);
};

export default ValidatedSubstepCard;

ValidatedSubstepCard.propTypes = {
	title: PropTypes.string,
	backToSubstep: PropTypes.func,
};
