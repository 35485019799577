import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-add-user.scss';

import { useDebouncedAction } from '../../../../../../shared';
import { Button, ErrorMessage, PopupDefault } from '../../../../../../theme';
import { FormUser } from '../../../../index';
import { createUser, fetchUserById } from '../../../../user.services';
import validateUserForm from '../../../../utils/validate-user-form';

/**
 * PopupControl object
 * @typedef {object} PopupControl
 * @prop {boolean} visible - is popup visible
 * @prop {function} setVisible - set is popup visible
 * @prop {function} show - show popup
 * @prop {function} hide - hide popup
 */

/**
 * popup to add user
 * @param {object} props
 * @param {PopupControl} props.popupControl
 * @returns {JSX.Element}
 */
const PopupAddUser = ({ onSubmitSuccess = () => {}, popupControl }) => {
	const { t } = useTranslation();
	const [formUser, setFormUser] = useState({});
	const [isUserExist, setIsUserExist] = useState(false);
	const [networkError, setNetworkError] = useState(false);
	const [formValid, setFormValid] = useState(false);
	const [idValidation, setIdValidation] = useState();

	const reInitForm = () => {
		setFormUser({});
		setNetworkError(false);
	};

	const requestUserById = useDebouncedAction((formUserId) => {
		if (formUserId) {
			fetchUserById(formUserId)
				.then((response) => {
					setNetworkError(false);
					const userFoundWithSameId = response?.data;
					setIsUserExist(!!userFoundWithSameId);
				})
				.catch(() => {
					setNetworkError(true);
				});
		}
	}, 500);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!isUserExist) {
			reInitForm();
			createUser(formUser)
				.then(popupControl.hide)
				.then(onSubmitSuccess)
				.catch((error) => {
					throw error;
				});
		}
	};

	const handleRoleAndAssociatedLine = (selectedRole, calculatedLines) => {
		const newFormUser = { ...formUser, role: selectedRole, associated_line: calculatedLines };
		setFormUser(newFormUser);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		const newFormUser = { ...formUser, [name]: value };
		setFormUser(newFormUser);
	};

	const handleLineChange = (selectedLines) => {
		// sort selectedLines before send form to the API
		const sortedSelectedLines = selectedLines.sort(
			(selectedLine1, selectedLine2) => selectedLine1 - selectedLine2
		);

		setFormUser((oldUser) => ({ ...oldUser, associated_line: sortedSelectedLines }));
	};

	const formValidation = () => {
		const { isValid, idValidationLabelKey } = validateUserForm(formUser, true);
		setFormValid(isValid);
		setIdValidation(t(idValidationLabelKey));
	};

	const getUserById = () => requestUserById(formUser.id);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(getUserById, [formUser.id]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(formValidation, [formUser]);

	return (
		<PopupDefault
			popupControl={popupControl}
			title={t('user:popup.create-user.title')}
			onClose={reInitForm}
		>
			<form className="popup-create-user" onSubmit={handleSubmit}>
				<div className="popup-create-user__form-wrapper">
					<FormUser
						form={formUser}
						onChange={handleChange}
						onRoleChange={handleRoleAndAssociatedLine}
						onLineChange={handleLineChange}
						isUserExist={isUserExist}
						idValidation={idValidation}
						create
					/>
				</div>
				<div className="popup-create-user__controller">
					<Button
						variant="primary"
						className="popup-create-user__submit"
						type="submit"
						disabled={!formValid || isUserExist}
						label={t('user:popup.create-user.submit')}
					/>
				</div>
				{networkError && (
					<ErrorMessage className="popup-create-user__network-error">
						{t('error.default')}
					</ErrorMessage>
				)}
			</form>
		</PopupDefault>
	);
};

export default PopupAddUser;

PopupAddUser.propTypes = {
	onSubmitSuccess: PropTypes.func,
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired,
	}).isRequired,
};
