import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { LoadWrapper } from '../../../../../../../../shared';
import SheetListItem from '../binder-sheet-list-item/binder-sheet-list-item';
import CreateSheetButton from '../create-sheet-button/create-sheet-button';

/**
 * Render the a sheet list on the binder detail page
 * @param {object} props
 * @param {object[]} props.sheets
 * @param {object} props.analysisResult
 * @param {object} props.binder
 * @param {boolean} props.loading
 * @param {function} props.showCreateSheetPopup
 * @returns {JSX.Element}
 */
const BinderSheetListContent = ({
	sheets = [],
	analysisResult = {},
	binder,
	loading,
	showCreateSheetPopup,
	defaultColor,
}) => {
	const isSheetListEmpty = sheets.length === 0;
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<LoadWrapper loading={loading}>
			{isSheetListEmpty && binder?.status === 'work-in-progress' && (
				<div className="binder-sheet-sheet-list__empty">
					<span className="empty__text">
						{t('binder:binder-detail.sheet-list.create-first-sheet', {
							id: binder?.id?.toUpperCase(),
						})}
					</span>
					<CreateSheetButton onClick={showCreateSheetPopup} />
				</div>
			)}
			<ul className="binder-sheet-list-content">
				{!isSheetListEmpty && (
					<li className="list__head-row">
						<span className="list__head-cell list__head-cell--number">
							{t('binder:binder-detail.sheet-list.head.number')}
						</span>
						<span className="list__head-cell list__head-cell--title">
							{t('binder:binder-detail.sheet-list.head.title')}
						</span>
						<span className="list__head-cell list__head-cell--update">
							{t('binder:binder-detail.sheet-list.head.test')}
						</span>
						<span className="list__head-cell list__head-cell--update">
							{t('binder:binder-detail.sheet-list.head.change')}
						</span>
						<span className="list__head-cell">
							{t('binder:binder-detail.sheet-list.head.change-date')}
						</span>
					</li>
				)}
				{!isSheetListEmpty &&
					sheets.map((sheet) => (
						<SheetListItem
							key={sheet.number}
							sheet={sheet}
							binder={binder}
							defaultColor={defaultColor}
							analysisResult={analysisResult}
							history={history}
						/>
					))}
			</ul>
		</LoadWrapper>
	);
};

export default BinderSheetListContent;

BinderSheetListContent.propTypes = {
	sheets: PropTypes.arrayOf(
		PropTypes.shape({
			number: PropTypes.string.isRequired,
			sheet_id: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired,
			color: PropTypes.string.isRequired,
			title: PropTypes.object.isRequired,
			analysis_result: PropTypes.string,
			status: PropTypes.string.isRequired,
			last_change_content_at: PropTypes.string.isRequired,
			editor: PropTypes.string,
			editor_firstname: PropTypes.string,
			editor_lastname: PropTypes.string,
			editor_id: PropTypes.string,
		})
	),
	analysisResult: PropTypes.shape({
		sheetsWarning: PropTypes.arrayOf(PropTypes.string),
	}),
	binder: PropTypes.shape({
		id: PropTypes.string,
		status: PropTypes.string.isRequired,
		tech_id: PropTypes.string.isRequired,
	}).isRequired,
	defaultColor: PropTypes.string,
	loading: PropTypes.bool,
	showCreateSheetPopup: PropTypes.func.isRequired,
};
