import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const NetworkSummary = ({ networkInfo, isProcessRecap }) => {
	const { t, i18n } = useTranslation();

	const isNetwork = Object.entries(networkInfo)[1] || [];
	const networkInfoList = Object.entries(networkInfo).slice(2);

	return (
		<div className={'rer-resume-summary__substep'}>
			<span
				className={classNames('rer-resume-summary__substep__title', {
					'rer-resume-summary__substep__title--lighter': isProcessRecap,
				})}
			>
				{t(`process:process-rer-resume.resume-rer.substep.${isNetwork?.[0]}`)} :
			</span>
			<span
				className={classNames('rer-resume-summary__substep__value', {
					'rer-resume-summary__substep__value--lighter': isProcessRecap,
				})}
			>
				{t(`process:process-rer-resume.resume-rer.${isNetwork?.[1]}`)}
			</span>
			{networkInfoList.map(([key, value]) => {
				const isTranslatable = i18n.exists(`process:process-rer-resume.resume-rer.${value}`);
				return (
					<>
						{isNetwork?.[1] === 'yes' && value && (
							<div className={'rer-resume-summary__substep--secondary'}>
								<span
									className={classNames('rer-resume-summary__substep__title--secondary', {
										'rer-resume-summary__substep__title--secondary--lighter': isProcessRecap,
									})}
								>
									{t(`process:process-rer-resume.resume-rer.${key}`)} :
								</span>
								<span
									className={classNames('rer-resume-summary__substep__value', {
										'rer-resume-summary__substep__value--lighter': isProcessRecap,
									})}
								>
									{i18n.exists(`process:process-rer-resume.resume-rer.${value}`)}
									{isTranslatable ? t(`process:process-rer-resume.resume-rer.${value}`) : value}
								</span>
							</div>
						)}
					</>
				);
			})}
		</div>
	);
};

export default NetworkSummary;

NetworkSummary.propTypes = {
	networkInfo: PropTypes.object,
	isProcessRecap: PropTypes.bool,
};
