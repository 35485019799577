import { useCallback, useContext } from 'react';

import { unpadStartNumberInside } from '../../../shared';
import { SheetContentContext } from '../context/sheet-content-context';

/**
 * reload data and content in context
 * @callback setSheetDataAndContentCallback
 * @param {object} sheet
 */

/**
 * reload sheet data in sheet content context
 * @callback reloadSheetData
 * @param {{binderId: string, sheetNumber?: string, sheetId?: string}} params
 * @param {boolean} [updateContent = true]
 */

/**
 * hook to reload sheet data and content in sheet content context
 * @returns {{reloadSheetData: reloadSheetDataCallback, refreshSheetData: setSheetDataAndContentCallback}}
 */
export function useSheetReload() {
	const { initSheetContent, setSheetData } = useContext(SheetContentContext);

	const setSheetDataAndContent = useCallback(
		(sheet, updateContent = true) => {
			if (!sheet) {
				console.error('Sheet data is undefined in setSheetDataAndContent');
				return;
			}

			try {
				console.info('Updating sheet data:', {
					hasContent: !!sheet.content,
					updateContent,
					sheetId: sheet.sheet_id,
				});

				const { content = [], ...restSheet } = sheet;

				if (!Array.isArray(content)) {
					console.error('Sheet content is not an array:', content);
					return;
				}

				const newSheetData = { ...restSheet, binder_id: undefined };

				if (updateContent) {
					initSheetContent(content);
				}
				setSheetData(newSheetData);
			} catch (error) {
				console.error('Error in setSheetDataAndContent:', error, {
					sheet,
					updateContent,
				});
			}
		},
		[initSheetContent, setSheetData]
	);

	const reloadSheetData = useCallback(
		(sheet = {}, updateContent = true) => {
			try {
				if (!sheet?.number) {
					console.error('Invalid sheet data in reloadSheetData:', sheet);
					return;
				}

				const updatedSheet = {
					...sheet,
					number: unpadStartNumberInside(sheet.number),
				};

				setSheetDataAndContent(updatedSheet, updateContent);
			} catch (error) {
				console.error('Error in reloadSheetData:', error, { sheet });
			}
		},
		[setSheetDataAndContent]
	);

	return { reloadSheetData, refreshSheetData: setSheetDataAndContent };
}
