import React, { useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './return-summary-template-view.scss';

import { useDndSheetReference } from '../../../../../../../../shared';
import { IconWrapper } from '../../../../../../../../theme';
import { IconArrowRight } from '../../../../../../../../theme/assets/img';
import { useSheetModeContext } from '../../../../../../context/sheet-mode-context';
import TemplateWrapper from '../../template-wrapper/template-wrapper';

const ReturnTemplateView = (props) => {
	const { template, currentPosition, moveCard, location } = props;
	const { readOnly } = useSheetModeContext();
	const ref = useRef(null);
	const { drag, isDragging } = useDndSheetReference(template, moveCard, ref);

	return (
		<TemplateWrapper
			id={template.id}
			className={classNames('return-summary-template-view', {
				'return-template-view--read-only': readOnly,
			})}
			template={template}
			location={location}
			reference={ref}
			isDragging={isDragging}
			dragRef={drag}
			currentPosition={currentPosition}
		>
			<div className="return-summary-template-view__content">
				<IconWrapper className="return-summary-template-view__icon" Component={IconArrowRight} />
				<span className="return-summary-template-view__text">Sommaire</span>
			</div>
		</TemplateWrapper>
	);
};

export default ReturnTemplateView;

ReturnTemplateView.propTypes = {
	template: PropTypes.object,
	currentPosition: PropTypes.object,
	moveCard: PropTypes.object,
	location: PropTypes.object,
};
