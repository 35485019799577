import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './damage-location-popup.scss';

import { Button, PopupDefault } from '../../../../../../../theme';

/**
 * Render the popup to add the car number
 * @param {object} props
 * @returns {JSX.Element}
 */
const DamageLocationPopup = ({ trainNumber, popupControl, equipmentInvolved, carKey }) => {
	const { t } = useTranslation();
	const [currentTrainNumber, setCurrentTrainNumber] = useState('');
	const object = equipmentInvolved?.find((car) => car.name === carKey);

	const handleSubmit = () => {
		object.train_number = currentTrainNumber;
		popupControl.hide();
	};

	const handleChange = (event) => {
		const { value } = event.target;
		setCurrentTrainNumber(value);
	};

	const validateInputNumber = () => {
		const regex = /^\d{4,5}$/i;
		const validRegex = regex.test(currentTrainNumber);
		return validRegex && currentTrainNumber;
	};

	const handleKeyPress = (e) => {
		const regex = /^\d{0,5}$/i;
		const validRegex = regex.test(e.key);
		if (!validRegex) {
			e.preventDefault();
		}
	};

	const handleClose = async () => {
		const carToDelete = equipmentInvolved.findIndex(
			(selectedCar) => selectedCar.name === object.name
		);
		equipmentInvolved.splice(carToDelete, 1);
	};

	const renderPopupForm = (name) => {
		return (
			<div>
				<label className={'damage-popup-damage-location__form-wrapper__input-wrapper'}>
					<input
						aria-label="Enter character"
						placeholder={t('process:process-rer-resume.damage-location.popup.placeholder')}
						name={name}
						type="text"
						className="input-field__input"
						value={trainNumber}
						onChange={handleChange}
						onKeyDown={handleKeyPress}
						required={true}
						maxLength={5}
					/>
				</label>
			</div>
		);
	};

	return (
		<PopupDefault
			className="popup-damage-location__wrapper"
			title={t('process:process-rer-resume.damage-location.popup.title')}
			popupControl={popupControl}
			onClose={handleClose}
		>
			<div className="popup-damage-location__form-wrapper">
				<div className="popup-damage-location__form-wrapper__title-wrapper">
					<span className="popup-damage-location__form-wrapper__title-wrapper__title">
						{t('process:process-rer-resume.damage-location.popup.content')}
					</span>
				</div>
				{renderPopupForm('train_number')}
				<div className="popup-damage-location__form__button-wrapper">
					<Button
						variant="primary"
						disabled={!validateInputNumber()}
						className="popup-damage-location__form__button-wrapper__validate"
						onClick={handleSubmit}
						label={t('process:process-rer-resume.damage-location.popup.validate')}
					/>
				</div>
			</div>
		</PopupDefault>
	);
};

export default DamageLocationPopup;

DamageLocationPopup.propTypes = {
	trainNumber: PropTypes.string,
	carKey: PropTypes.string,
	popupControl: PropTypes.object.isRequired,
	equipmentInvolved: PropTypes.array.isRequired,
};
