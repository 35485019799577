import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './rer-resume-conditions-drawer.scss';

import { formatJsonDate } from '../../../../../../shared';
import { Drawer } from '../../../../../../theme';
import { dateFormatTime } from '../../../../utils';
import RerResumeSummary from '../../../process-rer-resume/rer-resume-summary/rer-resume-summary';

/**
 * ResumeConditionsDrawer component
 * @param {object} props
 * @param {{ position_supply: 'normal' | 'direct', engine_units: number, bogie_car_units: number, resume: 'travelers' | 'hlp' | 'secours', last_update: Date }} props.resumeConditions
 * @returns {JSX.Element}
 */
const RerResumeConditionsDrawer = ({ processInfo, line }) => {
	const updatedAt = processInfo?.resume_conditions?.updated_at;
	const { t } = useTranslation();

	return (
		<Drawer
			drawerStatus={true}
			className="resume-conditions-drawer"
			leftText={t('process:history-view.rer-resume-conditions.title')}
			rightText={formatJsonDate(updatedAt, dateFormatTime)}
		>
			<div className="resume-condition-drawer__resume">
				<RerResumeSummary processInfo={processInfo} line={line} isProcessRecap={true} />
			</div>
		</Drawer>
	);
};

export default memo(RerResumeConditionsDrawer);

RerResumeConditionsDrawer.propTypes = {
	processInfo: PropTypes.object.isRequired,
	line: PropTypes.string.isRequired,
};
