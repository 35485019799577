import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './offline-header.scss';

import { LogoDigiproc } from '../../../../theme';

/**
 * Render the offline header
 * @param props
 * @param [props.className=""]
 * @return {JSX.Element}
 * @constructor
 */
const OfflineHeader = ({ className = '' }) => {
	const { t } = useTranslation();

	return (
		<header className={classnames('offline-header', className)}>
			<Link to="/">
				<LogoDigiproc className="offline-header__logo" />
			</Link>
			<span className="offline-header__separator" />
			<h1 className="offline-header__title">{t('user:offline-auth.title')}</h1>
		</header>
	);
};

export default OfflineHeader;

OfflineHeader.propTypes = {
	className: PropTypes.string,
};
