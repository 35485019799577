import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

import './sl-train-search.scss';

import { usePopup, useRailwayOnlineReturn, useSprContext } from '../../../../shared';
import { IconWrapper, SprPage, TextfieldAutocomplete } from '../../../../theme';
import { IconSearch } from '../../../../theme/assets/img';
import { SprInProgressProcessList } from '../../../process';
import { ReactComponent as IconTrain } from '../../assets/icon-train.svg';
import PopupCacheLineInfo from '../../components/popup-cache-line-info/popup-cache-line-info';
import { fetchTrain } from '../../train.services';

/**
 * Render the train-agent home-page
 * @return {JSX.Element}
 * @constructor
 */
const SlTrainSearch = () => {
	const { t } = useTranslation();
	const browserHistory = useHistory();
	const location = useLocation();
	const { associatedLine, resetCurrentBinder } = useSprContext();
	const [query, setQuery] = useState('');
	const cacheLinePopupControl = usePopup();

	useRailwayOnlineReturn(true);

	const onSelect = (selected) => {
		const { value: train } = selected;
		const {
			binder_auto: binderAuto,
			binder_driver: binderDriver,
			binder_officer: binderOfficer,
		} = train;

		const binderCount = !!binderAuto + !!binderDriver + !!binderOfficer;
		if (binderCount === 1) {
			const binderId = encodeURIComponent(`${binderAuto || binderDriver || binderOfficer}`);
			browserHistory.push(`/railway/train/${train?.tech_id}/binder/${binderId}`);
		} else if (binderCount > 1) {
			browserHistory.push(`/railway/train/${train?.tech_id}/binders`);
		} else {
			browserHistory.push('/404');
		}
	};

	const onChange = (e) => {
		setQuery(e.target.value);
	};

	const handleGetSuggestions = async (querySearch) => {
		const response = await fetchTrain(querySearch, { line: associatedLine, is_active: true });

		return response?.data?.map((train) => ({
			label: { className: 'search__suggestion', lines: [{ type: 'label', text: train.id }] },
			value: train,
		}));
	};

	const showPopupByQueryParam = () => {
		const q = qs.parse(location.search, { ignoreQueryPrefix: true });
		if (q.showLineStatus) {
			cacheLinePopupControl.show();
		}
	};

	useEffect(resetCurrentBinder, [resetCurrentBinder]);
	useEffect(showPopupByQueryParam, [location, cacheLinePopupControl]);

	return (
		<SprPage className="sl-train-search" allowLineSwitch>
			<PopupCacheLineInfo popupControl={cacheLinePopupControl} />
			<SprInProgressProcessList />
			<div className="sl-train-search__wrapper">
				<IconWrapper className="sl-train-search__train-image" Component={IconTrain} />
				<p className="sl-train-search__text">{t('train:sl-train-search.text')}</p>
				<div className="sl-train-search__search">
					<TextfieldAutocomplete
						getSuggestions={handleGetSuggestions}
						className="search__field"
						inputClassName="search__input"
						value={query}
						handleChange={onChange}
						label={t('train:sl-train-search.search-label')}
						icon={false}
						selectedValue={onSelect}
					/>
					<IconWrapper className="search__icon" Component={IconSearch} />
				</div>
				<Link className="sl-train-search__help-link" to="/railway/binders">
					{t('train:sl-train-search.help-link')}
				</Link>
			</div>
		</SprPage>
	);
};

export default SlTrainSearch;
