import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './popup-copy-train.scss';

import { checkFormError } from '../../../../../../shared';
import { Button, ErrorMessage, PopupDefault } from '../../../../../../theme';
import { copyTrain } from '../../../../train.services';
import { createTrainForm } from '../../../../utils/train-object-utils';
import {
	getFormTrainFieldMessageKey,
	validatePopupCopyTrainForm,
} from '../../../../utils/validate-popup-train-form';
import FormTrain from '../form-train/form-train';

/**
 * Render the PopupCopyTrain component
 * @param {object} props
 * @param {function} props.onSubmitSuccess Callback called after successful submit
 * @param {object} props.popupControl
 * @param {import("../../../../train.services").Train} props.train the train to update
 * @param {import("../../../../train.services").Train[]} props.trainList the train list of the train-list parent page
 * @param {string} props.line the line of the current train-list parent page
 * @returns {JSX.Element}
 */
const PopupCopyTrain = ({
	onSubmitSuccess = () => {},
	popupControl,
	train,
	line,
	trainList = [],
}) => {
	const { t } = useTranslation();
	/**@type {[import("../../../../train.services").Train, React.Dispatch<React.SetStateAction<Train>>]} */
	const [formData, setFormData] = useState({});
	const [networkError, setNetworkError] = useState(false);
	const formError = useMemo(
		() => validatePopupCopyTrainForm(formData?.id, trainList),
		[formData?.id, trainList]
	);
	const formErrorMessage = useMemo(() => getFormTrainFieldMessageKey(formError), [formError]);

	const reInitForm = () => {
		setFormData(createTrainForm(train, false));
		setNetworkError(false);
	};

	const handleIdChange = (event) => {
		const { name, value } = event.target;
		if (name === 'id') {
			setFormData((oldFormDate) => ({ ...oldFormDate, id: value }));
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if (!checkFormError(formError)) {
			reInitForm();
			copyTrain(formData.id, train.tech_id).then(popupControl.hide).then(onSubmitSuccess);
		}
	};

	useEffect(reInitForm, [train]);

	return (
		<PopupDefault
			popupControl={popupControl}
			title={t('train:popup.copy-train.title')}
			onClose={reInitForm}
			className={classnames('popup-copy-train__wrapper', {
				'popup-copy-train__wrapper--train': train,
			})}
		>
			<form className="popup-copy-train" onSubmit={handleSubmit}>
				<div className="popup-copy-train__form-wrapper">
					<FormTrain
						form={formData}
						validation={formError}
						errorMessage={formErrorMessage}
						onChange={handleIdChange}
						extendedBinderStatus
						copy
						line={line}
					/>
				</div>
				<div className="popup-copy-train__controller">
					<Button
						variant="primary"
						className="popup-copy-train__submit"
						type="submit"
						disabled={train.id === formData.id || checkFormError(formError)}
						label={t('train:popup.form.submit')}
					/>
				</div>
				{networkError && (
					<ErrorMessage className="popup-copy-train__network-error">
						{t('error.default')}
					</ErrorMessage>
				)}
			</form>
		</PopupDefault>
	);
};

export default PopupCopyTrain;

PopupCopyTrain.propTypes = {
	onSubmitSuccess: PropTypes.func,
	line: PropTypes.number,
	trainList: PropTypes.arrayOf(PropTypes.shape({})),
	popupControl: PropTypes.shape({
		hide: PropTypes.func,
		show: PropTypes.func,
		visible: PropTypes.bool,
	}),
	train: PropTypes.shape({
		id: PropTypes.string,
		tech_id: PropTypes.string,
		material_tech_id: PropTypes.string,
		brake_system: PropTypes.string,
		car_number: PropTypes.number,
		binder_auto: PropTypes.string,
		binder_driver: PropTypes.string,
		binder_officer: PropTypes.string,
		brake_isolation: PropTypes.bool,
		engine_system: PropTypes.bool,
	}).isRequired,
};
