import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './damage-location-form.scss';

import { checkFormError } from '../../../../../shared';
import { updateProcess } from '../../../process.services';
import { validateProcessDamageLocationForm } from '../../../utils';
import TrainTroubleFormFooter from '../shared/train-trouble-form-footer/train-trouble-form-footer';

import DamageLocationChoiceButton from './components/damage-location-choice-button/damage-location-choice-button';
import DamageLocationMissionCode from './components/damage-location-mission-code/damage-location-mission-code';
import TrainElementTable from './components/train-element-table/train-element-table';

const fields = [
	{
		name: 'train_composition',
		key: 'train-composition',
		type: 'choice',
		values: ['US', 'UM'],
	},
	{
		name: 'direction',
		key: 'direction',
		type: 'choice',
		values: ['left', 'right'],
	},
	{
		name: 'equipment_involved',
		key: 'equipment-involved',
		type: 'table',
	},
	{
		name: 'mission_code',
		key: 'mission-code',
		type: 'text',
	},
];

const DamageLocationForm = ({
	loadProcess,
	damageFormData,
	setDamageFormData,
	line,
	handleChange,
	processInfo,
	setStepNumber,
	stepNumber,
}) => {
	const { results_sheet: resultsSheet = {} } = processInfo?.resume_conditions || {};
	const { t } = useTranslation();
	const {
		train_composition: trainComposition,
		direction,
		emergency_lights: emergencyLights,
	} = damageFormData;
	const [areParentsSelected, setAreParentsSelected] = useState(false);

	const formError = useMemo(
		() => validateProcessDamageLocationForm(damageFormData),
		[damageFormData]
	);
	const isSubmitBlocked = useMemo(() => checkFormError(formError), [formError]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formattedData = {
			damage_location: {
				...damageFormData,
				step_completed: true,
			},
			results_sheet: processInfo?.resume_conditions?.results_sheet || {},
			resume_rer: processInfo?.resume_conditions?.resume_rer || {},
		};
		setStepNumber(Object.keys(resultsSheet).length > 0 ? 3 : 2);
		if (!checkFormError(formError)) {
			await updateProcess(
				processInfo.tech_id,
				{ resume_conditions: formattedData },
				{ action: 'updateProcessRerResume', line }
			);
		}
		loadProcess(processInfo?.tech_id);
	};

	const handleReset = (event) => {
		event.preventDefault();
		setDamageFormData({
			step_completed: false,
			emergency_lights: emergencyLights,
			train_composition: '',
			direction: '',
			equipment_involved: [],
			mission_code: '',
		});
	};

	const handleBackClick = async () => {
		await setDamageFormData({
			step_completed: false,
			emergency_lights: '',
			train_composition: '',
			direction: '',
			equipment_involved: [],
			mission_code: '',
		});
		const clearProcess = {
			...processInfo?.resume_conditions,
			damage_location: {},
			results_sheet: {},
			resume_rer: {},
		};
		await updateProcess(
			processInfo.tech_id,
			{ resume_conditions: clearProcess },
			{ action: 'updateProcessRerResume', line }
		);
		await loadProcess(processInfo.tech_id);
		await setStepNumber(0);
	};

	useEffect(() => {
		const trainCompositionSelected = ['US', 'UM'].includes(trainComposition);
		const directionSelected = ['left', 'right'].includes(direction);
		setAreParentsSelected(directionSelected && trainCompositionSelected);
	}, [damageFormData, trainComposition, direction]);

	const renderField = ({ name, key, type, values }) => {
		const isTableForm = type === 'table';
		const Label = !isTableForm ? (
			<span className="damage-location__form__fields__label">
				{t(`process:process-rer-resume.damage-location.${key}`)}
			</span>
		) : (
			''
		);
		const renderers = {
			choice: (
				<DamageLocationChoiceButton
					fieldName={name}
					formData={damageFormData}
					handleChange={handleChange}
					options={values || damageFormData[name]}
				/>
			),
			text: (
				<DamageLocationMissionCode
					fieldName={name}
					formData={damageFormData}
					handleChange={handleChange}
				/>
			),
			table: (
				<TrainElementTable
					fieldName={name}
					damageFormData={damageFormData}
					setDamageFormData={setDamageFormData}
					line={line}
					areParentsSelected={areParentsSelected}
				/>
			),
		};

		const render = renderers[type];
		return (
			<div key={key} className="damage-location__form__fields__input-row">
				{Label}
				{render}
			</div>
		);
	};

	return (
		<div className="damage-location__form">
			<div className="damage-location__form__fields">
				<div className="damage-location__form__fields__choice-button">
					{fields.map((field) => (field.type === 'choice' ? renderField(field) : null))}
				</div>
				{fields.map((field) => (field.type !== 'choice' ? renderField(field) : null))}
			</div>
			<TrainTroubleFormFooter
				line={line}
				handleBackClick={handleBackClick}
				handleReset={handleReset}
				isSubmitBlocked={isSubmitBlocked}
				handleSubmit={handleSubmit}
				fieldName={'damage-location'}
				stepNumber={stepNumber}
			/>
		</div>
	);
};

export default DamageLocationForm;

DamageLocationForm.propTypes = {
	loadProcess: PropTypes.func.isRequired,
	damageFormData: PropTypes.object.isRequired,
	setDamageFormData: PropTypes.func.isRequired,
	line: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
	processInfo: PropTypes.object.isRequired,
	setStepNumber: PropTypes.func.isRequired,
	stepNumber: PropTypes.number.isRequired,
};
