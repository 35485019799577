import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './dynamic-background-text.scss';

const DynamicBackgroundText = ({ className, text, color }) => {
	const container = useRef('');

	useEffect(() => {
		if (container?.current?.style && color) {
			container.current.style.setProperty('--bg-color', color);
		}
	}, [color]);

	return (
		<span ref={container} className={classNames('dynamic-background-text', className)}>
			{text}
		</span>
	);
};

export default DynamicBackgroundText;

DynamicBackgroundText.propTypes = {
	className: PropTypes.string,
	text: PropTypes.string,
	color: PropTypes.string,
};
