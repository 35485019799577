import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import classnames from 'classnames';

import './sl-suggested-sheet-sidebar.scss';

import { getProcessByTechId } from '../../../domains/process/process.services';
import { fetchSheetById } from '../../../domains/sheet/sheet.services';
import { iconLines } from '../../../domains/user/utils/associated-line-icon-utils/associated-line-icon.utils';
import { IconWrapper } from '../../../theme';
import { IconArrowRight } from '../../../theme/assets/img';
import { buildSprProcessUri } from '../..';
import { useSprContext } from '../../context/spr-context/spr-context';

const SlSuggestedSheetSidebar = () => {
	const { t } = useTranslation();
	const { processId } = useParams();
	const [currentSheetData, setCurrentSheetData] = useState([]);
	const [currentProcessusData, setCurrentProcessusData] = useState(null);
	const { associatedLine, currentBinder, side } = useSprContext();
	const binderTechId = currentSheetData?.binder_tech_id || (currentBinder && currentBinder.tech_id);

	useEffect(() => {
		if (processId) {
			getProcessByTechId(processId, { extendTrain: true })
				.then(({ data = {} }) => {
					setCurrentProcessusData(data);
					return data;
				})
				.then((process) => {
					return fetchSheetById(null, process?.sheet_tech_id);
				})
				.then(({ data: sheetFound = {} }) => {
					setCurrentSheetData(sheetFound);
				});
		}
	}, [processId]);

	const binderTypeModifier = useMemo(
		() => ({
			'binder__type--auto': currentBinder?.type === 'train-auto',
			'binder__type--driver': currentBinder?.type === 'train-driver',
			'binder__type--officer': currentBinder?.type === 'train-officer',
		}),
		[currentBinder?.type]
	);

	function generateInProgressProcessContent(processus) {
		const {
			description,
			position_info: positionInfo,
			position_type: positionType,
			track,
			train_id,
			processTechId,
		} = processus;

		const currentSheetNumberSearch = currentSheetData?.number_search ?? '';
		const currentSheetNumber = currentSheetData?.number ?? '';

		const trackName =
			positionType === 'interstation'
				? t(`process:popup.start-process-train.track.${track}`)
				: track;

		const uriInfos = {
			binderTechnicalId: binderTechId ?? '',
			sheetNumber: currentSheetNumber,
			processTechId: processTechId ?? '',
			trainId: train_id ?? '',
			processId: processId ?? '',
		};

		return (
			<>
				<div className="sl-sheet-sidebar__container-left">
					<div className="sl-sheet-sidebar__train-details__container">
						<div className="sl-sheet-sidebar__train-details">
							<IconWrapper
								className="sl-sheet-sidebar__associatedLine"
								Component={iconLines[associatedLine]}
							/>
							<div className="sl-sheet-sidebar__train-details__content">
								<span className="train-details__content__position bold">
									{t(`process:process-train-sheet-sidebar.position-type.${positionType}`)}
								</span>
								{track && (
									<span className="train-details__content__track">
										{positionInfo && `${positionInfo} - `}
										{trackName}
									</span>
								)}
								<span className="train-details__content__train">
									{t('process:process-train-sheet-sidebar.trainIdentifier')}
									{train_id}
								</span>
							</div>
							<div className="sl-sheet-sidebar__train-details__description">
								<span className="description-content">{description}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="sl-sheet-sidebar__container-right">
					<span className={classnames('binder__type', binderTypeModifier)}>
						{currentBinder?.id || currentSheetData?.binder_id}
					</span>
					<div className="sl-sheet-sidebar__container-link">
						<Link to={buildSprProcessUri(uriInfos, side === 'train' ? 'railway' : 'spr')}>
							<span className="sl-sheet-sidebar__link-cta">
								{t('process:process-train-sheet-sidebar.start-sheet-process')}
							</span>
							<span className="sl-sheet-sidebar__link-sheet-number">
								{currentSheetNumberSearch}
							</span>
						</Link>
						<IconWrapper className="sl-sheet-sidebar__link__icon" Component={IconArrowRight} />
					</div>
				</div>
			</>
		);
	}

	return currentProcessusData ? (
		<div className="sl-sheet-sidebar">{generateInProgressProcessContent(currentProcessusData)}</div>
	) : (
		<></>
	);
};

export default SlSuggestedSheetSidebar;
