import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './image-list-view.scss';

import { SheetImageLoader } from '../../../domains/sheet';

const ImageListView = ({ className, images = [] }) => {
	const renderImageView = (image) => (
		<li key={image?.imageId} className="image-list-view__item">
			<SheetImageLoader
				className="image-list-view__item__image"
				imageId={image?.imageId}
				tag="light"
			/>
			{image.title && <span className="image-list-view__item__title">{image.title}</span>}
			{image.description && (
				<span className="image-list-view__item__description">{image.description}</span>
			)}
		</li>
	);

	return (
		<ul className={classNames('image-list-view', className)}>{images.map(renderImageView)}</ul>
	);
};

export default ImageListView;

ImageListView.propTypes = {
	className: PropTypes.string,
	images: PropTypes.array,
};
