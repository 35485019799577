import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { SprContext } from '../../../../../../shared';
import BackToCurrentStep from '../../../../../../shared/components/back-to-current-step/back-to-current-step';
import getSignalInfo from '../../../../../../shared/utils/get-signal-info';
import { EditorRendering } from '../../../../../../theme';
import { SheetType } from '../../../../index';
import resolveSheetColor from '../../../../utils/resolve-sheet-color';

const SprSheetDetailSubheader = (props) => {
	const { sheetData } = props;
	const { processId } = useParams();
	const { signalList } = useContext(SprContext);

	const signalInfo = getSignalInfo(signalList, sheetData?.signal_tech_id);
	const sheetColor = resolveSheetColor(
		sheetData?.color,
		sheetData?.binder_color,
		sheetData?.theme_color
	);

	return (
		<div className="spr-sheet-detail__subheader">
			<div className="spr-sheet-detail__subheader__title">
				<SheetType
					className="subheader__title__sheet-type"
					type={sheetData?.type}
					sheetNumber={sheetData?.number}
					binderType={sheetData?.binder_type}
					signalInfo={signalInfo}
					color={sheetColor}
				/>
				<span className="subheader__title__sheet-title">
					<EditorRendering content={sheetData?.title} />
				</span>
			</div>
			<BackToCurrentStep className="spr-sheet-detail-page__back" processId={processId} />
		</div>
	);
};

export default SprSheetDetailSubheader;

SprSheetDetailSubheader.propTypes = {
	sheetData: PropTypes.shape({
		type: PropTypes.string,
		number: PropTypes.number,
		binder_type: PropTypes.string,
		signal_tech_id: PropTypes.string,
		color: PropTypes.string,
		binder_color: PropTypes.string,
		theme_color: PropTypes.string,
		title: PropTypes.string,
	}),
};
