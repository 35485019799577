/**
 * Check if the provided DraftJS render an empty text
 * @param {{ blocks: {text: string }[] }} parsedContent
 * @returns {boolean}
 */
const isDraftJSContentEmpty = (parsedContent) => {
	const strContentList = parsedContent?.blocks?.map(({ text = '' }) => text) || [];

	if (strContentList[0]) {
		strContentList[0] = strContentList[0].trimStart();
		strContentList[strContentList.length - 1] = strContentList[strContentList.length - 1].trimEnd();
		return !strContentList?.join('\n');
	}

	return true;
};

export default isDraftJSContentEmpty;
