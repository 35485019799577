/**
 * Format user name with identifier
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} id
 * @returns {string} e.g. pierre p. (12346)
 */

export const buildUserName = (firstName = ' ', lastName = ' ', id = ' ') =>
	`${firstName} ${lastName?.length > 0 && lastName.charAt(0)}. (${id})`;

/**
 * Format user name without identifier
 * @param {string} firstName
 * @param {string} lastName
 * @returns {string} e.g. pierre p.
 */
export const buildUserFullName = (firstName = ' ', lastName = ' ') =>
	`${firstName} ${lastName?.length > 0 && lastName.charAt(0)}. `;
