import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './history-multiple-choice-task-view.scss';

import { EditorRendering, IconWrapper } from '../../../../../../theme';
import { ReactComponent as IconCheck } from '../../../../../../theme/assets/img/icon-check.svg';

/**
 * Render HistoryMultipleChoiceTaskView component
 * @param {object} props
 * @param {object} props.task
 * @param {string} props.className
 * @param {boolean} props.passed
 * @return {JSX.Element}
 */
const HistoryMultipleChoiceTaskView = ({ task = {}, className, passed }) => {
	const { content } = task;

	const initClassName = (baseClassName) =>
		classnames(baseClassName, className, { [`${baseClassName}--passed`]: passed });

	const filterChoicesChecked = (choice) => choice.chosen;

	const renderChoiceBlocks = (choiceBlock, index) => {
		return (
			<div key={index} className="history-multiple-choice-task-view__choice-block">
				<div className="history-multiple-choice-task-view__choice-block--icon">
					<input
						id={index}
						type="checkbox"
						name={index}
						className={'history-multiple-choice-task-view__choice-block--input'}
						checked={true}
						disabled={true}
					/>
					<label
						htmlFor={index}
						className="multiple-choice-task-view--template-view__icon-head
							   multiple-choice-task-view--template-view__icon-head--active"
					>
						<IconWrapper
							className="multiple-choice-task-view--template-view__icon--check"
							Component={IconCheck}
						/>
					</label>
				</div>
				<div className="history-multiple-choice-task-view__choice-block__content">
					<div className="history-multiple-choice-task-view__choice-block__content--title">
						{choiceBlock.title}
					</div>
					<div className="history-multiple-choice-task-view__choice-block__content--subtitle">
						{choiceBlock.subtitle}
					</div>
				</div>
			</div>
		);
	};

	const renderChoicesBlocks = (choices) => {
		if (Array.isArray(choices)) {
			return choices.filter(filterChoicesChecked).map(renderChoiceBlocks);
		}
	};

	return (
		<div className={initClassName('history-multiple-choice-task-view')}>
			<div className="history-multiple-choice-task-view__header">
				<EditorRendering
					className="history-multiple-choice-task-view__text"
					content={content?.title}
				/>
			</div>
			<ul className="history-multiple-choice-task-view__choices">
				{renderChoicesBlocks(content?.choices)}
			</ul>
		</div>
	);
};

export default HistoryMultipleChoiceTaskView;

HistoryMultipleChoiceTaskView.propTypes = {
	task: PropTypes.shape({
		content: PropTypes.shape({
			title: PropTypes.string,
			choices: PropTypes.arrayOf(
				PropTypes.shape({
					chosen: PropTypes.bool,
					title: PropTypes.string,
					subtitle: PropTypes.string,
				})
			),
		}),
	}),
	className: PropTypes.string,
	passed: PropTypes.bool,
};
