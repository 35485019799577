import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { SheetLinkWrapper } from '../../../../../../shared';
import SheetType from '../../../../components/sheet-type/sheet-type';
import resolveSheetColor from '../../../../utils/resolve-sheet-color';

/**
 * @typedef SheetLink
 * @prop {string} uri
 * @prop {string} sheetId
 * @prop {string} sheetNumber
 * @prop {string} sheetNumberUnpadded
 * @prop {string} [binderTechId]
 * @prop {string} [color]
 * @prop {number} [jumpToActionPosition]
 * @prop {"man"|"child"|"parent"|"determination"|"m"|"resume"} [sheetType]
 */

/**
 * @param {object} props
 * @param {SheetLink} props.sheetLink
 * @param {string} props.sheetColor
 * @param {boolean} [props.disabled = false]
 * @returns {JSX.Element}
 */
const SheetTypeLink = ({ sheetLink = {}, sheetColor, disabled = false }) => {
	const { sheetNumberUnpadded, sheetType = '' } = sheetLink;
	const { pathname } = useLocation();

	return (
		<SheetLinkWrapper isLink={!disabled && pathname !== sheetLink?.uri} sheetLink={sheetLink}>
			<SheetType
				className="sheet-type--breadcrumb"
				type={sheetType}
				sheetNumber={sheetNumberUnpadded}
				color={resolveSheetColor(sheetColor, null)}
				isBinderTrain={true}
			/>
		</SheetLinkWrapper>
	);
};

export default SheetTypeLink;

SheetTypeLink.propTypes = {
	sheetLink: PropTypes.shape({
		sheetNumberUnpadded: PropTypes.string,
		sheetType: PropTypes.string,
	}),
	sheetColor: PropTypes.string,
	disabled: PropTypes.bool,
};
