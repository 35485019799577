const subTemplateBullet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

/**
 * get bullet point for an actin text
 * @param {boolean} isSubTemplate
 * @param {number} position
 * @param {"." | "-"} suffix
 * @returns
 */
const getActionBullet = (isSubTemplate, position, suffix = '.') => {
	if (position < 1) return '';
	const bullet = isSubTemplate
		? subTemplateBullet[Math.min(position, subTemplateBullet.length) - 1]
		: position;
	return `${bullet} ${suffix}`;
};

export default getActionBullet;
