import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './icon-wrapper.scss';

const IconWrapper = ({ className, Component = 'span', tooltip, ...rest }) => (
	<span className={classNames('icon-wrapper', className)} {...rest}>
		<Component fill="currentColor" role="img" aria-hidden="true" focusable="false" />
		{tooltip && (
			<span className="icon-wrapper__tooltip__wrapper">
				<span className="icon-wrapper__tooltip__text">{tooltip}</span>
			</span>
		)}
	</span>
);

export default IconWrapper;

IconWrapper.propTypes = {
	className: PropTypes.string,
	Component: PropTypes.elementType,
	tooltip: PropTypes.string,
};
