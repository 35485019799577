import React from 'react';
import PropTypes from 'prop-types';

import './square-color-icon.scss';

/**
 * @param {object} props
 * @param {string} props.color
 * @returns {JSX.Element}
 */
const SquareColorIcon = ({ color }) => {
	return <span className="square-color-icon" style={{ backgroundColor: color }} />;
};

export default SquareColorIcon;

SquareColorIcon.propTypes = {
	color: PropTypes.string.isRequired,
};
