import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './binder-filters.scss';

import { useAppSetupContext } from '../../../../../../shared';
import {
	FilterAssociatedLineLabel,
	FilterDocTypeOption,
	FilterDropDown,
	FilterLabelLine,
	FilterMaterialOption,
	FilterTags,
} from '../../../../../../theme';
import { BinderStatus } from '../../../../index';
import BinderFiltersSort from '../binder-filters-sort/binder-filters-sort';

const BinderFilters = ({
	className,
	associatedLineOptions,
	statusOptions,
	filter,
	updateFilter,
	resetFilter,
	binders,
	sort,
	updateSort,
	docTypesListOptions,
	materialListOptions,
	activeId,
}) => {
	const { t } = useTranslation();
	const { user } = useAppSetupContext();
	const userOrigin = user?.origin;

	// custom label for status filter tag
	const renderLabelStatus = (value) => {
		return (
			<label htmlFor={value}>
				<BinderStatus className="binder-filter__form__status--itemLabel" status={value} />
			</label>
		);
	};

	const tagsRender = {
		status: (status) => <BinderStatus className="binder-filters__tag--status" status={status} />,
		associatedLine: (line) => <FilterLabelLine line={line} />,
		docType: (docTypeList) => <FilterDocTypeOption docTypeList={docTypeList} />,
	};

	return (
		<div className={classnames('binder-filters', className)}>
			<FilterTags
				filter={filter}
				updateFilter={updateFilter}
				customFilterTagRender={tagsRender}
				resetFilter={resetFilter}
			/>
			<div className="binder-filters__form">
				{activeId === 'train' && (
					<>
						<FilterDropDown
							className="binder-filters__filter"
							onSelect={(material) => updateFilter('material', material)}
							options={materialListOptions}
							filterValue={filter?.material}
							staticSelectedLabel={t(
								'binder:binder-list.filter.filter-list.default-dropdown-material'
							)}
							renderOptionValue={(materialOptionsList) => (
								<FilterMaterialOption materialList={materialOptionsList} />
							)}
						/>
						{userOrigin === 'mts' && (
							<FilterDropDown
								className="binder-filters__filter"
								onSelect={(docType) => updateFilter('docType', docType)}
								options={docTypesListOptions}
								filterValue={filter?.docType}
								staticSelectedLabel={t(
									'binder:binder-list.filter.filter-list.default-dropdown-doc-type'
								)}
								renderOptionValue={(docTypeList) => (
									<FilterDocTypeOption docTypeList={docTypeList} />
								)}
							/>
						)}
					</>
				)}
				<FilterDropDown
					className="binder-filters__filter"
					onSelect={(line) => updateFilter('associatedLine', line)}
					options={associatedLineOptions}
					filterValue={filter?.associatedLine}
					staticSelectedLabel={t('binder:binder-list.filter.filter-list.default-dropdown-line')}
					renderOptionValue={(lineOption) => <FilterAssociatedLineLabel line={lineOption} />}
				/>
				<FilterDropDown
					className="binder-filters__filter"
					onSelect={(status) => updateFilter('status', status)}
					options={statusOptions}
					filterValue={filter?.status}
					staticSelectedLabel={t('binder:binder-list.filter.filter-list.default-dropdown-status')}
					renderOptionValue={(statusOption) => renderLabelStatus(statusOption)}
				/>
				<BinderFiltersSort binders={binders} sort={sort} updateSort={updateSort} />
			</div>
		</div>
	);
};

export default BinderFilters;

BinderFilters.propTypes = {
	className: PropTypes.string,
	associatedLineOptions: PropTypes.array,
	statusOptions: PropTypes.array,
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
	resetFilter: PropTypes.func,
	binders: PropTypes.array,
	sort: PropTypes.object,
	updateSort: PropTypes.func,
	docTypesListOptions: PropTypes.array,
	materialListOptions: PropTypes.array,
	activeId: PropTypes.string,
};
