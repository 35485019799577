import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './step-template-view.scss';

import { useDndSheetReference } from '../../../../../../../../shared';
import { useSheetModeContext } from '../../../../../../context/sheet-mode-context';
import TemplateWrapper from '../../template-wrapper/template-wrapper';

const StepTemplateView = ({ template, currentPosition, allowInsertAfter, moveCard, location }) => {
	const { t } = useTranslation();
	const { readOnly } = useSheetModeContext();
	const ref = useRef(null);
	const { drag, isDragging } = useDndSheetReference(template, moveCard, ref);

	const step = template?.content?.step;

	return (
		<TemplateWrapper
			id={template.id}
			className={classNames('step-template-view', { 'step-template-view--read-only': readOnly })}
			template={template}
			location={location}
			reference={ref}
			isDragging={isDragging}
			dragRef={drag}
			currentPosition={currentPosition}
			allowInsertAfter={allowInsertAfter}
		>
			<div className="step-template-view__text">
				<div className="step-template-view__content">
					<span className="content__step">
						{`${t('sheet:sheet-details.template.step.name')} ${step}`}
					</span>
					<span className="content__text">_ _h_ _</span>
				</div>
			</div>
		</TemplateWrapper>
	);
};

export default StepTemplateView;

StepTemplateView.propTypes = {
	template: PropTypes.object,
	currentPosition: PropTypes.number,
	allowInsertAfter: PropTypes.bool,
	moveCard: PropTypes.any,
	location: PropTypes.object,
};
