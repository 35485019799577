import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './train-list-content.scss';

import { usePopup } from '../../../../../../shared';
import { Button, ListContentTemplate } from '../../../../../../theme';
import { IconDuplicate, IconEdit, IconTrash } from '../../../../../../theme/assets/img';
import PopupCopyTrain from '../popup-copy-train/popup-copy-train';
import PopupDeleteTrain from '../popup-delete-train/popup-delete-train';
import PopupUpdateTrain from '../popup-update-train/popup-update-train';

import TrainListRow from './components/train-list-row/train-list-row';

/**
 * TrainListContent component
 * @param {object} props
 * @param {function} props.searchTrains
 * @param {number} props.associatedLine
 * @param {import("../../../../train.services").Train[]} props.trains
 * @returns {JSX.Element}
 */
const TrainListContent = ({ trains = [], searchTrains, associatedLine }) => {
	const [deleteTrain, setDeleteTrain] = useState(null);
	const [updateTrain, setUpdateTrain] = useState(null);
	const [copyTrain, setCopyTrain] = useState(null);
	const { t } = useTranslation();
	const popupDeleteTrainControl = usePopup();
	const popupUpdateTrainControl = usePopup();
	const popupCopyTrainControl = usePopup();

	const headers = [
		'id',
		'material',
		'carts',
		'brake-isolation',
		'brake',
		'engine-system',
		'binders',
	];

	const handlePopupDeleteTrainVisible = (e, train) => {
		e.preventDefault();
		setDeleteTrain(train);
		popupDeleteTrainControl.show();
	};

	const handlePopupUpdateTrainVisible = (e, train) => {
		e.preventDefault();
		setUpdateTrain(train);
		popupUpdateTrainControl.show();
	};

	const handlePopupCopyTrainVisible = (e, train) => {
		e.preventDefault();
		setCopyTrain(train);
		popupCopyTrainControl.show();
	};

	const onPopupActionSuccess = () => {
		searchTrains();
		setCopyTrain(null);
		setUpdateTrain(null);
		setDeleteTrain(null);
	};

	const renderTrain = (train) => (
		<TrainListRow
			key={train?.tech_id || train?.id + train?.line}
			train={train}
			trainList={trains}
			searchTrains={searchTrains}
			associatedLine={associatedLine}
		>
			<Button
				variant="minimal"
				className="cta__icon"
				iconTooltipContent={t('train:train-list.tooltip.edit')}
				icon={IconEdit}
				onClick={(e) => {
					handlePopupUpdateTrainVisible(e, train);
				}}
			/>
			<Button
				variant="minimal"
				className="cta__icon"
				iconTooltipContent={t('train:train-list.tooltip.copy')}
				icon={IconDuplicate}
				onClick={(e) => {
					handlePopupCopyTrainVisible(e, train);
				}}
			/>
			<Button
				variant="minimal"
				className="cta__icon"
				iconTooltipContent={t('train:train-list.tooltip.delete')}
				icon={IconTrash}
				onClick={(e) => {
					handlePopupDeleteTrainVisible(e, train);
				}}
			/>
		</TrainListRow>
	);

	return (
		<ListContentTemplate
			className="list--train"
			headers={headers}
			headerKeyPrefix="train:train-list.list.header-label."
		>
			{deleteTrain && (
				<PopupDeleteTrain
					onSubmitSuccess={onPopupActionSuccess}
					train={deleteTrain}
					popupControl={popupDeleteTrainControl}
				/>
			)}
			{updateTrain && (
				<PopupUpdateTrain
					popupControl={popupUpdateTrainControl}
					onSubmitSuccess={onPopupActionSuccess}
					line={associatedLine}
					train={updateTrain}
				/>
			)}
			{copyTrain && (
				<PopupCopyTrain
					popupControl={popupCopyTrainControl}
					onSubmitSuccess={onPopupActionSuccess}
					line={associatedLine}
					trainList={trains}
					train={copyTrain}
				/>
			)}
			{Array.isArray(trains) && trains.map(renderTrain)}
		</ListContentTemplate>
	);
};

export default TrainListContent;

TrainListContent.propTypes = {
	trains: PropTypes.arrayOf(PropTypes.object),
	searchTrains: PropTypes.func.isRequired,
	associatedLine: PropTypes.string.isRequired,
};
