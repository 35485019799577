import i18next from '../../config/i18next';

import PopupCreateSheet from './components/popup-create-sheet/popup-create-sheet';
import PopupDeleteSheet from './components/popup-delete-sheet/popup-delete-sheet';
import SheetImageLoader from './components/sheet-image-loader/sheet-image-loader';
import SheetPreview from './components/sheet-preview/sheet-preview';
import SheetStatus from './components/sheet-status/sheet-status';
import SheetType from './components/sheet-type/sheet-type';
import i18nSheet from './i18n/fr.json';
import RailwaySheetView from './pages/railway-sheet-view/railway-sheet-view';
import Comment from './pages/sheet-detail/components/sheet-comments/components/comment';
import SheetComments from './pages/sheet-detail/components/sheet-comments/sheet-comments';
import SheetEditionAction from './pages/sheet-detail/components/sheet-edition-action/sheet-edition-action';
import SheetEditionPanel from './pages/sheet-detail/components/sheet-edition-panel/sheet-edition-panel';
import SheetDetail from './pages/sheet-detail/sheet-detail';
import SprSheetDetail from './pages/spr-sheet-detail/spr-sheet-detail';
import defaultEditorToolbarConfig from './utils/default-editor-toolbarconfig.json';
import formatSheetNumberDiamond from './utils/format-sheet-number-diamond';
import resolveSheetColor from './utils/resolve-sheet-color';
import sheetColorList from './utils/sheet-color-list.json';
import sheetColorTrainList from './utils/sheet-color-train-list.json';
import sheetStatusMap from './utils/sheet-status-map.json';
import sheetTemplateMap from './utils/sheet-templates-map.json';
import sheetTypeMap from './utils/sheet-type-map.json';

i18next.addResourceBundle('fr', 'sheet', i18nSheet);

export {
	Comment,
	defaultEditorToolbarConfig,
	formatSheetNumberDiamond,
	PopupCreateSheet,
	PopupDeleteSheet,
	RailwaySheetView,
	resolveSheetColor,
	sheetColorList,
	sheetColorTrainList,
	SheetComments,
	SheetDetail,
	SheetEditionAction,
	SheetEditionPanel,
	SheetImageLoader,
	SheetPreview,
	SheetStatus,
	sheetStatusMap,
	sheetTemplateMap,
	SheetType,
	sheetTypeMap,
	SprSheetDetail,
};
