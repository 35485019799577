import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './history-table-task-view.scss';

/**
 * Historic view of a table task
 * @param {object} props
 * @returns {JSX.Element}
 */
const HistoryTableTaskView = ({ task = {}, className, passed }) => {
	const { content } = task;

	const renderTableHeader = (header) => {
		return (
			<tr className="history-table-task-view__table-tr">
				<th className="history-table-task-view__table-th">{header.columnOne}</th>
				<th className="history-table-task-view__table-th">{header.columnTwo}</th>
			</tr>
		);
	};

	const renderTableLine = (line) => {
		return (
			<tr className="history-table-task-view__table-tr">
				<td className="history-table-task-view__table-td">{line.columnOne}</td>
				<td className="history-table-task-view__table-td">{line.columnTwo}</td>
			</tr>
		);
	};

	const getComponentClassName = () => {
		const modifier = { 'history-table-task-view--passed': passed };
		return classnames('history-table-task-view', className, modifier);
	};

	return (
		<div className={getComponentClassName()}>
			<table className="history-table-task-view__table">
				<thead className="history-table-task-view__table-head">
					{content?.header && renderTableHeader(content.header)}
				</thead>
				<tbody className="history-table-task-view__table-body">
					{content?.lines?.map(renderTableLine)}
				</tbody>
			</table>
		</div>
	);
};

export default HistoryTableTaskView;

HistoryTableTaskView.propTypes = {
	task: PropTypes.shape({
		content: PropTypes.shape({
			header: PropTypes.shape({
				columnOne: PropTypes.string,
				columnTwo: PropTypes.string,
			}),
			lines: PropTypes.arrayOf(
				PropTypes.shape({
					columnOne: PropTypes.string,
					columnTwo: PropTypes.string,
				})
			),
		}),
	}),
	className: PropTypes.string,
	passed: PropTypes.bool,
};
