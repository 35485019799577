import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { IconWrapper } from '../../../../../../../theme';
import { IconArrowLeft } from '../../../../../assets';
import ResultsSheetTable from '../../../results-sheet/components/results-sheet-table/results-sheet-table';
import { getElementNumberMax } from '../../../utils/get-car-element-info';

const ValveManeuverSummary = ({
	line,
	damageLocation,
	resultsSheet,
	tableField,
	trainComposition,
	material,
	isProcessRecap = false,
}) => {
	const {
		valve_maneuver: valveManeuver,
		isolated_compressor_units: isolatedCompressorUnits,
		isolated_emd_units: isolatedEmdUnits,
		kadi_position,
	} = resultsSheet?.valve_maneuver_group || {};
	const { choice, pressure } = kadi_position || {};
	const hasValveManeuver = valveManeuver === 'yes';

	const { t } = useTranslation();

	return (
		<>
			<div className={'rer-resume-summary__substep'}>
				<span className="rer-resume-summary__substep__title">
					{t('process:process-rer-resume.results-sheet.valve-maneuver')}
				</span>
				<span
					className={classNames('rer-resume-summary__substep__value', {
						'rer-resume-summary__substep__value--lighter': isProcessRecap,
					})}
				>
					{t(`process:process-rer-resume.results-sheet.valve-maneuver-form.${valveManeuver}`)}
				</span>
			</div>
			{hasValveManeuver && (
				<div>
					<ResultsSheetTable
						displayTitle={false}
						formDataDamageLocation={damageLocation}
						resultsSheetFormData={resultsSheet}
						handleChange={() => {}}
						field={tableField}
						line={line}
						currentGroup={'valve_maneuver_group'}
					/>
					<div className={'rer-resume-summary__substep--secondary'}>
						<span
							className={classNames('rer-resume-summary__substep__title--secondary', {
								'rer-resume-summary__substep__title--secondary--lighter': isProcessRecap,
							})}
						>
							{t('process:process-rer-resume.results-sheet.isolated-compressor-units')}
						</span>
						<div>
							<span
								className={classNames('rer-resume-summary__substep__value', {
									'rer-resume-summary__substep__value--lighter': isProcessRecap,
								})}
							>
								{isolatedCompressorUnits}
							</span>
							<Trans
								parent="span"
								className={classNames('rer-resume-summary__substep__value', {
									'rer-resume-summary__substep__value--lighter': isProcessRecap,
								})}
								i18nKey="process:process-rer-resume.summary.operator"
								values={{
									name: 'compresseurs',
									number: getElementNumberMax('compresseurs', trainComposition, material),
								}}
							>
								<strong className="damage-form-number-selector__label--bold" />
							</Trans>
						</div>
					</div>
					<div className={'rer-resume-summary__substep--secondary'}>
						<span
							className={classNames('rer-resume-summary__substep__title--secondary', {
								'rer-resume-summary__substep__title--secondary--lighter': isProcessRecap,
							})}
						>
							{t('process:process-rer-resume.results-sheet.isolated-emd-units')}
						</span>
						<div>
							<span
								className={classNames('rer-resume-summary__substep__value', {
									'rer-resume-summary__substep__value--lighter': isProcessRecap,
								})}
							>
								{isolatedEmdUnits}
							</span>
							<Trans
								parent="span"
								className={classNames('rer-resume-summary__substep__value', {
									'rer-resume-summary__substep__value--lighter': isProcessRecap,
								})}
								i18nKey="process:process-rer-resume.summary.operator"
								values={{
									name: 'EMD',
									number: getElementNumberMax('EMD', trainComposition, material),
								}}
							>
								<strong className="damage-form-number-selector__label--bold" />
							</Trans>
						</div>
					</div>
					{line === 'B' && (
						<div className={'rer-resume-summary__substep--secondary'}>
							<span
								className={classNames('rer-resume-summary__substep__title--secondary', {
									'rer-resume-summary__substep__title--secondary--lighter': isProcessRecap,
								})}
							>
								{t('process:process-rer-resume.results-sheet.kadi-position')}
							</span>
							<span
								className={classNames('rer-resume-summary__substep__value', {
									'rer-resume-summary__substep__value--lighter': isProcessRecap,
								})}
							>
								{t(`process:process-rer-resume.results-sheet.kadi-position-form.${choice}`)}
								{pressure && (
									<IconWrapper className="right-arrow__icon" Component={IconArrowLeft} />
								)}
								{pressure}
							</span>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default ValveManeuverSummary;

ValveManeuverSummary.propTypes = {
	line: PropTypes.string,
	trainComposition: PropTypes.shape({}),
	material: PropTypes.string,
	damageLocation: PropTypes.object,
	resultsSheet: PropTypes.object,
	tableField: PropTypes.object,
	isProcessRecap: PropTypes.bool,
};
