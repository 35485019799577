import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { SprContext } from '../../../../../shared';
import RailWaySubheader from '../../../../../shared/components/railway-subheader/railway-subheader';
import { searchBinder } from '../../../../binder/binder.services';
import RailwayBinderList from '../railway-binder-list';

/**
 * Render the material binder list page
 * @return {JSX.Element}
 * @constructor
 */
const MaterialBinderList = ({ disableLineSwitch }) => {
	const { t } = useTranslation();
	const { materialTechId, processId } = useParams();
	const [binderList, setBinderList] = useState([]);
	const { setCurrentBinder, currentBinder, setTrainBinderCount, side, associatedLine } =
		useContext(SprContext);

	const fetchMaterialBinders = useCallback(
		async (material_tech_id) => {
			const { data = [] } = await searchBinder({
				material_tech_id,
				status: ['published'],
				associatedLine,
			});
			setBinderList(data);
			const [binderInfo = {}] = data;
			const headerBinder = {
				type: binderInfo.type,
				materialLabel: binderInfo.material_label,
				color: binderInfo.color,
			};
			setTrainBinderCount(data.length);
			setCurrentBinder(headerBinder);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setBinderList, setCurrentBinder, setTrainBinderCount]
	);

	const onBinderClick = (binder) => {
		setCurrentBinder(binder, true);
	};

	useEffect(() => fetchMaterialBinders(materialTechId), [materialTechId, fetchMaterialBinders]);

	const subHeader = (
		<RailWaySubheader
			title={currentBinder?.materialLabel}
			linkTo={'/railway/binders'}
			returnLabel={t('train:railway-binder-list.header.back-material')}
		/>
	);

	return (
		<RailwayBinderList
			binders={binderList}
			subHeader={subHeader}
			processId={processId}
			onBinderClick={onBinderClick}
			allowLineSwitch={side === 'train'}
			disabledLineSwitch={disableLineSwitch}
		/>
	);
};

const MaterialBinderListWrapper = (props) => <MaterialBinderList {...props} />;

export default MaterialBinderListWrapper;

MaterialBinderList.propTypes = {
	disableLineSwitch: PropTypes.bool,
};
