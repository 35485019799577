import { getElementNumberMax, getElementType } from './get-car-element-info';

/**
 * Validate yes no
 * @param value
 * @return {boolean}
 */
const validateYesNo = (value) => {
	return ['yes', 'no'].includes(value);
};

/**
 * Validate number
 * @param value
 * @param fieldName
 * @param trainComposition
 * @param material
 * @return {boolean}
 */
const validateNumber = (value, fieldName, trainComposition, material) => {
	const elementType = getElementType(fieldName);
	const numberMax = getElementNumberMax(elementType, trainComposition, material);
	return value >= 0 && value <= numberMax;
};

/**
 * Validate comment
 * @param value
 * @return {boolean}
 */
const validateComment = (value) => {
	return value?.length <= 140;
};

/**
 * Validate speed restriction line A
 * @param value
 * @return {boolean}
 */
const validateSpeedRestrictionLineA = (value) => {
	const validValueLength = value?.length > 0 && value?.length <= 6;
	const validSpeedOption = ['1/10', '1/4', '1/2', '30km/h'].includes(value);
	return validSpeedOption || validValueLength;
};

/**
 * Validate speed restriction line B - ratp side
 * @param value
 * @return {boolean}
 */
const validateSpeedRestrictionRatpLineB = (value) => {
	const validValueLength = value?.length > 0 && value?.length <= 6;
	const validSpeedOption = ['none', '1/10', '1/4', '1/2', '30km/h'].includes(value);
	return validSpeedOption || validValueLength;
};

/**
 * Validate speed restriction line B - rfn side
 * @param value
 * @return {boolean}
 */
const validateSpeedRestrictionRfnLineB = (value) => {
	return ['none', 'AUTOM', 'V120', 'ME 100', 'MA 80', '20km/h'].includes(value);
};

/**
 * Validate resume type - ratp side
 * @param value
 * @return {boolean}
 */
const validateResumeRatp = (value) => {
	return ['travellers', 'hlp', 'assistance'].includes(value);
};

/**
 * Validate resume type - rfn side
 * @param value
 * @return {boolean}
 */
const validateResumeRfn = (value) => {
	return ['travellers', 'hlp', 'assistance'].includes(value);
};

export {
	validateComment,
	validateNumber,
	validateResumeRatp,
	validateResumeRfn,
	validateSpeedRestrictionLineA,
	validateSpeedRestrictionRatpLineB,
	validateSpeedRestrictionRfnLineB,
	validateYesNo,
};
