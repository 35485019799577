import React from 'react';
import PropTypes from 'prop-types';

import './popup-offline.scss';

import { PopupWrapper } from '../../../../shared';
import { IconWrapper } from '../../..';

const PopupOffline = ({ popupControl, icon, title, children }) => {
	return (
		<PopupWrapper visible={popupControl.visible}>
			<div className="popup-offline">
				<div className="popup-offline__header">
					{icon && <IconWrapper className="popup-offline__icon" Component={icon} />}
					<h1 className="popup-offline__title">{title}</h1>
				</div>
				<div className="popup-offline__body">{children}</div>
			</div>
		</PopupWrapper>
	);
};

export default PopupOffline;

PopupOffline.propTypes = {
	popupControl: PropTypes.shape({
		visible: PropTypes.bool,
		setVisible: PropTypes.func,
		show: PropTypes.func,
		hide: PropTypes.func,
	}),
	icon: PropTypes.node,
	title: PropTypes.string,
	children: PropTypes.node,
};
