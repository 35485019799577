import { hasFieldError } from '../../../shared';

/**
 * Check if the provided zone link is valid
 * @param {string} link to zone
 * @param urlValidation
 * @returns {string[]}
 */
const binderZoneLinkValidator = (link = '', urlValidation = '') => {
	try {
		link = link.toLowerCase();
		if (link === '') {
			return [];
		}
		const url = new URL(link);
		const isValidGuepardLink = url.hostname.includes(urlValidation);
		return !isValidGuepardLink ? ['invalid'] : [];
	} catch (error) {
		return ['invalid'];
	}
};

/**
 * Validate validatePopupBinderZoneLink form data
 * @param {string} link to zone
 * @param {string} urlValidation
 * @returns {{link: string[]}} errors
 */
const validatePopupBinderZoneLink = (link, urlValidation) => ({
	link: binderZoneLinkValidator(link, urlValidation),
});

const getBinderZoneLinkMessageKey = (formError) => ({
	link: hasFieldError('invalid', formError?.link) ? 'binder:popup.form.error.zone-link-format' : '',
});

export { getBinderZoneLinkMessageKey, validatePopupBinderZoneLink };
