import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './popup-create-binder.scss';

import { checkFormError, useDebouncedAction } from '../../../../../shared';
import { Button, ErrorMessage, PopupDefault } from '../../../../../theme';
import { copyBinder, createBinder, fetchBinderListById } from '../../../binder.services';
import {
	getFormBinderFieldMessageKey,
	validatePopupCreateBinderForm,
	validatePopupCreateBinderTrainForm,
} from '../../../utils/validate-popup-binder-form';
import FormBinder from '../form-binder/form-binder';

/**
 * Render the PopupCreateBinder component
 * @param props.onSubmitSuccess Callback called after successful submit
 * @param props.popupControl
 * @param [props.train]
 * @returns {JSX.Element}
 */
const PopupCreateBinder = (props) => {
	const { onSubmitSuccess = () => {}, popupControl, train } = props;
	const { t } = useTranslation();

	const defaultBinderColor = '#C4C4C4';
	const [formData, setFormData] = useState({
		binderColor: defaultBinderColor,
	});
	const [existingBinderList, setExistingBinderList] = useState([]);
	const [extendedBinderStatus, setExtendedBinderStatus] = useState('');
	const [networkError, setNetworkError] = useState(false);

	const getFormError = () => {
		if (train) {
			return validatePopupCreateBinderTrainForm(formData, {}, existingBinderList);
		} else {
			return validatePopupCreateBinderForm(formData, {}, existingBinderList);
		}
	};

	const formError = getFormError();
	const formErrorMessage = getFormBinderFieldMessageKey(formError);

	const reInitForm = () => {
		setFormData({ color: defaultBinderColor, type: '' });
		setExtendedBinderStatus('');
		setNetworkError(false);
	};

	// Fetch existing binder by exact id (debounced)
	const requestBinderListById = useDebouncedAction((binderId) => {
		if (binderId?.trim()) {
			fetchBinderListById(binderId.trim())
				.then((response) => {
					setNetworkError(false);
					const binderList = response?.data;
					if (binderList.length > 0) {
						setExistingBinderList(binderList);

						// Manage form
						// binderList is ordered by published_at (desc), so first = latest
						const publishedBinder = binderList.find(({ status }) => status === 'published');
						if (publishedBinder) {
							setFormData((previousFormData) => ({
								...previousFormData,
								title: publishedBinder.title,
								color: publishedBinder?.color,
								abbreviation: publishedBinder?.abbreviation,
								lineNumber: publishedBinder?.associated_line,
							}));
							setExtendedBinderStatus(publishedBinder.status);
						} else {
							setFormData((previousFormData) => ({
								...previousFormData,
								title: '',
								color: defaultBinderColor,
								abbreviation: '',
								lineNumber: publishedBinder?.associated_line,
							}));
							setExtendedBinderStatus(binderList[0]?.status);
						}
					} else {
						setExistingBinderList([]);
						setExtendedBinderStatus('');
					}
				})
				.catch(() => {
					setNetworkError(true);
				});
		}
	}, 1000);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData((oldForm) => ({ ...oldForm, [name]: value }));
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		const existingBinderPublished = existingBinderList.find(
			(binder) => binder.status === 'published'
		);
		const {
			lineNumber: associated_line,
			color,
			material,
			station,
			stationType,
			...restFormData
		} = formData;
		if (!checkFormError(formError) && !existingBinderPublished) {
			reInitForm();
			const newBinder = { associated_line, color, ...restFormData };

			if (train) {
				newBinder.abbreviation = undefined;
			}

			// Add material ref for train binder type
			if (formData?.type?.startsWith('train-')) {
				newBinder.material_tech_id = material;
			}

			// Add station and stationType field when creating a binder of type man
			if (formData?.type === 'man') {
				newBinder.station_tech_id = station;
				newBinder.station_type = stationType;
			}

			createBinder(newBinder).then(popupControl.hide).then(onSubmitSuccess);
		} else if (!checkFormError(formError) && existingBinderPublished) {
			reInitForm();

			// Add material ref for train binder type
			const newBinder = { associated_line, color, ...restFormData };
			if (material && newBinder.type?.startsWith('train-')) {
				newBinder.material_tech_id = material;
			}

			copyBinder(newBinder, existingBinderPublished.tech_id)
				.then(popupControl.hide)
				.then(onSubmitSuccess);
		}
	};

	// ignore requestBinderListById dependency to avoid infinite render
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => requestBinderListById(formData.id), [formData.id]);

	return (
		<PopupDefault
			className={classnames('popup-create-binder__wrapper', {
				'popup-create-binder__wrapper--train': train,
			})}
			popupControl={popupControl}
			title={t(
				train ? 'binder:popup.create-binder.title-train' : 'binder:popup.create-binder.title'
			)}
			onClose={reInitForm}
		>
			<form className="popup-create-binder__form" onSubmit={handleSubmit}>
				<div className="popup-create-binder__form-wrapper">
					<FormBinder
						form={formData}
						validation={formError}
						errorMessage={formErrorMessage}
						onChange={handleChange}
						extendedBinderStatus={extendedBinderStatus}
						create
						train={train}
					/>
				</div>
				<div className="popup-create-binder__controller">
					<Button
						className="popup-create-binder__submit"
						variant="primary"
						type="submit"
						disabled={checkFormError(formError)}
						label={t('binder:popup.form.field.submit')}
					/>
				</div>
				{networkError && (
					<ErrorMessage className="popup-create-binder__network-error">
						{t('error.default')}
					</ErrorMessage>
				)}
			</form>
		</PopupDefault>
	);
};

PopupCreateBinder.propTypes = {
	onSubmitSuccess: PropTypes.func,
	popupControl: PropTypes.shape({
		hide: PropTypes.func,
		show: PropTypes.func,
		visible: PropTypes.bool,
	}),
	train: PropTypes.bool,
};

export default PopupCreateBinder;
