import React from 'react';
import { useTranslation } from 'react-i18next';

import './no-cache-available.scss';

import { ImgUnfinishedProcess } from '../../../domains/process/assets';
import OfflineHeader from '../../../domains/user/components/offline-header/offline-header';
import { IconWrapper } from '../../index';

/**
 * Render The no cache available error page
 * @return {JSX.Element}
 * @constructor
 */
const NoCacheAvailable = () => {
	const { t } = useTranslation();

	return (
		<main className="no-cache-available">
			<OfflineHeader />
			<div className="no-cache-available__content">
				<IconWrapper className="no-cache-available__image" Component={ImgUnfinishedProcess} />
				<p className="no-cache-available__text">{t('user:no-cache-available.text')}</p>
			</div>
		</main>
	);
};

export default NoCacheAvailable;
