import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import './image-form.scss';

import { useDebouncedAction } from '../../../../../../../shared';
import { useSheetContentContext } from '../../../../../context/sheet-content-context';
import { deleteImage, uploadImage } from '../../../../../image.services';

import ImageFormRow from './image-form-row/image-form-row';

const ImageForm = ({ className, content }) => {
	const imageInputName = 'imageId';

	const { sheetId, binderId } = useParams();
	const { t } = useTranslation();

	const { setCurrentNodeContent, getCurrentActive, saveSheetContent, updateDelay } =
		useSheetContentContext();
	const debouncedSetCurrentNodeContent = useDebouncedAction(setCurrentNodeContent, updateDelay);

	const [imageBlockList, setImageBlockList] = useState(content?.images || []);

	// Update Only images field in node content (keep other fields unchanged)
	const updateActiveNodeContent = (newImageBlocks) => {
		const activeNode = getCurrentActive();

		setImageBlockList(newImageBlocks);
		const savedImageBlock = newImageBlocks
			.filter(({ imageId, error }) => imageId && !error)
			.map(({ imageId, title, description, rowId }) => ({ imageId, title, description, rowId }));
		debouncedSetCurrentNodeContent({
			...activeNode?.content,
			images: savedImageBlock,
		});
	};

	// Update a single image block
	const updateImageBlocks = (position, name, value) => {
		const newImageBlockList = [...imageBlockList];
		newImageBlockList[position][name] = value;
		updateActiveNodeContent(newImageBlockList);
	};

	const handleFormChange = (position) => (event) => {
		const { name, value } = event.target;
		updateImageBlocks(position, name, value);
	};

	const removeBlockByPosition = (blocks, positionToRemove) =>
		blocks.filter((elm, elmPos) => positionToRemove !== elmPos);

	const handleDeleteImage = (position) => async () => {
		const { imageId } = imageBlockList[position];
		try {
			if (imageId) {
				// An image is loaded
				const response = await deleteImage(imageId);
				const { success } = response?.data;
				// Reset imageId
				updateImageBlocks(position, 'imageId', false);
				return { success };
			} else {
				// No image to remove
				return { success: true };
			}
		} catch (error) {
			updateImageBlocks(
				position,
				'error',
				t('sheet:sheet-details.image-form.network-error-delete-image')
			);
		} finally {
			// Reset file input value
			updateImageBlocks(position, 'file', false);
		}
	};

	const handleDeleteRow = (position) => async () => {
		const deleteImageResult = await handleDeleteImage(position)();
		if (deleteImageResult?.success) {
			const newImageBlockList = removeBlockByPosition(imageBlockList, position);
			updateActiveNodeContent(newImageBlockList);
		}
	};

	const handleContextFormSync = () => {
		const activeTemplate = getCurrentActive();
		// init current content node and set history if it's a new template
		const newForm = activeTemplate?.content?.images;
		if (!newForm) {
			// Initialize
			updateActiveNodeContent([]);
		} else {
			// Load images from sheet data
			setImageBlockList((previousImageList) => {
				return previousImageList.map((imageBlock, position) => ({
					...imageBlock,
					...(newForm[position] || {}),
				}));
			});
		}
	};

	const handleUploadImage = (position) => async () => {
		const { file: image, imageId } = imageBlockList[position] || {};
		if (image && !imageId) {
			try {
				updateImageBlocks(position, 'error', false);
				// Save the sheet here to ensure there is no orphan image left by the image cleanup process when sheet content is updated
				await saveSheetContent(binderId, sheetId);
				const response = await uploadImage(image);
				const { success, id } = response?.data;
				if (success) {
					updateImageBlocks(position, imageInputName, id);
				}
			} catch (error) {
				updateImageBlocks(position, 'file', false);
				if (error?.response?.status === 400 || error?.response?.status === 413) {
					updateImageBlocks(position, 'error', t('sheet:sheet-details.image-form.invalid-format'));
				} else {
					updateImageBlocks(position, 'error', t('sheet:sheet-details.image-form.network-error'));
				}

				console.error(error);
			}
		}
	};

	const addForm = () => {
		// uuid to ensure that upload is not trigger more than once (react rerender list process)
		const newFormData = { imageId: '', title: '', description: '', rowId: uuid() };
		updateActiveNodeContent([...imageBlockList, newFormData]);
	};

	const renderFormRow = (data, position) => {
		const { imageId, title, description, file, rowId, error } = data;
		return (
			<ImageFormRow
				key={rowId}
				imageId={imageId}
				title={title}
				description={description}
				errorHelperText={error}
				onUpload={handleUploadImage(position)}
				onTitleChange={handleFormChange(position)}
				onDescriptionChange={handleFormChange(position)}
				onFileChange={(event) => {
					updateImageBlocks(position, 'file', event.target.files[0]);
				}}
				onDeleteImage={handleDeleteImage(position)}
				onDeleteRow={handleDeleteRow(position)}
				fileInputFieldName={imageInputName}
				selectedFile={file}
				index={position}
			/>
		);
	};

	const showAddImageCta = () => {
		if (imageBlockList?.length > 0) {
			// All form finished to load their image, if a form delete his image return false to
			return imageBlockList.every((formData) => formData.imageId);
		}
		return true;
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(handleContextFormSync, [content?.images]);
	return (
		<div className={classNames(className, 'image-form')}>
			<ul
				className={classNames('image-form__list', {
					'image-form__list--empty':
						!imageBlockList || (imageBlockList && imageBlockList.length === 0),
				})}
			>
				{imageBlockList && imageBlockList.map(renderFormRow)}
			</ul>
			{showAddImageCta() && (
				<button className="image-form__cta-add" type="button" onClick={addForm}>
					{t('sheet:sheet-details.image-form.add-image-cta')}
				</button>
			)}
		</div>
	);
};

export default ImageForm;

ImageForm.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
};
