import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import './spr-binder-theme-sheet-list.scss';

import { buildSprProcessUri } from '../../../../../../shared';
import { EditorRendering } from '../../../../../../theme';
import { resolveSheetColor, SheetType } from '../../../../../sheet';
import { getBinderThemes } from '../../../../binder-theme.services';

/**
 * Spr Binder detail Theme sheet list
 * @param {object} props
 * @returns {JSX.Element}
 */
const SprBinderThemeSheetList = ({ binder = {}, sheetList = [] }) => {
	const { id: binderId } = useParams();
	const [themeList, setThemeList] = useState([]);

	const getThemes = () => {
		if (binder?.tech_id) {
			getBinderThemes(binder.tech_id).then((response) => {
				setThemeList(response.data);
			});
		}
	};

	const renderSheet = (sheet, theme) => {
		const info = {
			binderTechnicalId: binderId,
			sheetNumber: sheet?.number,
		};
		const color = resolveSheetColor(sheet.color, binder.color, theme.color);
		return (
			<Link to={buildSprProcessUri(info)}>
				<div key={sheet.number} className="list__row">
					<EditorRendering content={sheet.title} className="list__cell" />
					<SheetType
						className="list__cell--number"
						type={sheet.type}
						sheetNumber={sheet.number}
						color={color}
					/>
				</div>
			</Link>
		);
	};

	const generateThemeListItem = (theme) => {
		const sheetListFiltered = sheetList.filter((sheet) => sheet?.theme_tech_id === theme?.tech_id);
		if (sheetListFiltered.length > 0) {
			return (
				<div className="binder-sheet-theme-list__content__list">
					<div className="list__title">{theme.title}</div>
					{sheetListFiltered.map((sheet) => renderSheet(sheet, theme))}
				</div>
			);
		} else {
			return false;
		}
	};

	useEffect(getThemes, [binder?.tech_id]);

	return (
		<div className="spr-binder-theme-sheet-list">
			<div className="binder-sheet-theme-list__content">
				{Array.isArray(themeList) && themeList.map(generateThemeListItem)}
			</div>
		</div>
	);
};
export default SprBinderThemeSheetList;

SprBinderThemeSheetList.propTypes = {
	binder: PropTypes.shape({
		tech_id: PropTypes.string,
		color: PropTypes.string,
	}),
	sheetList: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			number: PropTypes.string,
			color: PropTypes.string,
		})
	),
};
