import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-create-sheet-copy-form.scss';

import TextfieldAutocomplete from '../../../../../theme/components/form/text-field-autocomplete/textfield-autocomplete';
import { searchBinder } from '../../../../binder/binder.services';
import { findSheetByBinderTechId } from '../../../sheet.services';
import { addSheetsTitleRaw } from '../../../utils/add-sheet-title-raw-utils/add-sheet-title-raw-utils';

const PopupCreateSheetCopyForm = ({ binder, setSheetSelected, isInitialSheet }) => {
	const { t } = useTranslation();
	const [radioSelected, setRadioSelected] = useState('');
	const sheetRadioTypes = ['same-binder', 'other-published', 'other-revoke'];
	const initialSheetRadioTypes = ['revoke-binder', 'published-binder', 'in-progress-binder'];
	const radioTypes = isInitialSheet ? initialSheetRadioTypes : sheetRadioTypes;

	const [binderList, setBinderList] = useState([]);
	const [binderField, setBinderField] = useState('');
	const [binderSelected, setBinderSelected] = useState();

	const [sheetList, setSheetList] = useState([]);
	const [sheetField, setSheetField] = useState('');

	const init = () => {
		setBinderField('');
		setBinderSelected('');
		setSheetField('');
		setSheetSelected('');
	};

	// handle input binder value
	const handleBinderInputChange = (event) => {
		setBinderField(event.target.value);
		setBinderSelected('');
	};

	// handle input sheet value
	const handleSheetInputChange = (event) => {
		setSheetField(event.target.value);
		setSheetSelected('');
	};

	const handleRadioChange = (e) => {
		init();
		const { value } = e.target;
		setRadioSelected(value);
		if (value === 'same-binder') {
			setBinderSelected(binder.tech_id);
			setBinderField(`${binder.id}`);
		} else if (value === 'other-published' || value === 'published-binder') {
			initBinderSearch('published');
		} else if (value === 'other-revoke' || value === 'revoke-binder') {
			initBinderSearch('revoke');
		} else if (value === 'in-progress-binder') {
			initBinderSearch('work-in-progress');
		}
	};

	const generateRadioButtons = (type) => {
		return (
			<li key={type} className="popup-create-sheet__form-copy-radio-type">
				<input
					type="radio"
					id={type}
					name="radio-type"
					value={type}
					checked={radioSelected === type}
					className="radio-type__input"
					onChange={handleRadioChange}
				/>
				<label htmlFor={type} className="radio-type__label">
					{t(`sheet:popup.create.field.radio.${type}`)}
				</label>
			</li>
		);
	};

	/**
	 * Binder autocompletions functions
	 */

	// api initialization
	const initBinderSearch = (filter) => {
		const params = {};
		if (filter) {
			params.status = filter;
		}
		searchBinder({ ...params }).then((response) => {
			setBinderList(response?.data);
		});
	};

	// autocomplete input search
	const getBinderSuggestions = async (query) => {
		const dataList = await searchExistingBinderById(query);
		return dataList.map(renderBinderLabelAutoCompletion);
	};

	const searchExistingBinderById = async (query) => {
		const copiedBinderList = [...binderList];
		if (query) {
			return copiedBinderList.filter((binderElement) => binderElement?.id?.includes(query));
		} else {
			return copiedBinderList;
		}
	};

	// preparation for render function
	const renderBinderLabelAutoCompletion = (binderRender) => {
		const { id } = binderRender;
		let text = id;
		if (binderRender.changed_description) {
			text = `${id} - ${binderRender.changed_description}`;
		}
		const lines = [{ type: 'label', text }];
		const label = { className: 'multiLink-template-form__autocomplete', lines };
		return { value: binderRender, label };
	};

	// handle click au autocompletion value
	const handleBinderSelectedValue = (binderValue) => {
		const { value } = binderValue;
		setBinderSelected(value.tech_id);
		setBinderField(value.id);
		setSheetSelected('');
		setSheetField('');
	};

	/**
	 * Sheet autocompletion functions
	 */
	const initSheetSearch = () => {
		if (binderSelected) {
			findSheetByBinderTechId(binderSelected, null, isInitialSheet).then((response) => {
				const sheetListWithTitleRaw = addSheetsTitleRaw(response?.data, isInitialSheet);
				setSheetList(sheetListWithTitleRaw);
			});
		}
	};

	const getSheetSuggestions = async (query) => {
		const dataList = await searchExistingSheetByNumber(query);
		return dataList.map(renderLabelAutoCompletion);
	};

	const searchExistingSheetByNumber = async (query) => {
		const copiedSheetList = [...sheetList];
		if (query) {
			return copiedSheetList.filter(
				(sheet) => sheet?.number_search?.includes(query) || sheet?.titleRaw?.includes(query)
			);
		} else {
			return copiedSheetList;
		}
	};

	const renderLabelAutoCompletion = (sheet) => {
		const { number_search, titleRaw } = sheet;
		const lines = [{ type: 'label', text: `${number_search.toUpperCase()} - ${titleRaw}` }];
		const label = { className: 'multiLink-template-form__autocomplete', lines };
		return { value: sheet, label };
	};

	// handle input sheet value selected by autocompletion
	const handleSheetSelectedValue = (sheet) => {
		const { value } = sheet;
		setSheetSelected(value.sheet_id);
		setSheetField(value.number_search);
	};

	useEffect(initSheetSearch, [binderSelected, isInitialSheet]);

	return (
		<div className="popup-create-sheet">
			<ul className="radio-types">{radioTypes.map(generateRadioButtons)}</ul>

			<div className="popup-create-sheet__form-copy__form-field">
				<TextfieldAutocomplete
					name="binderId"
					className="popup-create-sheet__form-copy__form-field__autocomplete"
					value={binderField}
					handleChange={(e) => {
						handleBinderInputChange(e);
					}}
					getSuggestions={getBinderSuggestions}
					refreshSuggestion={binderList}
					selectedValue={handleBinderSelectedValue}
					label={t('sheet:popup.create.field.binder-search.placeholder')}
					disabled={!radioSelected || radioSelected === 'same-binder'}
				/>
			</div>

			<div className="popup-create-sheet__form-copy__form-field">
				<TextfieldAutocomplete
					name="sheetNumber"
					className="popup-create-sheet__form-copy__form-field__autocomplete"
					value={sheetField}
					handleChange={(e) => {
						handleSheetInputChange(e);
					}}
					getSuggestions={getSheetSuggestions}
					refreshSuggestion={sheetList}
					selectedValue={handleSheetSelectedValue}
					label={t('sheet:popup.create.field.sheet-search.placeholder')}
					disabled={!radioSelected || !binderSelected}
				/>
			</div>
		</div>
	);
};

export default PopupCreateSheetCopyForm;

PopupCreateSheetCopyForm.propTypes = {
	binder: PropTypes.object,
	isInitialSheet: PropTypes.bool,
	setSheetSelected: PropTypes.func.isRequired,
};
