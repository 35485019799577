import { resolveSheetColor } from '../../sheet';

/**
 * @typedef {object} ResumeConditions
 * @prop {"normal" | "direct"} position_supply
 * @prop {"travelers"| "hlp" | "secours"} resume
 * @prop {number} engine_units
 * @prop {number} bogie_car_units
 * @prop {object} last_update
 */

/**
 * @typedef {object} Process
 * @prop {string} binder_tech_id
 * @prop {Date} created_at
 * @prop {string} description
 * @prop {Date} ended_at
 * @prop {object[]} history
 * @prop {number} line
 * @prop {string[]} owners_history
 * @prop {object} resume_conditions:
 * @prop {string} sheet_tech_id
 * @prop {string} started_at
 * @prop {string} tech_id
 * @prop {string} track
 * @prop {'train' | 'pcc'} type
 * @prop {ProcessStatus} status
 * @prop {string} [material_label]
 * @prop {string} [material_tech_id]
 * @prop {string} [train_id]
 * @prop {string} [train_tech_id]
 * @prop {string} [position_info]
 * @prop {'platform' | 'half-platform' | 'interstation' | 'other'} [position_type]
 * @prop {string} [link]
 * @prop {string} [label]
 * @prop {ResumeConditions} resume_conditions
 */

/**
 * @param {Process[]} processList
 * @returns {Process[]}
 */
const mapProcessList = (processList = []) => {
	return processList.map((process) => {
		const mappedProcess = { ...process };
		const history = process.history;

		// Get First Sheet title if more than one step has been runned
		if (history.length > 0) {
			mappedProcess.firstSheetTitle = history[0].sheet.title;
		}

		// Get Sheet Progression if more than one step has been runned
		if (history.length > 0) {
			mappedProcess.browsedSheets = history.reduce((sheets, historyItem) => {
				const sheet = historyItem.sheet;
				const binder = historyItem.binder;
				const foundSheet = sheets.find((sheetItem) => sheetItem.number === sheet.number);
				if (!foundSheet) {
					sheets.push({
						number: sheet.number,
						color: resolveSheetColor(sheet.color, binder.color),
						type: sheet.type,
						title: sheet.title,
						binder_type: binder.type,
						signalTechId: sheet.signalTechId,
					});
				}
				return sheets;
			}, []);
		}
		return mappedProcess;
	});
};

export default mapProcessList;
