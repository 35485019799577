import React from 'react';
import PropTypes from 'prop-types';

import './associated-lines-checkbox.scss';

import { IconWrapper } from '../../../../../index';

/**
 * Associated line icon checkbox
 * @param {object} props
 * @returns {JSX.Element}
 */
const AssociatedLinesCheckbox = ({
	line,
	checked,
	icon,
	iconDisabled,
	handleCheckboxChange,
	disabled = false,
}) => {
	return (
		<div className="associated-line-checkbox">
			<input
				id={`line-${line}`}
				type="checkbox"
				name={`line-${line}`}
				className="associated-line-checkbox__input"
				checked={checked}
				disabled={disabled}
				value={line}
				onChange={handleCheckboxChange}
			/>
			<label htmlFor={`line-${line}`} className="associated-line-checkbox__label">
				{icon && (
					<IconWrapper
						className="associated-line-checkbox__label__icon"
						Component={checked ? icon : iconDisabled}
					/>
				)}
			</label>
		</div>
	);
};

export default AssociatedLinesCheckbox;

AssociatedLinesCheckbox.propTypes = {
	line: PropTypes.string.isRequired,
	checked: PropTypes.bool.isRequired,
	icon: PropTypes.elementType,
	iconDisabled: PropTypes.elementType,
	handleCheckboxChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};
