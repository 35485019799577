import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { publishPendingEntity, synchroniseCache } from '../../cache-access';
import { cleanProcessFromCache } from '../../cache-access/cache/cache-service';
import { getStatus } from '../../cache-access/entity/status-utils';
import { useAppSetupContext, useSprContext } from '../../index';

const RailwayOfflineContext = createContext({});

const RailwayOfflineProvider = ({ children }) => {
	const [syncStatus, setSyncStatus] = useState({ data: {}, metadata: {} });
	const { online, user } = useAppSetupContext();
	const { associatedLine } = useSprContext();
	const location = useLocation();

	const isMainLine = useMemo(() => {
		const { associated_line: userAssociatedLines = [], main_line: mainLine } = user;
		const userLine = mainLine || (userAssociatedLines.length === 1 ? userAssociatedLines[0] : null);
		return userLine === associatedLine;
	}, [user, associatedLine]);

	const serverSync = useCallback(() => {
		console.info('Send pending cache to server');
		cleanProcessFromCache()
			.then(() => publishPendingEntity(associatedLine))
			.then(() => {
				console.info('Pending entity sent !');
			});
	}, [associatedLine]);

	const updateSyncStatus = useCallback(() => {
		getStatus(associatedLine).then((status) => {
			setSyncStatus(status);
		});
	}, [associatedLine]);

	/**
	 * @callback launchSync
	 * @param line The line to synchronize
	 * @return {void}
	 */
	const launchSync = useCallback(() => {
		if (online) {
			synchroniseCache(associatedLine);
		}
		// Add location.pathname as extra dependency to trigger sync on location change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [online, associatedLine, location.pathname]);

	/**
	 * Trigger sync and check for cache update every hours
	 */
	const triggerSync = () => {
		let intervalId;
		if (isMainLine || syncStatus.data.state === 'success') {
			launchSync();
			intervalId = setInterval(
				() => {
					launchSync();
				},
				60 * 60 * 1000
			);
		}

		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	};

	useEffect(() => {
		if (online) {
			serverSync();
		}
	}, [online, serverSync]);

	useEffect(triggerSync, [associatedLine, isMainLine, launchSync, syncStatus.data.state]);

	useEffect(() => {
		updateSyncStatus();
		const intervalId = setInterval(() => {
			updateSyncStatus();
		}, 1000);

		return () => clearInterval(intervalId);
	}, [updateSyncStatus]);

	return (
		<RailwayOfflineContext.Provider value={{ launchSync, isMainLine, syncStatus }}>
			{children}
		</RailwayOfflineContext.Provider>
	);
};

/**
 * Custom hooks to use RailwayOfflineContext
 * @returns {{
 *      launchSync: launchSync,
 *      isMainLine: boolean,
 *      syncStatus: {
 *          data: { state: string, lastSyncDate: Date, lastActivityDate: Date, cacheVersion: string },
 *          metadata: { ref: string }
 *      }
 * }}
 */
const useRailwayOfflineContext = () => useContext(RailwayOfflineContext);

export { RailwayOfflineContext, RailwayOfflineProvider, useRailwayOfflineContext };

RailwayOfflineProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
