import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './side-switch.scss';

import { advancedSprAccessRoles } from '../../../../../domains/user/utils/page-access-roles';
import { Button } from '../../../../../theme';
import { ReactComponent as IconSwitch } from '../../../../../theme/assets/img/icon-switch.svg';
import { useAppSetupContext } from '../../../../context/app-setup-context/app-setup-context';
import { useSprContext } from '../../../../context/spr-context/spr-context';
import { usePopup } from '../../../../index';
import PopupSideSwitch from '../popup-switch-side/popup-switch-side';

/**
 * Render the side switch component
 * @param {object} props
 * @param {string} [props.classNames=""]
 * @return {JSX.Element}
 * @constructor
 */
const SideSwitch = (props) => {
	const { className = '' } = props;

	const { t } = useTranslation();
	const history = useHistory();
	const { side, setSide } = useSprContext();
	const { user: currentUser } = useAppSetupContext();
	const { role: userRole } = currentUser || {};
	const isNotAuthorized = !advancedSprAccessRoles.includes(userRole);

	const popupSwitchSideControl = usePopup();

	const readOnly = useMemo(() => isNotAuthorized, [isNotAuthorized]);

	const handleSwitch = () => {
		if (side === 'pcc') {
			setSide('train');
			history.push('/railway');
		} else if (side === 'train') {
			setSide('pcc');
			history.push('/');
		}
	};

	return (
		<div className={classnames('side-switch', className)}>
			<PopupSideSwitch popupControl={popupSwitchSideControl} side={side} onConfirm={handleSwitch} />
			{readOnly ? (
				<p className="side-switch__text">{t(`theme:spr-page.header.current-side-${side}`)}</p>
			) : (
				<div className="side-switch__button">
					<p className="side-switch__cta">{t(`theme:spr-page.header.current-side-${side}`)}</p>
					<Button
						type="button"
						variant="secondary"
						onClick={popupSwitchSideControl.show}
						icon={IconSwitch}
					/>
				</div>
			)}
		</div>
	);
};

SideSwitch.propTypes = { className: PropTypes.string };

export default SideSwitch;
