import React, { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './multiple-link-train-task-view.scss';

import { IconWrapper } from '../../../../../../theme';
import { ReactComponent as IconArrowBreakline } from '../../../../../../theme/assets/img/icon-arrow-right.svg';
import { resolveSheetColor, SheetImageLoader, SheetType } from '../../../../../sheet/index';
import { fetchSheetByBinder } from '../../../../../sheet/sheet.services';
import { IconReturn } from '../../../../assets';
import { ContextRunProcess } from '../../../../components/context-run-process/context-run-process';

/**
 * View of a multiple link train task with navigation button to next sheets
 * @param {object} props
 * @returns {JSX.Element}
 */
const MultipleLinkTrainTaskView = ({ task = {}, previousTaskInfo = {} }) => {
	const { choice: previousChoice } = previousTaskInfo;

	const [binderSheets, setBinderSheets] = useState();
	const { currentSheetData, handleMultipleLinkTaskAnswer } = useContext(ContextRunProcess);

	const hasImage = task?.content?.hasImage;

	// Get sheets from same binder
	const initBinderSheets = () => {
		const param = {};
		fetchSheetByBinder(currentSheetData?.binder_tech_id, param).then((response) => {
			if (response?.data) {
				setBinderSheets(response?.data);
			}
		});
	};

	const generateChoiceBlock = (choice, index) => {
		const { text, category, sheetNumber, image } = choice;
		const binderSheet = binderSheets?.find((sheet) => sheet.number_search === sheetNumber);
		const sheetColor = resolveSheetColor(binderSheet?.color, binderSheet?.binder_color);
		const isPreviouslySelected =
			previousChoice?.sheetNumber === sheetNumber && previousChoice?.text === text;

		return (
			<>
				{!!category && <div className="multiple-link-train-task__category">{category}</div>}
				<div className="multiple-link-train-task__item" key={index}>
					<button
						type="button"
						className="multiple-link-train-task__link"
						key={sheetNumber}
						onClick={() => handleMultipleLinkTaskAnswer(task, choice)}
					>
						<div className="item__description" style={{ '--description-color': sheetColor }}>
							<span>{text}</span>
							{hasImage && (
								<SheetImageLoader
									className="image-list-view__item__image"
									imageId={image?.imageId}
									tag="light"
								/>
							)}
						</div>
						<IconWrapper className="item__arrow" Component={IconArrowBreakline} />
						<SheetType
							className="item__sheet-number"
							type={binderSheet?.type || 'parent'}
							sheetNumber={sheetNumber}
							color={sheetColor}
							binderType={'train-auto'}
						/>
						{isPreviouslySelected && (
							<IconWrapper
								className="multiple-link-train-task__item__icon-return"
								Component={IconReturn}
							/>
						)}
					</button>
				</div>
			</>
		);
	};

	useEffect(initBinderSheets, [currentSheetData]);

	return (
		<ul
			className={classnames('multiple-link-train-task-view', {
				'multiple-link-train-image-task-view': hasImage,
			})}
		>
			{task?.content?.items.map(generateChoiceBlock)}
		</ul>
	);
};

export default MultipleLinkTrainTaskView;

MultipleLinkTrainTaskView.propTypes = {
	previousTaskInfo: PropTypes.shape({
		choice: PropTypes.shape({
			sheetNumber: PropTypes.string,
			text: PropTypes.string,
		}),
	}),
	task: PropTypes.shape({
		content: PropTypes.shape({
			hasImage: PropTypes.bool,
			items: PropTypes.arrayOf(
				PropTypes.shape({
					text: PropTypes.string,
					category: PropTypes.string,
					sheetNumber: PropTypes.string,
					image: PropTypes.object,
				})
			),
		}),
	}),
};
