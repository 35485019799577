import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './train-binder.scss';

/**
 * TrainBinder component
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.label
 * @param {"auto" | "driver" | "officer"} props.type
 * @returns {JSX.Element}
 */
const TrainBinder = ({ className, label = '', type = '' }) => {
	return (
		<span className={classNames('binder-related', className, `binder-related--${type}`)}>
			{label}
		</span>
	);
};

export default TrainBinder;

TrainBinder.propTypes = {
	label: PropTypes.string,
	className: PropTypes.string,
	type: PropTypes.string,
};
