import { useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { ItemTypes } from '../../utils/drag-and-drop-item-types';

/**
 * @param template
 * @param moveCard from useDndHandleMove
 * @param ref
 * @param isSubNode
 * @returns {{drag: ConnectDragSource, isDragging: boolean}}
 */
const useDndSheetReference = (template, moveCard, ref, isSubNode = false) => {
	const templateType = isSubNode ? ItemTypes.SUB_NODE_TASK : ItemTypes.NODE_TASK;

	// initialize item getting dragged
	const [{ isDragging }, drag, connectDrag] = useDrag({
		item: { id: template.id, type: templateType, template: template },
		collect: (monitor) => {
			return {
				isDragging: monitor.isDragging(),
			};
		},
	});

	// execute moveCard on item droppable
	const [, connectDrop] = useDrop({
		accept: templateType,
		hover({ id: draggedId }) {
			if (draggedId !== template.id && moveCard) {
				moveCard(draggedId, template.id);
			}
		},
	});

	connectDrop(ref);

	const hideHTMLDefaultOpacityEffect = () => {
		// hide default html dnd opacity on ref
		if (connectDrag) {
			connectDrag(getEmptyImage(), { captureDraggingState: true });
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(hideHTMLDefaultOpacityEffect, []);

	/**
	 * drag: element used to enable dragging ( for example takoyaki icon on sheetEditionDetail)
	 * isDragging: boolean if dragging is live or not
	 */
	return { drag, isDragging };
};

export default useDndSheetReference;
