import React from 'react';
import PropTypes from 'prop-types';

import './process-action-button.scss';

import { Button } from '../../../../../../theme';

/**
 * ProcessActionButton props
 * @typedef {object} Props
 * @prop {string} label
 * @prop {object} children
 * @prop {object} Icon
 * @prop {function} handleClick
 */

/**
 * ProcessActionButton Icon
 * @param {Props} props
 * @returns {JSX.Element}
 */
const ProcessActionButton = ({ handleClick, label, Icon, iconPositionRight = false }) => {
	return (
		<Button
			variant={'secondary'}
			type="button"
			onClick={handleClick}
			icon={Icon}
			label={label}
			iconPositionRight={iconPositionRight}
		/>
	);
};

export default ProcessActionButton;

ProcessActionButton.propTypes = {
	handleClick: PropTypes.func.isRequired,
	iconPositionRight: PropTypes.bool,
	label: PropTypes.string.isRequired,
	Icon: PropTypes.elementType,
};
