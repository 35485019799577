import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './rer-resume-summary.scss';

import { Button } from '../../../../../theme';

import RerResumeSummaryLineA from './component/rer-resume-summary-line-A/rer-resume-summary-line-A';
import RerResumeSummaryLineB from './component/rer-resume-summary-line-B/rer-resume-summary-line-B';

const RerResumeSummary = ({ processInfo, line, handleBackButton, isProcessRecap }) => {
	const { t } = useTranslation();

	const tableField = {
		name: 'affected_equipment',
		key: 'affected-equipment',
		step: 0,
		line: ['A', 'B'],
		type: 'table',
	};

	const { has_emergency_lights_on: hasEmergencyLightsOn = false } =
		processInfo?.resume_conditions || {};

	const summaryTitleLineB = hasEmergencyLightsOn ? 'summary-title-emd' : 'summary-title-ccu-pact';
	const summaryTitle = line === 'A' ? 'summary-title-ccu' : summaryTitleLineB;

	const warningMessage = hasEmergencyLightsOn ? 'summary-warning-bogie' : 'summary-warning';

	return (
		<div className={'rer-resume-summary'}>
			{!isProcessRecap && (
				<div className={'rer-resume-summary__title'}>
					{t(`process:process-rer-resume.summary.${summaryTitle}`)}
				</div>
			)}

			{line === 'A' && (
				<RerResumeSummaryLineA
					processInfo={processInfo}
					tableField={tableField}
					isProcessRecap={isProcessRecap}
				/>
			)}
			{line === 'B' && (
				<RerResumeSummaryLineB
					processInfo={processInfo}
					tableField={tableField}
					isProcessRecap={isProcessRecap}
				/>
			)}

			{!isProcessRecap && (
				<div className={'rer-resume-summary__warning'}>
					{t(`process:process-rer-resume.summary.${warningMessage}`)}
				</div>
			)}

			{hasEmergencyLightsOn && !isProcessRecap && (
				<div className="rer-resume-summary__warning__button">
					<Button
						variant="link"
						onClick={() => handleBackButton(3)}
						label={t('process:process-rer-resume.summary.back')}
					/>
				</div>
			)}
		</div>
	);
};

export default RerResumeSummary;

RerResumeSummary.propTypes = {
	processInfo: PropTypes.object,
	line: PropTypes.string,
	handleBackButton: PropTypes.func,
	isProcessRecap: PropTypes.bool,
};
