import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './resume-form-line-B.scss';

import { Button } from '../../../../../../../../theme';
import ResumeFormStepOneLineB from '../resume-form-step-one-line-B/resume-form-step-one-line-B';
import ResumeFormStepThreeLineB from '../resume-form-step-three-line-B/resume-form-step-three-line-B';
import ResumeFormStepTwoLineB from '../resume-form-step-two-line-B/resume-form-step-two-line-B';

const ResumeFormLineB = ({
	resumeStep,
	handleChange,
	handleReset,
	handleSubmit,
	resumeForm,
	backToSubstep,
	handleBackClick,
	stepNumber,
	hasEmergencyLightOn,
	trainComposition,
}) => {
	const { t } = useTranslation();

	const handleSubmitResumeForm = () => {
		const updatedResumeForm = { ...resumeForm, step_completed: true };
		handleSubmit(updatedResumeForm);
	};

	const propsObject = {
		resumeStep: resumeStep,
		stepNumber: stepNumber,
		handleChange: handleChange,
		handleReset: handleReset,
		handleSubmit: handleSubmit,
		resumeForm: resumeForm,
		backToSubstep: backToSubstep,
		handleBackClick: handleBackClick,
		hasEmergencyLightOn: hasEmergencyLightOn,
		trainComposition: trainComposition,
	};

	return (
		<>
			{resumeStep === 0 && <ResumeFormStepOneLineB {...propsObject} />}
			{resumeStep >= 1 && <ResumeFormStepTwoLineB {...propsObject} />}
			{resumeStep >= 2 && <ResumeFormStepThreeLineB {...propsObject} />}
			{resumeStep >= 3 && (
				<div className="resume-rer__recap__button-wrapper">
					<Button
						variant="primary"
						type="submit"
						onClick={handleSubmitResumeForm}
						label={t('process:process-rer-resume.footer.confirm-resume')}
						className="resume-rer__recap__button-wrapper__button"
					/>
				</div>
			)}
		</>
	);
};

export default ResumeFormLineB;

ResumeFormLineB.propTypes = {
	resumeStep: PropTypes.number,
	handleChange: PropTypes.func,
	handleReset: PropTypes.func,
	handleSubmit: PropTypes.func,
	resumeForm: PropTypes.object,
	backToSubstep: PropTypes.func,
	handleBackClick: PropTypes.func,
	trainComposition: PropTypes.shape({}),
	stepNumber: PropTypes.number,
	hasEmergencyLightOn: PropTypes.bool,
};
