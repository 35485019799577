import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './create-sheet-button.scss';

import { Button } from '../../../../../../../../theme';
import { IconPlus } from '../../../../../../assets';

/**
 * CreateSheetButton component
 * @param {object} props
 * @param {function} props.onClick
 * @returns {JSX.Element}
 */
const CreateSheetButton = ({ onClick }) => {
	const { t } = useTranslation();

	return (
		<Button
			variant="primary"
			type="button"
			icon={IconPlus}
			label={t('binder:binder-detail.sheet-list.create-cta-label')}
			onClick={onClick}
		/>
	);
};

export default CreateSheetButton;

CreateSheetButton.propTypes = {
	onClick: PropTypes.func.isRequired,
};
