import React from 'react';
import { EditorState, Modifier, RichUtils } from 'draft-js';
import PropTypes from 'prop-types';

export const AlphabetListButton = ({ editorState, handleChange }) => {
	const ALPHA_LIST_TYPE = 'alpha-list-item';

	const handleAlphabetListClick = () => {
		const selection = editorState.getSelection();
		const contentState = editorState.getCurrentContent();
		const currentBlock = contentState.getBlockForKey(selection.getStartKey());
		const currentType = currentBlock.getType();

		if (currentType === ALPHA_LIST_TYPE) {
			const newState = RichUtils.toggleBlockType(editorState, 'unstyled');
			handleChange(newState);
			return;
		}

		let workingState = editorState;
		if (currentType === 'ordered-list-item' || currentType === 'unordered-list-item') {
			workingState = RichUtils.toggleBlockType(editorState, 'unstyled');
		}

		const newState = RichUtils.toggleBlockType(workingState, ALPHA_LIST_TYPE);
		handleChange(newState);
	};

	const handleReturn = (e) => {
		if (e.key === 'Enter') {
			const selection = editorState.getSelection();
			const contentState = editorState.getCurrentContent();
			const currentBlock = contentState.getBlockForKey(selection.getStartKey());

			if (currentBlock.getType() === ALPHA_LIST_TYPE) {
				const newContentState = Modifier.splitBlock(contentState, selection);
				const newState = EditorState.push(editorState, newContentState, 'split-block');

				const newSelection = newState.getSelection();
				const finalContentState = Modifier.setBlockType(
					newState.getCurrentContent(),
					newSelection,
					ALPHA_LIST_TYPE
				);

				handleChange(EditorState.push(newState, finalContentState, 'change-block-type'));
				return true;
			}
		}
		return false;
	};

	const currentBlock = editorState
		.getCurrentContent()
		.getBlockForKey(editorState.getSelection().getStartKey());
	const isActive = currentBlock.getType() === ALPHA_LIST_TYPE;

	return (
		<div
			className={`rdw-option-wrapper ${isActive ? 'rdw-option-active' : ''}`}
			onClick={handleAlphabetListClick}
			title="Liste alphabétique"
			onKeyDown={handleReturn}
		>
			<svg width="15" height="15" viewBox="0 0 15 15">
				{/* A */}
				<path
					fill="currentColor"
					d="M1.8 2L0.5 4.5h0.5l0.3-0.7h1.7l0.3 0.7h0.5L2.5 2H1.8zM1.5 3.3l0.5-1.1l0.5 1.1H1.5z"
				/>
				{/* B */}
				<path
					fill="currentColor"
					d="M0.5 6.5h1.8c0.5 0 0.8 0.2 0.8 0.7s-0.3 0.7-0.8 0.7H1v1H0.5V6.5zm1.6 1c0.3 0 0.5-0.1 0.5-0.3s-0.2-0.3-0.5-0.3H1v0.6H2.1z"
				/>
				{/* C */}
				<path
					fill="currentColor"
					d="M3 11.5c0-0.6-0.4-1-1-1H0.5v2h1.5c0.6 0 1-0.4 1-1zM2 12H1v-1h1c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z"
				/>
				{/* Lines */}
				<path fill="currentColor" d="M4.5 2.5h9v0.5h-9zM4.5 7.5h9v0.5h-9zM4.5 11.5h9v0.5h-9z" />
			</svg>
		</div>
	);
};

AlphabetListButton.propTypes = {
	editorState: PropTypes.object,
	handleChange: PropTypes.func.isRequired,
};
