import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-create-initial-sheet.scss';

import { Button, ColorChooser, PopupDefault, TextField } from '../../../../../../../../../theme';
import PopupCreateSheetCopyForm from '../../../../../../../../sheet/components/popup-create-sheet/components/popup-create-sheet-copy-form';
import { createSheet } from '../../../../../../../../sheet/sheet.services';

/**
 * Render the popup to create man binder initial sheet
 * @param {object} props
 * @param {object} props.popupControl
 * @returns {JSX.Element}
 */
const PopupCreateInitialSheet = ({ binder, onCreate, popupControl }) => {
	const [checkCopySheet, setCheckCopySheet] = useState(false);
	const [sheetSelected, setSheetSelected] = useState();

	const { t } = useTranslation();
	const form = {
		type: 'initial',
		number: t('binder:popup.create-initial-sheet.label-title-value'),
		color: '#B8FEFF',
		content: [],
		title: 'Fiche initiale',
		binderType: binder?.type,
	};

	const params = checkCopySheet ? { action: 'copy', sheetId: sheetSelected } : {};

	const isPopupReady = () => {
		if (checkCopySheet) {
			return !!sheetSelected;
		}
		return true;
	};

	const handleValidate = (event) => {
		event.preventDefault();
		const popupReady = isPopupReady();
		if (popupReady) {
			createSheet(binder?.tech_id, form, params).then(onCreate);
		}
		popupControl.hide();
	};

	return (
		<PopupDefault popupControl={popupControl} title={t('binder:popup.create-initial-sheet.title')}>
			<div className="popup-create-initial-sheet">
				<div className="popup-create-initial-sheet__content">
					<TextField
						label={t('binder:popup.create-initial-sheet.label-title')}
						disabled
						name="title"
						value={t('binder:popup.create-initial-sheet.label-title-value')}
					/>
					<ColorChooser
						name="color"
						value="#B8FEFF"
						label={t('binder:popup.form.field.binder-color.label')}
						withOptions={false}
					/>
					<div className="popup-create-sheet__form-check">
						<input
							type="checkbox"
							checked={checkCopySheet}
							onChange={() => setCheckCopySheet(!checkCopySheet)}
							className="popup-create-sheet__checkbox"
						/>
						<span>{t('sheet:popup.create.field.checkbox')}</span>
					</div>
					{checkCopySheet && (
						<PopupCreateSheetCopyForm setSheetSelected={setSheetSelected} isInitialSheet={true} />
					)}
				</div>
				<Button
					variant="primary"
					className="popup-create-initial-sheet__submit"
					onClick={handleValidate}
					label={t('binder:popup.create-signal.validate')}
					disabled={!isPopupReady()}
				/>
			</div>
		</PopupDefault>
	);
};

export default PopupCreateInitialSheet;

PopupCreateInitialSheet.propTypes = {
	binder: PropTypes.shape({
		tech_id: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
	}).isRequired,
	onCreate: PropTypes.func.isRequired,
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired,
		visible: PropTypes.bool,
	}).isRequired,
};
