import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './step-template-form.scss';

import useStepTemplate from '../../../../utils/use-step-template';

const StepTemplateForm = ({ content = {} }) => {
	const { t } = useTranslation();
	const { updateStepContent } = useStepTemplate();

	const updateStepContentEffect = () => {
		//avoid update at selection
		if (!content.step) {
			updateStepContent();
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(updateStepContentEffect, [content]);

	return (
		<div className="step-template-form">
			<span className="step-template-form__step">
				{`${t('sheet:sheet-details.template.step.name')} ${content?.step}`}
			</span>
			<span className="step-template-form__text">_ _h_ _</span>
		</div>
	);
};

export default StepTemplateForm;

StepTemplateForm.propTypes = {
	content: PropTypes.object,
};
