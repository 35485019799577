import { unpadStartNumberInside } from '../../../shared';

/**
 * Check if the provided sheet number does not exist in the provided sheet list
 * @param sheetNumberValue
 * @param availableSheetList
 * @return {string[]}
 */
const sheetNumberValidator = (sheetNumberValue = '', availableSheetList = [], sheetNumber) => {
	// Get sheet with same sheet number
	let filteredSheetList = availableSheetList;
	if (sheetNumber) {
		filteredSheetList = availableSheetList.filter(
			({ number }) => unpadStartNumberInside(number) !== unpadStartNumberInside(sheetNumber)
		);
	}
	const sheetWithSameNumber = filteredSheetList.find(
		({ number }) =>
			unpadStartNumberInside(sheetNumberValue.trim().toLowerCase()) ===
			unpadStartNumberInside(number)
	);
	// sheet number valid if there is no matching sheet
	return sheetWithSameNumber ? ['invalid'] : [];
};

export { sheetNumberValidator };
