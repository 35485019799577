import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './resume-template-view.scss';

import { useDndSheetReference } from '../../../../../../../../shared';
import TemplateWrapper from '../../template-wrapper/template-wrapper';

const ResumeTemplateView = ({
	template,
	location,
	currentPosition,
	allowInsertAfter,
	moveCard,
	processCurrentTemplate,
}) => {
	const ref = useRef(null);
	const { drag, isDragging } = useDndSheetReference(template, moveCard, ref, location?.parentId);

	const { t } = useTranslation();

	const type = template?.content?.type;

	return (
		<TemplateWrapper
			id={template.id}
			location={location}
			className={`resume-template-view resume-template-view--${type}`}
			template={template}
			currentPosition={currentPosition}
			allowInsertAfter={allowInsertAfter}
			reference={ref}
			isDragging={isDragging}
			dragRef={drag}
			processCurrentTemplate={processCurrentTemplate}
		>
			<div className="resume-template-view__text">
				{t(`sheet:sheet-details.template.resume.${type}-label`)}
			</div>
		</TemplateWrapper>
	);
};

export default ResumeTemplateView;

ResumeTemplateView.propTypes = {
	template: PropTypes.object,
	location: PropTypes.object,
	currentPosition: PropTypes.number,
	allowInsertAfter: PropTypes.bool,
	moveCard: PropTypes.any,
	processCurrentTemplate: PropTypes.object,
};
