import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { isAfter, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import './binder-detail-subheader.scss';

import { usePopup, useTabs } from '../../../../../../shared';
import { Button, ToggleSwitch } from '../../../../../../theme';
import { ReactComponent as IconEdit } from '../../../../../../theme/assets/img/icon-edit.svg';
import { generateBinderPdf } from '../../../../binder.services';
import {
	PopupBinderEditTitle,
	PopupConfirmRevokeBinder,
	PopupConfirmWaitingBinder,
	PopupConfirmWorkInProgressBinder,
	PopupFailWaitingBinder,
} from '../../../../components/popup';
import StatusFilter from '../../../../components/status-filter/status-filter';
import { binderStatusRules } from '../../../../index';
import { getStoredActiveTab } from '../../../../utils/binder-list-localstorage-utils';

/**
 * BinderDetailSubheader component
 * @param {object} props
 * @param {object} props.binder - respective binder
 * @param {boolean} props.readOnly - is detail read only
 * @param {boolean} props.isSaving - is saving
 * @param {function} props.handleChange - change handler
 * @param {function} props.reloadBinder - handler reload binder on edit
 * @returns {JSX.Element}
 */
const BinderDetailSubheader = ({ binder, readOnly, reloadBinder, handleChange, isSaving }) => {
	const { t } = useTranslation();
	const subtitleDisplayStatus = binderStatusRules.hideSubtitle;

	const history = useHistory();

	const [newStatusEvent, setNewStatusEvent] = useState();
	const [loading, setLoading] = useState(false);
	const [loadingModifier, setLoadingModifier] = useState(false);

	const { activeId } = useTabs(getStoredActiveTab());

	const isBinderTrain = activeId === 'train';

	const popupBinderEditTitleControl = usePopup();
	const popupConfirmRevokeControl = usePopup();
	const popupConfirmWaitingControl = usePopup();
	const popupConfirmWorkInProgressControl = usePopup();
	const popupFailWaitingControl = usePopup();

	const handleFilterChange = (event) => {
		const { value } = event.target;
		if (value !== binder.status && !isSaving) {
			// show confirmation popup when changing to these status
			const confirmationStatus = {
				waiting: 'waiting',
				revoke: 'revoke',
				wip: 'work-in-progress',
			};
			if (Object.values(confirmationStatus).includes(value)) {
				if (value === confirmationStatus.waiting) {
					if (
						isAfter(parseISO(binder.published_at), new Date()) &&
						binder.is_rectification !== null
					) {
						popupConfirmWaitingControl.show();
					} else {
						popupFailWaitingControl.show();
					}
				} else if (value === confirmationStatus.revoke) {
					popupConfirmRevokeControl.show();
				} else if (value === confirmationStatus.wip) {
					popupConfirmWorkInProgressControl.show();
				}
				setNewStatusEvent(event);
			} else {
				handleChange(event);
			}
		}
	};

	const handleLegendChange = (event) => {
		event.target = {
			name: 'show_legend_pdf',
			value: !binder.show_legend_pdf,
		};
		handleChange(event);
	};

	const handleBinderPdfGenerationClick = (modified) => {
		if (modified) {
			setLoadingModifier(true);
		} else {
			setLoading(true);
		}
		generateBinderPdf(binder?.tech_id, modified)
			.then((response) => {
				if (modified) {
					setLoadingModifier(false);
				} else {
					setLoading(false);
				}
				history.push(`/document/${response?.data?.tech_id}/binder`);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
				setLoadingModifier(false);
			});
	};

	const displayBinderDetailSubheaderOptions = () => (
		<div className="binder-details__subheader__options">
			<PopupConfirmRevokeBinder
				popupControl={popupConfirmRevokeControl}
				binder={binder}
				handleConfirm={() => handleChange(newStatusEvent)}
			/>
			<PopupConfirmWaitingBinder
				popupControl={popupConfirmWaitingControl}
				binder={binder}
				handleConfirm={() => handleChange(newStatusEvent)}
			/>
			<PopupConfirmWorkInProgressBinder
				popupControl={popupConfirmWorkInProgressControl}
				binder={binder}
				handleConfirm={() => handleChange(newStatusEvent)}
				handlePrintPdf={handleBinderPdfGenerationClick}
				loading={loading}
			/>
			<PopupFailWaitingBinder popupControl={popupFailWaitingControl} />

			<div className="binder-details__binder-type-status">
				<StatusFilter
					name="status"
					value={binder.status || null}
					onChange={handleFilterChange}
					disabled={isSaving}
				/>
			</div>
		</div>
	);

	return (
		<>
			<PopupBinderEditTitle
				popupControl={popupBinderEditTitleControl}
				binder={binder}
				onSubmitSuccess={reloadBinder}
				binderTrain={isBinderTrain}
			/>
			<div className="binder-subheader__titles__container">
				<div className="binder-subheader__titles">
					<h2 className="binder-subheader__titles__title">
						{`${binder.title} - ${
							binder?.type === 'man'
								? ` ${binder?.station_label} - ${t(`binder:station-type.${binder?.station_type}`)}`
								: ` ${t(`binder:binder-detail.head.${binder.type}`)}`
						}`}
					</h2>

					{!readOnly && (
						<Button
							variant="minimal"
							icon={IconEdit}
							small
							className="binder-subheader__titles__edit-icon"
							onClick={popupBinderEditTitleControl.show}
							iconTooltipContent={t('binder:binder-detail.tooltip.edit')}
						/>
					)}
					{displayBinderDetailSubheaderOptions()}
				</div>
				<div className="binder-subheader__titles	">
					<div className="options__pdf">
						<span className="options__pdf__switch-label">
							{t('binder:binder-detail.head.show-legend-pdf')}
						</span>
						<ToggleSwitch
							className="options__pdf__switch-toggle"
							isChecked={binder.show_legend_pdf}
							disabled={readOnly || isSaving}
							handleOnChange={handleLegendChange}
						/>
						<Button
							variant="secondary"
							label={t('binder:binder-detail.head.view-sheets-pdf-button')}
							className="options__pdf-cta"
							onClick={() => handleBinderPdfGenerationClick(true)}
							disabled={!binder?.tech_id}
							loading={loadingModifier}
						/>
						<Button
							variant="primary"
							onClick={() => handleBinderPdfGenerationClick(false)}
							disabled={!binder?.tech_id}
							label={t('binder:binder-detail.head.view-binder-pdf-button')}
							loading={loading}
						/>
					</div>
				</div>
			</div>
			<span className="binder-subheader__titles__subtitle">
				{!subtitleDisplayStatus.includes(binder.status) && binder.subtitle}
			</span>
		</>
	);
};

export default BinderDetailSubheader;

BinderDetailSubheader.propTypes = {
	binder: PropTypes.shape({
		status: PropTypes.string,
		published_at: PropTypes.string,
		tech_id: PropTypes.string,
		title: PropTypes.string,
		subtitle: PropTypes.string,
		is_rectification: PropTypes.bool,
		show_legend_pdf: PropTypes.bool,
		type: PropTypes.string,
		station_label: PropTypes.string,
		station_type: PropTypes.string,
	}),
	handleChange: PropTypes.func.isRequired,
	reloadBinder: PropTypes.func.isRequired,
	isSaving: PropTypes.bool.isRequired,
	readOnly: PropTypes.bool.isRequired,
};
