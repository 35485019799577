import { hasFieldError, requiredFieldValidator } from '../../../shared';

/**
 * @typedef TrainFormData
 * @property {string} id
 * @property {string} material_tech_id
 * @property {string} brake_system
 * @property {number} car_number
 * @property {string} binder_auto
 * @property {string} binder_driver
 * @property {string} binder_officer
 * @property {boolean} engine_system
 * @property {boolean} brake_isolation
 */

/**
 * @typedef TrainFormMessage
 * @property {string} id
 * @property {string} material_tech_id
 * @property {string} brake_system
 * @property {string} car_number
 * @property {string} binder_auto
 * @property {string} binder_driver
 * @property {string} binder_officer
 * @property {string} engine_system
 * @property {string} brake_isolation
 */

/**
 * @typedef TrainFormError
 * @property {string[]} id
 * @property {string[]} material_tech_id
 * @property {string[]} brake_system
 * @property {string[]} car_number
 * @property {string[]} binder_auto
 * @property {string[]} binder_driver
 * @property {string[]} binder_officer
 * @property {string[]} engine_system
 * @property {string[]} brake_isolation
 */

/**
 * Validate Train id
 * @param {string} trainId form data train id
 * @param {TrainFormData[]} trainList list of existing trains
 * @returns {string[]} id errors
 */
const validateTrainId = (trainId, trainList = []) => {
	// check id length under 10 characters
	const idValidation = requiredFieldValidator(trainId);
	if (trainId?.length > 10) {
		idValidation.push('length');
	}
	if (trainId && trainList?.some((train) => train.id === trainId)) {
		idValidation.push('existing');
	}

	return idValidation;
};

/**
 * Validate PopupUpdateTrain form data
 * @param {TrainFormData} form form data to validate
 * @returns {TrainFormError}
 */
const validatePopupUpdateTrainForm = (form) => {
	const {
		material_tech_id,
		brake_system,
		car_number,
		binder_auto,
		binder_driver,
		binder_officer,
		engine_system,
		brake_isolation,
	} = form;

	return {
		id: [],
		material_tech_id: requiredFieldValidator(material_tech_id),
		brake_system: requiredFieldValidator(brake_system),
		car_number: requiredFieldValidator(car_number),
		binder_auto: requiredFieldValidator(binder_auto || binder_driver || binder_officer),
		binder_driver: requiredFieldValidator(binder_auto || binder_driver || binder_officer),
		binder_officer: requiredFieldValidator(binder_auto || binder_driver || binder_officer),
		engine_system: requiredFieldValidator(engine_system),
		brake_isolation: requiredFieldValidator(brake_isolation),
	};
};

/**
 * Validate PopupCreateTrain form data
 * @param {TrainFormData} form form data to validate
 * @param {TrainFormData[]} trainList list of existing trains
 * @returns {TrainFormError}
 */
const validatePopupCreateTrainForm = (form, trainList) => {
	let { id } = form;
	if (id !== undefined) id = id.trim();
	return {
		...validatePopupUpdateTrainForm(form),
		id: validateTrainId(id, trainList),
	};
};

/**
 * Validate Train id
 * @param {string} trainId form data to validate
 * @param {TrainFormData[]} trainList list of existing trains
 * @returns {{ id: string[] }}
 */
const validatePopupCopyTrainForm = (trainId, trainList) => ({
	id: validateTrainId(trainId, trainList),
});

/**
 * get text key corresponding to formErrors
 * @param {TrainFormError} formError
 * @returns {TrainFormMessage} i18 text key
 */
const getFormTrainFieldMessageKey = (formError) => ({
	id: hasFieldError('existing', formError?.id) ? 'train:popup.form.error.id-existing' : '',
	material_tech_id: '',
	brake_system: '',
	car_number: '',
	binder_auto: '',
	binder_driver: '',
	binder_officer: '',
	engine_system: '',
	brake_isolation: '',
});

export {
	getFormTrainFieldMessageKey,
	validatePopupCopyTrainForm,
	validatePopupCreateTrainForm,
	validatePopupUpdateTrainForm,
};
