import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const TabsNavPanel = ({ id, activeTab, children, className = '' }) => {
	if (id === activeTab) {
		return <div className={classNames('tabs-nav-panel', className)}>{children}</div>;
	}
	return null;
};

export default TabsNavPanel;

TabsNavPanel.propTypes = {
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	activeTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	children: PropTypes.node,
	className: PropTypes.string,
};
