import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './sheet-comments.scss';

import { useDebouncedAction } from '../../../../../../shared';
import { Button, Editor, IconWrapper } from '../../../../../../theme';
import { ReactComponent as IconScrollbarDown } from '../../../../../../theme/assets/img/icon-scrollbar-arrow-down.svg';
import { ReactComponent as IconScrollbarUp } from '../../../../../../theme/assets/img/icon-scrollbar-arrow-up.svg';
import { createComment, fetchCommentBySheetId } from '../../../../comment.services';
import { defaultEditorToolbarConfig, SheetPreview } from '../../../../index';

import Comment from './components/comment';

/**
 * SheetComments component
 * @param {object} props
 * @param {string} props.sheetId
 * @param {string} props.binderTechId
 * @param {string} props.binderStatus
 * @param {string} props.binderIteration
 * @param {'sheet' | 'theme' | 'man'  | 'train-driver' | 'train-officer' | 'train-auto'} props.binderType
 * @param {string} props.sheetColor
 * @param {string} props.sheetNumber
 * @param {'man'|'child'|'parent'|'determination'|'m'|'resume'|'initial'} props.sheetType
 * @param {{title: string, destination: string}} props.signalInfo
 * @returns {JSX.Element}
 */
const SheetComments = ({
	sheetId,
	binderTechId,
	binderStatus,
	binderIteration,
	binderType,
	sheetColor,
	sheetNumber,
	sheetType,
	signalInfo,
}) => {
	const { t } = useTranslation();
	const listCommentScroll = useRef({});
	const [iterationsWithCommentsList, setIterationsWithCommentsList] = useState([]);
	const [contentComment, setContentComment] = useState('');
	const [initComment, setInitComment] = useState('');
	const [activeButton, setActiveButton] = useState(false);

	const [visibilityIconUp, setVisibilityIconUp] = useState('hidden');
	const [visibilityIconDown, setVisibilityIconDown] = useState('hidden');

	const unauthorizedBinderStatusList = ['waiting', 'published', 'revoke', 'cancelled'];
	const isCommentAuthorizedToPublish = () => !unauthorizedBinderStatusList.includes(binderStatus);

	const retrieveCommentsList = () => {
		fetchCommentBySheetId(sheetId).then((response) => {
			const filteredComments = getFilteredCommentsByIteration(response?.data);
			setIterationsWithCommentsList(filteredComments);
			goDown();
		});
	};

	const getFilteredCommentsByIteration = (commentsList) => {
		let filteredCommentsByIteration = [];
		// transform comments list into objects with iteration number and list of associated comments
		commentsList.forEach((comment) => {
			let iterationExists = filteredCommentsByIteration.find(
				(iteration) => iteration.number === comment.binder_iteration
			);
			if (iterationExists) {
				return iterationExists.comments.push(comment);
			} else {
				return filteredCommentsByIteration.push({
					number: comment.binder_iteration,
					comments: [comment],
				});
			}
		});
		return filteredCommentsByIteration;
	};

	const handlePublishButtonStatus = (value) => {
		const plainTextLength = value?.blocks[0]?.text.trim().length;
		setActiveButton(plainTextLength && plainTextLength !== 0);
	};

	const handleChange = (e) => {
		let { value } = e.target;
		setContentComment(value);
		handlePublishButtonStatus(value);
	};

	const handlePublish = () => {
		const commentData = {
			content: JSON.stringify(contentComment),
			sheet_id: sheetId,
			binder_tech_id: binderTechId,
			binder_iteration: binderIteration,
		};
		createComment(commentData)
			.then(() => {
				retrieveCommentsList();
				setInitComment(null);
				handlePublishButtonStatus(null);
			})
			.catch((error) => {
				throw error;
			});
	};

	const clearEditorComment = () => {
		setInitComment(contentComment);
	};

	const handleCancel = () => {
		setContentComment(null);
		setInitComment(null);
		handlePublishButtonStatus(null);
	};

	const goUp = () => (listCommentScroll.current.scrollTop = 0);
	const goDown = () => {
		const target = listCommentScroll.current;
		if (target) {
			target.scrollTop = target.scrollHeight - target.clientHeight;
		}
	};

	const updateArrowVisibility = useDebouncedAction((target) => {
		const positionScroll = target?.scrollHeight - target?.clientHeight;

		const isUp = target?.scrollTop === 0;
		const isDown = target?.scrollTop >= positionScroll;

		setVisibilityIconUp(isUp ? 'hidden' : '');
		setVisibilityIconDown(isDown ? 'hidden' : '');
	}, 100);

	const updateCommentScrollArrowVisibility = () => updateArrowVisibility(listCommentScroll.current);

	const displayComment = (comment) => {
		return (
			<Comment
				key={comment.id}
				comment={comment}
				retrieveCommentsList={retrieveCommentsList}
				toolbarConfig={defaultEditorToolbarConfig}
				binderIteration={binderIteration}
			/>
		);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(retrieveCommentsList, []);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(updateCommentScrollArrowVisibility, [iterationsWithCommentsList]);

	return (
		<div className="sheet-comments">
			<div className="sheet-comments__preview">
				<SheetPreview
					sheetColor={sheetColor}
					sheetNumber={sheetNumber}
					sheetType={sheetType}
					binderType={binderType}
					signalInfo={signalInfo}
				/>
				<div className="sheet-comments__side-view">
					{Array.isArray(iterationsWithCommentsList) && iterationsWithCommentsList.length !== 0 && (
						<div className="sheet-comments__side-view__list">
							<div className="list__header">
								<IconWrapper
									className={`scroll__button scroll__button--up ${visibilityIconUp}`}
									Component={IconScrollbarUp}
									onClick={goUp}
								/>
							</div>
							<div
								className="list__comments"
								onScroll={(e) => updateArrowVisibility(e.target)}
								ref={listCommentScroll}
							>
								{iterationsWithCommentsList.map((iteration) => (
									<div key={iteration.number} className="list__comments__wrapper">
										<div className="list__comments__wrapper__iteration-number">
											{t('sheet:sheet-details.comments.binder-iteration')}
											<span>{iteration.number}</span>
										</div>
										{iteration.comments.map(displayComment)}
									</div>
								))}
							</div>
							<div className="list__footer">
								<IconWrapper
									className={`scroll__button scroll__button--down ${visibilityIconDown}`}
									Component={IconScrollbarDown}
									onClick={goDown}
								/>
							</div>
						</div>
					)}
					<div className="sheet-comments__side-view__publication">
						{isCommentAuthorizedToPublish() ? (
							<form className="comment-form" onSubmit={(event) => event.preventDefault()}>
								<Editor
									className="comment-form__editor"
									name="comment"
									value={initComment || null}
									toolbarConfig={defaultEditorToolbarConfig}
									onChange={(e) => handleChange(e)}
									onBlur={clearEditorComment}
								/>
								<div className="publication__actions">
									<Button
										variant="minimal"
										label={t('sheet:sheet-details.comments.cta-cancel-publish')}
										disabled={!activeButton}
										onClick={handleCancel}
									/>
									<Button
										variant="primary"
										label={t('sheet:sheet-details.comments.cta-publish')}
										disabled={!activeButton}
										onClick={handlePublish}
									/>
								</div>
							</form>
						) : (
							<div className="sheet-comments__side-view__publication__not-allowed">
								{t('sheet:sheet-details.comments.publish-not-allowed')}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SheetComments;

SheetComments.propTypes = {
	listCommentScroll: PropTypes.shape({}),
	sheetId: PropTypes.string,
	binderTechId: PropTypes.string,
	binderStatus: PropTypes.string,
	binderIteration: PropTypes.number,
	binderType: PropTypes.oneOf([
		'sheet',
		'theme',
		'man',
		'train-driver',
		'train-officer',
		'train-auto',
	]),
	sheetColor: PropTypes.string,
	sheetNumber: PropTypes.string,
	sheetType: PropTypes.oneOf([
		'man',
		'child',
		'parent',
		'determination',
		'm',
		'resume',
		'appendix',
	]),
	signalInfo: PropTypes.shape({
		title: PropTypes.string,
		destination: PropTypes.string,
	}),
};
