import React, { useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './color-chooser-option.scss';

import { ReactComponent as IconCheck } from '../../../assets/img/icon-check.svg';
import IconWrapper from '../../icon-wrapper/icon-wrapper';

/**
 * Render a ColorChooserOption components
 * @param {object} props
 * @param {string} props.value
 * @param {boolean} props.checked
 * @param {function} props.onChange
 * @param {boolean} props.read
 * @returns {JSX.Element}
 */
const ColorChooserOption = ({ className, value, checked, onChange, read }) => {
	const handleClick = () => {
		if (!read) {
			onChange(value);
		}
	};

	const getComponentClassName = () => {
		const modifier = {
			'color-chooser-option--checked': checked,
			'color-chooser-option--read': read,
		};
		return classnames('color-chooser-option', className, modifier);
	};
	useEffect(() => {}, [value]);
	return (
		<button
			type="button"
			onClick={handleClick}
			className={getComponentClassName()}
			style={{ '--option-color': value }}
		>
			{checked && <IconWrapper className="color-chooser-option__icon" Component={IconCheck} />}
		</button>
	);
};

export default ColorChooserOption;

ColorChooserOption.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string,
	checked: PropTypes.bool,
	onChange: PropTypes.func,
	read: PropTypes.bool,
};
