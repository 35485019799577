import {
	getLocalStorageContent,
	setStoredLocalStorageContentProperty,
} from '../../utils/localstorage-utils';

const storageName = 'SprContext';

/**
 * Get the SprContext storage content
 * @return {{ associatedLine: number|undefined, currentBinder: Object|undefined }}
 */
const getSprStorageContent = () => getLocalStorageContent(storageName);

/**
 * Update the content property in SprContext storage
 * @param {"associatedLine"|"side"|"currentBinder"|"trainBinderCount"} key
 * @param {any} value
 */
const setStoredContentProperty = (key, value) => {
	setStoredLocalStorageContentProperty(storageName, key, value);
};

/**
 * Update the associated Line in SprContext storage
 * @param {number} associatedLine The current user associated line
 */
const setStoredAssociatedLine = (associatedLine) => {
	setStoredContentProperty('associatedLine', associatedLine);
};

/**
 * Update the associated Line in SprContext storage
 * @param {"pcc"| "train"} side The current user associated line
 */
const setStoredSide = (side) => {
	setStoredContentProperty('side', side);
};

/**
 * Update the trainbinders count in SprContext storage
 * @param {number} trainBinderCount amount of trainbinders
 */
const setStoredTrainBinderCount = (trainBinderCount) => {
	setStoredContentProperty('trainBinderCount', trainBinderCount);
};

/**
 * Update the signalList count in SprContext storage
 * @param {number} signalList
 */
const setStoredSignalList = (signalList) => {
	setStoredContentProperty('signalList', signalList);
};

/**
 * Update the current binder in SprContext storage
 * @param {object} currentBinder the current binder details
 */
const setStoredCurrentBinder = (currentBinder = {}) => {
	setStoredContentProperty('currentBinder', currentBinder);
};

export {
	getSprStorageContent,
	setStoredAssociatedLine,
	setStoredCurrentBinder,
	setStoredSide,
	setStoredSignalList,
	setStoredTrainBinderCount,
};
