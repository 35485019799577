import i18next from '../../config/i18next';

import CdrRunEnd from './components/cdr-run-end/cdr-run-end';
import CdrRunProcess from './components/cdr-run-process/cdr-run-process';
import SprInProgressProcessList from './components/spr-in-progress-process-list/spr-in-progress-process-list';
import i18nProcess from './i18n/fr.json';
import ProcessBinderDetail from './pages/process-binder-detail/process-binder-detail';
import ProcessBinderList from './pages/process-binder-list/process-binder-list';
import ProcessHistoryView from './pages/process-history-view/process-history-view';
import ProcessList from './pages/process-list/process-list';
import ProcessRerResumePage from './pages/process-rer-resume/process-rer-resume-page';
import ProcessResume from './pages/process-resume/process-resume';
import SprRunProcess from './pages/spr-run-process/spr-run-process';
import taskTypeMap from './utils/task-types-map.json';

i18next.addResourceBundle('fr', 'process', i18nProcess);

export {
	CdrRunEnd,
	CdrRunProcess,
	ProcessBinderDetail,
	ProcessBinderList,
	ProcessHistoryView,
	ProcessList,
	ProcessRerResumePage,
	ProcessResume,
	SprInProgressProcessList,
	SprRunProcess,
	taskTypeMap,
};
