import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import '../binder-sheet-list-content/binder-sheet-list-content.scss';
import './binder-initial-sheet.scss';

import { Button } from '../../../../../../../../theme';
import { IconPlus } from '../../../../../../assets';
import SheetListItem from '../binder-sheet-list-item/binder-sheet-list-item';

/**
 * BinderInitialSheet component
 * @param {object} props
 * @param {function} props.onClick
 * @param {function} props.refreshSheetList
 * @returns {JSX.Element}
 */

const BinderInitialSheet = ({
	analysisResult,
	binder,
	initialSheet,
	onClick,
	refreshSheetList,
}) => {
	const { t } = useTranslation();
	const isSheetListEmpty = initialSheet.length === 0;
	const history = useHistory();

	return (
		<div>
			<div className="binder-initial-sheet">
				<h3 className="binder-sheet-list__title">
					{t('binder:binder-detail.sheet-list.title-initial')}
				</h3>
				<Button
					className="binder-sheet-list__create-cta"
					icon={IconPlus}
					disabled={!isSheetListEmpty || binder.status !== 'work-in-progress'}
					label={t('binder:binder-detail.sheet-list.create-initial-sheet')}
					onClick={onClick}
					variant="primary"
				/>
			</div>
			<ul className="binder-sheet-list-content">
				{!isSheetListEmpty && (
					<li className="list__head-row">
						<span className="list__head-cell list__head-cell--number">
							{t('binder:binder-detail.sheet-list.head.number')}
						</span>
						<span className="list__head-cell list__head-cell--title--initial">
							{t('binder:binder-detail.sheet-list.head.title')}
						</span>
						<span className="list__head-cell list__head-cell--update">
							{t('binder:binder-detail.sheet-list.head.test')}
						</span>
						<span className="list__head-cell list__head-cell--update">
							{t('binder:binder-detail.sheet-list.head.change')}
						</span>
						<span className="list__head-cell list__head-cell--update">
							{t('binder:binder-detail.sheet-list.head.change-date')}
						</span>
					</li>
				)}
				{!isSheetListEmpty > 0 &&
					initialSheet.map((sheet) => (
						<SheetListItem
							analysisResult={analysisResult}
							binder={binder}
							defaultColor={binder?.color}
							history={history}
							key={sheet.number}
							sheet={sheet}
							refreshSheetList={refreshSheetList}
						/>
					))}
			</ul>
		</div>
	);
};

export default BinderInitialSheet;

BinderInitialSheet.propTypes = {
	analysisResult: PropTypes.shape({
		sheetsWarning: PropTypes.arrayOf(PropTypes.string),
	}),
	binder: PropTypes.shape({
		color: PropTypes.string,
		status: PropTypes.string.isRequired,
		tech_id: PropTypes.string.isRequired,
	}),
	initialSheet: PropTypes.arrayOf(
		PropTypes.shape({
			number: PropTypes.string.isRequired,
			sheet_id: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired,
			color: PropTypes.string.isRequired,
			title: PropTypes.object.isRequired,
			analysis_result: PropTypes.string,
			status: PropTypes.string.isRequired,
			last_change_content_at: PropTypes.string.isRequired,
			editor: PropTypes.string,
			editor_firstname: PropTypes.string,
			editor_lastname: PropTypes.string,
			editor_id: PropTypes.string,
		})
	),
	onClick: PropTypes.func.isRequired,
	refreshSheetList: PropTypes.func.isRequired,
};
