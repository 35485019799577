import { formatDistanceStrict } from 'date-fns';
import { fr } from 'date-fns/locale';

/**
 * Format duration between json date
 * @param {string} jsonDate The Json date to format
 * @param {string} baseJsonDate The Json date to format to compare with
 * @param {string} fallbackStr The fallback string if jsonDate is falsy
 * @returns {string}
 */
const formatDistanceJsonDate = (jsonDate, baseJsonDate, fallbackStr = '') =>
	jsonDate && baseJsonDate
		? formatDistanceStrict(new Date(jsonDate), new Date(baseJsonDate), {
				locale: fr,
				unit: 'minute',
			})
		: fallbackStr;

export default formatDistanceJsonDate;
