import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './template-actions.scss';

import CopyTemplateButton from '../../copy-template-button/copy-template-button';
import DeleteTemplateButton from '../../delete-template-button/delete-template-button';

const TemplateActions = ({ className, readOnly, viewType, template, location }) => {
	const showCopyCta = [
		'resume',
		'note',
		'action',
		'call',
		'systemEffect',
		'question',
		'multipleChoice',
		'table',
		'officerIntervention',
		'pccInstruction',
	].includes(template?.type);
	const showDeleteCta = template?.type !== 'next';

	return (
		<div
			className={classNames('template-actions', `template-actions--${template?.type}`, className)}
		>
			{!viewType && showCopyCta && <CopyTemplateButton template={template} />}
			{!readOnly && showDeleteCta && (
				<DeleteTemplateButton template={template} location={location} />
			)}
		</div>
	);
};

export default TemplateActions;

TemplateActions.propTypes = {
	template: PropTypes.object,
	className: PropTypes.string,
	readOnly: PropTypes.bool,
	viewType: PropTypes.string,
	location: PropTypes.object,
};
