import React from 'react';

import './return-triangle.scss';

import { IconReturn } from '../../../domains/process/assets';
import IconWrapper from '../icon-wrapper/icon-wrapper';

const ReturnTriangle = () => (
	<span className="return-triangle">
		<IconWrapper className="return-triangle__icon" Component={IconReturn} />
	</span>
);

export default ReturnTriangle;
