import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import './spr-run-process-subheader.scss';

import { SubheaderReturn, usePopup } from '../../../../../../shared';
import { Button, IconWrapper } from '../../../../../../theme';
import { IconTrain } from '../../../../../binder/assets';
import { IconEffect, IconSheetPdf, IconStep } from '../../../../../sheet/assets';
import { IconDots, IconMap } from '../../../../assets';
import { useRunProcessContext } from '../../../../components/context-run-process/context-run-process';
import ProcessActionButton from '../action-button/process-action-button';
import PopupSystemEffectList from '../popup-system-effect-list/popup-system-effect-list';
import SprRunProcessBreadcrumb from '../spr-run-process-breadcrumb/spr-run-process-breadcrumb';

/**
 * spr run proces header
 * @param {object} props
 * @param {object} [props.showActionButtons]
 * @param {function} [props.setShowActionButtons]
 * @returns {JSX.Element}
 */
const SprRunProcessSubheader = ({ showActionButtons, setShowActionButtons = () => {} }) => {
	const {
		currentSheetData = {},
		processTechId,
		processInfo,
		processStarted,
		processHist = [],
	} = useRunProcessContext();
	const location = useLocation();
	const { t } = useTranslation();
	const history = useHistory();
	const { binder_id: binderId, binder_tech_id: binderTechId } = currentSheetData;
	const { type: processType, train_id: processTrainId, line: processLine } = processInfo || {};
	const isRerProcess = processLine === 'A' || processLine === 'B';
	const displayDamageLocationFormBtn = isRerProcess && processType === 'train';
	const displayProcessResumeFormBtn = processTechId && processType === 'metro' && !isRerProcess;

	const [systemEffects, setSystemEffects] = useState([]);
	const popupSystemEffectControl = usePopup();

	const prefixLink = location.pathname.startsWith('/spr') ? 'spr' : 'railway';
	const showReturn =
		typeof processStarted === 'boolean' && (!processStarted || processHist?.length === 0);

	// Get the system effect template list from current sheet content for displaying system effect popup
	const getSystemEffectsList = () => {
		const { content } = currentSheetData;
		let newSystemEffects = [];
		if (content) {
			newSystemEffects = content.filter(({ type }) => type === 'systemEffect');
		}
		setSystemEffects(newSystemEffects);
	};

	const SubheaderTitleRunProcess = (
		<>
			<PopupSystemEffectList
				popupControl={popupSystemEffectControl}
				systemEffects={systemEffects}
			/>
			<div className="spr-run-process__head">
				{processType === 'train' && (
					<>
						<div className="subheader__train">
							<IconWrapper className="subheader__train__icon" Component={IconTrain} />
							{processTrainId && <span className="subheader__train__id">{processTrainId}</span>}
						</div>
						<span className="subheader__separator" />
					</>
				)}
				{processStarted && <SprRunProcessBreadcrumb />}
				{showReturn && <span className="head__binder-id">{binderId}</span>}
			</div>
		</>
	);

	const ActionButtons = (
		<>
			<ProcessActionButton
				handleClick={() => history.push(`/${prefixLink}/process/${processTechId}`)}
				label={t('sheet:run-process.cta-step')}
				Icon={IconStep}
			/>
			<ProcessActionButton
				handleClick={() =>
					history.push(
						`/${prefixLink}/binders/${binderTechId}/sheet/${currentSheetData?.sheet_id}/process/${processTechId}`
					)
				}
				label={t('sheet:run-process.cta-sheet')}
				Icon={IconSheetPdf}
			/>
			<div className="spr-run-process__actions__buttons__right">
				{systemEffects?.length !== 0 && (
					<ProcessActionButton
						handleClick={popupSystemEffectControl.show}
						label={t('sheet:run-process.cta-effect')}
						Icon={IconEffect}
					>
						<span className="cta__sheet-number">{currentSheetData?.number_search}</span>
					</ProcessActionButton>
				)}
				{currentSheetData?.binder_type === 'man' && currentSheetData?.binder_zone_link && (
					<ProcessActionButton
						handleClick={() =>
							window.open(currentSheetData?.binder_zone_link, '_blank', 'noopener, noreferrer')
						}
						label={t('sheet:run-process.cta-zone-link')}
						Icon={IconMap}
					/>
				)}
				{prefixLink === 'railway' && (
					<div className="spr-run-process__actions__buttons__right__railway-buttons">
						{displayProcessResumeFormBtn && (
							<ProcessActionButton
								handleClick={() => history.push(`/${prefixLink}/process/${processTechId}/resume`)}
								label={t('sheet:run-process.resume-conditions')}
								Icon={IconTrain}
							/>
						)}
						{displayDamageLocationFormBtn && (
							<ProcessActionButton
								handleClick={() =>
									history.push(`/${prefixLink}/process/${processTechId}/process-rer-resume`)
								}
								label={t('sheet:run-process.damage-location')}
								Icon={IconTrain}
								className="spr-run-process__actions__buttons__right__railway-buttons__btn"
							/>
						)}
					</div>
				)}
			</div>
		</>
	);

	const SubheaderOptionsRunProcess = (
		<div className="spr-run-process__actions">
			{showReturn && (
				<SubheaderReturn
					onClick={history.goBack}
					label={t('process:spr-run-process.come-back-list-sheet')}
				/>
			)}
			{!showReturn && processStarted && (
				<>
					<div className="action__buttons">{ActionButtons}</div>
					<div className="action__buttons__dropdown">
						<span>{t('sheet:run-process.button-actions')}</span>
						<Button
							variant="secondary"
							small
							icon={IconDots}
							className="action__buttons__icons"
							onClick={() => setShowActionButtons((prev) => !prev)}
						/>
						{showActionButtons && <div className="action__buttons__wrapper">{ActionButtons}</div>}
					</div>
				</>
			)}
		</div>
	);

	useEffect(getSystemEffectsList, [currentSheetData]);

	return (
		<div className="spr-run-process__subheader">
			{SubheaderTitleRunProcess}
			{SubheaderOptionsRunProcess}
		</div>
	);
};

export default SprRunProcessSubheader;

SprRunProcessSubheader.propTypes = {
	showActionButtons: PropTypes.bool,
	setShowActionButtons: PropTypes.func,
};
