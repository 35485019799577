import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './popup-copy-binder.scss';

import { checkFormError } from '../../../../../shared';
import { Button, ErrorMessage, PopupDefault } from '../../../../../theme';
import { copyBinder, fetchBinderListById } from '../../../binder.services';
import {
	getFormBinderFieldMessageKey,
	validatePopupCopyBinderForm,
} from '../../../utils/validate-popup-binder-form';
import FormBinder from '../form-binder/form-binder';

/**
 * Render the PopupCopyBinder component
 * @param {object} props
 * @param {function} props.onSubmitSuccess Callback called after successful submit
 * @param {object} props.binder binder to copy
 * @param {object} props.popupControl
 * @param {boolean} props.train is the popup for a train binder
 * @returns {JSX.Element}
 */
const PopupCopyBinder = ({ onSubmitSuccess = () => {}, binder, popupControl, train, onClose }) => {
	const { t } = useTranslation();
	const [formData, setFormData] = useState({});
	const [existingBinderList, setExistingBinderList] = useState([]);
	const [networkError, setNetworkError] = useState(false);
	const [hasNewBinderId, setHasNewBinderId] = useState(false);
	const [hasEditBinderId, setHasEditBinderId] = useState(false);

	const [extendedBinderStatus, setExtendedBinderStatus] = useState(null);

	const reInitForm = () => {
		onClose();
		setFormData({});
		setExistingBinderList([]);
		setNetworkError(false);
	};

	const getFormError = () => validatePopupCopyBinderForm(formData, binder, existingBinderList);
	const formError = getFormError();
	const formErrorMessage = getFormBinderFieldMessageKey(formError);

	// Fetch existing binder by exact id
	const fetchExistingBinderById = useCallback(
		async (binderId) => {
			if (!binderId) return Promise.resolve(null);

			return fetchBinderListById(binderId)
				.then((response) => {
					setNetworkError(false);
					const binderList = response?.data || [];
					setExistingBinderList(binderList);
					setHasNewBinderId(binderId !== binder.id && train);
					const publishedBinder = binderList.find(
						({ status, id }) => status === 'published' && binder.id !== id
					);
					setHasEditBinderId(
						binderList.some(({ tech_id, id }) => tech_id !== binder.tech_id && binderId === id)
					);

					setExtendedBinderStatus(publishedBinder?.status);
					return binder;
				})
				.catch((error) => {
					console.error(error);
					setNetworkError(true);
					throw error;
				});
		},
		[binder, train]
	);

	const isIdTouchedAfterLineUpdate = () => {
		const isUpdateId = binder.id !== formData.id;
		const isTitleUpdated = binder.title !== formData.title;
		if ((isUpdateId && isTitleUpdated) || train) return false;
		const isLineNumberUpdated = Number(binder.associated_line[0]) !== Number(formData.lineNumber);
		return isLineNumberUpdated;
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if (!checkFormError(formError)) {
			const { lineNumber: associated_line, material: material_tech_id, ...restFormData } = formData;
			const newBinder = { associated_line, ...restFormData };

			// if copying train binder, then add material field
			if (train) {
				newBinder.material_tech_id = material_tech_id;
			} else if (binder?.type === 'man') {
				newBinder.station_tech_id = binder?.station_tech_id;
				newBinder.station_type = binder?.station_type;
			}

			copyBinder(newBinder, binder.tech_id).then(() => {
				reInitForm();
				onSubmitSuccess();
				popupControl.hide();
			});
		}
	};

	const restoreInitialBenderId = () => {
		if (
			formData.lineNumber &&
			binder.associated_line &&
			formData.lineNumber[0] === binder.associated_line[0]
		) {
			setFormData((oldFormData) => ({
				...oldFormData,
				id: binder.id,
			}));
		}
	};

	useEffect(() => {
		if (popupControl.visible && binder.id) {
			fetchExistingBinderById(binder.id).then((currentBinder) => {
				if (!currentBinder) return;

				setFormData((oldFormData) => ({
					...oldFormData,
					lineNumber: train ? currentBinder.associated_line : currentBinder.associated_line[0],
					id: currentBinder.id,
					title: currentBinder.title,
					subtitle: currentBinder.status === 'published' ? '' : currentBinder.subtitle,
					color: currentBinder.color,
					abbreviation: currentBinder.abbreviation,
					type: currentBinder.type,
					material: currentBinder.material_tech_id,
				}));
			});
		}
	}, [popupControl.visible, binder.id, fetchExistingBinderById, train]);

	useEffect(() => {
		fetchExistingBinderById(formData.id);
	}, [
		formData.id,
		binder.id,
		fetchExistingBinderById,
		binder.associated_line,
		formData.lineNumber,
	]);

	useEffect(restoreInitialBenderId, [formData.lineNumber, binder.associated_line, binder.id]);

	return (
		<PopupDefault
			popupControl={popupControl}
			title={t('binder:popup.copy-binder.title')}
			onClose={reInitForm}
			className={classnames('popup-copy-binder__wrapper', {
				'popup-copy-binder__wrapper--train': train,
			})}
		>
			<form className="popup-copy-binder" onSubmit={handleSubmit}>
				<div className="popup-copy-binder__form-wrapper">
					<FormBinder
						form={formData}
						validation={formError}
						errorMessage={formErrorMessage}
						onChange={handleChange}
						copy
						train={train}
						hasCopyBinderId={hasNewBinderId}
						hasEditBinderId={hasEditBinderId}
						extendedBinderStatus={extendedBinderStatus}
						initialLineNumber={binder.associated_line ? binder.associated_line[0] : null}
					/>
				</div>
				<div className="popup-copy-binder__controller">
					<Button
						variant="primary"
						className="popup-copy-binder__submit"
						type="submit"
						disabled={checkFormError(formError) || isIdTouchedAfterLineUpdate()}
						label={t('binder:popup.form.field.submit')}
					/>
				</div>
				{networkError && (
					<ErrorMessage className="popup-copy-binder__network-error">
						{t('error.default')}
					</ErrorMessage>
				)}
			</form>
		</PopupDefault>
	);
};

export default PopupCopyBinder;

PopupCopyBinder.propTypes = {
	onClose: PropTypes.func,
	onSubmitSuccess: PropTypes.func,
	binder: PropTypes.shape({
		id: PropTypes.string,
		type: PropTypes.string,
		station_label: PropTypes.string,
		station_type: PropTypes.string,
		station_tech_id: PropTypes.string,
		tech_id: PropTypes.string,
		associated_line: PropTypes.arrayOf(PropTypes.string),
	}),
	popupControl: PropTypes.shape({
		hide: PropTypes.func,
		show: PropTypes.func,
		visible: PropTypes.bool,
	}),
	train: PropTypes.bool,
};
