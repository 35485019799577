import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-edit-theme.scss';

import { checkFormError } from '../../../../../shared';
import { PopupDefault } from '../../../../../theme';
import { updateBinderTheme } from '../../../binder-theme.services';
import { getFormThemeFieldMessageKey, validateThemeForm } from '../../../utils/validate-theme-form';
import FormTheme from '../../popup/form-theme/form-theme';

/**
 * Render the PopupEditTheme component
 * @param props.popupControl
 * @param props.binder
 * @param props.handleClose Callback called after closing the popup
 * @returns {JSX.Element}
 */
const PopupEditTheme = (props) => {
	const { popupControl, binder, theme, themeList, handleClose } = props;
	const { t } = useTranslation();

	const [formData, setFormData] = useState({ title: theme.title });

	const formError = validateThemeForm(formData, themeList, theme);
	const formErrorMessage = getFormThemeFieldMessageKey(formError);

	const updateTheme = () => {
		setFormData({ title: theme.title });
	};

	const handleChange = (event) => {
		setFormData((previousForm) => {
			const { name, value } = event.target;
			return { ...previousForm, [name]: value };
		});
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if (!checkFormError(formError)) {
			// Handle default binder color
			if (!formData.color) {
				formData.color = theme.color;
			}

			updateBinderTheme(binder.tech_id, theme.tech_id, formData).then(() => {
				setFormData({});
				handleClose();
				popupControl.hide();
			});
		}
	};

	useEffect(updateTheme, [theme]);

	return (
		<PopupDefault popupControl={popupControl} title={t('binder:popup.form-theme.edit-title')}>
			<form className="popup-edit-theme" onSubmit={handleSubmit}>
				<FormTheme
					formData={formData}
					binder={binder}
					theme={theme}
					formError={formError}
					formErrorMessage={formErrorMessage}
					handleChange={handleChange}
				/>
			</form>
		</PopupDefault>
	);
};

PopupEditTheme.propTypes = {
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired,
	}).isRequired,
	binder: PropTypes.shape({
		tech_id: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
	}),
	theme: PropTypes.shape({
		tech_id: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
	}),
	themeList: PropTypes.arrayOf(PropTypes.shape({})),
	handleClose: PropTypes.func,
};

export default PopupEditTheme;
