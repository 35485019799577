import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './simple-link-other-sheet-view.scss';

import { binderStatusRules } from '../../../../../../../../../binder';
import { searchBinder } from '../../../../../../../../../binder/binder.services';
import SimpleLinkSheetnumber from '../simple-link-sheetnumber/simple-link-sheetnumber';

const SimpleLinkOtherSheetView = ({ content, isTrainBinder }) => {
	const { binder: currentBinder, sheetTitle, actionId, actionPosition } = content || {};
	const { t } = useTranslation();
	const [publishedBinder, setPublishedBinder] = useState({});
	const subtitleDisplayStatus = binderStatusRules.hideSubtitle;

	// display binder's subtitle dynamically from published version
	const retrieveSheetBinder = () => {
		if (currentBinder?.id) {
			const params = { id: currentBinder?.id };
			searchBinder(params).then((response) => {
				let newPublishedBinder = {};
				if (response?.data) {
					newPublishedBinder = response.data.find((binder) => binder.status === 'published');
				}
				setPublishedBinder(newPublishedBinder);
			});
		}
	};

	useEffect(retrieveSheetBinder, [content, currentBinder?.id]);
	return (
		<div className="simple-link-other-sheet-view__content">
			<div className="simple-link-other-sheet-view__sheet-number">
				<SimpleLinkSheetnumber content={content} />
			</div>
			<div className="simple-link-other-sheet-view__informations">
				<div className="informations--id">{currentBinder?.id}</div>
				<div className="informations--title">{sheetTitle}</div>
				{!subtitleDisplayStatus.includes(publishedBinder?.status) && (
					<div className="informations--subtitle">{currentBinder?.subtitle}</div>
				)}
				{isTrainBinder && actionId && (
					<div>
						{t('sheet:sheet-details.template.simpleLink.action-option', {
							bullet: actionPosition + 1,
						})}
					</div>
				)}
			</div>
		</div>
	);
};

export default SimpleLinkOtherSheetView;

SimpleLinkOtherSheetView.propTypes = {
	content: PropTypes.object,
	isTrainBinder: PropTypes.bool,
};
