import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './multiple-choice-template-view.scss';

import { useDndHandleMove, useDndSheetReference } from '../../../../../../../../shared';
import { EditorRendering, IconWrapper } from '../../../../../../../../theme';
import { IconAdd, IconCheck } from '../../../../../../../../theme/assets/img';
import { useSheetContentContext } from '../../../../../../context/sheet-content-context';
import { createTemplate } from '../../../../../../context/sheet-content-context-utils';
import { useSheetModeContext } from '../../../../../../context/sheet-mode-context';
import TemplateWrapper from '../../template-wrapper/template-wrapper';
import NewTemplateView from '../new-template-view/new-template-view';
import SpaceTemplateView from '../space-template-view/space-template-view';
import TextTemplateView from '../text-template-view/text-template-view';

/**
 * MultipleChoiceTemplateView
 * @param {object} props
 * @param {{content: {subnodes: object[], choices: object[]}}} props.template
 * @param {number} props.currentPosition
 * @param {boolean} props.allowInsertAfter
 * @param {(id: string, afterId: string) => void} props.moveCard
 * @param {object} props.processCurrentTemplate
 * @param {object} props.location
 * @returns {JSX.Element}
 */
const MultipleChoiceTemplateView = ({
	template,
	currentPosition,
	allowInsertAfter,
	moveCard,
	processCurrentTemplate,
	location,
}) => {
	const content = template?.content;
	const { t } = useTranslation();

	const { readOnly, viewType } = useSheetModeContext();

	const { setCurrentNodeContent, setAdditionalInformation, setActiveLocation } =
		useSheetContentContext();

	// dnd enabling for this template
	const ref = useRef(null);
	const { drag, isDragging } = useDndSheetReference(template, moveCard, ref);

	const setSubNodes = (newSubNodes) => {
		content.subNodes = newSubNodes;
		setCurrentNodeContent(content, true);
	};

	// dnd handle move sub card
	const { moveCard: moveSubCard } = useDndHandleMove(content?.subNodes, setSubNodes);

	const components = {
		new: NewTemplateView,
		note: TextTemplateView,
		action: { template: TextTemplateView, icon: 'action' },
		call: { template: TextTemplateView, icon: 'call' },
		space: SpaceTemplateView,
	};

	const loadTemplateComponent = (templateToLoad, index) => {
		const ComponentConfig = components[templateToLoad.type];
		let ComponentName = ComponentConfig;
		let additionalConfig = {};

		// in case the template selected has additional configuration like icon or input type
		if (ComponentConfig?.template) {
			ComponentName = ComponentConfig.template;
			additionalConfig = { icon: ComponentConfig.icon };
		}

		if (!!viewType && templateToLoad?.type === 'space') {
			return null;
		}

		return (
			<div className="content__sub-node" key={templateToLoad.id}>
				{React.createElement(ComponentName, {
					key: templateToLoad.id,
					template: templateToLoad,
					location: { id: templateToLoad.id, parentId: template.id },
					currentPosition: index,
					allowInsertAfter: false,
					processCurrentTemplate,
					moveCard: moveSubCard,
					...additionalConfig,
				})}
			</div>
		);
	};

	const addNewSubTemplate = (e) => {
		e.stopPropagation();
		const node = createTemplate();
		const newQuestionTemplate = { ...template };
		if (Array.isArray(newQuestionTemplate.content?.subNodes)) {
			newQuestionTemplate.content.subNodes.push(node);
		} else {
			newQuestionTemplate.content.subNodes = [node];
		}
		setActiveLocation({ id: node.id, parentId: template.id });
	};

	// change active template is not allowed if multipleLink form is invalid
	const preventChangeNodeActive = () => {
		if (!readOnly && content?.choices) {
			const blockValid = content?.choices.filter((block) => block.title && block.subtitle);
			// multipleLink template need two configurations to be valid
			if (blockValid.length < 2) {
				// conditional if multipleLink template is in a yes/no template
				setActiveLocation(location);
				// send informations to form multipleLink
				setAdditionalInformation({ multipleChoiceInvalid: true });
			}
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(preventChangeNodeActive, [template.active]);

	const renderChoiceBlocks = (choiceBlock, index) => {
		return (
			<div key={index} className="multiple-choice__choice-block">
				<div className="multiple-choice__choice-block--icon">
					<div className="multiple-choice--template-view__icon-head">
						<IconWrapper
							className="multiple-choice--template-view__icon--check"
							Component={IconCheck}
						/>
					</div>
				</div>
				<div className="multiple-choice__choice-block__content">
					<div className="multiple-choice__choice-block__content--title">{choiceBlock.title}</div>
					<div className="multiple-choice__choice-block__content--subtitle">
						{choiceBlock.subtitle}
					</div>
				</div>
			</div>
		);
	};

	return (
		<TemplateWrapper
			id={template.id}
			className="multiple-choice--template-view "
			template={template}
			location={location}
			reference={ref}
			isDragging={isDragging}
			dragRef={drag}
			currentPosition={currentPosition}
			allowInsertAfter={allowInsertAfter}
			processCurrentTemplate={processCurrentTemplate}
		>
			<div className="multiple-choice__header">
				<EditorRendering className="multiple-choice__header--title" content={content?.title} />
			</div>

			<div className="multiple-choice__content">
				<div className="multiple-choice__choice-blocks">
					{Array.isArray(content?.choices) && content.choices.map(renderChoiceBlocks)}
				</div>

				<div className="multiple-choice__sub-content">
					<div className="content__sub-nodes">
						<div className="content__sub-nodes__title">
							{t('sheet:sheet-details.template.multipleChoice.title-subnode')}
						</div>
						{Array.isArray(content?.subNodes) && content?.subNodes?.map(loadTemplateComponent)}
					</div>

					{!readOnly && (
						<div className="content__sub-nodes--add-button">
							<button type="button" onClick={addNewSubTemplate}>
								<IconWrapper
									className="content__add-button__icon"
									Component={IconAdd}
									tooltip={t('sheet:sheet-details.template.add-tooltip')}
								/>
								{(!content?.subNodes ||
									(Array.isArray(content?.subNodes) && content?.subNodes.length === 0)) && (
									<span className="content__sub-nodes--add-button--message">
										{t('sheet:sheet-details.template.multipleChoice.add-subnode')}
									</span>
								)}
							</button>
						</div>
					)}
				</div>
			</div>
		</TemplateWrapper>
	);
};

export default MultipleChoiceTemplateView;

MultipleChoiceTemplateView.propTypes = {
	template: PropTypes.shape({
		id: PropTypes.string,
		active: PropTypes.bool,
		content: PropTypes.shape({
			subNodes: PropTypes.arrayOf(PropTypes.object),
			choices: PropTypes.arrayOf(PropTypes.object),
		}),
	}),
	allowInsertAfter: PropTypes.bool,
	currentPosition: PropTypes.number,
	moveCard: PropTypes.func,
	processCurrentTemplate: PropTypes.object,
	location: PropTypes.object,
};
