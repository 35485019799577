import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import './sl-binder-detail.scss';

import { buildSprProcessUri, useSprContext } from '../../../../shared';
import RailWaySubheader from '../../../../shared/components/railway-subheader/railway-subheader';
import SlSuggestedSheetSidebar from '../../../../shared/components/sl-suggested-sheet-sidebar/sl-suggested-sheet-sidebar';
import { EditorRendering, SprPage, ToggleSwitch } from '../../../../theme';
import { getProcessByTechId } from '../../../process/process.services';
import { resolveSheetColor, SheetType } from '../../../sheet';
import { fetchSheetByBinder } from '../../../sheet/sheet.services';
import { getTrainByTechId } from '../../../train/train.services';
import { fetchBinderByTechId, searchBinder } from '../../binder.services';

/**
 * Render the SL Binder Detail page
 * @return {JSX.Element}
 * @constructor
 */
const SlBinderDetail = ({ disableLineSwitch }) => {
	const { t } = useTranslation();
	const {
		binderId: encodedBinderId,
		trainTechId: trainParamTechId,
		materialTechId,
		processId,
	} = useParams();

	const binderId = useMemo(() => decodeURIComponent(encodedBinderId), [encodedBinderId]);

	const {
		setTrainBinderCount,
		currentBinder: binder,
		setCurrentBinder: setBinder,
		trainBinderCount,
		side,
		associatedLine,
	} = useSprContext();

	const [sheetList, setSheetList] = useState([]);
	const [hasChildSheet, setHasChildSheet] = useState(false);
	const [sheetTechId, setSheetTechId] = useState('');
	const [trainId, setTrainId] = useState('');
	const mDeterminination = sheetList.filter((item) => item.type === 'mdetermination');
	const haveMDetermination = mDeterminination.length > 0;

	const setupTrain = (processTrainTechId) => {
		const trainTechId = trainParamTechId || processTrainTechId;
		// Get current train detail
		if (trainTechId) {
			getTrainByTechId(trainTechId).then((response) => {
				const {
					binder_auto: binderAuto,
					binder_driver: binderDriver,
					binder_officer: binderOfficer,
					id,
				} = response.data;
				setTrainBinderCount(!!binderAuto + !!binderDriver + !!binderOfficer);
				setTrainId(id);
			});
		}
	};

	const setupBinder = async () => {
		// Get published binder detail
		if (binderId) {
			const { data = [] } = await searchBinder({
				id: binderId,
				status: ['published'],
				associatedLine,
			});
			const [publishedBinder = {}] = data;
			const binderWithHeader = {
				...publishedBinder,
				color: publishedBinder.color,
				materialLabel: publishedBinder.material_label,
				type: publishedBinder.type,
			};
			setBinder(binderWithHeader);
		}
	};

	const initPage = () => {
		if (processId) {
			// get train and binder info from process
			getProcessByTechId(processId, { extendOwner: false }).then((response) => {
				setSheetTechId(response?.data?.sheet_tech_id);
				setupTrain(response?.data?.train_tech_id);
			});
			fetchBinderByTechId(binderId).then((response) => {
				const binderInfo = response.data;
				const headerBinder = {
					tech_id: binderInfo.tech_id,
					type: binderInfo.type,
					materialLabel: binderInfo.material_label,
					color: binderInfo.color,
					title: binderInfo.title,
				};
				setBinder(headerBinder);
			});
		} else {
			setupTrain();
			setupBinder();
		}
	};

	const refreshSheetList = useCallback((binderTechId, sheetTypes) => {
		if (binderTechId) {
			fetchSheetByBinder(binderTechId, { type: sheetTypes, line: associatedLine }).then(
				(response) => {
					setSheetList(response.data);
				}
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getSheetList = () => {
		const sheetTypes = ['mdetermination', 'm', 'determination', 'parent', 'resume'];
		if (hasChildSheet) {
			sheetTypes.push('child');
		}
		refreshSheetList(binder?.tech_id, sheetTypes);
	};

	const renderToggleFilter = (classNameModifier) => {
		return (
			<div className={`sl-binder-detail__toggle sl-binder-detail__toggle--${classNameModifier}`}>
				<span className="toggle__label">{t('binder:sl-binder-detail.list.toggle-label')}</span>
				<span className="toggle__label toggle__label--phone">
					{t('binder:sl-binder-detail.list.toggle-label-phone')}
				</span>
				<ToggleSwitch
					className="toggle__input"
					isChecked={hasChildSheet}
					handleOnChange={() => setHasChildSheet(!hasChildSheet)}
				/>
			</div>
		);
	};

	const renderSheet = (sheet) => {
		const { number, type, title, sheet_id: sheetId } = sheet;
		const color = resolveSheetColor(sheet?.color, binder?.color, '');
		const info = {
			binderTechnicalId: binder?.tech_id,
			sheetNumber: number,
			trainTechId: trainParamTechId,
			materialTechId,
			processId,
		};
		const uri = buildSprProcessUri(info, 'railway');
		return (
			<li key={sheetId} className="list__sheet">
				<Link className="sheet__link" to={uri}>
					<SheetType
						className="sheet__number"
						color={color}
						sheetNumber={number}
						type={type}
						binderType={binder?.type}
					/>
					<EditorRendering className="sheet__title" content={title} />
				</Link>
			</li>
		);
	};

	const renderMDeterminationLink = () => {
		if (haveMDetermination) {
			const { number } = mDeterminination[0];
			const info = {
				binderTechnicalId: binder?.tech_id,
				sheetNumber: number,
				trainTechId: trainParamTechId,
				materialTechId,
				processId,
			};
			const uri = buildSprProcessUri(info, 'railway');
			return <Redirect to={uri}> </Redirect>;
		}
		return null;
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(initPage, [
		processId,
		trainParamTechId,
		setTrainBinderCount,
		binderId,
		setBinder,
		sheetList,
	]);
	useEffect(getSheetList, [refreshSheetList, binder?.tech_id, hasChildSheet, sheetList]);

	const renderSubHeader = (hasMultipleBinders) => {
		const returnLabel = t(
			hasMultipleBinders
				? 'binder:sl-binder-detail.header.binder-list-link'
				: 'binder:sl-binder-detail.header.home-link'
		);

		return (
			<RailWaySubheader
				{...{ trainTechId: trainParamTechId, returnLabel, trainId }}
				className="railway-header--binder-detail"
				trainLabel={binder?.id}
				title={binder?.title}
				goBack
			/>
		);
	};

	return (
		<SprPage
			className="sl-binder-detail"
			subheader={
				haveMDetermination === false &&
				sheetList.length > 0 &&
				renderSubHeader(trainBinderCount > 1)
			}
			displayCurrentBinderColor
			allowLineSwitch={side === 'train'}
			disabledLineSwitch={disableLineSwitch}
		>
			{renderMDeterminationLink()}
			{processId && sheetTechId && haveMDetermination === false && <SlSuggestedSheetSidebar />}

			{haveMDetermination === false && sheetList.length > 0 && (
				<>
					<p className="sl-binder-detail___text">{t('binder:sl-binder-detail.select-sheet')}</p>
					{renderToggleFilter('top')}
					<ul className="sl-binder-detail___list">
						<li key="head" className="list__head">
							<span className="list__head__number">
								{t('binder:sl-binder-detail.list.head-number')}
							</span>
							<span className="list__head__number list__head__number--phone">
								{t('binder:sl-binder-detail.list.head-number-phone')}
							</span>
							<span className="list__head__title">
								{t('binder:sl-binder-detail.list.head-title')}
							</span>
						</li>
						{sheetList?.map(renderSheet)}
					</ul>
					{renderToggleFilter('bottom')}
				</>
			)}
		</SprPage>
	);
};

const SlBinderDetailWrapper = (props) => <SlBinderDetail {...props} />;

export default SlBinderDetailWrapper;

SlBinderDetail.propTypes = {
	disableLineSwitch: PropTypes.bool,
};
