import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-delete-comment.scss';

import { PopupConfirm } from '../../../../../../../../theme';
import { deleteComment } from '../../../../../../comment.services';

/**
 * @param {object} props
 * @param {number} props.commentId
 * @param {function} props.retrieveCommentsList
 * @param {object} props.popupControl
 * @returns {JSX.Element}
 */
const PopupDeleteComment = ({ commentId, retrieveCommentsList, popupControl }) => {
	const { t } = useTranslation();

	const handleConfirm = () => {
		deleteComment(commentId)
			.then(() => {
				retrieveCommentsList();
			})
			.catch((error) => {
				throw error;
			});
	};

	return (
		<PopupConfirm
			popupControl={popupControl}
			onConfirm={handleConfirm}
			title={t('sheet:sheet-details.comments.pop-up.delete-comment.title')}
			ctaConfirmLabel={t('sheet:sheet-details.comments.pop-up.delete-comment.cta-confirm-label')}
		>
			<div className="popup-delete-comment">
				<p className="popup-delete-comment__text">
					{t('sheet:sheet-details.comments.pop-up.delete-comment.text')}
				</p>
			</div>
		</PopupConfirm>
	);
};

export default PopupDeleteComment;

PopupDeleteComment.propTypes = {
	commentId: PropTypes.number,
	retrieveCommentsList: PropTypes.func,
	popupControl: PropTypes.object,
};
