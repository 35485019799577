import i18next from '../../config/i18next';

import ErrorBoundary from './components/error-boundary/error-boundary';
import i18nError from './i18n/fr.json';
import ErrorDownPage from './pages/error-down-page/error-down-page';
import ErrorNetworkPage from './pages/error-network-page/error-network-page';
import NotFoundPage from './pages/not-found-page/not-found-page';
import ProcessUnfinishedPage from './pages/process-unfinished-page/process-unfinished-page';
import UnauthorizedPage from './pages/unauthorized-page/unauthorized-page';

i18next.addResourceBundle('fr', 'error', i18nError);

export {
	ErrorBoundary,
	ErrorDownPage,
	ErrorNetworkPage,
	NotFoundPage,
	ProcessUnfinishedPage,
	UnauthorizedPage,
};
