import React from 'react';

import { useRunProcessContext } from '../../../../components/context-run-process/context-run-process';
import HistoryTaskLoader from '../../../../components/history-task-loader/history-task-loader';

const RunProcessHistory = () => {
	const { processHist, cdrMode, processInfo, isTrainBinder } = useRunProcessContext();

	/**
	 * Remove task always hidden in history
	 * @param {any} task
	 * @return {boolean}
	 */
	const filterTaskToHide = (task) => {
		const hiddenTaskType = ['history-start', 'pageBreak', 'space', 'step'];
		return !hiddenTaskType.includes(task?.type);
	};

	/**
	 * Remove SimpleLink step from history in cdrMode
	 * @param {any} task
	 * @return {boolean}
	 */
	const filterSimpleLinkStepInCdrMode = (task) =>
		task?.type !== 'simpleLink' || (cdrMode && task?.content?.type !== 'step');

	/**
	 * Remove task to hide in history
	 * @param history The task history
	 * @return {any[]}
	 */
	const cleanTaskHistory = (history = []) =>
		history.filter(filterTaskToHide).filter(filterSimpleLinkStepInCdrMode);

	const filterLastTask = (taskList = []) => {
		const filteredTaskList = cleanTaskHistory(taskList);
		const lastHistoryTask = filteredTaskList[filteredTaskList.length - 1];

		if (lastHistoryTask?.type === 'termination' && lastHistoryTask?.type === 'simpleLink') {
			// 2 + 1 task to handle termination task automatically passed
			return filteredTaskList.slice(-3);
		}
		return filteredTaskList.slice(-2);
	};

	const generateProcessHistoryItem = (taskList = []) => {
		return filterLastTask(taskList).map((task) => (
			<HistoryTaskLoader
				key={task.id}
				task={task}
				processInfo={processInfo}
				isTrainBinder={isTrainBinder}
				passed
				cdrMode
			/>
		));
	};

	return generateProcessHistoryItem(processHist);
};

export default RunProcessHistory;
