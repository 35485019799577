import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import './binder-detail-info.scss';

import { Button, Drawer, Editor, ErrorMessage } from '../../../../../../theme';
import { IconEdit } from '../../../../../../theme/assets/img';
import { isTrainBinderType } from '../../../../utils/binder-type';
import BinderDetailInfoDirector from '../binder-detail-info-director/binder-detail-info-director';

/**
 * BinderDetailCardTemplate component
 * @param {object} props
 * @param {object} props.binder
 * @param {object[]} props.existingBinderList
 * @param {boolean} props.readOnly
 * @param {function} props.onSubmit
 * @param {boolean} props.networkError
 * @param {boolean} props.drawerStatus
 * @returns {JSX.Element}
 */
const BinderDetailInfo = ({
	binder,
	existingBinderList,
	readOnly,
	onSubmit,
	networkError,
	drawerStatus,
}) => {
	const { t } = useTranslation();
	const [infoEditionText, setInfoEditionText] = useState('');
	const [infoRectificationText, setInfoRectificationText] = useState('');
	const [isEditing, setIsEditing] = useState(false);
	const [initialBinder, setInitialBinder] = useState({});
	const [displayedBinder, setDisplayedBinder] = useState({});
	const editorToolbarConfig = {
		options: ['inline', 'list'],
		inline: { options: ['bold', 'italic'] },
		list: { options: ['unordered'] },
	};

	const isTrainBinder = isTrainBinderType(binder?.type);

	const defineDetailInfo = () => {
		let newBinder = { ...binder };
		if (!isEditing) {
			// find the parent's binder to get it's edition text
			const parentBinder = existingBinderList.find(({ tech_id }) => tech_id === binder.parent);
			// set rectification and edition texts to display
			const description = binder.changed_description ? binder.changed_description : '';
			const parentEdition = parentBinder?.changed_description
				? parentBinder.changed_description
				: '';

			// display info subtitle if is_rectification is true or false only
			if (binder.is_rectification) {
				// display the edition of the parent binder and the current rectification
				setInfoEditionText(`${t('binder:binder-detail.info.edition')} : ${parentEdition}`);
				setInfoRectificationText(`${t('binder:binder-detail.info.correction')} : ${description}`);
			} else if (typeof binder.is_rectification === 'boolean' && !binder.is_rectification) {
				// display only the current edition text
				setInfoEditionText(`${t('binder:binder-detail.info.edition')} : ${description}`);
				setInfoRectificationText('');
			} else {
				// do not display the description at init
				setInfoEditionText('');
				setInfoRectificationText('');
			}
			if (
				binder.parent !== null &&
				binder.associated_line[0] !== binder.parent_line &&
				!binder.type.startsWith('train')
			) {
				setInfoEditionText(
					`${t('binder:binder-detail.info.duplication')} ${binder.parent_line} le ${format(new Date(binder.iteration_date), 'dd/MM/yyyy')}`
				);
			}
		} else {
			newBinder.line_manager = displayedBinder.line_manager;
			newBinder.isf_version = displayedBinder.isf_version;
			newBinder.abrogated_documents = displayedBinder.abrogated_documents;
		}
		setDisplayedBinder(newBinder);
		setInitialBinder(binder);
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		if (name === 'manager') {
			setDisplayedBinder({
				...displayedBinder,
				line_manager: {
					...displayedBinder?.line_manager,
					manager: value,
				},
			});
		} else if (name === 'title') {
			let newManager = displayedBinder.line_manager?.manager;
			if (value === 'null') {
				newManager = null;
			}
			setDisplayedBinder({
				...displayedBinder,
				line_manager: {
					...displayedBinder?.line_manager,
					manager: newManager,
					title: value !== 'null' ? value.replace('Male', '').replace('Female', '') : null,
					gender: value === 'null' ? null : value.includes('Female') ? 'female' : 'male',
				},
			});
		} else {
			setDisplayedBinder({ ...displayedBinder, [name]: value });
		}
	};

	const startEdition = () => {
		setIsEditing(true);
	};

	const cancelEdition = () => {
		setIsEditing(false);
		setInitialBinder(binder);
		setDisplayedBinder(binder);
	};

	const saveEdition = () => {
		setIsEditing(false);
		onSubmit(displayedBinder);
		setInitialBinder(binder);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(defineDetailInfo, [binder, existingBinderList]);

	return (
		<div className="binder-detail-info">
			<Drawer
				title={t('binder:binder-detail.info.title')}
				drawerStatus={drawerStatus}
				leftText={infoEditionText}
				rightText={infoRectificationText}
			>
				<div className="binder-detail-info__content">
					<div className="content__subtitle">
						<span>
							{t('binder:binder-detail.info.subtitle')} : {displayedBinder.document_manager}
						</span>
						{isEditing ? (
							<div className="subtitle__buttons">
								<Button
									variant="minimal"
									label={t('binder:binder-detail.info.cancel-button')}
									onClick={cancelEdition}
								/>
								<Button
									onClick={saveEdition}
									variant="primary"
									label={t('binder:binder-detail.info.save-button')}
								/>
							</div>
						) : (
							!readOnly && (
								<Button
									className="subtitle__edit-icon"
									variant="minimal"
									icon={IconEdit}
									iconTooltipContent={t('binder:binder-detail.tooltip.edit')}
									onClick={startEdition}
								/>
							)
						)}
					</div>
					{networkError && <ErrorMessage>{t('error.default')}</ErrorMessage>}
					<div className="content__group">
						<h2 className="content__group__title">1/ {t('binder:binder-detail.info.approving')}</h2>
						<BinderDetailInfoDirector
							isTrainBinder={isTrainBinder}
							lineManager={displayedBinder.line_manager}
							isEditing={isEditing}
							handleChange={handleChange}
							documentManager={displayedBinder.document_manager}
						/>
					</div>
					<div className="content__group">
						<h2 className="content__group__title">2/ {t('binder:binder-detail.info.version')}</h2>
						<Editor
							className="content__group__editor"
							name="isf_version"
							label={t('binder:binder-detail.info.version')}
							value={initialBinder.isf_version || ''}
							disabled={!isEditing}
							toolbarConfig={editorToolbarConfig}
							onChange={handleChange}
						/>
					</div>
					<div className="content__group">
						<h2 className="content__group__title">
							3/ {t('binder:binder-detail.info.abrogated-documents')}
						</h2>
						<Editor
							className="content__group__editor"
							name="abrogated_documents"
							label={t('binder:binder-detail.info.abrogated-documents')}
							value={initialBinder.abrogated_documents || ''}
							disabled={!isEditing}
							toolbarConfig={editorToolbarConfig}
							onChange={handleChange}
						/>
					</div>
				</div>
			</Drawer>
		</div>
	);
};

export default BinderDetailInfo;

BinderDetailInfo.propTypes = {
	binder: PropTypes.shape({
		associated_line: PropTypes.arrayOf(PropTypes.string),
		tech_id: PropTypes.string,
		is_rectification: PropTypes.bool,
		iteration_date: PropTypes.string,
		changed_description: PropTypes.string,
		line_manager: PropTypes.object,
		isf_version: PropTypes.string,
		abrogated_documents: PropTypes.string,
		parent: PropTypes.string,
		parent_line: PropTypes.string,
		line_manager_gender: PropTypes.bool,
		type: PropTypes.string,
	}),
	readOnly: PropTypes.bool,
	existingBinderList: PropTypes.arrayOf(
		PropTypes.shape({
			tech_id: PropTypes.string,
			changed_description: PropTypes.string,
		})
	),
	onSubmit: PropTypes.func,
	networkError: PropTypes.bool,
	drawerStatus: PropTypes.bool,
};
