import React from 'react';
import PropTypes from 'prop-types';

import './add-new-template.scss';

import { IconWrapper } from '../../../../../../../theme';
import { IconAdd } from '../../../../../../../theme/assets/img';
import { useSheetContentContext } from '../../../../../context/sheet-content-context';

const AddNewNode = ({ position, location }) => {
	const { insertEmptyNodeAt } = useSheetContentContext();

	const addNewTemplate = () => {
		insertEmptyNodeAt(position, location);
	};

	return (
		<div className="add-new-node">
			<button type="button" className="add-new-node__button" onClick={addNewTemplate}>
				<IconWrapper className="add-new-node__icon" Component={IconAdd} />
			</button>
		</div>
	);
};

export default AddNewNode;

AddNewNode.propTypes = {
	position: PropTypes.number.isRequired,
	location: PropTypes.object.isRequired,
};
