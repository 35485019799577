import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-edit-sheet-title.scss';

import { checkFormError } from '../../../../shared';
import {
	ColorChooser,
	Dropdown,
	Editor,
	ErrorMessage,
	PopupConfirm,
	TextField,
} from '../../../../theme';
import { listAllBinderSignal } from '../../../binder/binder-signal.services';
import { getBinderThemes } from '../../../binder/binder-theme.services';
import { resolveSheetColor, sheetColorList, sheetColorTrainList } from '../..';
import { fetchSheetByBinder, updateSheet } from '../../sheet.services';

import signalOptionToString from './utils/signal-option-to-string';
import validateEditSheetTitleForm from './validate-edit-sheet-title-form';

/**
 * @param {object} props
 * @param {object} props.binder
 * @param {boolean} props.isTrainBinder
 * @param {function} props.onSubmitSuccess
 * @param {object} props.popupControl
 * @param {object} props.sheet
 * @returns {JSX.Element}
 */

const PopEditSheetTitle = ({ binder, isTrainBinder, onSubmitSuccess, popupControl, sheet }) => {
	const {
		title: sheetTitle,
		number: sheetNumber,
		theme_tech_id: themeTechId,
		type: sheetType,
	} = sheet;
	const { t } = useTranslation();

	const [form, setForm] = useState({ title: sheetTitle, number: sheetNumber });
	const [sheetList, setSheetList] = useState([]);
	const [isSheetListLoading, setIsSheetListLoading] = useState(false);
	const [networkError, setNetworkError] = useState(false);
	const [existingSheet, setExistingSheet] = useState();
	const [isExistingSheetLoading, setIsExistingSheetLoading] = useState(true);
	const [formValidation, setFormValidation] = useState({});
	const [themeList, setThemeList] = useState();
	const [signalList, setSignalList] = useState([]);
	const [warningMessage, setWarningMessage] = useState();

	const editorToolbarConfig = {
		options: ['inline', 'list', 'colorPicker'],
		inline: { options: ['bold', 'italic'] },
		list: { options: ['unordered'] },
		colorPicker: { colors: ['black', 'red', 'transparent'] },
	};

	const formError = useMemo(
		() =>
			validateEditSheetTitleForm(form, {
				binderType: binder.type,
				sheetNumber,
				sheetList,
				existingSheet,
			}),
		[sheetList, form, binder.type, sheetNumber, existingSheet]
	);

	const isFormReady = !isSheetListLoading && !isExistingSheetLoading && !checkFormError(formError);

	const loadBinderSheetList = () => {
		if (popupControl.visible && binder.tech_id) {
			setIsExistingSheetLoading(true);
			fetchSheetByBinder(binder.tech_id)
				.then((response) => {
					setExistingSheet(response?.data);
				})
				.finally(() => setIsExistingSheetLoading(false));
		}
	};

	const renderThemeOptions = (theme) => (
		<option
			key={theme?.tech_id}
			value={theme?.tech_id}
			disabled={sheet?.theme_tech_id === theme?.tech_id}
		>
			{theme?.title}
		</option>
	);

	const renderSignalOption = (signal) => {
		const { tech_id: techId } = signal;
		return (
			<option key={techId} value={techId} disabled={sheet?.signal_tech_id === techId}>
				{signalOptionToString(signal)}
			</option>
		);
	};

	const getThemes = () => {
		if (binder?.tech_id) {
			getBinderThemes(binder.tech_id).then((response) => {
				setThemeList(response.data);
			});
		}
	};

	const getSignalList = () => {
		if (binder?.tech_id) {
			listAllBinderSignal(binder?.tech_id).then((response) => {
				setSignalList(response?.data);
			});
		}
	};

	const handleConfirm = () => {
		if (isFormReady) {
			const update = {
				title: form.title,
				number: form.number,
				color: form.color,
				signalTechId: form.sheetSignal,
				themeTechId: form.themeTechId,
			};

			updateSheet(sheet.binder_tech_id, sheet.sheet_id, update)
				.then((response) => {
					onSubmitSuccess(response?.data);

					// Reset form after data's submission
					setNetworkError(false);
					popupControl.hide();
				})
				.catch((error) => {
					console.error(error);
					setNetworkError(true);
				});
		}
	};

	const handleFormValidation = () => {
		if (!isExistingSheetLoading) {
			const newFormValidation = {
				number: formError?.number?.includes('invalid')
					? t('sheet:popup.create.error.existing-sheet-number')
					: '',
				title: '',
				type: '',
			};
			setFormValidation(newFormValidation);
		}
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setForm({ ...form, [name]: value });
		setWarningMessage(
			name === 'number' && sheetType === 'parent'
				? t('sheet:popup.create.error.verif-sheet-children')
				: ''
		);
	};

	const handleUpdateSheetListBySignal = () => {
		if (form.sheetSignal) {
			setIsSheetListLoading(true);
			fetchSheetByBinder(binder?.tech_id, { signalTechId: form.sheetSignal })
				.then((response) => setSheetList(response?.data))
				.finally(() => setIsSheetListLoading(false));
		}
	};

	const resetPopup = () => {
		setForm({ title: sheetTitle, number: sheetNumber });
		setSheetList([]);
		setIsSheetListLoading(false);
	};

	useEffect(getSignalList, [binder.tech_id]);
	useEffect(getThemes, [binder.tech_id]);
	useEffect(loadBinderSheetList, [binder, popupControl.visible]);
	useEffect(handleFormValidation, [form, isExistingSheetLoading, existingSheet, t, formError]);
	useEffect(handleUpdateSheetListBySignal, [form.sheetSignal, binder.tech_id]);

	return (
		<PopupConfirm
			className="popup-edit-sheet-title__wrapper"
			onCancel={resetPopup}
			onClose={resetPopup}
			onConfirm={handleConfirm}
			popupControl={popupControl}
			title={t('sheet:popup.edit-title.title')}
			ctaConfirmDisabled={!isFormReady}
		>
			<form className="popup-binder-edit-title">
				<TextField
					name="number"
					label={t('sheet:popup.create.field.sheet-number.label')}
					value={form.number}
					helperText={formValidation?.number || warningMessage}
					invalid={formValidation?.number?.length > 0}
					onChange={handleChange}
				/>
				<Editor
					name="title"
					label={t('sheet:popup.edit-title.field.title.label')}
					value={sheet?.title}
					toolbarConfig={editorToolbarConfig}
					onChange={handleChange}
				/>
				{sheet.theme_tech_id && (
					<Dropdown
						name="themeTechId"
						value={form.themeTechId || themeTechId}
						onChange={handleChange}
						placeholder={t('sheet:popup.edit-title.field.theme.label')}
					>
						{themeList?.map(renderThemeOptions)}
					</Dropdown>
				)}
				{binder.type === 'man' && (
					<Dropdown
						className="form__signal"
						value={form.sheetSignal}
						name="sheetSignal"
						onChange={handleChange}
						placeholder={t('sheet:popup.edit-title.signal-dropdown-placeholder')}
					>
						{signalList.map(renderSignalOption)}
					</Dropdown>
				)}
				{binder.type !== 'man' && (
					<ColorChooser
						name="color"
						label={t('sheet:popup.create.field.color.label')}
						value={form.color || resolveSheetColor(sheet.color, binder.color, sheet.theme_color)}
						onChange={handleChange}
						colorList={isTrainBinder ? sheetColorTrainList : sheetColorList}
					/>
				)}
				{formError.sheetSignal.includes('invalid') && (
					<ErrorMessage>{t('sheet:popup.edit-title.sheet-exist')}</ErrorMessage>
				)}
				{networkError && (
					<ErrorMessage className="popup-binder-edit-title__network-error">
						{t('error.default')}
					</ErrorMessage>
				)}
			</form>
		</PopupConfirm>
	);
};

export default PopEditSheetTitle;

PopEditSheetTitle.propTypes = {
	binder: PropTypes.object.isRequired,
	isTrainBinder: PropTypes.bool,
	onSubmitSuccess: PropTypes.func,
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired,
		visible: PropTypes.bool.isRequired,
	}).isRequired,
	sheet: PropTypes.object.isRequired,
};
