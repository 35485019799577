import { hasFieldError, requiredFieldValidator } from '../../../shared';

import {
	binderAbbreviationValidator,
	binderAssociatedLineListValidator,
	binderIdValidator,
	binderSubtitleValidator,
} from './form-utils';

/**
 * @typedef BinderFormData
 * @property {string} type
 * @property {string} id
 * @property {string} lineNumber
 * @property {string} title
 * @property {string} subtitle
 * @property {string} abbreviation
 * @property {string} color
 */

/**
 * @typedef BinderFormError
 * @property {string[]} type
 * @property {string[]} id
 * @property {string[]} lineNumber
 * @property {string[]} abbreviation
 * @property {string[]} title
 * @property {string[]} subtitle
 * @property {string[]} color
 */

/**
 * Validate PopupBinderEditTitle form data
 * @param {BinderFormData} form form data to validate
 * @param currentBinder
 * @param binderList
 * @returns {BinderFormError}
 */
const validatePopupEditBinderForm = (form, currentBinder, binderList) => {
	const { id, title, subtitle, abbreviation, color, type, station, stationType } = form;

	const isBinderTrain = ['train-driver', 'train-officer', 'train-auto'].includes(
		currentBinder.type
	);

	const idErrors = [requiredFieldValidator(id), binderIdValidator(id)].flat();
	const subtitleErrors = [
		requiredFieldValidator(subtitle),
		binderSubtitleValidator(subtitle, currentBinder, binderList),
	].flat();
	const abbreviationErrors = isBinderTrain
		? []
		: [requiredFieldValidator(abbreviation), binderAbbreviationValidator(abbreviation)].flat();
	const stationErrors = type === 'man' ? requiredFieldValidator(station) : [];
	const stationTypeErrors = type === 'man' ? requiredFieldValidator(stationType) : [];

	return {
		type: requiredFieldValidator(type),
		id: idErrors,
		title: requiredFieldValidator(title),
		subtitle: subtitleErrors,
		abbreviation: abbreviationErrors,
		color: requiredFieldValidator(color),
		lineNumber: [],
		station: stationErrors,
		stationType: stationTypeErrors,
	};
};

/**
 * Validate PopupCreateBinderTheme form data
 * @param {BinderFormData} form form data to validate
 * @param currentBinder
 * @param binderList
 * @returns {BinderFormError}
 */
const validatePopupCreateBinderForm = (form, currentBinder, binderList) => {
	const { lineNumber } = form;
	const formError = validatePopupEditBinderForm(form, currentBinder, binderList);
	return {
		...formError,
		lineNumber: requiredFieldValidator(lineNumber),
	};
};

/**
 * Validate PopupCreateBinder form data for train
 * @param {BinderFormData} form form data to validate
 * @param currentBinder
 * @param binderList
 * @returns {BinderFormError}
 */
const validatePopupCreateBinderTrainForm = (form, currentBinder, binderList) => {
	const { id, title, subtitle, color, type, material, lineNumber } = form;

	const idErrors = [requiredFieldValidator(id), binderIdValidator(id)].flat();
	const subtitleErrors = [
		requiredFieldValidator(subtitle),
		binderSubtitleValidator(subtitle, currentBinder, binderList, true),
	].flat();
	const lineNumberErrors = [
		requiredFieldValidator(lineNumber),
		binderAssociatedLineListValidator(lineNumber),
	].flat();

	return {
		type: requiredFieldValidator(type),
		id: idErrors,
		title: requiredFieldValidator(title),
		subtitle: subtitleErrors,
		color: requiredFieldValidator(color),
		lineNumber: lineNumberErrors,
		material: requiredFieldValidator(material),
		abbreviation: [],
	};
};

/**
 * Validate PopupCopyBinder form data
 * @param {BinderFormData} form form data to validate
 * @param currentBinder
 * @param binderList
 * @returns {BinderFormError}
 */
const validatePopupCopyBinderForm = (form, currentBinder, binderList) => {
	const { id, title, color, lineNumber, abbreviation, subtitle } = form;
	const subtitleErrors = [
		binderSubtitleValidator(subtitle, currentBinder, binderList, true),
	].flat();
	const abbreviationErrors = [binderAbbreviationValidator(abbreviation)].flat();

	const idErrors = [requiredFieldValidator(id), binderIdValidator(id)].flat();

	const lineNumberErrors = [
		requiredFieldValidator(lineNumber),
		binderAssociatedLineListValidator(lineNumber),
	].flat();

	return {
		id: idErrors,
		title: requiredFieldValidator(title),
		subtitle: subtitleErrors,
		color: requiredFieldValidator(color),
		lineNumber: lineNumberErrors,
		abbreviation: abbreviationErrors,
		type: [],
	};
};

/**
 * Get error validation message i18n key for abbreviation field
 * @param abbreviationErrorList
 * @returns {string}
 */
const getBinderAbbreviationMessage = (abbreviationErrorList) => {
	if (hasFieldError('format', abbreviationErrorList))
		return 'binder:popup.form.error.abbreviation-format';
	if (hasFieldError('length', abbreviationErrorList))
		return 'binder:popup.form.error.abbreviation-length';
	return '';
};

const getFormBinderFieldMessageKey = (formError) => ({
	type: '',
	id: hasFieldError('invalid', formError?.id) ? 'binder:popup.form.error.id-format' : '',
	title: '',
	subtitle: hasFieldError('invalid', formError?.subtitle)
		? 'binder:popup.form.error.existing-subtitle'
		: '',
	abbreviation: getBinderAbbreviationMessage(formError?.abbreviation),
	color: '',
	lineNumber: '',
});

export {
	getBinderAbbreviationMessage,
	getFormBinderFieldMessageKey,
	validatePopupCopyBinderForm,
	validatePopupCreateBinderForm,
	validatePopupCreateBinderTrainForm,
	validatePopupEditBinderForm,
};
