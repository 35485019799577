import React, { useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import './custom-dropdown.scss';

import { focusInCurrentTarget } from '../../../../shared';
import { getUniqueKey } from '../../../../shared/utils/string-utils';
import { Button as BtnIcon } from '../../../../theme';
import { IconArrowDropDown } from '../../../assets/img';

/**
 * Render CustomDromdown
 * @param {object} props
 * @param {string} [props.className]
 * @param {Array} [props.options=[]]
 * @param {string} [props.defaultValue=""]
 * @param {boolean} props.renderOptionValue
 * @param {function} [props.onChange]
 * @param {string} props.label
 * @param {string} props.name
 * @param {boolean} props.disabled
 * @param {function} props.renderStaticDefaultValue
 * @param {function} props.renderActiveValue
 * @return {JSX.Element}
 * @constructor
 */
const CustomDropdown = ({
	className = '',
	options = [],
	defaultValue,
	renderOptionValue,
	onChange = () => {},
	label,
	name,
	disabled,
	renderStaticDefaultValue,
	renderActiveValue = false,
}) => {
	const customDropdownWrapperRef = useRef(null);

	const [expanded, setExpanded] = useState(false);
	const [selected, setSelected] = useState();
	const [internalValue, setInternalValue] = useState(defaultValue);
	const internalId = useMemo(uuid, []);

	const initClassName = () => {
		const modifier = { 'custom-dropdown--disabled': disabled };
		return classnames('custom-dropdown', modifier, className);
	};

	const setIconClassName = () =>
		classnames('custom-dropdown__arrow', { 'custom-dropdown__arrow--disabled': disabled });

	const handleClickButton = () => setExpanded(!expanded);

	const handleClickOption = (newValue, optionId) => () => {
		onChange({ target: { name, value: newValue } });
		setInternalValue(newValue);
		setExpanded(false);
		setSelected(optionId);
	};

	const handleDropdownBlur = (event) => {
		if (!focusInCurrentTarget(event)) {
			setExpanded(false);
		}
	};

	useEffect(() => {
		setInternalValue(defaultValue);
	}, [defaultValue]);

	const renderSelectedValue = (selectedValue) => {
		if (renderActiveValue) {
			return renderActiveValue(selectedValue);
		} else {
			renderOptionValue(selectedValue);
		}
	};

	const popupTopOffset = customDropdownWrapperRef.current
		? customDropdownWrapperRef.current.offsetHeight
		: 0;

	return (
		<div
			ref={customDropdownWrapperRef}
			className={initClassName()}
			onBlur={handleDropdownBlur}
			style={{ '--popup-top-offset': `${popupTopOffset}px` }}
		>
			<span className="custom-dropdown__label" id={`${internalId}-label`}>
				{label}
			</span>
			<div className="custom-dropdown__wrapper">
				<button
					id={`${internalId}-button`}
					className="custom-dropdown__button"
					type="button"
					onClick={handleClickButton}
					disabled={disabled}
					aria-haspopup="listbox"
					aria-expanded={expanded}
					aria-labelledby={`${internalId}-button ${internalId}-label`}
				>
					{renderStaticDefaultValue ? renderStaticDefaultValue : renderSelectedValue(internalValue)}
					<BtnIcon
						variant="minimal"
						small
						icon={IconArrowDropDown}
						className={setIconClassName()}
					/>
				</button>
				<ul
					id={`${internalId}-list`}
					className="custom-dropdown__popup"
					tabIndex={-1}
					role="listbox"
					aria-labelledby={`${internalId}-label`}
				>
					{Array.isArray(options) &&
						options.map((option, pos) => {
							const optionId = `${internalId}-item-${pos}`;
							if (defaultValue !== option) {
								return (
									<li
										id={optionId}
										className="custom-dropdown__option"
										onClick={handleClickOption(option)}
										role="option"
										key={`${getUniqueKey(option)}`}
										aria-selected={optionId === selected}
									>
										{renderOptionValue(option)}
									</li>
								);
							}
							return false;
						})}
				</ul>
			</div>
		</div>
	);
};

export default CustomDropdown;

CustomDropdown.propTypes = {
	className: PropTypes.string,
	options: PropTypes.array,
	defaultValue: PropTypes.any,
	renderOptionValue: PropTypes.func.isRequired,
	onChange: PropTypes.func,
	label: PropTypes.string,
	name: PropTypes.string,
	disabled: PropTypes.bool,
	renderStaticDefaultValue: PropTypes.node,
	renderActiveValue: PropTypes.func,
};
