import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { isBefore, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import './binder-publish-card.scss';

import { formatJsonDate } from '../../../../../../../shared';
import { Button, TextField } from '../../../../../../../theme';
import { dateFormatFull, dateFormatFullDate } from '../../../../../../process/utils';
import BinderDetailCardTemplate from '../binder-detail-card-template/binder-detail-card-template';

/**
 * PopupControl object
 * @typedef {object} PopupControl
 * @prop {boolean} visible - is popup visible
 * @prop {function} setVisible - set is popup visible
 * @prop {function} show - show popup
 * @prop {function} hide - hide popup
 */

/**
 * BinderPublishCard component
 * @param {object} props
 * @param {string} props.binderPublishedAt
 * @param {string} props.binderPublishedTempAt
 * @param {string} props.binderStatus
 * @param {string[]} props.bindebinderWarnings
 * @param {function} props.handleChange
 * @param {boolean} props.readOnly
 * @param {PopupControl} props.popupControl
 * @returns {JSX.Element}
 */
const BinderPublishCard = ({
	binderPublishedAt,
	binderPublishedTempAt,
	binderStatus,
	bindersWarnings,
	handleChange,
	readOnly,
	popupControl = {},
}) => {
	const { t } = useTranslation();

	const statusButtonDisabled = ['waiting', 'published', 'revoke', 'cancelled'];

	const generatePublishDateTitle = () => {
		if (binderPublishedAt) {
			const isPublishedPassed = isBefore(parseISO(binderPublishedAt), new Date());

			const publishDateClassName = classnames('binder-publish-card__publish-date__text', {
				'binder-publish-card__publish-date__text--inactive':
					isPublishedPassed && binderStatus !== 'waiting',
			});

			return (
				<>
					{t('binder:binder-detail.specification.date-title')} :
					<span className={publishDateClassName}>
						{' '}
						{formatJsonDate(binderPublishedAt, dateFormatFull)}
					</span>
				</>
			);
		} else {
			return t('binder:binder-detail.specification.temp-date-title');
		}
	};

	return (
		<BinderDetailCardTemplate
			title={generatePublishDateTitle()}
			binderWarnings={bindersWarnings}
			className="binder-publish-card"
		>
			<TextField
				className="binder-publish-card__publish-date__field"
				name="published_temp_at"
				label={
					binderPublishedAt ? '' : t('binder:binder-detail.specification.date-content-placeholder')
				}
				value={formatJsonDate(binderPublishedAt, dateFormatFullDate) || binderPublishedTempAt || ''}
				disabled={readOnly || !!binderPublishedAt}
				onChange={handleChange}
			/>
			<Button
				variant="secondary"
				className="binder-publish-card__start-analysis"
				disabled={statusButtonDisabled.includes(binderStatus)}
				onClick={popupControl.show}
				label={t('binder:binder-detail.specification.date-content-button')}
			/>
		</BinderDetailCardTemplate>
	);
};

export default BinderPublishCard;

BinderPublishCard.propTypes = {
	binderPublishedAt: PropTypes.string,
	binderPublishedTempAt: PropTypes.string,
	binderStatus: PropTypes.string,
	bindersWarnings: PropTypes.arrayOf(PropTypes.string),
	handleChange: PropTypes.func,
	readOnly: PropTypes.bool,
	popupControl: PropTypes.object,
};
