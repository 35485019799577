import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './binder-signal-choice.scss';

import { checkFormError, requiredFieldValidator } from '../../../../../../shared';
import { useSprContext } from '../../../../../../shared/context/spr-context/spr-context';
import { Button, IconWrapper } from '../../../../../../theme';
import { ReactComponent as IconArrowRight } from '../../../../../../theme/assets/img/icon-arrow-right.svg';
import { useRunProcessContext } from '../../../../../process/components/context-run-process/context-run-process';
import { fetchSheetByBinder } from '../../../../../sheet/sheet.services';
import { listAllBinderSignal } from '../../../../binder-signal.services';
import SignalDestinationSelector from '../signal-destination-selector/signal-destination-selector';
import SignalTitleSelector from '../signal-title-selector/signal-title-selector';

/**
 * Render the Signal choice form for a binder a type 'man'
 * @param {object} props
 * @param {object} props.binder
 * @param {function} props.onConfirm
 * @returns {JSX.Element}
 * @constructor
 */
const BinderSignalChoice = (props) => {
	const { binder, onConfirm } = props;
	const [form, setForm] = useState({});
	const [signalList, setSignalList] = useState([]);
	const { processTechId, handleNextSheetData, processInfo } = useRunProcessContext();
	const { associatedLine } = useSprContext();
	const { t } = useTranslation();

	const validateForm = (formData) => ({
		tech_id: requiredFieldValidator(formData?.tech_id),
		origin: requiredFieldValidator(formData?.origin),
		destination: requiredFieldValidator(formData?.destination),
		title: requiredFieldValidator(formData?.title),
	});

	const buttonText = processTechId
		? t('binder:spr-binder-detail.signal-choice.continue')
		: t('binder:spr-binder-detail.signal-choice.validate');

	const formError = useMemo(() => validateForm(form), [form]);

	const retrieveSignalList = () => {
		if (binder?.tech_id) {
			listAllBinderSignal(binder.tech_id).then((response) => setSignalList(response.data));
		}
	};
	// Get signal title list without duplicate
	const signalTitleList = useMemo(
		() =>
			signalList.reduce((result, signal) => {
				if (result.length === 0) {
					return [{ title: signal.title, tech_id: signal.tech_id }];
				}

				// Insert Signal only if title not exists
				if (!result.some((signalInfo) => signalInfo.title === signal.title)) {
					result.push({ title: signal.title, tech_id: signal.tech_id });
				}

				return result;
			}, []),
		[signalList]
	);

	const destinationList = useMemo(
		() => signalList.filter((signal) => signal.title === form.title),
		[signalList, form.title]
	);

	const handleContinueProcess = async () => {
		const type = ['parent', 'determination', 'child'];
		const params = { type, signalTechId: form.tech_id, line: associatedLine };
		const { data = [] } = await fetchSheetByBinder(binder?.tech_id, params);
		const [firstSheet] = data;
		handleNextSheetData(firstSheet, processInfo.binder_id, binder?.tech_id, form.tech_id);
	};

	const handleSubmit = () => {
		if (!checkFormError(formError)) {
			processTechId ? handleContinueProcess() : onConfirm(form);
		}
	};
	const handleChange = (value) => {
		setForm(value);
	};
	useEffect(retrieveSignalList, [binder?.tech_id]);
	return (
		<div className="binder-signal-choice">
			<div className="binder-signal-choice__titles">
				<p className="binder-signal-choice__heading">
					{t('binder:spr-binder-detail.signal-choice.title.head')}
				</p>
				<p className="binder-signal-choice__text">
					{t('binder:spr-binder-detail.signal-choice.title.text')}
				</p>
				<SignalTitleSelector
					name="title"
					className="binder-signal-choice__titles__list"
					signalTitleList={signalTitleList}
					value={form}
					onChange={handleChange}
				/>
			</div>
			<IconWrapper className="binder-signal-choice__arrow" Component={IconArrowRight} />
			<div className="binder-signal-choice__destinations">
				<p className="binder-signal-choice__heading">
					{t('binder:spr-binder-detail.signal-choice.destination.head')}
				</p>
				<p className="binder-signal-choice__text">
					{t('binder:spr-binder-detail.signal-choice.destination.text')}
				</p>
				<div className="destinations__wrapper">
					{form?.title && (
						<span className="destinations__selected-title">{`${form.title} sélectionné`}</span>
					)}
					<SignalDestinationSelector
						name="signal"
						className="destinations__list"
						destinationList={destinationList}
						value={form}
						onChange={handleChange}
					/>
				</div>
				<Button
					variant="primary"
					className="binder-signal-choice__submit"
					onClick={handleSubmit}
					disabled={checkFormError(formError)}
					label={buttonText}
				/>
			</div>
		</div>
	);
};

export default BinderSignalChoice;

BinderSignalChoice.propTypes = {
	binder: PropTypes.shape({
		id: PropTypes.number,
		tech_id: PropTypes.string,
	}).isRequired,
	onConfirm: PropTypes.func.isRequired,
};
