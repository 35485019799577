import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './history-step-task-view.scss';

import { formatJsonDate } from '../../../../../../shared';
import { dateFormatTime } from '../../../../utils';

/**
 * history step template component
 * @param {object} props
 * @param {object} props.task
 * @returns {JSX.Element}
 */
const HistoryStepTaskView = ({ task = {} }) => {
	const { finishedAt, content } = task;
	const { t } = useTranslation();

	return (
		<div className="history-step-task-view">
			<span className="content__step">
				{`${t('sheet:sheet-details.template.step.name')} ${content?.step}`}
			</span>
			<span className="content__text">{formatJsonDate(finishedAt, dateFormatTime)}</span>
		</div>
	);
};

export default HistoryStepTaskView;

HistoryStepTaskView.propTypes = {
	task: PropTypes.shape({
		content: PropTypes.shape({
			step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
		finishedAt: PropTypes.string,
	}),
};
