import qs from 'qs';

import { http } from '../../config';

const imageRootUrl = '/image';

const uploadImage = async (image) => {
	const bodyFormData = new FormData();
	bodyFormData.append('image', image);
	return http.post(imageRootUrl, bodyFormData, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
};

const deleteImage = async (imageId) => http.delete(`${imageRootUrl}/${imageId}`);

const getImageUrl = (imageId, tag = 'original') =>
	http.get(`${imageRootUrl}/${imageId}${qs.stringify({ tag }, { addQueryPrefix: true })}`, {
		responseType: 'blob',
	});

export { deleteImage, getImageUrl, uploadImage };
