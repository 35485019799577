import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './multi-link-category.scss';

import { IconWrapper, TextField } from '../../../../../../../../../../theme';
import { ReactComponent as IconTrash } from '../../../../../../../../../../theme/assets/img/icon-trash.svg';

/**
 * Render the MultiLinkCategory component
 * @param {object} props
 * @param {function} props.onChange
 * @param {string} props.category
 * @returns {JSX.Element}
 */
const MultiLinkCategory = ({ onChange, category }) => {
	const { t } = useTranslation();
	const [displayForm, setDisplayForm] = useState(false);

	const handleDeleteCategory = () => {
		setDisplayForm(false);
		onChange({ target: { name: 'category', value: undefined } }, true);
	};

	if (!displayForm && !category) {
		return (
			<button
				className="multi-link-category--link"
				type="button"
				onClick={() => setDisplayForm(true)}
			>
				{t('sheet:sheet-details.template.multipleLink.add-category')}
			</button>
		);
	}

	return (
		<div className="multi-link-category">
			<label className="multi-link-category__label">
				{t('sheet:sheet-details.template.multipleLink.label-category')}
			</label>
			<div className="multi-link-category__wrapper">
				<TextField
					className="multi-link-category__input"
					name="category"
					value={category}
					onChange={onChange}
					handleBlur={() => onChange({ target: { name: 'category', value: category } }, true)}
					placeholder={t('sheet:sheet-details.template.multipleLink.placeholder-category')}
				/>
				<button
					className="multi-link-category__delete"
					type="button"
					onClick={handleDeleteCategory}
				>
					<IconWrapper
						className="multi-link-category__icon"
						Component={IconTrash}
						tooltip={t('sheet:sheet-details.tooltip.delete')}
					/>
				</button>
			</div>
		</div>
	);
};

export default MultiLinkCategory;

MultiLinkCategory.propTypes = {
	onChange: PropTypes.func.isRequired,
	category: PropTypes.string.isRequired,
};
