import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import './text-template-view.scss';

import { useDndSheetReference } from '../../../../../../../../shared';
import { IconWrapper } from '../../../../../../../../theme';
import { ReactComponent as IconCheckboxEmpty } from '../../../../../../../../theme/assets/img/checkbox-unchecked.svg';
import { ReactComponent as IconCall } from '../../../../../../../../theme/assets/img/icon-call.svg';
import EditorRendering from '../../../../../../../../theme/components/editor-rendering/editor-rendering';
import ImageListView from '../../../../../../../../theme/components/image-list-view/image-list-view';
import TemplateWrapper from '../../template-wrapper/template-wrapper';
import AppendixListView from '../appendix-list-view/appendix-list-view';

import getActionBullet from './utils/get-action-bullet';

const TextTemplateView = ({
	template,
	currentPosition,
	icon,
	allowInsertAfter,
	moveCard,
	processCurrentTemplate,
	bulletPosition,
	scrollToThisAction,
	location,
}) => {
	const text = template?.content?.text;
	const images = template?.content?.images || [];
	const appendixes = template?.content?.appendixes || [];

	// Dnd enabling
	const ref = useRef(null);

	if (scrollToThisAction) {
		// scroll to an action if it is pointed to from a link
		ref?.current?.scrollIntoView();
	}

	const { drag, isDragging } = useDndSheetReference(template, moveCard, ref, location?.parentId);

	const icons = {
		call: IconCall,
		action: IconCheckboxEmpty,
	};

	const renderIcon = (iconName) => {
		if (icons[iconName]) {
			return <IconWrapper className="text-template-view__icon" Component={icons[iconName]} />;
		}
		return null;
	};

	const renderImages = () => {
		const displayImagesTypes = ['note', 'action', 'systemEffect'];

		if (displayImagesTypes.includes(template?.type) && images.length > 0) {
			return <ImageListView className="text-template-view__images" images={images} />;
		}
		return null;
	};

	const renderAppendixes = () => {
		let displayAppendixTypes = ['note', 'action', 'call'];

		if (appendixes.length > 0 && displayAppendixTypes.includes(template?.type)) {
			return (
				<AppendixListView className="text-template-view__appendixes" appendixes={appendixes} />
			);
		}
		return null;
	};

	return (
		<TemplateWrapper
			id={template.id}
			className={`text-template-view text-template-view--${template?.type}`}
			reference={ref}
			isDragging={isDragging}
			dragRef={drag}
			template={template}
			location={location}
			currentPosition={currentPosition}
			allowInsertAfter={allowInsertAfter}
			processCurrentTemplate={processCurrentTemplate}
		>
			<div className="text-template-view__content">
				{renderIcon(icon)}
				{Number.isInteger(bulletPosition) && (
					<span className="text-template-view__bullet">
						{getActionBullet(location.parentId, bulletPosition, '.')}
					</span>
				)}
				<EditorRendering className="text-template-view__text" content={text} />
			</div>
			{renderImages()}
			{renderAppendixes()}
		</TemplateWrapper>
	);
};

export default TextTemplateView;

TextTemplateView.propTypes = {
	template: PropTypes.object,
	processCurrentTemplate: PropTypes.func,
	bulletPosition: PropTypes.number,
	scrollToThisAction: PropTypes.bool,
	location: PropTypes.shape({
		parentId: PropTypes.string,
	}),
	currentPosition: PropTypes.number,
	icon: PropTypes.string,
	allowInsertAfter: PropTypes.bool,
	moveCard: PropTypes.any,
};
