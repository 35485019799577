import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classname from 'classnames';
import PropTypes from 'prop-types';

import { Dropdown } from '../../../../theme';

/**
 * Render the dropdown components to select a station type
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.value
 * @param {string} [props.defaultValue=""]
 * @param {string} props.name
 * @param {string} props.label
 * @param {string} props.placeholder
 * @param {function} props.onChange
 * @param {boolean} props.disabled
 * @returns {JSX.Element}
 */
const StationTypeDropdown = (props) => {
	const {
		className,
		value,
		defaultValue = '',
		name,
		label,
		placeholder,
		onChange,
		disabled,
	} = props;

	const { t } = useTranslation();

	const renderOption = () => {
		const stationTypeList = ['communication', 'connection', 'avoidance'];
		return stationTypeList.map((stationType) => (
			<option key={stationType} value={stationType}>
				{t(`binder:station-type.${stationType}`)}
			</option>
		));
	};

	useEffect(() => {
		// Set sheet as default value if no value provided
		if (!value) {
			onChange({ target: { name, value: defaultValue } });
		}
		// ignore onChange dependency to avoid infinite render
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name, value, defaultValue]);

	return (
		<Dropdown
			className={classname('station-type-dropdown', className)}
			value={value}
			name={name}
			onChange={onChange}
			label={label}
			placeholder={placeholder}
			disabled={disabled}
		>
			{renderOption()}
		</Dropdown>
	);
};

StationTypeDropdown.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string,
	defaultValue: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

export default StationTypeDropdown;
