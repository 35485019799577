import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import './popup-cache-line-info.scss';

import { formatJsonDate, useSprContext } from '../../../../shared';
import { getEntity } from '../../../../shared/cache-access/entity/entity-utils';
import { Button, IconWrapper, PopupDefault } from '../../../../theme';
import { IconDownload } from '../../../../theme/assets/img';
import { dateFormatDate } from '../../../process/utils';
import { iconLines } from '../../../user/utils/associated-line-icon-utils/associated-line-icon.utils';

/**
 * Render The CacheLineInfo popup
 * @param {object} props
 * @return {JSX.Element}
 * @constructor
 */
const PopupCacheLineInfo = ({ popupControl }) => {
	const [lineInfo, setLineInfo] = useState({});
	const { associatedLine } = useSprContext();
	const { t } = useTranslation();

	const history = useHistory();
	const location = useLocation();
	const hasCache = lineInfo.lastSyncDate;

	const getLineInfo = () => {
		if (popupControl.visible) {
			getEntity('status', associatedLine)
				.then((statusCacheEntry) => {
					setLineInfo(statusCacheEntry.data);
				})
				.catch(console.error);
		}
	};

	const removeSearchParam = () => history.replace(location.pathname);

	const handleValidate = () => {
		popupControl.hide();
		removeSearchParam();
	};

	useEffect(getLineInfo, [associatedLine, popupControl.visible]);

	const mainText = t(
		hasCache ? 'train:popup.cache-line-info.text' : 'train:popup.cache-line-info.text-cache-missing'
	);
	const popupTitle = t(
		hasCache
			? 'train:popup.cache-line-info.title'
			: 'train:popup.cache-line-info.title-cache-missing'
	);

	return (
		<PopupDefault
			className="popup-cache-line-info__wrapper"
			title={popupTitle}
			onClose={removeSearchParam}
			popupControl={popupControl}
		>
			<div className="popup-cache-line-info">
				<h1 className="popup-cache-line-info__title">{t('train:popup.cache-line-info.warning')}</h1>
				<p className="popup-cache-line-info__text">{mainText}</p>
				{hasCache && (
					<div className="popup-cache-line-info__status">
						<IconWrapper
							className="popup-cache-line-info__icon popup-cache-line-info__icon--line"
							Component={iconLines[associatedLine]}
						/>
						<IconWrapper
							className="popup-cache-line-info__icon popup-cache-line-info__icon--download"
							Component={IconDownload}
						/>
						<p className="popup-cache-line-info__status__text">
							{t('train:popup.cache-line-info.date', {
								date: formatJsonDate(lineInfo.lastSyncDate, dateFormatDate),
							})}
						</p>
					</div>
				)}
				<p className="popup-cache-line-info__text-light">{t('train:popup.cache-line-info.note')}</p>
				<Button
					className="popup-cache-line-info__validate"
					onClick={handleValidate}
					label={t('train:popup.cache-line-info.validate')}
				/>
			</div>
		</PopupDefault>
	);
};

export default PopupCacheLineInfo;

PopupCacheLineInfo.propTypes = {
	popupControl: PropTypes.shape({
		hide: PropTypes.func,
		show: PropTypes.func,
		visible: PropTypes.bool,
	}),
};
