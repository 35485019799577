import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import TrainBinder from '../train-binder/train-binder';

/**
 * TrainListRow component
 * @param {object} props
 * @param {import("../../../../../../train.services").Train} props.train
 * @param {string} props.associatedLine
 * @param {import("../../../../../../train.services").Train[]} props.trainList
 * @param {function} props.searchTrains
 * @returns {JSX.Element}
 */
const TrainListRow = ({ train, children: actions }) => {
	const { t } = useTranslation();
	const {
		id,
		tech_id,
		material_label,
		brake_system,
		car_number,
		binder_auto,
		binder_driver,
		binder_officer,
		brake_isolation,
		engine_system,
	} = train;

	const cells = [
		{ label: id, className: 'id' },
		{ label: material_label, className: 'material' },
		{ label: car_number, className: 'carts' },
		{
			label: t(`train:train-list.list.${engine_system ? 'active' : 'inactive'}`),
			className: 'engine-system',
		},
		{ label: t(`train:train-list.list.${brake_system}`), className: 'brake' },
		{
			label: t(`train:train-list.list.${brake_isolation ? 'active' : 'inactive'}`),
			className: 'brake-isolation',
		},
	];

	return (
		<li className="list__item" key={tech_id || id}>
			<div className="list__item__cells">
				{cells.map(({ label, className }) => (
					<span key={className} className={`item__cell item__cell--${className}`}>
						{label}
					</span>
				))}
				<span className="item__cell item__cell--binders">
					{binder_auto && <TrainBinder label={binder_auto} type={'auto'} />}
					{binder_driver && <TrainBinder label={binder_driver} type={'driver'} />}
					{binder_officer && <TrainBinder label={binder_officer} type={'officer'} />}
				</span>
			</div>
			<span className="item__cell item__cell--cta">{actions}</span>
		</li>
	);
};

export default TrainListRow;

TrainListRow.propTypes = {
	train: PropTypes.shape({
		id: PropTypes.string,
		tech_id: PropTypes.string,
		material_tech_id: PropTypes.string,
		material_label: PropTypes.string,
		brake_system: PropTypes.string,
		car_number: PropTypes.number,
		binder_auto: PropTypes.string,
		binder_driver: PropTypes.string,
		binder_officer: PropTypes.string,
		brake_isolation: PropTypes.bool,
		engine_system: PropTypes.bool,
	}).isRequired,
	children: PropTypes.node,
};
