import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './image-form-file-input.scss';

import { IconWrapper } from '../../../../../../../../theme';
import { ReactComponent as IconCross } from '../../../../../../../../theme/assets/img/close.svg';
import { SheetImageLoader } from '../../../../../../';

const ImageFormFileInput = ({ className, value, imageId, onChange, onDelete, errorHelperText }) => {
	const wrapperClassName = classNames('image-form-file-input', className);
	const { t } = useTranslation();

	const handleFileClick = (event) => {
		if (value) {
			event.preventDefault();
		}
	};

	if (imageId) {
		return (
			<div className={wrapperClassName}>
				<span className="image-form-file-input__preview">
					<SheetImageLoader
						className="image-form-file-input__thumbnail"
						imageId={imageId}
						tag="thumbnail"
					/>
				</span>
				<IconWrapper
					className="image-form-file-input__delete"
					onClick={onDelete}
					Component={IconCross}
				/>
			</div>
		);
	}

	return (
		<>
			<label className={wrapperClassName}>
				<input
					className="image-form-file-input__input"
					type="file"
					onChange={onChange}
					onClick={handleFileClick}
				/>
				<span className="image-form-file-input__placeholder">
					{t('sheet:sheet-details.image-form.file-input-placeholder')}
				</span>
			</label>
			{errorHelperText && <p className="image-form-file-input__error">{errorHelperText}</p>}
		</>
	);
};

export default ImageFormFileInput;

ImageFormFileInput.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string,
	imageId: PropTypes.string,
	onChange: PropTypes.func,
	onDelete: PropTypes.func,
	errorHelperText: PropTypes.string,
};
