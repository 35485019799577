import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './tabs-nav.scss';

const TabsNav = ({ children, className }) => {
	return <div className={classnames('tabs-nav', className)}>{children}</div>;
};

export default TabsNav;

TabsNav.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};
