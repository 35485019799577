import {
	getBinderForSync,
	getImagesRefForSync,
	getSheetForSync,
	getTrainForSync,
} from './api-service';
import { getApiItemRef } from './api-utils';

/**
 * Fetch all entity needed to evaluate sync content (no image file)
 * @param {string} line Current line
 * @return {{sheet: CacheEntity[], binder: CacheEntity[], train: CacheEntity[], image: { ref: string, sheetRef: string }[]}}
 */
const fetchAllEntityFromAPI = async (line) => {
	const train = await getTrainForSync(line);
	const binder = await getBinderForSync(line);

	// Get sheet for all binder
	const binderIds = binder?.map((binderData) => binderData.data.tech_id);
	const sheetList = await getSheetForSync(binderIds);

	// Get image refs for all sheets
	const sheetIds = sheetList.map((sheet) => getApiItemRef('sheet', sheet));
	const image = await getImagesRefForSync(sheetIds);

	return { binder, sheet: sheetList, train, image };
};

export default fetchAllEntityFromAPI;
