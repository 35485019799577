import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './back-to-current-step.scss';

import { ReactComponent as IconArrowLeft } from '../../../domains/process/assets/icon-arrow-left.svg';
import { Button } from '../../../theme';
import { buildSprProcessUri } from '../../index';

const BackToCurrentStep = (props) => {
	const { className, processId } = props;
	const location = useLocation();
	const { t } = useTranslation();

	const prefixLink = location.pathname.startsWith('/spr') ? 'spr' : 'railway';

	return (
		<Link
			to={buildSprProcessUri({ processId }, prefixLink)}
			className={classNames('back-to-current-step', className)}
		>
			<Button variant="primary" small icon={IconArrowLeft} />
			<span className="back-to-current-step__text">
				{t('process:history-view.back-to-current-step')}
			</span>
		</Link>
	);
};

export default BackToCurrentStep;

BackToCurrentStep.propTypes = {
	className: PropTypes.string,
	processId: PropTypes.string.isRequired,
};
