import React from 'react';

import './return-summary-template-form.scss';

import { IconWrapper } from '../../../../../../../../theme';
import { IconArrowRight } from '../../../../../../../../theme/assets/img';

const ReturnSummaryTemplateForm = () => {
	return (
		<div className="return-summary-template__content">
			<IconWrapper className="return-summary-template__icon" Component={IconArrowRight} />
			<span className="return-summary-template__text">Sommaire</span>
		</div>
	);
};

export default ReturnSummaryTemplateForm;
