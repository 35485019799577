import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './process-train-subheader.scss';

import { buildSprProcessUri, buildUserName, usePopup } from '../../../../shared';
import { buildTrainProcess } from '../../../../shared/utils/build-process-train-uri';
import { processHistoryToSheetLinks } from '../../../../shared/utils/sheet-read-utils';
import { Button, IconWrapper } from '../../../../theme';
import { IconEye, IconTrain } from '../../../../theme/assets/img';
import { resolveSheetColor, SheetType } from '../../../sheet';
import { IconSheetPdf, IconStep } from '../../../sheet/assets';
import LinkPathDisplay from '../../../sheet/pages/railway-sheet-view/components/linkpath-display/linkpath-display';
import { useSheetReadContext } from '../../../sheet/pages/railway-sheet-view/components/sheet-read-context/sheet-read-context';
import { fetchSheetById } from '../../../sheet/sheet.services';
import ProcessActionButton from '../../pages/spr-run-process/components/action-button/process-action-button';
import PopupEditProcessTrain from '../pop-up-edit-process-train/pop-up-edit-process-train';

/**
 * Process train subheader for direct live and misc infos
 * @param {{[hideButton]: 'step'|'sheet'|'direct'}} props
 * @returns {JSX.Element}
 */
const ProcessTrainSubheader = ({ hideButton }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const {
		addSheetProcessLinks = () => {},
		currentProcess: process,
		currentTrain: train,
	} = useSheetReadContext();
	const {
		owner_firstname: firstName,
		owner_lastname: lastName,
		owner_id: userId,
		started_at,
		status,
		line,
	} = process || {};

	const isProcessStarted = !!started_at;
	const isProcessFinished = status === 'closed';

	// without the train, train-id is left out
	const trainLabel = train?.material_label
		? `${train?.material_label} - ${train?.id}`
		: process?.material_label;
	const userLabel = userId
		? `${t('process:process-train-subheader.in-progress')} ${buildUserName(firstName, lastName, userId)}`
		: t('user:user-deleted');

	const {
		binder_id: binderId = '',
		binder_tech_id: binderTechId,
		tech_id: processTechId,
		sheet_tech_id,
	} = process;
	const lastSheetTechId = process?.history?.[process?.history.length - 1]?.sheet?.techId || '';

	const [currentSheet, setCurrentSheet] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const popupEditProcessTrainControl = usePopup();

	const displayPopupEditTrain = () => popupEditProcessTrainControl.show();

	/**
	 * create sheet links based on the process history
	 * @param {object[]} processHistory
	 */
	const loadSheetLinks = (newProcess) => {
		if (newProcess?.history && Array.isArray(newProcess.history)) {
			const sheetLinks = processHistoryToSheetLinks(newProcess);
			addSheetProcessLinks(sheetLinks);
		}
	};

	// create sheet links based upon process history
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => loadSheetLinks(process), [process]);

	useEffect(() => {
		if (sheet_tech_id) {
			setIsLoading(true);

			fetchSheetById(binderTechId, sheet_tech_id)
				.then((response) => {
					setCurrentSheet(response.data);
				})
				.finally(() => setIsLoading(false));
		}
	}, [sheet_tech_id, binderTechId]);

	return (
		<div
			className={classNames('process-train-subheader', {
				'process-train-subheader--started': isProcessStarted,
			})}
		>
			{!isLoading && (
				<>
					<div className="process-train-subheader__title">
						<IconWrapper className="process-train-subheader__title__icon" Component={IconTrain} />
						{!isProcessStarted && (
							<span>{t('process:process-train-subheader.process-not-owned')}</span>
						)}
					</div>
					{!isProcessStarted && (
						<div className="process-train-subheader__unstarted-process__container">
							<SheetType
								className="list__cell list__cell--number"
								type={currentSheet?.type}
								binderType={currentSheet?.binder_type}
								sheetNumber={currentSheet?.number}
								color={resolveSheetColor(currentSheet?.color, currentSheet?.binder_color)}
							/>
							<span className="process-train-subheader__unstarted-process__txt">
								{t('sheet:unstarted-process.title-initialize')}
							</span>

							<Button
								variant="dark"
								onClick={displayPopupEditTrain}
								label={t('sheet:unstarted-process.cta-modify-sheet')}
							/>
						</div>
					)}
					{popupEditProcessTrainControl.show && (
						<PopupEditProcessTrain
							popupControl={popupEditProcessTrainControl}
							line={line}
							process={process}
						/>
					)}
					{isProcessStarted && (
						<>
							<div className="process-train-subheader__status">
								<span className="process-train-subheader__user">{`${binderId} - ${trainLabel} - ${userLabel}`}</span>
								<LinkPathDisplay isProcessLinkDisplay />
							</div>
							<div className="process-train-subheader__actions">
								{!isProcessFinished && (
									<>
										{hideButton !== 'step' && (
											<ProcessActionButton
												handleClick={() =>
													history.push(buildTrainProcess({ processId: processTechId }, 'spr', true))
												}
												label={t('sheet:run-process.cta-step')}
												Icon={IconStep}
											/>
										)}
										{hideButton !== 'sheet' && (
											<ProcessActionButton
												handleClick={() =>
													history.push(
														`/spr/read/binders/${binderId}/sheet/${lastSheetTechId}/process/${processTechId}`
													)
												}
												label={t('sheet:run-process.cta-sheet')}
												Icon={IconSheetPdf}
											/>
										)}
										{hideButton !== 'direct' && (
											<ProcessActionButton
												handleClick={() =>
													history.push(
														buildSprProcessUri({ processId: processTechId }, 'spr', true)
													)
												}
												label={t('sheet:run-process.cta-direct')}
												Icon={IconEye}
											/>
										)}
									</>
								)}
								{isProcessFinished && (
									<Button
										className="process-train-subheader__report"
										onClick={() => history.push(`/spr/process/${processTechId}`)}
										label={t('sheet:run-process.cta-show-report')}
									/>
								)}
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
};

export default ProcessTrainSubheader;

ProcessTrainSubheader.propTypes = {
	hideButton: PropTypes.oneOf(['step', 'sheet', 'direct']),
};
