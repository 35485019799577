import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './binder-zone-link-card.scss';

import { Button } from '../../../../../../../theme';
import BinderDetailCardTemplate from '../binder-detail-card-template/binder-detail-card-template';

/**
 * BinderZoneLinkCard component
 * @param {object} props
 * @param {string} props.binderZoneLink
 * @param {popupControl} props.popupControl
 * @returns {JSX.Element}
 */
const BinderZoneLinkCard = ({ popupControl = {}, binderZoneLink }) => {
	const { t } = useTranslation();

	return (
		<BinderDetailCardTemplate
			title={t('binder:binder-detail.specification.zone-title')}
			className="binder-zone-link-card"
		>
			<div>
				<span>{t('binder:binder-detail.specification.zone-subtitle')}</span>
				{binderZoneLink && (
					<a
						className="binder-zone-link-card__link"
						href={binderZoneLink}
						target="_blank"
						rel="noreferrer noopener nofollow"
					>
						{binderZoneLink}
					</a>
				)}
			</div>
			<Button
				variant="secondary"
				className="binder-zone-link-card__edit"
				onClick={popupControl.show}
				label={t(
					`binder:binder-detail.specification.${binderZoneLink ? 'zone-change-button' : 'zone-add-button'}`
				)}
			/>
		</BinderDetailCardTemplate>
	);
};

export default BinderZoneLinkCard;

BinderZoneLinkCard.propTypes = {
	binderZoneLink: PropTypes.string,
	popupControl: PropTypes.object,
};
