import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import './spr-binder-sheet-list.scss';

import {
	buildSprProcessSheetUri,
	buildSprProcessUri,
	createSheetLink,
	setStoredSheetLinks,
} from '../../../../../../shared';
import { EditorRendering } from '../../../../../../theme';
import { resolveSheetColor, SheetType } from '../../../../../sheet';
import { useSheetReadContext } from '../../../../../sheet/pages/railway-sheet-view/components/sheet-read-context/sheet-read-context';

/**
 * Spr Binder detail sheet list props
 * @typedef BinderSheetListProps
 * @prop {object} binder
 * @prop {object[]} sheetList
 * @prop {boolean} [redirectTypeDetermination = false]
 * @prop {boolean} [isProcessRedirect = true]
 */

/**
 * binder sheet list component
 * @param {BinderSheetListProps} props
 * @returns {JSX.Element}
 */
const SprBinderSheetListInner = ({
	sheetList = [],
	binder = {},
	redirectTypeDetermination = false,
	isProcessRedirect = true,
}) => {
	const { t } = useTranslation();
	const { id: binderId, processId } = useParams();
	const { addSheetLink = () => {} } = useSheetReadContext();
	const history = useHistory();

	const isThereDeterminationSheet = sheetList.some((sheet) => sheet.type === 'determination');
	const buildUri = isProcessRedirect
		? (info) => buildSprProcessUri(info)
		: ({ sheetNumber, binderTechnicalId }) =>
				buildSprProcessSheetUri(processId, binderTechnicalId, sheetNumber);
	const handleSheetClick = isProcessRedirect
		? () => {}
		: (sheetLink) => {
				setStoredSheetLinks();
				addSheetLink(sheetLink);
			};

	const renderSheet = (sheet) => {
		const { number, sheet_id } = sheet;
		const info = {
			binderTechnicalId: sheet?.binder_tech_id,
			sheetNumber: number,
		};

		const uri = buildUri(info);
		const sheetLink = createSheetLink(
			uri,
			sheet_id,
			number,
			sheet?.binder_tech_id,
			sheet?.color,
			null,
			sheet?.type,
			sheet?.title
		);

		return (
			<Link key={sheet_id || number} to={uri} onClick={() => handleSheetClick(sheetLink)}>
				<li className="list__row">
					<SheetType
						className="list__cell list__cell--number"
						type={sheet.type}
						binderType={binder?.type}
						sheetNumber={number}
						color={resolveSheetColor(sheet.color, binder.color)}
					/>
					<EditorRendering content={sheet.title} className="list__cell" />
				</li>
			</Link>
		);
	};

	if (redirectTypeDetermination && isThereDeterminationSheet) {
		const firstDeterminationSheet = sheetList.find((sheet) => sheet.type === 'determination');
		const urlFirstDeterSheet = buildUri({
			binderTechnicalId: binderId,
			sheetNumber: firstDeterminationSheet?.number,
		});
		history.replace(urlFirstDeterSheet);
	}

	return (
		<div className="spr-binder-sheet-list">
			<ul className="binder-sheet-list__list">
				<li className="list__head-row">
					<span className="list__head-cell list__head-cell--number">
						{t('binder:spr-binder-detail.sheet-list.head.number')}
					</span>
					<span className="list__head-cell">
						{t('binder:spr-binder-detail.sheet-list.head.title')}
					</span>
				</li>
				{sheetList.map(renderSheet)}
			</ul>
		</div>
	);
};

export default SprBinderSheetListInner;

SprBinderSheetListInner.propTypes = {
	binder: PropTypes.shape({
		tech_id: PropTypes.string,
		color: PropTypes.string,
	}),
	sheetList: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			number: PropTypes.string,
			color: PropTypes.string,
		})
	),
	redirectTypeDetermination: PropTypes.bool,
	isProcessRedirect: PropTypes.bool,
};
