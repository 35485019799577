import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import './sheet-details-subheader-options.scss';

import { Button, TabsNav, TabsNavItem } from '../../../../theme';
import { SheetContentContext } from '../../context/sheet-content-context';
import SheetEditionAction from '../../pages/sheet-detail/components/sheet-edition-action/sheet-edition-action';
import { generateSheetPdf } from '../../sheet.services';
import { useSheetReload } from '../../utils/use-sheet-reload';

/**
 * sheet detail subheader component
 * @param {object} props
 * @param {{activeTab: number}} props.tabsItemConfig
 * @param {boolean} props.isBinderStatusWIP
 * @returns {JSX.Element}
 */
const SheetDetailsSubheaderOptions = ({ tabsItemConfig, isBinderStatusWIP }) => {
	const { sheetData } = useContext(SheetContentContext);
	const { refreshSheetData } = useSheetReload();
	const { binderId } = useParams();
	const { t } = useTranslation();
	const history = useHistory();
	const [loading, setLoading] = useState(false);

	const renderSheetModified = () => {
		if (sheetData.binder_iteration_date > sheetData.last_change_content_at) {
			return (
				<span className="sheet-edition__information__not-modified">
					{t('sheet:sheet-details.tabs-panel.information.not-modified')}
				</span>
			);
		}
	};

	const handleSheetPdfGenerationClick = () => {
		setLoading(true);
		generateSheetPdf(sheetData?.sheet_id)
			.then((response) => {
				history.push(`/document/${response?.data?.tech_id}/sheet`);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	return (
		<div className="sheet-details-subheader-options">
			<TabsNav>
				<TabsNavItem id={0} {...tabsItemConfig}>
					{t('sheet:sheet-details.tabs-label.read')}
				</TabsNavItem>
				<TabsNavItem id={1} {...tabsItemConfig} disabled={!isBinderStatusWIP}>
					{t('sheet:sheet-details.tabs-label.edition')}
				</TabsNavItem>
				<TabsNavItem id={2} {...tabsItemConfig}>
					{t('sheet:sheet-details.tabs-label.comments')}
				</TabsNavItem>
				<TabsNavItem id={3} {...tabsItemConfig}>
					{t('sheet:sheet-details.tabs-label.line-view')}
				</TabsNavItem>
			</TabsNav>
			<div className="sheet-edition-container__actions">
				{tabsItemConfig.activeTab !== 2 && (
					<SheetEditionAction
						refresh={refreshSheetData}
						lastChangeAt={sheetData?.last_change_at}
						binderId={binderId}
					/>
				)}
				{tabsItemConfig.activeTab === 2 && (
					<div className="sheet-edition__information">{renderSheetModified()}</div>
				)}

				<Button
					variant="primary"
					onClick={handleSheetPdfGenerationClick}
					className="sheet-detail-page__pdf"
					disabled={!sheetData?.sheet_id}
					label={t('sheet:sheet-details.tabs-panel.actions.create-pdf')}
					loading={loading}
				></Button>
			</div>
		</div>
	);
};

export default SheetDetailsSubheaderOptions;

SheetDetailsSubheaderOptions.propTypes = {
	tabsItemConfig: PropTypes.object.isRequired,
	isBinderStatusWIP: PropTypes.bool,
};
