import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './history-simple-link-task-view.scss';

import { IconWrapper } from '../../../../../../theme';
import { ReactComponent as IconArrowLongRight } from '../../../../../../theme/assets/img/icon-arrow-long-right.svg';
import { ReactComponent as IconArrowRight } from '../../../../../../theme/assets/img/icon-arrow-right.svg';
import { resolveSheetColor, SheetType } from '../../../../../sheet';

/**
 * history simple link template component
 * @param {object} props
 * @param {object} props.task
 * @param {object} props.className
 * @param {boolean} props.passed
 * @returns {JSX.Element}
 */
const HistorySimpleLinkTaskView = ({ className, task, passed }) => {
	const { content } = task;
	const { t } = useTranslation();

	const initClassName = (baseClassName) =>
		classNames(baseClassName, className, {
			[`${baseClassName}--step`]: content?.type === 'step',
			[`${baseClassName}--sheet`]: content?.type === 'sheet',
			[`${baseClassName}--other-sheet`]: content?.type === 'other-sheet',
			[`${baseClassName}--passed`]: passed,
		});

	/**
	 * Get the simpleLink sheet info depending on simpleLink type
	 * @param taskToAnalyse
	 * @return {{sheetNumber: String, sheetTitle: String, sheetType: string, binderColor: String}}
	 */
	const getSheetInfo = (taskToAnalyse) => {
		if (taskToAnalyse?.content?.type === 'sheet') {
			return {
				sheetType: taskToAnalyse?.content?.sheetType,
				sheetNumber: taskToAnalyse?.content?.sheetNumber,
				sheetTitle: '',
				binderColor: taskToAnalyse?.binder?.color,
				sheetColor: taskToAnalyse?.content?.sheetColor,
			};
		}
		if (taskToAnalyse?.content?.type === 'other-sheet') {
			return {
				sheetType: taskToAnalyse?.content?.sheetType,
				sheetNumber: taskToAnalyse?.content?.sheetNumber,
				sheetTitle: '',
				binderColor: taskToAnalyse?.binder?.color,
				sheetColor: taskToAnalyse?.content?.sheetColor,
			};
		}
	};

	const renderSheetLink = () => {
		const sheetInfo = getSheetInfo(task);
		if (sheetInfo) {
			const { sheetType, sheetNumber, sheetColor, binderColor, sheetTitle } = sheetInfo || {};

			return (
				<>
					<IconWrapper
						className="history-simple-link-template-view__arrow"
						Component={IconArrowLongRight}
					/>
					<SheetType
						className="history-simple-link-template-view__sheet-number"
						type={sheetType}
						sheetNumber={sheetNumber}
						color={resolveSheetColor(sheetColor, binderColor)}
					/>
					<span className="history-simple-link-template-view__sheet-title">{sheetTitle}</span>
				</>
			);
		}
		return false;
	};

	return (
		<div className={initClassName('history-simple-link-phase-view')}>
			{content?.type === 'step' && (
				<>
					<IconWrapper
						className="history-simple-link-template-view__icon"
						Component={IconArrowRight}
					/>
					<span className="history-simple-link-phase-view__text">
						{`${t('sheet:sheet-details.template.simpleLink.step')} ${content.stepNumber}`}
					</span>
				</>
			)}
			{(content?.type === 'sheet' || content?.type === 'other-sheet') && renderSheetLink()}
		</div>
	);
};

export default HistorySimpleLinkTaskView;

HistorySimpleLinkTaskView.propTypes = {
	task: PropTypes.shape({
		content: PropTypes.shape({
			type: PropTypes.string,
			stepNumber: PropTypes.number,
			sheetNumber: PropTypes.string,
			sheetTitle: PropTypes.string,
			sheetColor: PropTypes.string,
			binderColor: PropTypes.string,
			sheetType: PropTypes.string,
		}),
		binder: PropTypes.shape({
			color: PropTypes.string,
		}),
		sheet: PropTypes.shape({
			color: PropTypes.string,
		}),
	}),
	className: PropTypes.string,
	passed: PropTypes.bool,
};
