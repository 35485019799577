import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './spinner.scss';

import { IconWrapper } from '../../';
import { ReactComponent as IconSpinner } from '../../assets/img/icon-spinner.svg';

const Spinner = ({ className }) => {
	return <IconWrapper className={classNames('spinner', className)} Component={IconSpinner} />;
};

export default Spinner;

Spinner.propTypes = {
	className: PropTypes.string,
};
