import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './pcc-instruction-template-view.scss';

import { useDndSheetReference } from '../../../../../../../../shared';
import TemplateWrapper from '../../template-wrapper/template-wrapper';

/**
 * PccInstruction template view
 * @param {object} props
 * @param {object} props.template
 * @param {boolean} props.allowInsertAfter
 * @param {function} props.moveCard
 * @param {boolean} [props.isSubNode = false]
 * @param {number} props.currentPosition
 * @param {object} props.location
 * @param {object} props.processCurrentTemplate
 * @returns {JSX.Element}
 */
const PccInstructionTemplateView = ({
	template,
	allowInsertAfter,
	moveCard,
	isSubNode = false,
	currentPosition,
	location,
	processCurrentTemplate,
}) => {
	const { t } = useTranslation();
	const ref = useRef(null);
	const { drag, isDragging } = useDndSheetReference(template, moveCard, ref, isSubNode);

	return (
		<TemplateWrapper
			id={template.id}
			className="pcc-instruction-template"
			reference={ref}
			isDragging={isDragging}
			dragRef={drag}
			template={template}
			location={location}
			allowInsertAfter={allowInsertAfter}
			currentPosition={currentPosition}
			processCurrentTemplate={processCurrentTemplate}
		>
			<div className="pcc-instruction-template__title">
				{t('sheet:sheet-details.template.pccInstruction.label')}
			</div>
		</TemplateWrapper>
	);
};

export default PccInstructionTemplateView;

PccInstructionTemplateView.propTypes = {
	template: PropTypes.object,
	currentPosition: PropTypes.number,
	processCurrentTemplate: PropTypes.object,
	allowInsertAfter: PropTypes.bool,
	moveCard: PropTypes.any,
	location: PropTypes.object,
	isSubNode: PropTypes.bool,
};
