import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './spr-page.scss';

import { SprHeader } from '../../../../shared';
import { getSprStorageContent } from '../../../../shared/context/spr-context/spr-context-localstorage-utils';
import BorderOverlay from '../../border-overlay/border-overlay';

/**
 * Spr page wrapper component
 * @param {object} props
 * @param {string} [props.className]
 * @param {string} [props.classNameHeader]
 * @param {object} props.children
 * @param {object} [props.subheader]
 * @param {boolean} [props.allowLineSwitch]
 * @param {boolean} [props.binderAbbreviationNotVisible]
 * @param {boolean} [props.mainInfosNotVisible]
 * @param {boolean} [props.displayHeader=true]
 * @param {string[]} [props.borderOverlayType]
 * @param {boolean} [props.disabledLineSwitch=false]
 * @return {JSX.Element}
 * @constructor
 */
const SprPage = ({
	className,
	classNameHeader,
	children,
	subheader,
	allowLineSwitch = false,
	binderAbbreviationNotVisible,
	mainInfosNotVisible,
	displayHeader = true,
	borderOverlayType = [],
	disabledLineSwitch = false,
}) => {
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		const { associatedLine, side } = getSprStorageContent() || {};
		if (location.pathname !== '/' && (!associatedLine || !side)) {
			history.push('/');
		}
	}, [history, location]);

	const headerRef = useRef(null);
	return (
		<>
			{borderOverlayType.length > 0 && (
				<BorderOverlay
					height={window?.innerHeight - headerRef?.current?.clientHeight || 0}
					top={headerRef?.current?.clientHeight || 0}
					types={borderOverlayType}
				/>
			)}
			<main className="spr-page">
				{displayHeader && (
					<header
						ref={headerRef}
						className={classnames(
							'spr-page__header',
							{ 'spr-page__header--shadow': !mainInfosNotVisible },
							classNameHeader
						)}
					>
						<SprHeader
							binderAbbreviationNotVisible={binderAbbreviationNotVisible}
							mainInfosNotVisible={mainInfosNotVisible}
							allowLineSwitch={allowLineSwitch}
							disabledLineSwitch={disabledLineSwitch}
						/>
						{subheader}
					</header>
				)}
				<div className={classnames('spr-page__content', className)}>{children}</div>
			</main>
		</>
	);
};

export default SprPage;

SprPage.propTypes = {
	className: PropTypes.string,
	classNameHeader: PropTypes.string,
	children: PropTypes.node,
	subheader: PropTypes.node,
	allowLineSwitch: PropTypes.bool,
	binderAbbreviationNotVisible: PropTypes.bool,
	mainInfosNotVisible: PropTypes.bool,
	displayHeader: PropTypes.bool,
	borderOverlayType: PropTypes.arrayOf(PropTypes.string),
	disabledLineSwitch: PropTypes.bool,
};
