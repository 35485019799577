import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import '../binder-sheet-list-content/binder-sheet-list-content.scss';

import { formatJsonDate } from '../../../../../../../../shared';
import UserNameLabel from '../../../../../../../../shared/components/user-name-label/user-name-label';
import { EditorRendering, IconWrapper } from '../../../../../../../../theme';
import { IconPathCircle } from '../../../../../../../../theme/assets/img';
import { resolveSheetColor, SheetStatus, SheetType } from '../../../../../../../sheet';
import DeleteSheetButton from '../delete-sheet-button/delete-sheet-button';

/**
 * Render the a sheet list on the binder detail page
 * @param {object} props
 * @param {object[]} props.sheets
 * @param {object} props.analysisResult
 * @param {object} props.binder
 * @param {boolean} props.loading
 * @param {function} props.showCreateSheetPopup
 * @param {function} props.refreshSheetList
 * @returns {JSX.Element}
 */

const SheetListItem = ({ sheet, binder, defaultColor, analysisResult, refreshSheetList }) => {
	const isBinderWIP = binder?.status === 'work-in-progress';
	const endOfUrl = isBinderWIP ? '/edit' : '';
	const sheetLink = `/binders/${binder?.tech_id}/sheet/${sheet?.sheet_id}${endOfUrl}`;
	const color = resolveSheetColor(sheet.color, defaultColor);

	const { t } = useTranslation();

	const history = useHistory();
	return (
		<li
			key={sheet.number}
			className="list__row"
			onClick={sheetLink ? () => history.push(sheetLink) : () => {}}
		>
			<Link to={sheetLink} className="list__row__link">
				<SheetType
					className="list__cell list__cell--number"
					type={sheet.type}
					sheetNumber={sheet.number}
					color={resolveSheetColor(sheet.color, defaultColor)}
					binderType={binder?.type}
				/>
				<EditorRendering
					content={sheet.title}
					className="list__cell"
					noStyle
					sheetNumber={sheet.number}
					color={color}
				/>
				<span className="list__cell list__cell--status">
					{sheet.analysis_result && (
						<IconWrapper
							className={`list__cell--icon list__cell_analyse--${sheet.analysis_result}`}
							Component={IconPathCircle}
							tooltip={t(`binder:binder-detail.tooltip.analysis.${sheet.analysis_result}`)}
						/>
					)}
				</span>
				<SheetStatus
					className="list__cell list__cell--status"
					warning={analysisResult?.sheetsWarning?.find(
						(resultSheet) => resultSheet === sheet.sheet_id
					)}
					tooltip={t('binder:binder-detail.tooltip.status-warning')}
					status={sheet.status}
				/>
				<span className="list__cell">
					{formatJsonDate(sheet.last_change_content_at, 'dd/MM/yy')}
					{sheet.editor && (
						<span className="list__cell__tooltip">
							<UserNameLabel
								className="list__cell__tooltip__text"
								user={{
									firstname: sheet.editor_firstname,
									lastname: sheet.editor_lastname,
									id: sheet.editor_id,
								}}
							/>
						</span>
					)}
				</span>
			</Link>
			{isBinderWIP && (
				<span className="list__cell action__wrapper" onClick={(e) => e.stopPropagation()}>
					<DeleteSheetButton
						className="action__icon"
						sheet={sheet}
						onDelete={refreshSheetList}
						defaultColor={defaultColor}
					/>
				</span>
			)}
		</li>
	);
};

export default SheetListItem;

SheetListItem.propTypes = {
	analysisResult: PropTypes.shape({
		sheetsWarning: PropTypes.arrayOf(PropTypes.string),
	}),
	refreshSheetList: PropTypes.func,
	sheet: PropTypes.shape({
		status: PropTypes.string,
		editor: PropTypes.string,
		editor_firstname: PropTypes.string,
		editor_id: PropTypes.string,
		editor_lastname: PropTypes.string,
		analysis_result: PropTypes.string,
		number: PropTypes.string,
		last_change_content_at: PropTypes.string,
		sheet_id: PropTypes.string,
		type: PropTypes.string,
		color: PropTypes.string,
		title: PropTypes.object,
	}),
	binder: PropTypes.shape({
		type: PropTypes.string,
		status: PropTypes.string,
		tech_id: PropTypes.string,
	}),
	defaultColor: PropTypes.string,
};
