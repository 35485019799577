import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './resume-conditions-drawer.scss';

import { formatJsonDate } from '../../../../../../shared';
import { Drawer } from '../../../../../../theme';
import { dateFormatTime } from '../../../../utils';

const InfoLine = ({ title, info }) => (
	<div className="resume-conditions-drawer__info-line">
		<span className="resume-conditions-drawer__info-line__title">{title}</span>
		<span className="resume-conditions-drawer__info-line__info">{info}</span>
	</div>
);

InfoLine.propTypes = {
	title: PropTypes.string.isRequired,
	info: PropTypes.string.isRequired,
};

/**
/**
 * ResumeConditionsDrawer component
 * @param {object} props
 * @param {{ position_supply: 'normal' | 'direct', engine_units: number, bogie_car_units: number, resume: 'travelers' | 'hlp' | 'secours', last_update: Date }} props.resumeConditions
 * @returns {JSX.Element}
 */
const ResumeConditionsDrawer = ({ resumeConditions, brakeSystem }) => {
	const { position_supply, engine_units, bogie_car_units, resume, last_update } =
		resumeConditions || {};
	const { t } = useTranslation();

	return (
		<Drawer
			drawerStatus={true}
			className="resume-conditions-drawer"
			leftText={t('process:history-view.resume-conditions.title')}
			rightText={formatJsonDate(last_update, dateFormatTime)}
		>
			<InfoLine
				info={position_supply}
				title={t('process:history-view.resume-conditions.position-supply')}
			/>
			<InfoLine
				info={engine_units}
				title={t('process:history-view.resume-conditions.engine-units')}
			/>
			<InfoLine
				info={bogie_car_units}
				title={t(`process:history-view.resume-conditions.${brakeSystem}-units`)}
			/>
			<div className="resume-condition-drawer__resume">
				<span className="resume-condition-drawer__resume__title">
					{t('process:history-view.resume-conditions.resume')}
				</span>
				<span className="resume-condition-drawer__resume__info">{resume}</span>
			</div>
		</Drawer>
	);
};

ResumeConditionsDrawer.propTypes = {
	resumeConditions: PropTypes.shape({
		position_supply: PropTypes.oneOf(['normal', 'direct']).isRequired,
		resume: PropTypes.oneOf(['travelers', 'hlp', 'secours']).isRequired,
		engine_units: PropTypes.number.isRequired,
		bogie_car_units: PropTypes.number.isRequired,
		last_update: PropTypes.object.isRequired,
	}).isRequired,
	brakeSystem: PropTypes.string.isRequired,
};

export default memo(ResumeConditionsDrawer);
