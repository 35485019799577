import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './line-selector.scss';

import {
	iconLines,
	iconLinesDisabled,
} from '../../../../domains/user/utils/associated-line-icon-utils/associated-line-icon.utils';
import { setupLineScopeAllowed } from '../../../../domains/user/utils/user-associated-line-scope';
import { useAppSetupContext } from '../../../../shared';

import AssociatedLinesCheckbox from './components/associated-lines-checkbox/associated-lines-checkbox';

/**
 * Render a field which lets you select one or more line
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.name
 * @param {function} props.onChange
 * @param {number[]} [props.value=[]]
 * @param {string[]} [props.lineList]
 * @param {boolean} props.disabled
 * @returns {JSX.Element}
 */
const LineSelector = (props) => {
	const { user } = useAppSetupContext();
	const userLines = setupLineScopeAllowed(user);
	const { className, name, onChange, value = [], lineList = userLines, disabled = false } = props;

	const handleChange = (event) => {
		const { value: selectedLine, checked } = event.target;

		const newValue = [...value, selectedLine]
			// remove value if not checked
			.filter((line) => checked || line !== selectedLine);

		onChange({ target: { name, value: newValue } });
	};

	return (
		<ul className={classnames('line-selector', className)}>
			{lineList.map((line) => (
				<li className="line-selector__line" key={line}>
					<AssociatedLinesCheckbox
						line={line}
						icon={iconLines[line]}
						iconDisabled={iconLinesDisabled[line]}
						checked={value?.includes(line) || false}
						handleCheckboxChange={handleChange}
						disabled={disabled}
					/>
				</li>
			))}
		</ul>
	);
};

export default LineSelector;

LineSelector.propTypes = {
	className: PropTypes.string,
	value: PropTypes.arrayOf(PropTypes.number),
	name: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	lineList: PropTypes.arrayOf(PropTypes.number),
	disabled: PropTypes.bool,
};
