import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './binder-man-sheet-list.scss';

import { usePopup } from '../../../../../../../../../shared';
import { Button, SearchBar } from '../../../../../../../../../theme';
import { IconPlus } from '../../../../../../../assets';
import { listAllBinderSignal } from '../../../../../../../binder-signal.services';
import BinderDetailSignal from '../../binder-detail-signal/binder-detail-signal';
import BinderInitialSheet from '../../binder-initial-sheet/binder-initial-sheet';
import { PopupCreateInitialSheet, PopupCreateSignal } from '../../popup';

/**
 * Render the sheet list of a binder of type man
 * @param {object} props
 * @param {object[]} props.sheets
 * @param {object} props.binder
 * @param {function} props.search
 * @param {function} props.onSearch
 * @param {function} props.refreshSheetList
 * @param {object} props.analysisResult
 * @returns {JSX.Element}
 */
const BinderManSheetList = ({
	sheets = [],
	binder,
	search,
	onSearch,
	analysisResult,
	refreshSheetList,
}) => {
	const [signalList, setSignalList] = useState([]);
	const { t } = useTranslation();

	const popupCreateSignalControl = usePopup();
	const popupCreateInitialSheetControl = usePopup();

	const hasSignals = Array.isArray(signalList) && signalList?.length > 0;

	const getBinderSignal = () => {
		if (binder?.tech_id) {
			listAllBinderSignal(binder.tech_id).then((response) => {
				setSignalList(response.data);
			});
		}
	};
	const renderSignal = (signal) => {
		const signalSheets = sheets.filter((sheet) => sheet?.signal_tech_id === signal?.tech_id);
		return (
			<li className="binder-man-sheet-list__signal" key={signal?.tech_id}>
				<BinderDetailSignal
					analysisResult={analysisResult}
					binder={binder}
					signal={signal}
					signalList={signalList}
					sheets={signalSheets}
					refreshSheetList={refreshSheetList}
					refreshSignalList={getBinderSignal}
				/>
			</li>
		);
	};
	const initialSheet = sheets.filter((sheet) => sheet?.type === 'initial');

	useEffect(getBinderSignal, [binder?.tech_id]);

	return (
		<div className="binder-man-sheet-list">
			<PopupCreateSignal
				popupControl={popupCreateSignalControl}
				binderTechId={binder?.tech_id}
				onCreate={getBinderSignal}
				signalList={signalList}
			/>
			<div className="binder-man-sheet-list__head">
				<h3 className="binder-man-sheet-list__title">{t('binder:binder-detail.man-list.title')}</h3>
				<span className="binder-man-sheet-list__iteration">
					{t('binder:binder-detail.sheet-list.iteration', {
						number: binder?.iteration,
					})}
				</span>
				{hasSignals && (
					<div className="binder-man-sheet-list__actions">
						<SearchBar
							className="actions__search"
							value={search}
							onChange={onSearch}
							placeholder={t('binder:binder-detail.sheet-list.search-bar-placeholder')}
						/>
						<Button
							className="actions__add-signal"
							variant="primary"
							onClick={popupCreateSignalControl.show}
							disabled={binder?.status !== 'work-in-progress'}
							icon={IconPlus}
							label={t('binder:binder-detail.man-list.create-signal')}
						/>
					</div>
				)}
			</div>
			<div className="binder-sheet-component">
				<BinderInitialSheet
					initialSheet={initialSheet}
					binder={binder}
					analysisResult={analysisResult}
					onClick={popupCreateInitialSheetControl.show}
					refreshSheetList={refreshSheetList}
				/>
				<PopupCreateInitialSheet
					binder={binder}
					onCreate={refreshSheetList}
					popupControl={popupCreateInitialSheetControl}
				/>
				{hasSignals && (
					<ul className="binder-man-sheet-list__signals">{signalList.map(renderSignal)}</ul>
				)}
				{!hasSignals && (
					<div className="binder-man-sheet-list__no-signal">
						<span className="no-signal__text">
							{t('binder:binder-detail.man-list.create-first-signal', {
								id: binder?.id?.toUpperCase(),
							})}
						</span>
						<Button
							className="actions__add-signal"
							variant="primary"
							onClick={popupCreateSignalControl.show}
							disabled={binder?.status !== 'work-in-progress'}
							icon={IconPlus}
							label={t('binder:binder-detail.man-list.create-signal')}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default BinderManSheetList;

BinderManSheetList.propTypes = {
	sheets: PropTypes.arrayOf(
		PropTypes.shape({
			number: PropTypes.string.isRequired,
			sheet_id: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired,
			color: PropTypes.string.isRequired,
			title: PropTypes.object.isRequired,
			analysis_result: PropTypes.string,
			status: PropTypes.string.isRequired,
			last_change_content_at: PropTypes.string.isRequired,
			editor: PropTypes.string,
			editor_firstname: PropTypes.string,
			editor_lastname: PropTypes.string,
			editor_id: PropTypes.string,
		})
	),
	binder: PropTypes.shape({
		status: PropTypes.string.isRequired,
		tech_id: PropTypes.string.isRequired,
		iteration: PropTypes.number,
		id: PropTypes.string,
	}),
	search: PropTypes.string.isRequired,
	onSearch: PropTypes.func.isRequired,
	refreshSheetList: PropTypes.func.isRequired,
	analysisResult: PropTypes.shape({
		sheetsWarning: PropTypes.arrayOf(PropTypes.string),
	}),
};
