import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-abort-process.scss';

import { PopupConfirm } from '../../../../../../theme';

const PopupAbortProcess = ({ popupControl, onConfirm }) => {
	const { t } = useTranslation();

	return (
		<PopupConfirm
			popupControl={popupControl}
			title={t('process:popup.abort-process.title')}
			onConfirm={onConfirm}
		>
			<div className="popup-abort-process">
				<p className="popup-abort-process__text">{t('process:popup.abort-process.text')}</p>
			</div>
		</PopupConfirm>
	);
};

export default PopupAbortProcess;

PopupAbortProcess.propTypes = {
	popupControl: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired,
};
